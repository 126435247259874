import React, {useMemo} from 'react'
import {Grid, Typography, withStyles} from '@material-ui/core'
import Select from 'react-select'

import {styles, selectStyles} from './styles'

const AudienceCountrySelector = ({classes, audienceLocales, setSelectedLocale, selectedLocale}) => {
  const localeValuesAndLabels = useMemo(() => {
    return audienceLocales.map(locale => ({
      value: locale.code,
      label: locale.name,
      lucidId: locale.lucidId,
    }))
  }, [])

  return (
    <Grid className={classes.countrySelectorContainer}>
      <Typography className={classes.label}>Language & Country</Typography>
      <Select
        components={{
          IndicatorSeparator: () => null,
          ClearIndicator: () => null,
        }}
        options={localeValuesAndLabels}
        value={selectedLocale}
        onChange={setSelectedLocale}
        styles={selectStyles}
      />
    </Grid>
  )
}

export default withStyles(styles)(AudienceCountrySelector)
