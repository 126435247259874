import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import {connect} from 'react-redux'
import _ from 'lodash'
import Helmet from 'react-helmet'
import {bindActionCreators, compose} from 'redux'
import {action as toggleMenu} from 'redux-burger-menu'
import TagManager from 'react-gtm-module'
import Header from './common/Header.jsx'
import Sidebar from './common/Sidebar.jsx'
import NotificationContainer from './common/NotificationContainer.jsx'
import {getProfile} from './profile/redux/actions'
import UserSession from './auth/UserSession'
import ImageUtils from './utils/ImageUtils'
import PageUtils from './utils/PageUtils'
import {GridContainerPage, GridItemPage} from './App.styles.js'
import {logout} from './auth/redux/actions'
import LoginHeader from './common/LoginHeader.jsx'
import Footer from './common/Footer.jsx'

import settings from '../../config/settings.json'

const oldHeaderLocations = ['login', 'signup', 'forgot', 'forgot-password']
const footerLocations = ['login', 'signup', 'forgot', 'forgot-password']
const sidebarlessLocations = ['login', 'signup', 'forgot', 'forgot-password']

class App extends React.Component {
  constructor() {
    super()

    this.state = {
      showOldHeader: true,
      showFooter: true,
      showSidebar: false,
      sidebarWidth: 256,
      isSidebarVisible: true,
    }

    this.toggleSidebar = this.toggleSidebar.bind(this)
  }

  componentDidMount() {
    if (UserSession.getToken() && !this.props.profile) {
      this.props.getProfile()
    }
    this.setHeaderFooterSidebarState(this.props.location)

    // Remove the server-side injected CSS.
    const jssStyles = document.getElementById('jss-server-side')
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles)
    }

    TagManager.initialize({
      gtmId: settings.googleTagManagerKey,
    })

    const ReactPixel = require('react-facebook-pixel').default
    ReactPixel.init('2391342581131135')
  }

  componentWillReceiveProps(nextProps) {
    const hasLocationChanged =
      _.get(nextProps, 'location.pathname') !== _.get(this.props, 'location.pathname')
    if (hasLocationChanged) {
      this.setHeaderFooterSidebarState(nextProps.location)
    }
  }

  setHeaderFooterSidebarState(location) {
    const showFooter = footerLocations.indexOf(location.pathname.split('/')[1]) >= 0
    const showSidebar = sidebarlessLocations.indexOf(location.pathname.split('/')[1]) < 0
    const showOldHeader = oldHeaderLocations.indexOf(location.pathname.split('/')[1]) >= 0

    if (
      showFooter !== this.state.showFooter ||
      showSidebar !== this.state.showSidebar ||
      showOldHeader !== this.state.showOldHeader
    ) {
      this.setState({showFooter, showSidebar, showOldHeader})
    }
  }

  toggleSidebar() {
    this.setState(({isSidebarVisible}) => ({
      isSidebarVisible: !isSidebarVisible,
    }))
  }

  render() {
    const {auth, profile, burgerMenu, location} = this.props
    const {showOldHeader, showSidebar, showFooter, isSidebarVisible, sidebarWidth} = this.state

    return (
      <div id="app">
        <GridContainerPage
          id="appContain"
          className="ba-site"
          container
          direction="column"
          alignItems="center"
          style={{margin: 0}}
        >
          <CssBaseline />
          <Helmet
            defaultTitle={PageUtils.getTitle()}
            titleTemplate={`${PageUtils.getTitle()} - %s`}
            meta={[
              {name: 'twitter:card', content: 'summary'},
              {property: 'og:title', content: PageUtils.getTitle()},
              {property: 'og:site_name', content: PageUtils.getTitle()},
              {property: 'og:image', content: ImageUtils.getAppLogoUrl()},
              {
                property: 'og:description',
                content:
                  'EmotionTrac is a video testing website coupled with a mobile focus group.',
              },
            ]}
          />
          {showOldHeader ? (
            <LoginHeader />
          ) : (
            <Header
              auth={auth}
              profile={profile}
              onHomeClick={() => {}}
              burgerMenu={burgerMenu}
              toggleSidebar={this.toggleSidebar}
              sidebarWidth={sidebarWidth}
              isSidebarVisible={isSidebarVisible}
            />
          )}
          {showSidebar ? (
            <Sidebar
              location={location}
              isSidebarVisible={isSidebarVisible}
              sidebarWidth={sidebarWidth}
            />
          ) : null}
          <GridItemPage
            container
            justify="center"
            id="main-page"
            style={{
              paddingTop: 80,
              paddingLeft: `${isSidebarVisible && showSidebar ? sidebarWidth : 0}px`,
              flexGrow: 1,
              transition: 'padding-left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
            }}
          >
            {this.props.children}
          </GridItemPage>
          {showFooter ? <Footer /> : null}
          <NotificationContainer />
        </GridContainerPage>
      </div>
    )
  }
}

App.displayName = 'App'

function mapStateToProps(state) {
  return {
    auth: state.auth,
    burgerMenu: state.burgerMenu,
    profile: _.get(state.profile, 'profile', null),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({toggleMenu, logout, getProfile}, dispatch)
}

// Wrap the component to inject dispatch and state into it
export default compose(connect(mapStateToProps, mapDispatchToProps))(App)
