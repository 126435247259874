const styles = () => ({
  chartContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    '& .apexcharts-series-markers': {
      display: 'none',
    },
    '& .apexcharts-xaxistooltip': {
      padding: 0,
      borderWidth: 0,
      borderRadius: 8,
      background: '#028C21',
      color: '#fff',
      '& div': {
        fontSize: '11px !important',
        padding: 2,
      },
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
    '& .apexcharts-xaxistooltip.apexcharts-active': {
      opacity: 1,
      transition: '0.15s ease opacity',
    },
    '& .apexcharts-legend-text': {
      cursor: 'pointer',
    },
  },
  rapportChartContainer: {
    '& .apexcharts-legend': {
      flexDirection: 'row-reverse',
      justifyContent: 'end !important',
    },
  },
  trendLineLegend: {
    alignSelf: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    corsor: 'pointer',
    position: 'relative',
    zIndex: 1,
    '& p': {
      cursor: 'default',
    },
  },
  line: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    background: '#9d9d9d',
    marginRight: '3px',
  },
  modalGroupName: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '33px',
    color: '#262C38',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
  },
  modalDemographic: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#131822',
    marginBottom: '24px',
    textTransform: 'capitalize',
  },
  modalSubdemographicsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  modalSubdemographicsBulletPoint: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: '#76B9FE',
    marginRight: '8px',
  },
  modalGroupNameBulletPoint: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: '#F44336',
    marginRight: '12px',
  },
  modalSubdemographics: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#131822',
    marginRight: '24px',
    textTransform: 'capitalize',
  },
  legendsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '16px',
  },
  meanLineTextContainer: {
    display: 'flex',
    marginTop: '24px',
    alignItems: 'center',
  },
  helpIcon: {
    color: '#017EFF',
    marginRight: '4px',
    width: '15px',
    height: '15px',
  },
  meanLineText: {
    fontSize: '14px',
    lineHeight: '14px',
  },
})

export default styles
