import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import Loading from '../../common/Loading'
import CreativeConclusion from './CreativeConclusion'
import {getCampaign, getDemographicsWithValues} from '../redux/actions'
import utils from '../utils'

function Conclusions(props) {
  const {getCampaign, getDemographicsWithValues, campaign, params, router} = props

  useEffect(() => {
    if (campaign.campaign.id !== Number(params.id)) {
      getCampaign(params.id, {expand: 'lists'})
    }
  }, [])

  useEffect(() => {
    if (campaign.campaign.campaignVideos) {
      const {campaignVideoId} = campaign.campaign.campaignVideos[0]

      getDemographicsWithValues(campaignVideoId)
    }
  }, [campaign.campaign])

  if (campaign.isFetching) {
    return <Loading />
  }

  if (utils.isLegalCampaign(campaign.campaign)) {
    router.push(router.location.pathname.replace('conclusions', 'tab-scores'))
    return null
  }

  return <CreativeConclusion params={params} />
}

const mapStateToProps = ({campaign}) => ({
  campaign,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCampaign,
      getDemographicsWithValues,
    },
    dispatch,
  )

export default compose(connect(mapStateToProps, mapDispatchToProps))(Conclusions)
