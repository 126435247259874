const styles = theme => ({
  objectivesMomentsContainer: {
    width: '100%',
    height: 'min-content',
    padding: theme.spacing.unit * 4,
  },
  objectivesMomentsPaper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4,
    padding: theme.spacing.unit * 3,
  },
  header: {
    alignItems: 'center',
    '& h5': {
      fontWeight: 500,
    },
  },
  button: {
    marginLeft: 'auto',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 5}px`,
  },
  sectionTitle: {
    fontWeight: 500,
    fontSize: '1.1rem',
    marginRight: '16px',
  },
  sectionSubtitle: {
    fontWeight: 500,
    fontSize: '0.9rem',
  },
  objectiveContainer: {
    alignItems: 'center',
    textTransform: 'capitalize',
    padding: `0 ${theme.spacing.unit}px`,
  },
  caseTypesSelect: {
    margin: `${theme.spacing.unit * 3} 0 ${theme.spacing.unit * 5}`,
    width: '32ch',
  },
  notchedOutline: {
    borderColor: `${theme.palette.border ? theme.palette.border.main : 'initial'} !important`,
  },
  errorOutline: {
    borderColor: `${theme.palette.secondary.main} !important`,
  },
  selectIcon: {
    marginRight: theme.spacing.unit * 3,
  },
  selectMenu: {
    maxHeight: '500px',
  },
  warningModal: {
    padding: theme.spacing.unit * 4,
  },
  warningTitle: {
    fontWeight: 500,
    fontSize: '1.5rem',
  },
  warningText: {
    fontSize: '1.1rem',
    margin: `${theme.spacing.unit * 4} 0`,
  },
  warningButtonsContainer: {
    gridGap: `${theme.spacing.unit * 2}px`,
    '& > button': {
      flexGrow: 1,
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
    },
  },
  emptyObjectivesContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 4,
    paddingBottom: theme.spacing.unit * 4,
    gridGap: `${theme.spacing.unit}px`,
    background: theme.palette.grey[50],
    '& h6': {
      fontWeight: 500,
      color: theme.palette.grey[800],
    },
    '& p': {
      color: theme.palette.grey[600],
      fontSize: '1rem',
      width: '52ch',
      textAlign: 'center',
    },
  },
  awardCompensationQuestion: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#333333',
    opacity: 0.9,
  },
  awardCompensationError: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#F44336',
    opacity: 0.9,
  },
  answerLabel: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '15px',
    letterSpacing: '-0.02em',
    color: '#292D32',
    opacity: 0.8,
    marginBottom: '8px',
  },
  awardAnswerContainer: {
    display: 'flex',
    gap: '12px',
    padding: '24px 0 12px 0',
    flexWrap: 'wrap',
  },
})

export default styles
