import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import _ from 'lodash'

import {Grid, Paper, Typography, withStyles} from '@material-ui/core'

import Loading from '../common/Loading'
import InfoButton from '../common/InfoButton'
import InfoModal from '../common/InfoModal'
import DemographicKeyDropdownButton from '../common/DemographicKeyDropdownButton'
import TestDetailHeader from '../common/TestDetailHeader'
import ExportButton from '../common/ExportButton'
import ExportSnapshotButton from '../common/ExportSnapshotButton'
import NoDataAvailable from '../common/NoDataAvailable'
import SurveyResultsOpenEndedAnswers from './SurveyResultsOpenEndedAnswers'
import SurveyResultsMultipleChoiceAnswers from './SurveyResultsMultipleChoiceAnswers'
import SurveyResultsQuestionDropdown from './SurveyResultsQuestionDropdown'
import SurveyResultsGenderSwitch from './SurveyResultsGenderSwitch'

import {
  getCampaign,
  getDemographics,
  getDemographicsWithValues,
  getSurveyResults,
} from './redux/actions'

import {textModalKey, videoModalKey} from '../constants/ModalKeys'

import infoIcon from '../../assets/icons/info.svg'
import tutorialIcon from '../../assets/icons/tutorial.svg'

import styles from './styles/TabScores.styles'

function SurveyResults(props) {
  const [textModalOpen, setTextModalOpen] = useState(false)
  const [videoModalOpen, setVideoModalOpen] = useState(false)
  const [hasFetchedDemographics, setHasFetchedDemographics] = useState(false)
  const [hasFetchedDemographicsWithValues, setHasFetchedDemographicsWithValues] = useState(false)
  const [showingMultipleChoiceAnswer, setShowingMultipleChoiceAnswer] = useState(true)
  const [currentQuestion, setCurrentQuestion] = useState('')
  const [filterQuery, setFilterQuery] = useState('')
  const [selectedGender, setSelectedGender] = useState('all')
  const [dropDownQuestions, setDropDownQuestions] = useState([])

  const {
    campaign,
    classes,
    demographics,
    demographicsWithValues,
    getDemographicsWithValues,
    getCampaign,
    getDemographics,
    getSurveyResults,
    params,
  } = props

  useEffect(() => {
    getCampaign(params.id, {expand: 'lists'})
    checkFirstTimeVisit()
  }, [])

  useEffect(() => {
    if (campaign.campaign.id === Number(params.id)) {
      const {campaignVideoId} = campaign.campaign.campaignVideos[0]

      if (!hasFetchedDemographics) {
        getDemographics(campaignVideoId)
        setHasFetchedDemographics(true)
      }

      if (!hasFetchedDemographicsWithValues) {
        getDemographicsWithValues(campaignVideoId)
        setHasFetchedDemographicsWithValues(true)
      }
    }
  }, [campaign.campaign])

  useEffect(() => {
    if (campaign.campaign.surveyQuestionsDetail) {
      const mcqQuestions = campaign.campaign.surveyQuestionsDetail.filter(question =>
        showingMultipleChoiceAnswer ? question.type === 0 : question.type === 1,
      )
      setDropDownQuestions(mcqQuestions)
      if (mcqQuestions.length) setCurrentQuestion(mcqQuestions[0].question)
    }
  }, [campaign.campaign.surveyQuestionsDetail, showingMultipleChoiceAnswer])

  useEffect(() => {
    const campId = campaign.campaign.id
    if (!_.isEmpty(currentQuestion)) {
      getSurveyResults(campId, {
        question: currentQuestion,
        demographic: filterQuery,
        gender: selectedGender,
      })
    }
  }, [currentQuestion])

  useEffect(() => {
    const campId = campaign.campaign.id
    if (!_.isEmpty(currentQuestion)) {
      getSurveyResults(campId, {
        question: currentQuestion,
        demographic: filterQuery,
        gender: selectedGender,
      })
    }
  }, [selectedGender])

  const modalTable = {
    [textModalKey]: value => setTextModalOpen(value),
    [videoModalKey]: value => setVideoModalOpen(value),
  }

  const openModal = modalKey => {
    modalTable[modalKey](true)
  }

  const closeModal = modalKey => {
    modalTable[modalKey](false)
  }

  const checkFirstTimeVisit = () => {
    const hasVisited = window.sessionStorage.getItem('hasVisited-survey-results')

    if (!hasVisited) {
      window.sessionStorage.setItem('hasVisited-survey-results', true)
    } else {
      setTextModalOpen(false)
    }
  }

  const renderModals = () => {
    return (
      <>
        <InfoModal open={textModalOpen} onClose={() => closeModal(textModalKey)}>
          <>
            <Typography variant="h4">Survey Questions Explained</Typography>
            <Typography variant="body1">
              Click &ldquo;Clear Results&ldquo; to return to the male versus female responses.{' '}
              <br />
              <br /> Toggle between male and female to see results after submitting demographics
              from the &ldquo;Compare&ldquo; button.
              <br />
              <br />
              Refer to the color key when reviewing your responses based on the demographics you
              selected to compare.
              <br />
              <br />
            </Typography>
          </>
        </InfoModal>
        <InfoModal open={videoModalOpen} onClose={() => closeModal(videoModalKey)}>
          <>
            <Typography variant="h4">Survey Questions Explained</Typography>
            <iframe
              width="750"
              height="480"
              src="https://www.youtube.com/embed/UY-vnbnsaDc?&autoplay=1"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullscreen
            />
          </>
        </InfoModal>
      </>
    )
  }

  const renderData = () => {
    if (showingMultipleChoiceAnswer) {
      return (
        <SurveyResultsMultipleChoiceAnswers
          campaign={campaign}
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
          demographicsWithValues={demographicsWithValues}
          responseCount={getResponseCount()}
        />
      )
    }

    return (
      <SurveyResultsOpenEndedAnswers
        campaign={campaign}
        currentQuestion={currentQuestion}
        selectedGender={selectedGender}
        setCurrentQuestion={setCurrentQuestion}
        responseCount={getResponseCount()}
      />
    )
  }

  const getResponseCount = () => {
    const surveyAnswers = campaign.surveyResults.results.filter(
      answers => answers.question === currentQuestion,
    )
    if (surveyAnswers.length) {
      return surveyAnswers[0].totalResponses
    }
  }

  const getFilteredData = selectedDemographic => {
    const campId = campaign.campaign.id
    setFilterQuery(selectedDemographic)
    if (!_.isEmpty(currentQuestion)) {
      if (selectedDemographic !== '')
        getSurveyResults(campId, {
          question: currentQuestion,
          demographic: selectedDemographic,
          gender: selectedGender,
        })
      else getSurveyResults(campId, {question: currentQuestion, gender: selectedGender})
    }
  }

  return (
    <Grid container className={classes.tabScoresContainer}>
      {renderModals()}
      <TestDetailHeader
        title="Survey Questions"
        responseCount={getResponseCount()}
        isSurvey={true}
        showingMultipleChoiceAnswer={showingMultipleChoiceAnswer}
        setShowingMultipleChoiceAnswer={setShowingMultipleChoiceAnswer}
        isFetching={campaign.surveyResults.isFetching}
      />
      <Paper elevation={0} className={classes.tabScoresPaper}>
        {!campaign.campaign.id ? (
          <Loading />
        ) : (
          <>
            {campaign.campaign.surveyQuestionsDetail ? (
              <>
                <Grid container className={classes.paperHeader} style={{marginBottom: 16}}>
                  <Grid item xs className={classes.paperHeaderTextContainer}>
                    <SurveyResultsQuestionDropdown
                      campId={campaign.campaign.id}
                      dropDownQuestions={dropDownQuestions}
                      currentQuestion={currentQuestion}
                      setCurrentQuestion={setCurrentQuestion}
                      isFetching={campaign.surveyResults.isFetching}
                    />
                  </Grid>
                  <Grid item className={classes.paperButtonContainer}>
                    {showingMultipleChoiceAnswer && (
                      <DemographicKeyDropdownButton
                        demographics={demographics}
                        getFilteredData={getFilteredData}
                        isFetching={campaign.surveyResults.isFetching}
                      />
                    )}
                    <ExportSnapshotButton
                      targetId="survey-results-multiple-choices-chart"
                      exportName="SurveyResults"
                      targetTmpStyle={null}
                    />
                    <ExportButton
                      endpoint={`/campaigns/${campaign.campaign.id}/survey-answer-export-csv`}
                      reportName={`Test_${campaign.campaign.title}_Survey Results`}
                      type="csv"
                    />
                    <div style={{display: 'none'}}>
                      <InfoButton
                        icon={infoIcon}
                        alt="info"
                        onClick={() => openModal(textModalKey)}
                      />
                      <InfoButton
                        icon={tutorialIcon}
                        alt="tutorial"
                        onClick={() => openModal(videoModalKey)}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container className={classes.paperHeader}>
                  {dropDownQuestions.length ? (
                    <Grid item className={classes.paperButtonContainer} style={{paddingTop: 5}}>
                      <SurveyResultsGenderSwitch
                        selectedGender={selectedGender}
                        setSelectedGender={setSelectedGender}
                        isFetching={campaign.surveyResults.isFetching}
                      />
                    </Grid>
                  ) : null}
                </Grid>
                <Grid container className={classes.gaugeList}>
                  {renderData()}
                </Grid>
              </>
            ) : (
              <NoDataAvailable />
            )}
          </>
        )}
      </Paper>
    </Grid>
  )
}

SurveyResults.propTypes = {
  campaign: PropTypes.objectOf(PropTypes.any).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  demographics: PropTypes.arrayOf(PropTypes.object).isRequired,
  demographicsWithValues: PropTypes.objectOf(PropTypes.any).isRequired,
  getCampaign: PropTypes.func.isRequired,
  getDemographics: PropTypes.func.isRequired,
  params: PropTypes.shape({id: PropTypes.string}).isRequired,
}

const mapStateToProps = ({campaign}) => ({
  campaign,
  demographics: campaign.demographics.fields,
  demographicsWithValues: campaign.demographicsWithValues.values,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCampaign,
      getDemographics,
      getSurveyResults,
      getDemographicsWithValues,
    },
    dispatch,
  )

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(SurveyResults)
