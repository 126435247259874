import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Grid, Typography} from '@material-ui/core'
// import Videocam from '@material-ui/icons/Videocam'
// import PlayArrow from '@material-ui/icons/PlayArrow'

const styles = () => ({
  videoCard: {
    position: 'relative',
    cursor: 'pointer',
    background: 'black',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      maxHeight: '225px',
      objectFit: 'cover',
    },
    borderRadius: '4px',
  },
  videoInfo: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px',
    borderRadius: '4px 0px',
    '& p': {
      color: '#FFF',
      fontSize: '0.8rem',
      lineHeight: '0.8rem',
    },
  },
  videoTitle: {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    marginBottom: 12,
  },
})

const TutorialVideoCard = ({classes, title, time, openVideoModal, videoThumb}) => {
  return (
    <Grid item xs={12} md={6} lg={4} xl={3}>
      <Grid className={classes.videoCard} onClick={() => openVideoModal()}>
        <img src={videoThumb} alt="video thumb" />
        <Grid container className={classes.videoInfo}>
          <Typography className={classes.videoTitle}>
            <strong>{title}</strong>
          </Typography>
          <Typography>
            <strong>Length: </strong>
            {time}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(TutorialVideoCard)
