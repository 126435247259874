import React from 'react'
import PropTypes from 'prop-types'

import {colors, options} from '../constants/VerticalCharts'

const VerticalChart = ({xaxis, yaxis, Chart}) => {
  const chartOptions = {
    ...options,
    xaxis: {
      ...options.xaxis,
      categories: xaxis,
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#fff'],
      },
    },
    annotations: {
      position: 'back',
      yaxis: [
        {
          // dashed line
          y: yaxis[0].data[0], // benchmark value
          borderColor: colors[0],
          strokeDashArray: 3,
        },
      ],
    },
  }

  return <Chart options={chartOptions} series={yaxis} type="bar" height={300} width={320} />
}

VerticalChart.propTypes = {
  xaxis: PropTypes.arrayOf(PropTypes.string).isRequired,
  yaxis: PropTypes.arrayOf(PropTypes.object).isRequired,
  Chart: PropTypes.func.isRequired,
}

export default VerticalChart
