import TagManager from 'react-gtm-module'

export const eventTypes = {
  videoUpload: 'video_upload',
  orderSummary: 'order_summary',
  placePurchase: 'place_purchase',
}

// later add more methods or options if necessary
const tagManager = {
  fireEvent: (event, params = {}) => {
    TagManager.dataLayer({
      dataLayer: {
        event,
        ...params,
      },
    })
  },
}

export default tagManager
