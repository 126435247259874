import React, {useEffect, useRef, useState} from 'react'
import ReactDOMServer from 'react-dom/server'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {withStyles} from '@material-ui/core'
import withWidth, {isWidthDown} from '@material-ui/core/withWidth'

import NoLineChartData from '../../common/NoLineChartData'
import LineChartTooltip from '../../common/LineChartTooltip'

import defaultOptions from '../../constants/RapportChart'

import {formatter, getGroupColor, getGroupGender, getTickAmount} from './utils'

import styles from '../styles/LineChart.styles'

const RapportChart = React.memo(props => {
  const {
    classes,
    width,
    eTracRapport,
    eTracRapportByGroup,
    video,
    currentTime,
    isEmpty,
    isPaused,
    seek,
    duration,
    Chart,
  } = props

  if (!Chart || !window) return null

  const hasSelectedGenderColor = useRef()

  const [options, setOptions] = useState({})
  const [totalData, setTotalData] = useState([])
  const [groupSeries, setGroupSeries] = useState([])
  const [xaxis, setXaxis] = useState([])

  const totalDataColor = '#282828'

  useEffect(() => {
    setOptions(defaultOptions(seek))
  }, [])

  useEffect(() => {
    const sum = Object.values(eTracRapport).reduce((a, b) => a + b, 0)
    const rapportData = Object.values(eTracRapport)
    setTotalData(rapportData)

    hasSelectedGenderColor.current = {
      male: false,
      female: false,
    }

    console.groupCollapsed('Rapport Data')
    console.table(eTracRapport)
    console.groupEnd()
    console.log(`Average Rapport: ${sum / rapportData.length}`)

    setXaxis(Object.keys(eTracRapport).map(key => Number(key)))

    console.group('Average Rapport by group')
    const rapportGroupSeries = eTracRapportByGroup.map(
      ({groupName, groupDetails, events}, index) => {
        const groupSum = Object.values(events).reduce((a, b) => a + b, 0)
        const groupRapportData = Object.values(events)

        console.log(`${groupName}: ${groupSum / groupRapportData.length}`)

        const groupGender = getGroupGender(groupDetails)
        const shouldPickRandomColor = hasSelectedGenderColor.current[groupGender]
        const {color, hasSelectedGenderColor: selectedGenderColor} = getGroupColor(
          groupGender,
          index,
          shouldPickRandomColor,
        )

        hasSelectedGenderColor.current[groupGender] =
          selectedGenderColor || hasSelectedGenderColor.current[groupGender]

        return {type: 'line', data: groupRapportData, name: groupName, color}
      },
    )
    console.groupEnd()

    setGroupSeries(rapportGroupSeries)
  }, [eTracRapport, eTracRapportByGroup])

  if (_.isEmpty(options)) return null

  const adjustedCurrentTime = Math.min(currentTime, duration)

  return (
    <div
      id="rapport-chart"
      className={`${classes.chartContainer} ${classes.rapportChartContainer}`}
    >
      <Chart
        options={{
          ...options,
          chart: {
            ...options.chart,
            events: {
              mounted: chart => {
                chart.windowResizeHandler()
              },
            },
          },
          xaxis: {
            ...options.xaxis,
            categories: xaxis || [],
            tickAmount: getTickAmount(isWidthDown('md', width), duration),
            labels: {
              show: !isEmpty,
              formatter,
            },
            crosshairs: {
              show: isPaused,
            },
            tooltip: {
              enabled: isPaused,
              offsetY: 10,
            },
          },
          yaxis: {
            show: true,
            min: 0,
            max: 100,
            labels: {
              show: false,
              maxWidth: 0,
            },
          },
          tooltip: {
            enabled: true,
            followCursor: true,
            custom: ({dataPointIndex}) => {
              if (!isPaused) return ''

              return ReactDOMServer.renderToStaticMarkup(
                <LineChartTooltip video={video} dataPointIndex={dataPointIndex} />,
              )
            },
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            position: 'top',
            horizontalAlign: 'right',
            fontSize: '14px',
            fontFamily: 'Roboto',
            fontWeight: 400,
            onItemClick: {
              toggleDataSeries: true,
            },
          },
          annotations: {
            ...options.annotations,
            xaxis: [
              {
                x: adjustedCurrentTime,
                x2: adjustedCurrentTime + 0.002 * duration,
                borderColor: 'transparent',
                fillColor: '#848484',
                opacity: 0.7,
                label: {
                  borderWidth: 0,
                  borderRadius: 8,
                  text: formatter(adjustedCurrentTime),
                  position: 'bottom',
                  orientation: 'horizontal',
                  offsetY: 24,
                  style: {
                    background: '#028C21',
                    color: '#fff',
                  },
                },
              },
            ],
          },
        }}
        series={[
          ...(groupSeries || {}),
          {type: 'line', data: totalData || [], name: 'Total', color: totalDataColor},
        ]}
        width="100%"
        height={250}
      />
      {isEmpty && <NoLineChartData />}
    </div>
  )
})

RapportChart.propTypes = {
  Chart: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  currentTime: PropTypes.number,
  duration: PropTypes.number.isRequired,
  seek: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  video: PropTypes.objectOf(PropTypes.any).isRequired,
  isPaused: PropTypes.bool,
  eTracRapport: PropTypes.objectOf(PropTypes.number),
  eTracRapportByGroup: PropTypes.arrayOf(PropTypes.object),
}

RapportChart.defaultProps = {
  currentTime: 0,
  isPaused: true,
  eTracRapport: {},
  eTracRapportByGroup: [],
}

export default withWidth()(withStyles(styles)(RapportChart))
