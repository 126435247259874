import React, {useEffect, useState, useRef, useMemo} from 'react'
import ReactDOMServer from 'react-dom/server'
import _ from 'lodash'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core'
import withWidth, {isWidthDown} from '@material-ui/core/withWidth'
import HelpIcon from '@material-ui/icons/Help'

import LineChartTooltip from '../../common/LineChartTooltip'
import NoLineChartData from '../../common/NoLineChartData'
import Loading from '../../common/Loading'

import defaultOptions, {meanLineSeries, benchmarkSeries, sentimentColors} from './constants'

import {
  formatter,
  getTickAmount,
  getSentimentXaxis,
  getSentimentYaxis,
  getGroupColor,
  getGroupGender,
  getSentimentBenchmarkData,
} from './utils'

import styles from '../styles/LineChart.styles'

const MultipleSentimentChart = React.memo(props => {
  const {
    classes,
    width,
    emotionsByTime,
    currentEmotion,
    video,
    currentTime,
    isPaused,
    seek,
    duration,
    isEmpty,
    showYAxis,
    showMeanLine,
    Chart,
    emotionsByGroup,
    sentimentBenchmarks,
  } = props

  if (!Chart || !window) return null

  const hasSelectedGenderColor = useRef()

  const [xaxis, setXaxis] = useState([])
  const [max, setMax] = useState(100)
  const [series, setSeries] = useState([])
  const [selectedGroups, setSelectedGroups] = useState([])
  const [groupColorTable, setGroupColorTable] = useState({})

  const chartRef = useRef()

  const options = useMemo(() => defaultOptions(seek), [seek])

  useEffect(() => {
    const colorTable = {}
    hasSelectedGenderColor.current = {
      male: false,
      female: false,
    }

    const selected = emotionsByGroup.map(({groupName, groupDetails}, index) => {
      const groupGender = getGroupGender(groupDetails)
      const shouldPickRandomColor = hasSelectedGenderColor.current[groupGender]
      const {color: groupColor, hasSelectedGenderColor: selectedGenderColor} = getGroupColor(
        groupGender,
        index,
        shouldPickRandomColor,
      )

      colorTable[groupName] = groupColor

      hasSelectedGenderColor.current[groupGender] =
        selectedGenderColor || hasSelectedGenderColor.current[groupGender]

      return groupName
    })
    setSelectedGroups(selected)
    setGroupColorTable(colorTable)
  }, [emotionsByGroup])

  useEffect(() => {
    if (chartRef.current && chartRef.current.chart) {
      const {chart} = chartRef.current

      resetSelectedGroups()
      chart.resetSeries()
    }
  }, [currentEmotion])

  useEffect(() => {
    if (!_.isEmpty(groupColorTable) || !emotionsByGroup.length) {
      const xaxis = getSentimentXaxis(emotionsByTime)
      const [yaxis, mean, max] = getSentimentYaxis(emotionsByTime, currentEmotion)
      let totalMax = max

      const groupSeries = []

      const emotions = [...emotionsByGroup]

      emotions.forEach(group => {
        const [groupData, groupMean, groupMax] = getSentimentYaxis(group.events, currentEmotion)

        if (totalMax < groupMax) totalMax = groupMax

        groupSeries.push({
          type: 'line',
          name: group.groupName,
          data: groupData.length ? groupData : Array(duration + 1).fill(0), // preventing groupData from being an empty array
          color: groupColorTable[group.groupName],
          mean: groupMean,
          zIndex: 1,
        })
      })

      const [benchmarkData, benchmarkValue] = getSentimentBenchmarkData(
        sentimentBenchmarks,
        currentEmotion,
        yaxis,
        totalMax,
      )

      const tempSeries = [
        {
          type: 'area',
          data: yaxis,
          name: 'Total',
          zIndex: 0,
        },
        ...groupSeries,
      ]

      if (benchmarkData) {
        console.log('Benchmark Value: ', (benchmarkValue * 100) / totalMax)

        tempSeries.unshift({
          ...benchmarkSeries,
          data: benchmarkData,
        })

        totalMax = benchmarkValue * 2
      }

      if (showMeanLine) {
        const meanData = yaxis.map(() => mean)

        tempSeries.unshift({...meanLineSeries, data: meanData})
      }

      console.log('Total Average: ', (mean * 100) / totalMax)

      console.group(`Average ${_.capitalize(currentEmotion)} by group`)
      groupSeries.forEach(group => {
        console.log(`${group.name}: ${(group.mean * 100) / totalMax}`)
      })
      console.groupEnd()

      setSeries(tempSeries)

      setXaxis(xaxis)
      setMax(totalMax)
    }
  }, [currentEmotion, emotionsByTime, emotionsByGroup, groupColorTable])

  if (_.isEmpty(options)) return null

  let adjustedCurrentTime = currentTime

  if (currentTime > duration) {
    adjustedCurrentTime = duration
  }

  const resetSelectedGroups = () => {
    const selected = []
    emotionsByGroup.forEach(({groupName}) => {
      selected.push(groupName)
    })
    setSelectedGroups(selected)
  }

  const getTotalLineColor = () => {
    if (selectedGroups.length) {
      return `${sentimentColors[currentEmotion]}60`
    }

    return sentimentColors[currentEmotion]
  }

  const getFillColors = () => {
    const benchmark = sentimentBenchmarks ? sentimentBenchmarks[currentEmotion] : null

    const fillColors = [getTotalLineColor()]

    if (benchmark) fillColors.unshift(benchmarkSeries.color)
    if (showMeanLine) fillColors.unshift(meanLineSeries.color)

    return fillColors
  }

  const getDashArray = () => {
    const benchmark = sentimentBenchmarks ? sentimentBenchmarks[currentEmotion] : null

    if (!benchmark) return []

    const dashArray = [benchmarkSeries.dashArrayValue]

    if (showMeanLine) dashArray.unshift(meanLineSeries.dashArrayValue)

    return dashArray
  }

  const getStrokeWidth = () => {
    const benchmark = sentimentBenchmarks ? sentimentBenchmarks[currentEmotion] : null

    const strokeWidth = []

    if (showMeanLine) strokeWidth.push(meanLineSeries.strokeWidth)
    if (benchmark) strokeWidth.push(benchmarkSeries.strokeWidth)

    return [...strokeWidth, 0, ...selectedGroups.map(() => 2)]
  }

  const getTimelineMarker = () => {
    if (isEmpty) {
      return {}
    }

    return {
      x: adjustedCurrentTime,
      x2: duration ? adjustedCurrentTime + 0.001 * duration : adjustedCurrentTime,
      borderColor: 'transparent',
      fillColor: '#848484',
      opacity: 0.7,
      label: {
        borderWidth: 0,
        borderRadius: 8,
        text: formatter(adjustedCurrentTime),
        position: 'bottom',
        orientation: 'horizontal',
        offsetY: 24,
        style: {
          background: '#028C21',
          color: '#fff',
        },
      },
    }
  }

  if (emotionsByGroup.length && _.isEmpty(groupColorTable)) {
    return <Loading />
  }

  return (
    <>
      <div id="multiple-sentiment-chart" className={classes.chartContainer}>
        <Chart
          ref={ref => {
            chartRef.current = ref
          }}
          options={{
            ...options,
            chart: {
              ...options.chart,
              events: {
                legendClick: (_chartContext, seriesIndex, config) => {
                  const {series} = config.config
                  const seriesName = series[seriesIndex].name

                  if (
                    seriesName !== 'Total' &&
                    seriesName !== 'Mean Line' &&
                    seriesName !== 'Benchmark'
                  ) {
                    setSelectedGroups(prevState => _.xor(prevState, [seriesName]))
                  }
                },
                click: (_event, _chartContext, config) => {
                  if (config.dataPointIndex !== -1) seek(config.dataPointIndex)
                },
                mounted: chart => {
                  chart.windowResizeHandler()
                },
              },
            },
            legend: {
              show: true,
              position: 'top',
              horizontalAlign: 'right',
              fontSize: '14px',
              fontFamily: 'Roboto',
              fontWeight: 400,
              markers: {
                fillColors: getFillColors(),
              },
              onItemClick: {
                toggleDataSeries: true,
              },
            },
            stroke: {
              show: !isEmpty,
              width: getStrokeWidth(),
              dashArray: getDashArray(),
            },
            fill: {
              type: 'solid',
              opacity: 1,
              colors: [getTotalLineColor()],
            },
            xaxis: {
              ...options.xaxis,
              categories: xaxis,
              tickAmount: getTickAmount(isWidthDown('md', width), duration),
              labels: {
                show: !isEmpty,
                formatter,
              },
              crosshairs: {
                show: isPaused,
              },
              tooltip: {
                enabled: isPaused,
                offsetY: 10,
              },
            },
            yaxis: {
              ...options.yaxis,
              labels: {
                ...options.yaxis.labels,
                formatter: (_, index) => {
                  const {tickAmount} = options.yaxis
                  if (index === tickAmount) return 'HIGH'
                  if (index === tickAmount / 2) return 'AVERAGE'
                  if (index === 0) return 'LOW'
                },
              },
              show: showYAxis,
              max,
            },
            tooltip: {
              enabled: true,
              followCursor: true,
              custom: ({dataPointIndex}) => {
                if (!isPaused) return ''

                return ReactDOMServer.renderToStaticMarkup(
                  <LineChartTooltip video={video} dataPointIndex={dataPointIndex} />,
                )
              },
            },
            annotations: {
              xaxis: [getTimelineMarker()],
            },
          }}
          series={series}
          width="100%"
          height={250}
        />
        {isEmpty ? (
          <NoLineChartData />
        ) : (
          <div className={classes.meanLineTextContainer}>
            <HelpIcon className={classes.helpIcon} fontSize="small" />
            <span className={classes.meanLineText}>
              The Benchmark Line represents the reference point for evaluating the fluctuating
              emotional responses over time.
            </span>
          </div>
        )}
      </div>
    </>
  )
})

MultipleSentimentChart.propTypes = {
  Chart: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  currentEmotion: PropTypes.string.isRequired,
  currentTime: PropTypes.number,
  duration: PropTypes.number,
  emotionsByTime: PropTypes.objectOf(PropTypes.object).isRequired,
  isEmpty: PropTypes.bool.isRequired,
  isPaused: PropTypes.bool,
  seek: PropTypes.func.isRequired,
  video: PropTypes.objectOf(PropTypes.any).isRequired,
  width: PropTypes.string.isRequired,
  showYAxis: PropTypes.bool,
  showMeanLine: PropTypes.bool,
}

MultipleSentimentChart.defaultProps = {
  currentTime: 0,
  duration: 0,
  isPaused: true,
  showYAxis: false,
  showMeanLine: true,
}

export default withWidth()(withStyles(styles)(MultipleSentimentChart))
