import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core'
import {useDropzone} from 'react-dropzone'

import NewTestErrorContext from '../campaign/context/NewTestErrorContext'

import uploadDocumentIcon from '../../assets/icons/document-upload.svg'

import VideoUtils from '../utils/VideoUtils'

const styles = theme => ({
  uploadArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 500,
    border: '2px dashed #bdbdbd',
    borderRadius: 10,
    textAlign: 'center',
    padding: `${theme.spacing.unit * 4} 0`,
    marginTop: theme.spacing.unit * 3,
    cursor: 'pointer',
    color: '#292d3299',
    '&:hover': {
      background: '#292d320d',
    },
    '& p': {
      margin: theme.spacing.unit,
    },
  },
  invalidFile: {
    borderColor: '#EB5757',
    background: '#eb57571a',
    '&:hover': {
      background: '#eb57571a',
    },
  },
  validFile: {
    borderColor: '#219653',
    background: '#2196531a',
    '&:hover': {
      background: '#2196531a',
    },
  },
  uploadDescription: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.4rem',
    '& img': {
      width: 20,
      marginRight: theme.spacing.unit * 2,
    },
  },
  fileSize: {
    fontSize: '0.9rem',
  },
})

const acceptedFiles =
  'video/mp4,video/quicktime,video/mpeg,video/x-msvideo,video/x-ms-wmv,video/avi'

const maxFileSize = 52428800

const VideoUploadArea = props => {
  const {
    classes,
    setVideoFile,
    isFileRejected,
    setIsFileRejected,
    setRejectedFileError,
    setIsConvertingVideo,
  } = props

  const {setVideoError} = useContext(NewTestErrorContext)

  const setFile = async file => {
    const reader = new FileReader()
    reader.onload = () => {
      const audio = new Audio(reader.result)
      audio.onloadedmetadata = () => {
        setVideoFile(Object.assign(file, {duration: audio.duration}))
        setIsConvertingVideo(false)
      }
    }
    reader.readAsDataURL(file.blob ? file.blob : file)
  }

  const {getRootProps, getInputProps, isDragReject, isDragAccept} = useDropzone({
    accept: acceptedFiles,
    maxFiles: 1,
    maxSize: maxFileSize,
    onDrop: async ([file]) => {
      if (file) {
        let newFile = {}

        setIsConvertingVideo(true)

        if (file.type !== 'video/mp4') {
          newFile = await VideoUtils.convertVideo(file)
        } else {
          newFile = Object.assign(file, {preview: URL.createObjectURL(file)})
        }

        setFile(newFile)
        setIsFileRejected(false)
        setRejectedFileError('')
        setVideoError('')
      } else {
        setIsFileRejected(true)
      }
    },
    onDropRejected: async ([file]) => {
      if (file) {
        setIsFileRejected(true)
        if (!acceptedFiles.split(',').includes(file.type)) {
          setRejectedFileError('Your file format is not acceptable.')
        } else if (file.size > maxFileSize) {
          setRejectedFileError('Your file exceeds the 50mb limit.')
        }
      } else {
        setRejectedFileError('')
        setIsFileRejected(false)
      }
    },
  })

  return (
    <div
      {...getRootProps()}
      className={`
        ${classes.uploadArea}
        ${isDragReject || isFileRejected ? classes.invalidFile : ''}
        ${isDragAccept ? classes.validFile : ''}
      `}
    >
      <input {...getInputProps()} />
      <p className={classes.uploadDescription}>
        <img src={uploadDocumentIcon} alt="upload" />
        Click to Browse or Drag & Drop
      </p>
      <p>Allowed Types: mp4, mov, mpeg, avi, wmv</p>
      <p className={classes.fileSize}>Max File Size: 50MB</p>
    </div>
  )
}

VideoUploadArea.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  setVideoFile: PropTypes.func.isRequired,
  isFileRejected: PropTypes.bool.isRequired,
  setIsFileRejected: PropTypes.func.isRequired,
}

export default withStyles(styles)(VideoUploadArea)
