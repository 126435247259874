import React, {useCallback, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import _ from 'lodash'

import {Button, Grid, Paper, Typography, withStyles} from '@material-ui/core'
import CompareArrowsIcon from '@material-ui/icons/CompareArrows'

import useModalState from '../../hooks/useModalState'

import Gauge from './Gauge'
import VerticalCharts from './VerticalCharts'
import CompareGauges from './CompareGauges'
import TabSwitchButtons from './TabSwitchButtons'
import CompareVerticalCharts from './CompareVerticalCharts'
import Loading from '../common/Loading'
import InfoButtons from '../common/InfoButtons'
import InfoModal from '../common/InfoModal'
import DemographicDropdownButton from '../common/DemographicDropdownButton'
import CompareDemographicsModal from '../common/CompareDemographicsModal'
import NoDataAvailable from '../common/NoDataAvailable'
// import FocusTimeTrack from './FocusTimeTrack'
import TestDetailHeader from '../common/TestDetailHeader'
import BenchmarkExplained from './BenchmarkExplained'

import {getCampaign, getTabScore, getDemographicsWithValues} from './redux/actions'

import {appBaseURL} from '../../../config/settings.json'

import utils from './utils.js'

import {testTypes} from '../constants/NewTest'

import styles from './styles/TabScores.styles'

function TabScores(props) {
  const [textModalOpen, openTextModal, closeTextModal] = useModalState(true)
  const [videoModalOpen, openVideoModal, closeVideoModal] = useModalState(false)
  const [
    compareDemographicsOpen,
    openCompareDemographicsModal,
    closeCompareDemographicsModal,
  ] = useModalState(false)
  const [selectParents, setSelectParents] = useState([])
  const [selectChildren, setSelectChildren] = useState({})
  const [scores, setScores] = useState([])
  const [benchmarks, setBenchmarks] = useState({})
  const [audienceResults, setAudienceResults] = useState({})
  const [hasFetchedTabScores, setHasFetchedTabScores] = useState(false)
  const [hasFetchedDemographics, setHasFetchedDemographics] = useState(false)
  const [isComparing, setIsComparing] = useState(false)
  const [showingVerticalBarChart, setShowingVerticalBarChart] = useState(false)
  const [filterText, setFilterText] = useState('All Demographics')
  const [selectedDemographicFilters, setSelectedDemographicFilters] = useState({})

  const {
    campaign,
    classes,
    demographicsWithValues,
    getCampaign,
    getDemographicsWithValues,
    getTabScore,
    params,
    tabScoreResults,
    tabScoreComparison,
    focusTimeTrackFilter,
    router,
  } = props

  useEffect(() => {
    if (campaign.campaign.id !== Number(params.id)) {
      getCampaign(params.id, {expand: 'lists'})
    }
    if (!utils.checkFirstTimeVisit('hasVisitedTabScores')) {
      closeTextModal()
    }
  }, [])

  useEffect(() => {
    if (campaign.campaign.id === Number(params.id)) {
      if (campaign.campaign.campaignType === 1) {
        router.push(`${appBaseURL}`)
      }

      const {campaignVideoId} = campaign.campaign.campaignVideos[0]

      if (!hasFetchedTabScores) {
        getTabScore(campaignVideoId, {
          demographic: {},
          focus_time: focusTimeTrackFilter,
        })
        setHasFetchedTabScores(true)
      }

      if (!hasFetchedDemographics) {
        getDemographicsWithValues(campaignVideoId)
        setHasFetchedDemographics(true)
      }
    }
  }, [campaign.campaign])

  useEffect(() => {
    const {xaxis, yaxis, audienceResults, benchmarks} = tabScoreResults

    if (campaign.campaign.id === Number(params.id)) {
      if (hasFetchedTabScores && yaxis && xaxis && !_.isEmpty(yaxis) && !_.isEmpty(xaxis)) {
        setScores(yaxis[0].data)
        setBenchmarks(benchmarks)
        setAudienceResults(audienceResults)
      }
    }
  }, [tabScoreResults])

  useEffect(() => {
    if (campaign.campaign.id === Number(params.id)) {
      if (hasFetchedDemographics && !_.isEmpty(demographicsWithValues)) {
        const [parents, children] = utils.getSelectParentsAndChildren(demographicsWithValues)

        setSelectParents(parents)
        setSelectChildren(children)
      }
    }
  }, [demographicsWithValues])

  useEffect(() => {
    if (campaign.campaign.id === Number(params.id)) {
      const {campaignVideoId} = campaign.campaign.campaignVideos[0]
      getTabScore(campaignVideoId, {
        demographic: selectedDemographicFilters,
        focus_time: focusTimeTrackFilter,
      })
    }
  }, [focusTimeTrackFilter])

  const getFilteredData = useCallback(
    selectedFilters => {
      const {campaignVideoId} = campaign.campaign.campaignVideos[0]
      const query = utils.getQueryFromFilters(selectedFilters)

      setFilterText(utils.createFilterText(query, selectParents))
      setSelectedDemographicFilters(query)

      getTabScore(campaignVideoId, {demographic: query, focus_time: focusTimeTrackFilter})
    },
    [campaign.campaign.campaignVideos],
  )

  const getFilteredDataDebounce = _.debounce(getFilteredData, 1000)

  const handleClearResults = () => {
    setIsComparing(false)
    getFilteredData({})
  }

  const renderModals = () => {
    return (
      <>
        <InfoModal open={textModalOpen} onClose={closeTextModal}>
          <>
            <Typography variant="h4">TAB&rsquo;s Explained</Typography>
            <Typography variant="body1">
              <strong>Trust, Attention & Believability</strong> are core KPIs. The scores represent
              the audience&apos;s takeaway. These KPI&apos;s call out any red flags and/or pain
              points in your message.
              <br />
              <br />
              <strong>Takeaway</strong> If any of your scores are not aligned with the benchmarks,
              review your message to see specifically what sentiments are driving positive or
              negative reactions.
              <br />
              <br />
              <strong>Trust Metric</strong> reports how factual the audience finds the content.
              Scores above the benchmark metric indicate that the audience considers your message to
              be truthful. Below the benchmark means the audience may be skeptical to your story
              content. (Benchmark
              {benchmarks.trust}
              )
              <br />
              <br />
              <strong>Attention Metric</strong> speaks to level of the audience engagement. Scores
              above the benchmark indicate that the audience finds the content compelling. Scores
              below the benchmark indicate that the audience finds your messaging unclear and/or
              confusing. (Benchmark
              {benchmarks.attention}
              )
              <br />
              <br />
              <strong>Believability Metric</strong> reports how truthful/convincing your message is
              to the audience. Scores above the benchmark indicate that the audience believes in the
              message. Scores below the benchmark indicate lack of belief in your message.
              (Benchmark
              {benchmarks.believability})
            </Typography>
          </>
        </InfoModal>
        <InfoModal open={videoModalOpen} onClose={closeVideoModal}>
          <>
            <Typography variant="h4">TAB&rsquo;s Explained</Typography>
            <iframe
              width="750"
              height="480"
              src="https://www.youtube.com/embed/UY-vnbnsaDc?&autoplay=1"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </>
        </InfoModal>
        <CompareDemographicsModal
          selectParents={selectParents}
          selectChildren={selectChildren}
          open={compareDemographicsOpen}
          onClose={closeCompareDemographicsModal}
          isComparing={isComparing}
          setIsComparing={setIsComparing}
        />
      </>
    )
  }

  const renderGauges = () => {
    if (
      campaign.isFetching ||
      campaign.demographicsWithValues.isFetching ||
      campaign.tabScore.isFetching ||
      !hasFetchedTabScores ||
      !hasFetchedDemographics
    ) {
      return <Loading />
    }

    if (_.isEmpty(tabScoreResults.tabscoreyaxis) || _.isEmpty(tabScoreResults.tabscorexaxis)) {
      return (
        <>
          {filterText && (
            <Grid item xs={12} className={classes.filterTextContainer}>
              <Typography className={classes.filterText}>{filterText}</Typography>
            </Grid>
          )}
          <NoDataAvailable />
        </>
      )
    }

    return (
      <>
        {filterText && (
          <Grid item xs={12} className={classes.filterTextContainer}>
            <Typography className={classes.filterText}>{filterText}</Typography>
          </Grid>
        )}
        <Grid item xs={6} lg={4}>
          <Gauge
            title="Trust"
            audienceResult={audienceResults.trust}
            benchmark={benchmarks.trust}
            score={scores[0]}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <Gauge
            title="Attention"
            audienceResult={audienceResults.attention}
            benchmark={benchmarks.attention}
            score={scores[1]}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Gauge
            title="Believability"
            audienceResult={audienceResults.believability}
            benchmark={benchmarks.believability}
            score={scores[2]}
          />
        </Grid>
      </>
    )
  }

  const renderData = () => {
    if (isComparing) {
      if (showingVerticalBarChart) {
        return <CompareVerticalCharts />
      }

      return <CompareGauges />
    }

    if (showingVerticalBarChart) {
      return (
        <VerticalCharts
          tabScoreResults={tabScoreResults}
          isFetching={campaign.tabScore.isFetching}
          filterText={filterText}
        />
      )
    }

    return (
      <Grid container className={classes.gaugeList}>
        {renderGauges()}
      </Grid>
    )
  }

  const getResponseCount = () => {
    if (!isComparing) {
      return tabScoreResults.responseCount
    }

    return tabScoreComparison
      ? tabScoreComparison.reduce((acc, curr) => acc + curr.responseCount, 0)
      : 0
  }

  return (
    <Grid container className={classes.tabScoresContainer}>
      {/* <FocusTimeTrack /> */}
      {renderModals()}
      <TestDetailHeader title="TAB Scores" responseCount={getResponseCount()} />
      <Paper elevation={0} className={classes.tabScoresPaper}>
        {campaign.campaign.isFecthing ? (
          <Loading />
        ) : (
          <>
            <Grid container className={classes.paperHeader}>
              <Grid item xs className={classes.paperHeaderTextContainer}>
                <Typography className={classes.paperHeaderText}>TAB Scoring Results</Typography>
              </Grid>
              <Grid item className={classes.paperButtonContainer}>
                {isComparing && (
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.clearComparisonButton}
                    onClick={handleClearResults}
                  >
                    Clear Results
                  </Button>
                )}
                {campaign.isFetching ||
                campaign.demographicsWithValues.isFetching ||
                campaign.tabScore.isFetching ||
                !hasFetchedTabScores ||
                !hasFetchedDemographics ? null : (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.compareButton}
                    onClick={openCompareDemographicsModal}
                  >
                    <CompareArrowsIcon fontSize="small" className={classes.buttonIcon} />
                    Compare
                  </Button>
                )}
                {!isComparing && (
                  <DemographicDropdownButton
                    selectParents={selectParents}
                    selectChildren={selectChildren}
                    getFilteredData={getFilteredDataDebounce}
                  />
                )}
                <TabSwitchButtons
                  showingVerticalBarChart={showingVerticalBarChart}
                  setShowingVerticalBarChart={setShowingVerticalBarChart}
                />
                <InfoButtons openTextModal={openTextModal} openVideoModal={openVideoModal} />
              </Grid>
            </Grid>
            {renderData()}
            <BenchmarkExplained
              type={testTypes.legal}
              style={{margin: showingVerticalBarChart ? '8 0' : '32 0 8'}}
              noTitle
            />
          </>
        )}
      </Paper>
    </Grid>
  )
}

TabScores.propTypes = {
  campaign: PropTypes.objectOf(PropTypes.any).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  demographicsWithValues: PropTypes.objectOf(PropTypes.object).isRequired,
  getCampaign: PropTypes.func.isRequired,
  getDemographicsWithValues: PropTypes.func.isRequired,
  getTabScore: PropTypes.func.isRequired,
  params: PropTypes.shape({id: PropTypes.string}).isRequired,
  tabScoreComparison: PropTypes.arrayOf(PropTypes.object).isRequired,
  tabScoreResults: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
}

const mapStateToProps = ({campaign}) => ({
  tabScoreResults: campaign.tabScore.results,
  tabScoreComparison: campaign.tabScoreComparison.results,
  demographicsWithValues: campaign.demographicsWithValues.values,
  campaign,
  focusTimeTrackFilter: campaign.focusTimeTrackFilter,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCampaign,
      getTabScore,
      getDemographicsWithValues,
    },
    dispatch,
  )

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(TabScores)
