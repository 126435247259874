import React from 'react'
import {Divider as DividerMUI, withStyles} from '@material-ui/core'

const styles = () => ({
  divider: {
    width: '100%',
    margin: '20px 0px',
    height: '1px',
    backgroundColor: '#BDBFC2',
  },
})

const Divider = ({classes, ...props}) => {
  return <DividerMUI className={classes.divider} {...props} />
}

export default withStyles(styles)(Divider)
