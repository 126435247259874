const styles = theme => ({
  page: {
    padding: '0',
    // marginTop: 20,
    width: '100%',
  },
  title: {
    margin: '0 0 40px 0',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    color: '#545454',
    fontSize: '28px',
    letterSpacing: 'normal',
    lineHeight: 1.97,
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
  },
  loginInfo: {
    width: '75%',
    fontSize: '52px',
    wordSpacing: 10,
    lineHeight: 1.37,
    color: '#fff',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      fontSize: 45,
    },
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  container: {
    height: 'calc(100vh - 100px)',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 200px)',
      minHeight: '400px',
      maxHeight: '500px',
      paddingTop: 30,
    },
  },
  registerContainer: {
    paddingTop: 50,
    height: 'calc(100vh - 100px)',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 200px)',
      minHeight: '750px',
      paddingTop: 30,
    },
  },
  loginButton: {
    borderRadius: 21,
    textTransform: 'none',
    height: '42px',
    backgroundColor: '#007bc0',
    boxShadow: 'none',
    color: '#fff',
  },
  uploadButton: {
    borderRadius: 21,
    minWidth: 200,
    textTransform: 'none',
    height: '42px',
    backgroundColor: '#d2363b',
    boxShadow: 'none',
    color: '#fff',
  },
  inputField: {
    border: '1px solid #e3e3e3',
    borderRadius: 21,
    padding: 13,
    height: '40px',
  },
  card: {
    maxWidth: '700px',
    margin: '0 auto',
  },
  cardCentered: {
    maxWidth: '700px',
    margin: '0 auto',
    justifyContent: 'center',
    textAlign: 'center',
  },
  cardActions: {
    marginTop: '-50px',
    borderTop: '1px solid #ccc',
    justifyContent: 'center',
  },
  cardContent: {
    padding: '100px',
  },
  confirmButton: {
    marginTop: '20px',
  },
  hintText: {
    color: '#a1a1a1',
    fontSize: '17px',
    textAlign: 'left',
  },
  blackLink: {
    fontWeight: 'bold !important',
    textDecoration: 'none',
    color: '#a1a1a1 !important',
  },
})

export default styles
