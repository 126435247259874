import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {TextField, Divider, withStyles, Radio, FormControlLabel} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import useUpdateEffect from '../../hooks/useUpdateEffect'

const styles = theme => ({
  select: {
    width: theme.spacing.unit * 20,
    height: theme.spacing.unit * 5,
    '& div': {
      width: '100%',
      height: '100%',
      padding: 0,
    },
    '& .MuiInputBase-disabled': {
      background: 'red',
    },
  },
  icon: {
    marginRight: theme.spacing.unit,
    color: '#292d3299 !important',
  },
  outline: {
    borderColor: '#b0b1b31f !important',
  },
  selectBox: {
    width: theme.spacing.unit * 50,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    '& ul': {
      padding: 0,
    },
  },
  label: {
    color: theme.palette.grey[800],
  },
  checkbox: {
    padding: theme.spacing.unit,
  },
  divider: {
    background: '#F1F2F4',
    margin: `${theme.spacing.unit} 0`,
  },
  selectParent: {
    margin: 0,
    marginLeft: theme.spacing.unit * -1,
  },
  selectChild: {
    margin: 0,
    marginLeft: theme.spacing.unit * 2,
  },
})

const DemographicKeyDropdownButton = props => {
  const {classes, getFilteredData, demographics, isFetching} = props
  const demographicKeys = []
  // remove gender
  for (let index = 0; demographics.length && index < demographics.length; index++) {
    if (demographics[index].key !== 'gender') demographicKeys.push(demographics[index])
  }
  const demographicKeysLength = demographicKeys.length
  const [selectedDemographic, setSelectedDemographic] = useState('')

  useUpdateEffect(() => {
    getFilteredData(selectedDemographic)
  }, [selectedDemographic])

  const handleDemographicChange = demographicKey => {
    if (selectedDemographic === demographicKey) setSelectedDemographic('')
    else setSelectedDemographic(demographicKey)
  }

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  return (
    <TextField
      value=""
      select
      label="Demographics"
      InputProps={{classes: {notchedOutline: classes.outline}}}
      variant="outlined"
      InputLabelProps={{
        shrink: false,
        style: {
          left: '45%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          color: isFetching ? '#00000061' : '#292D32',
          width: '100%',
          textAlign: 'center',
        },
      }}
      SelectProps={{
        classes: {icon: classes.icon},
        className: classes.select,
        IconComponent: ExpandMoreIcon,
        MenuProps: {classes: {paper: classes.selectBox}},
        disabled: isFetching,
      }}
    >
      <FormControlLabel
        className={classes.selectChild}
        classes={{label: classes.label}}
        control={
          <Radio
            className={classes.checkbox}
            checked={selectedDemographic === ''}
            onChange={() => handleDemographicChange('')}
            color="primary"
          />
        }
        label={capitalizeFirstLetter('None')}
      />
      <Divider className={classes.divider} />
      {Object.keys(demographicKeys).map(index => (
        <div key={index}>
          <FormControlLabel
            className={classes.selectChild}
            classes={{label: classes.label}}
            control={
              <Radio
                className={classes.checkbox}
                checked={selectedDemographic === demographicKeys[index].key}
                onChange={() => handleDemographicChange(demographicKeys[index].key)}
                color="primary"
              />
            }
            label={demographicKeys[index].label}
          />
          {index !== demographicKeysLength - 1 && <Divider className={classes.divider} />}
        </div>
      ))}
    </TextField>
  )
}

DemographicKeyDropdownButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  demographics: PropTypes.arrayOf(PropTypes.object).isRequired,
  getFilteredData: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
}

export default withStyles(styles)(DemographicKeyDropdownButton)
