import React from 'react'
import {Button, withStyles} from '@material-ui/core'

const styles = () => ({
  secondaryButton: {
    backgroundColor: '#017eff14',
    '&:hover': {
      border: '1px solid #017EFF',
      backgroundColor: '#017eff24',
    },
  },
})

const SecondaryButton = ({classes, children, ...props}) => {
  return (
    <Button variant="outlined" color="primary" className={classes.secondaryButton} {...props}>
      {children}
    </Button>
  )
}

export default withStyles(styles)(SecondaryButton)
