import React from 'react'
import PropTypes from 'prop-types'
import {Grid, Typography, withStyles} from '@material-ui/core'

import InfoTooltip from '../common/InfoTooltip'

import useModalState from '../../hooks/useModalState'

import styles, {
  gaugeLowColorArray,
  gaugeMediumColorArray,
  gaugeHighColorArray,
  gaugeLowColor,
  gaugeMediumColor,
  gaugeHighColor,
} from './styles/Gauge.styles'

function Gauge({title, audienceResult, classes, benchmark, score}) {
  const [isTooltipOpen, openTooltip, closeTooltip] = useModalState(false)

  const benchmarkAngle = benchmark * 1.8
  const scoreAngle = score * 1.8
  const pointerAngle = score * 1.88
  const scoreBenchmarkDiff = Math.abs(score - benchmark)

  const gaugeDescription = {
    Trust: 'How trusting the audience finds the content.',
    Attention: 'How much attention the audience had with the content.',
    Believability: 'How believable the audience found the content.',
  }

  const gradientTransitions = {
    redYellow: {
      // (initialYellowRed - initialRedRed) / (initialYellowAngle - finalRedAngle)
      redDelta: (gaugeMediumColorArray[0] - gaugeLowColorArray[0]) / 20,
      greenDelta: (gaugeMediumColorArray[1] - gaugeLowColorArray[1]) / 20,
      blueDelta: (gaugeMediumColorArray[2] - gaugeLowColorArray[2]) / 20,
    },
    yellowGreen: {
      redDelta: (gaugeHighColorArray[0] - gaugeMediumColorArray[0]) / (190 - benchmarkAngle),
      greenDelta: (gaugeHighColorArray[1] - gaugeMediumColorArray[1]) / (190 - benchmarkAngle),
      blueDelta: (gaugeHighColorArray[2] - gaugeMediumColorArray[2]) / (190 - benchmarkAngle),
    },
  }

  const getScoreColor = () => {
    if (scoreAngle <= 30) return gaugeLowColor

    if (scoreAngle < 50) {
      const colorDeltas = gradientTransitions.redYellow
      const red = (scoreAngle - 30) * colorDeltas.redDelta + gaugeLowColorArray[0]
      const green = (scoreAngle - 30) * colorDeltas.greenDelta + gaugeLowColorArray[1]
      const blue = (scoreAngle - 30) * colorDeltas.blueDelta + gaugeLowColorArray[2]

      return `rgb(${red},${green},${blue})`
    }

    if (scoreAngle < benchmarkAngle) return gaugeMediumColor

    const colorDeltas = gradientTransitions.yellowGreen
    const red = (scoreAngle - benchmarkAngle) * colorDeltas.redDelta + gaugeMediumColorArray[0]
    const green = (scoreAngle - benchmarkAngle) * colorDeltas.greenDelta + gaugeMediumColorArray[1]
    const blue = (scoreAngle - benchmarkAngle) * colorDeltas.blueDelta + gaugeMediumColorArray[2]

    return `rgb(${red},${green},${blue})`
  }

  return (
    <div className={classes.outterContainer}>
      <Grid container className={classes.titleContainer}>
        <Typography className={classes.gaugeTitle}>{title}</Typography>
        <Typography
          className={classes.gaugeBenchmark}
          onMouseEnter={openTooltip}
          onMouseLeave={closeTooltip}
        >
          {`Benchmark = ${benchmark}`}
          <InfoTooltip
            open={isTooltipOpen}
            style={{fontSize: '14px'}}
            text=""
            // text="-- ask client for text --"
          />
        </Typography>
        <Typography className={classes.gaugeDescription}>{gaugeDescription[title]}</Typography>
      </Grid>
      <div className={classes.gaugeContainer}>
        <span style={{marginRight: '8px', opacity: score < 4 ? 0.2 : 1}}>
          &nbsp;&nbsp;&nbsp;&nbsp;0
        </span>
        <div className={classes.gaugeInnerContainer}>
          <div
            className={classes.gaugeCircle}
            style={{
              background: `conic-gradient(from -90deg at 50% 50%,
              ${gaugeLowColor} 0deg 30deg,
              ${gaugeMediumColor} 50deg ${benchmarkAngle}deg,
              ${gaugeHighColor} 180deg)`,
            }}
          />
          <div className={classes.pointer} style={{transform: `rotate(${pointerAngle - 94}deg)`}} />
        </div>
        <span
          className={classes.benchmarkContainer}
          style={{
            transform: `rotate(${benchmarkAngle - 90}deg)`,
            opacity: scoreBenchmarkDiff < 4 && benchmark !== score ? 0.2 : 1,
          }}
        >
          <span
            style={{
              display: 'inline-block',
              transform: `rotate(${(benchmarkAngle - 90) * -1}deg)`,
            }}
          >
            {benchmark}
          </span>
        </span>
        {benchmark !== score && (
          <span
            className={classes.scoreContainer}
            style={{transform: `rotate(${scoreAngle - 90}deg)`}}
          >
            <span
              style={{display: 'inline-block', transform: `rotate(${(scoreAngle - 90) * -1}deg)`}}
            >
              {score}
            </span>
          </span>
        )}
        <span style={{marginLeft: '8px', opacity: score > 96 ? 0.2 : 1}}>100</span>
      </div>
      <Grid container>
        <Grid item xs={3} className={classes.resultContainer}>
          <Grid container direction="column" alignItems="center">
            <Typography className={classes.resultTitle}>Benchmark</Typography>
            <Typography className={classes.resultValue} style={{color: '#2f80ed'}}>
              {`${benchmark}%`}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs className={classes.resultContainer}>
          <Grid container direction="column" alignItems="center">
            <Typography className={classes.resultTitle}>Your Score</Typography>
            <Typography className={classes.resultValue} style={{color: getScoreColor(score)}}>
              {`${score}%`}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.resultContainer}>
          <Grid item>
            <Typography className={classes.resultTitle}>Audience Is</Typography>
            <Typography className={classes.resultValue}>{audienceResult}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

Gauge.propTypes = {
  audienceResult: PropTypes.string.isRequired,
  benchmark: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  score: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
}

export default withStyles(styles)(Gauge)
