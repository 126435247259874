import React from 'react'
import PropTypes from 'prop-types'
import {Grid, IconButton, withStyles} from '@material-ui/core'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const styles = theme => ({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.grey[700],
    userSelect: 'none',
  },
  buttonIcon: {
    color: theme.palette.grey[500],
  },
})

const StepPagination = ({classes, text, current, total, prev, next, ...containerProps}) => {
  return (
    <Grid container className={classes.container} {...containerProps}>
      <IconButton aria-label="previous" onClick={prev}>
        <ChevronLeftIcon className={classes.buttonIcon} />
      </IconButton>
      {`${text} ${current + 1}/${total}`}
      <IconButton aria-label="next" onClick={next}>
        <ChevronRightIcon className={classes.buttonIcon} />
      </IconButton>
    </Grid>
  )
}

StepPagination.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  text: PropTypes.string.isRequired,
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  prev: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
}

export default withStyles(styles)(StepPagination)
