import React, {useState, useEffect, useContext} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import {connect} from 'react-redux'
import _ from 'lodash'
import {
  Button,
  FormControlLabel,
  Grid,
  // IconButton,
  // InputAdornment,
  // MenuItem,
  // OutlinedInput,
  Radio,
  RadioGroup,
  // Select,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
// import MomentUtils from '@date-io/moment'
// import {DateTimePicker, MuiPickersUtilsProvider} from 'material-ui-pickers'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import SaveSetupChangesButton from '../SaveSetupChangesButton'
import UploadVideo from '../../video/UploadVideo'
import VideoThumb from '../../video/VideoThumb'
import InputValidator from '../../common/InputValidator'
import Loading from '../../common/Loading'
import InfoTooltip from '../../common/InfoTooltip'

import NewTestContext from '../context/NewTestContext'
import NewTestErrorContext from '../context/NewTestErrorContext'

import pageUtil from '../../utils/PageUtils'
import utils from '../utils'
import tagManager, {eventTypes} from '../../utils/GTMUtils'
// import timezones from '../utils/timezone'

// import calendarIcon from '../../assets/icons/calendar.svg'

import {contextFields, testNameMaxLength, testTypes} from '../../constants/NewTest'

import styles from '../styles/TestSetup.styles'

// const SelectIcon = withStyles(theme => ({
//   root: {
//     marginRight: theme.spacing.unit / 2,
//     color: theme.palette.grey[800],
//   },
// }))(ExpandMoreIcon)

const TestSetup = props => {
  const {classes, availableVideos, isActive, isFetching, nextStep, configs} = props

  const {
    setStateField,
    // setStateFields,
    testName,
    testType,
    // timezone,
    // startDate,
    videoFile,
    showObjectivesMoments,
    caseType,
    isEditing,
    setIsEditing,
    hasPassed,
    setHasPassed,
    objectives,
    hasOpenedDefaultTooltip,
    setHasOpenedDefaultTooltip,
  } = useContext(NewTestContext)

  const {
    videoError,
    setVideoError,
    nameError,
    setNameError,
    objectivesMomentsError,
    setObjectivesMomentsError,
  } = useContext(NewTestErrorContext)

  const [isConvertingVideo, setIsConvertingVideo] = useState(false)
  const [stepInfoTooltipOpen, setStepInfoTooltipOpen] = useState(false)

  useEffect(() => {
    if (hasPassed.testSetup && !isEditing.testSetup) {
      setIsEditing({...isEditing, testSetup: true})
    }
  }, [videoFile, testName, testType, objectives])

  useEffect(() => {
    setTimeout(() => {
      if (!hasOpenedDefaultTooltip.testSetup) {
        setStepInfoTooltipOpen(true)
        setHasOpenedDefaultTooltip({...hasOpenedDefaultTooltip, testSetup: true})
      }
    }, 500)

    return () => {
      URL.revokeObjectURL(videoFile.preview)
    }
  }, [])

  const validateTestName = () => {
    if (!testName.length) {
      setNameError('Test name is required')

      return 'Test name is required'
    }

    setNameError('')

    return ''
  }

  const validateVideo = () => {
    if (_.isEmpty(videoFile)) {
      setVideoError('You must select a video!')

      return 'You must select a video!'
    }

    setVideoError('')

    return ''
  }

  const setVideoFile = video => {
    if (!_.isEmpty(video)) {
      // if a video is being uploaded, fire the event addToCart
      tagManager.fireEvent(eventTypes.videoUpload)
    }
    setStateField(contextFields.videoFile, video)
  }

  const validateObjectivesMoments = () => {
    if (!caseType) {
      setObjectivesMomentsError('You must select a Case Type!')

      return 'You must select a Case Type!'
    }

    return ''
  }

  const handleSetMomentsClick = () => {
    if (videoFile.duration) {
      showObjectivesMoments()
    }
    // add video error message
  }

  const handleEditingClick = () => {
    let errorStrings = validateTestName() + validateVideo()

    if (testType === testTypes.legal) {
      errorStrings += validateObjectivesMoments()
    }

    if (!errorStrings) {
      setIsEditing({...isEditing, testSetup: false})
    } else {
      utils.scrollToError('TestSetup')
    }
  }

  const handleNextClick = () => {
    let errorStrings = validateTestName() + validateVideo()

    if (testType === testTypes.legal) {
      errorStrings += validateObjectivesMoments()
    }

    if (!errorStrings) {
      pageUtil.scrollToTop()
      setHasPassed({...hasPassed, testSetup: true})
      nextStep()
    } else {
      utils.scrollToError('TestSetup')
    }
  }

  const renderUploadArea = () => {
    if (isConvertingVideo) {
      return (
        <Grid style={{fontSize: '1rem'}}>
          <Loading />
        </Grid>
      )
    }

    if (videoFile.preview || videoFile.id) {
      return <VideoThumb videoFile={videoFile} setVideoFile={setVideoFile} />
    }

    return (
      <InputValidator errors={videoError} customValidation={validateVideo}>
        <UploadVideo
          availableVideos={availableVideos}
          isFetching={isFetching}
          videoFile={videoFile}
          setVideoFile={setVideoFile}
          setIsConvertingVideo={setIsConvertingVideo}
        />
      </InputValidator>
    )
  }

  return (
    // <MuiPickersUtilsProvider utils={MomentUtils}>
    <Grid item lg={3} md={6} sm={12} className={classes.newTestSection}>
      <Grid className={classes.nameSection}>
        <Grid className={classes.titleSection}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Test Setup
            <InfoTooltip
              text={
                <span>
                  Begin your test by following the steps below.
                  <br />
                  <br />
                  {`Test costs are based on ${configs.campaignMinimumPanelists} panelists viewing up to a maximum of one minute of video.`}
                  <br />
                  <br />
                  {`Each additional minute viewed and each additional panelist adds ${utils.formatValue(
                    configs.pricePerMinute,
                  )} per panelist minute.`}
                </span>
              }
              placement="right"
              open={stepInfoTooltipOpen}
              onOpen={() => setStepInfoTooltipOpen(true)}
              onClose={() => setStepInfoTooltipOpen(false)}
            />
          </Typography>
          <SaveSetupChangesButton
            hasPassed={hasPassed.testSetup}
            isEditing={isEditing.testSetup}
            onClick={handleEditingClick}
          />
        </Grid>
        <Typography variant="body1" className={classes.sectionSubtitle}>
          Test Name
        </Typography>
        <InputValidator
          errors={nameError}
          shouldValidateOnBlur={true}
          customValidation={validateTestName}
        >
          <TextField
            value={testName}
            onChange={({target}) => setStateField(contextFields.testName, target.value.trimStart())}
            variant="outlined"
            className={classes.nameInput}
            inputProps={{
              classes: {
                root: classes.nameInputOutline,
                notchedOutline: nameError ? classes.errorOutline : classes.notchedOutline,
              },
              maxLength: testNameMaxLength,
            }}
            placeholder="Type your test name"
          />
          {testName?.length ? (
            <Typography className={classes.nameLengthIndicator}>
              {`${testName.length}/${testNameMaxLength}`}
            </Typography>
          ) : null}
        </InputValidator>
      </Grid>
      <Grid className={classes.typeSection}>
        <Typography variant="h6" className={classes.sectionTitle}>
          Test Type
        </Typography>
        <Typography variant="body1" className={classes.sectionSubtitle}>
          Are you testing a Creative or Legal Case?
        </Typography>
        <RadioGroup
          aria-label="test-type"
          name="testType"
          value={testType}
          onChange={({target}) => setStateField(contextFields.testType, target.value)}
          row
        >
          <FormControlLabel
            value={testTypes.creative}
            control={<Radio color="primary" />}
            label="Creative"
            labelPlacement="end"
          />
          <FormControlLabel
            value={testTypes.legal}
            control={<Radio color="primary" />}
            label="Legal Case"
            labelPlacement="end"
          />
        </RadioGroup>
      </Grid>
      <Grid className={classes.uploadSection}>
        <Typography variant="h6" className={classes.sectionTitle}>
          Upload Video
        </Typography>
        {renderUploadArea()}
        <Typography variant="body1" className={classes.sectionSubtitle}>
          Allowed Types: mp4, mov, mpeg, avi, wmv
          <br /> Max File Size: 50MB
        </Typography>
      </Grid>
      {!_.isEmpty(videoFile) && testType === testTypes.legal && (
        <Grid className={classes.uploadSection}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Case Type & Objectives
          </Typography>
          <InputValidator
            errors={objectivesMomentsError}
            customValidation={validateObjectivesMoments}
          >
            <Button
              variant="contained"
              color="primary"
              style={{width: 'fit-content', padding: '8 32'}}
              onClick={handleSetMomentsClick}
            >
              Set Case Type & Objectives
            </Button>
          </InputValidator>
        </Grid>
      )}
      {/* <Grid
          className={classes.scheduleSection}
          style={{display: 'none'}}
          // style={!isActive && !isEditing.testSetup ? {paddingBottom: 0} : {}}
        >
          <Typography variant="h6" className={classes.sectionTitle}>
            Schedule Your Test
            <InfoTooltip
              text={
                <span>
                  Select a time zone and the start time to begin delivering the test to the
                  audience.
                  <br />
                  <br />
                  If you don&apos;t select a start time, the test will deploy immediately after
                  being published.
                </span>
              }
              placement="right"
            />
          </Typography>
          <Typography variant="body1" className={classes.sectionSubtitle}>
            Preferred Time Zone
          </Typography>
          <Select
            className={classes.selectRoot}
            classes={{icon: classes.selectIcon, selectMenu: classes.selectMenu}}
            value={timezone}
            variant="outlined"
            IconComponent={SelectIcon}
            onChange={({target}) => {
              setStateFields(
                [contextFields.startDate, contextFields.timezone],
                [pageUtil.getDatetimeInTimeZone(target.value), target.value],
              )
            }}
            input={
              <OutlinedInput labelWidth={0} classes={{notchedOutline: classes.notchedOutline}} />
            }
          >
            {timezones.map((timezone, index) => (
              <MenuItem key={index} value={timezone.name} disabled={timezone.isDisabled}>
                {`${timezone.label} (GMT ${timezone.offset})`}
              </MenuItem>
            ))}
          </Select>
          <Typography variant="body1" style={{marginTop: 16}} className={classes.sectionSubtitle}>
            Start Time
          </Typography>
          <DateTimePicker
            InputProps={{
              classes: {notchedOutline: classes.notchedOutline, input: classes.dateTimeInput},
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <img src={calendarIcon} alt="calendar" className={classes.calendarIcon} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            clearable
            minDate={pageUtil.getDatetimeInTimeZone(timezone)}
            variant="outlined"
            value={startDate}
            fullWidth
            onChange={e => {
              setStateField(contextFields.startDate, e.toDate())
            }}
            format="MM/DD/YYYY @ hh:mm a"
          />
        </Grid> */}
      {isActive && (
        <Button
          variant="contained"
          color="primary"
          className={classes.nextButton}
          onClick={handleNextClick}
        >
          Next
          <ChevronRightIcon fontSize="small" style={{marginLeft: 8}} />
        </Button>
      )}
      <SaveSetupChangesButton
        hasPassed={hasPassed.testSetup}
        isEditing={isEditing.testSetup}
        onClick={handleEditingClick}
        variant="secondary"
        style={{
          alignSelf: 'flex-end',
          marginTop: 'auto',
        }}
      />
    </Grid>
    // </MuiPickersUtilsProvider>
  )
}

TestSetup.propTypes = {
  availableVideos: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isFetching: PropTypes.bool.isRequired,
  nextStep: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  configs: PropTypes.objectOf(PropTypes.number),
}

TestSetup.defaultProps = {
  configs: {
    campaignMinimumPanelists: 50,
    minimumPanelistsPerGroup: 50,
    groupPanelistsIncrementDecrementOffset: 25,
    pricePerMinute: 6,
  },
}

const mapStateToProps = ({campaign}) => ({
  configs: campaign.configs,
})

export default compose(connect(mapStateToProps), withStyles(styles))(TestSetup)
