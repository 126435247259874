/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import {Grid, Divider, Checkbox, FormControlLabel, withStyles, Typography} from '@material-ui/core'

const styles = theme => ({
  selectBox: {
    display: 'grid',
    textAlign: 'left',
  },
  selectGroups: {
    display: 'flex',
    flexDirection: 'column',
  },
  sectionTitle: {
    fontSize: '1.2rem',
    fontWeight: 500,
  },
  label: {
    color: theme.palette.grey[800],
    lineHeight: 1,
  },
  checkbox: {
    padding: theme.spacing.unit,
  },
  selectAllDivider: {
    background:
      'repeating-linear-gradient(to right, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12) 8px, transparent 8px, transparent 16px)',
    margin: `${theme.spacing.unit} 0`,
  },
  groupDivider: {
    margin: `${theme.spacing.unit * 2} 0`,
    height: 2,
    background:
      'repeating-linear-gradient(to right, #BDBFC2, #BDBFC2 8px, transparent 8px, transparent 16px)',
  },
  divider: {
    margin: `${theme.spacing.unit} 0`,
  },
  selectParent: {
    margin: 0,
    marginLeft: theme.spacing.unit * -1,
  },
  selectChild: {
    margin: 0,
    marginLeft: theme.spacing.unit * 2,
  },
})

function DemographicSelect(props) {
  const {
    classes,
    handleGroupChange,
    handleSelectAllGroupsChange,
    areAllGroupsSelected,
    isGroupSelected,
    handleChildChange,
    handleParentChange,
    isChildSelected,
    isParentSelected,
    selectChildren,
    selectParents,
    groups,
    disabledDemographics,
    disabledGroups,
  } = props

  return (
    <Grid className={classes.selectBox}>
      {groups && groups.length ? (
        <Grid className={classes.selectGroups}>
          <Typography
            className={classes.sectionTitle}
            style={disabledGroups ? {color: '#00000061'} : {}}
          >
            Selected Groups
          </Typography>
          <FormControlLabel
            className={classes.selectParent}
            classes={{label: classes.label}}
            control={
              <Checkbox
                className={classes.checkbox}
                checked={areAllGroupsSelected()}
                onChange={handleSelectAllGroupsChange}
                color="primary"
                disabled={disabledGroups}
              />
            }
            label="Select All"
          />
          {groups.map((group, index) => (
            <FormControlLabel
              key={index}
              className={classes.selectParent}
              classes={{label: classes.label}}
              control={
                <Checkbox
                  className={classes.checkbox}
                  checked={isGroupSelected(group)}
                  onChange={() => handleGroupChange(group)}
                  color="primary"
                  disabled={disabledGroups}
                />
              }
              label={group.name}
            />
          ))}
          <Divider className={classes.groupDivider} />
        </Grid>
      ) : null}

      <Typography
        className={classes.sectionTitle}
        style={disabledDemographics ? {color: '#00000061'} : {}}
      >
        Demographics
      </Typography>
      {selectParents.map((parent, index) => {
        if (selectChildren[parent.label]) {
          return (
            <React.Fragment key={index}>
              <FormControlLabel
                className={classes.selectParent}
                classes={{label: classes.label}}
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={isParentSelected(parent.label)}
                    onChange={() => handleParentChange(parent.label)}
                    color="primary"
                    disabled={disabledDemographics}
                  />
                }
                label={
                  <span>
                    {`${parent.value} `}
                    <span style={{fontStyle: 'italic'}}>(Select All)</span>
                  </span>
                }
              />
              {selectChildren[parent.label].map((child, index) => (
                <FormControlLabel
                  key={index}
                  className={classes.selectChild}
                  classes={{label: classes.label}}
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      checked={isChildSelected(parent.label, child.label)}
                      onChange={() => handleChildChange(parent.label, child.label)}
                      color="primary"
                      disabled={disabledDemographics}
                    />
                  }
                  label={child.value}
                />
              ))}
              {index !== selectParents.length - 1 && <Divider className={classes.divider} />}
            </React.Fragment>
          )
        }

        return null
      })}
    </Grid>
  )
}

DemographicSelect.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleGroupChange: PropTypes.func,
  handleSelectAllGroupsChange: PropTypes.func,
  isGroupSelected: PropTypes.func,
  areAllGroupsSelected: PropTypes.func,
  handleChildChange: PropTypes.func.isRequired,
  handleParentChange: PropTypes.func.isRequired,
  isChildSelected: PropTypes.func.isRequired,
  isParentSelected: PropTypes.func.isRequired,
  selectChildren: PropTypes.objectOf(PropTypes.any).isRequired,
  selectParents: PropTypes.arrayOf(PropTypes.object).isRequired,
  groups: PropTypes.arrayOf(PropTypes.object),
  disabledDemographics: PropTypes.bool,
  disabledGroups: PropTypes.bool,
}

DemographicSelect.defaultProps = {
  groups: [],
  handleGroupChange: () => {},
  handleSelectAllGroupsChange: () => {},
  isGroupSelected: () => {},
  areAllGroupsSelected: () => {},
  disabledDemographics: false,
  disabledGroups: false,
}

export default React.memo(withStyles(styles)(DemographicSelect))
