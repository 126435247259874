import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {Grid, withStyles} from '@material-ui/core'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import withWidth from '@material-ui/core/withWidth'
import * as Actions from './redux/actions'
import styles from './styles/Campaign.styles'
import pageUtil from '../utils/PageUtils'
import TableData from './TableData.jsx'
import Loading from '../common/Loading.jsx'

class CampaignList extends React.Component {
  constructor() {
    super()
    this.state = {
      page: 0,
      rowsPerPage: 30,
    }
    this.changePage = this.changePage.bind(this)
  }

  componentDidMount() {
    this.props.getCampaigns({orderBy: '-from_date'})
    const {width} = this.props
    const mobile = !!(width === 'xs' || width === 'sm')
    if (!mobile) {
      pageUtil.scrollToTop()
    }
    const ReactPixel = require('react-facebook-pixel').default
    ReactPixel.pageView()
  }

  changePage(e, page) {
    this.props.getCampaigns({orderBy: '-from_date', page: page + 1})
    this.setState({page})
  }

  getStatus(campaign) {
    const fromDate = new Date(campaign.fromDate)
    const toDate = new Date(campaign.toDate)
    const today = new Date()
    if (fromDate > today) {
      return <p style={{color: '#ff8906'}}>PENDING</p>
    }
    if (fromDate <= today && today <= toDate) {
      return <p style={{color: '#00C000'}}>RUNNING</p>
    }
    if (toDate < today) {
      return <p style={{color: '#FF2C52'}}>COMPLETE</p>
    }
  }

  render() {
    const {classes, isFetching, results, count} = this.props

    return (
      <Grid container justify="center" style={{padding: '30'}}>
        <Helmet title="Tests" />
        {isFetching ? (
          <Loading />
        ) : (
          <Grid item xs={12}>
            {results.length === 0 ? (
              <Grid item>
                <p className={classes.hintText}>You do not have any tests yet</p>
              </Grid>
            ) : (
              <TableData
                data={results}
                getStatus={this.getStatus}
                count={count}
                page={this.state.page}
                rowsPerPage={this.state.rowsPerPage}
                changePage={this.changePage}
              />
            )}
          </Grid>
        )}
      </Grid>
    )
  }
}

CampaignList.displayName = 'CampaignList'

CampaignList.propTypes = {}

CampaignList.contextTypes = {
  router: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

function mapStateToProps(state) {
  return {
    ...state.campaign,
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withWidth(),
  withStyles(styles),
)(CampaignList)
