const styles = theme => ({
  container: {
    overflow: 'auto',
    overflowX: 'hidden',
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
  },
  label: {
    fontSize: '16px',
    color: '#131822',
    textDecoration: 'underline',
    textDecorationSkipInk: 'auto',
    cursor: 'pointer',
  },
  removeDemographicButton: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    marginLeft: '8px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  demographicContainer: {
    '&:not(:first-child)': {
      marginTop: '10px',
    },
  },
  demographicChip: {
    color: 'white',
    margin: '0 10px 10px 0',
    padding: '11px 12px 11px 16px',
    '& span': {
      padding: 0,
      paddingRight: '12px',
      fontSize: '14px',
    },
  },
  deleteIcon: {
    margin: 0,
    color: 'white',
    '&:hover': {
      filter: 'brightness(0.9)',
      color: 'white',
    },
  },
})

export default styles
