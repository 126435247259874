import React from 'react'
import {Button, Dialog, Grid, Typography} from '@material-ui/core'

const WarningModal = props => {
  const {classes, warnings, openModal, handleCloseModal} = props
  return (
    <Dialog PaperProps={{classes: {root: classes.confirmEditingModal}}} open={openModal}>
      <Typography variant="h5" className={classes.confirmEditingTitle}>
        NOTICE
      </Typography>
      <Grid className={classes.confirmEditingTextContainer}>
        {warnings.map((warning, index) => (
          <Typography key={index} className={classes.confirmEditingText}>
            {warning}
          </Typography>
        ))}
      </Grid>
      <Button
        className={classes.confirmEditingButton}
        variant="contained"
        color="primary"
        onClick={() => handleCloseModal()}
      >
        Continue
      </Button>
    </Dialog>
  )
}

export default WarningModal
