const styles = theme => ({
  chartsContainer: {
    padding: `${theme.spacing.unit * 3} 0`,
  },
  compareChartsContainer: {
    padding: `${theme.spacing.unit * 3} 0`,
  },
  filterResponseContainer: {
    textTransform: 'capitalize',
    display: 'flex',
    padding: '0 24px !important',
    '& p': {
      color: theme.palette.grey[800],
      fontSize: '1rem',
      fontWeight: 500,
    },
  },
  filterText: {
    textTransform: 'capitalize',
    marginBottom: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit * 3,
    color: theme.palette.grey[800],
    fontSize: '1rem',
    fontWeight: 500,
    [theme.breakpoints.down(1077)]: {
      paddingLeft: theme.spacing.unit / 2,
    },
  },
  textContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  chartTitle: {
    fontSize: '1.2rem',
    fontWeight: 500,
    color: theme.palette.grey[700],
  },
  chartBenchmark: {
    color: '#219653',
    fontWeight: 500,
    margin: `${theme.spacing.unit / 2} 0`,
    position: 'relative',
  },
  chartDescription: {
    width: '27ch',
    color: theme.palette.grey[700],
  },
  benchmarkTextContainer: {
    display: 'grid',
    gridGap: `${theme.spacing.unit * 2}px`,
    justifyContent: 'flex-end',
    marginTop: theme.spacing.unit * 3,
  },
  benchmarkText: {
    fontSize: '1.1rem',
    fontWeight: 300,
    lineHeight: '1.4rem',
    width: '49ch',
  },
  textIconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      marginRight: theme.spacing.unit * 2,
    },
  },
})

export default styles
