import React, {useState, useEffect, useMemo} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {Grid, TableBody, withStyles} from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import ReactPaginate from 'react-paginate'
import _ from 'lodash'
import NoDataAvailable from '../common/NoDataAvailable'
import Loading from '../common/Loading'

import circleNext from '../../assets/icons/circleNext.svg'
import circleBack from '../../assets/icons/circleBack.svg'

import {getSurveyResults} from './redux/actions'

import styles from './styles/NewTableData.styles'

const ITEMS_PER_PAGE = 10

const tableHeaderCellCol1Style = {
  color: '#3A3B3F',
  fontFamily: 'Roboto',
  fontSize: '14px',
  letterSpacing: '-0.05px',
  lineHeight: '20px',
  width: '850px',
  overflow: 'hidden',
}
const tableHeaderCellCol2Style = {
  ...tableHeaderCellCol1Style,
  width: '400px',
}
const tableCellCol1Style = {
  ...tableHeaderCellCol1Style,
  borderBottom: 'none',
}
const tableCellCol2Style = {
  ...tableCellCol1Style,
  width: '400px',
}
const demographicCellTitleStyle = {
  ...tableHeaderCellCol2Style,
  cursor: 'pointer',
}

function SurveyResultsOpenEndedAnswers(props) {
  const {classes, campaign, currentQuestion, setCurrentQuestion, selectedGender} = props
  const [dropDownQuestions, setDropDownQuestions] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [currentSorting, setCurrentSorting] = useState(null)

  useEffect(() => {
    const openEndedQuestions = campaign.campaign.surveyQuestionsDetail.filter(
      question => question.type === 1,
    )
    setDropDownQuestions(openEndedQuestions)
    if (openEndedQuestions.length) {
      setCurrentQuestion(openEndedQuestions[0].question)
    }
  }, [campaign.campaign])

  useEffect(() => {
    if (!_.isEmpty(currentQuestion)) {
      setCurrentPage(0)
      setCurrentSorting(null)
    }
  }, [currentQuestion, selectedGender])

  const sortByDemographic = () => {
    setCurrentSorting(prevState => (prevState === 'asc' ? 'desc' : 'asc'))
  }

  const getSortingIcon = () => {
    const className = 'fa fa-sort'

    return currentSorting ? className + `-${currentSorting}` : className
  }

  const sortedResults = useMemo(() => {
    const results = campaign.surveyResults.results.find(
      answers => answers.question === currentQuestion,
    )

    if (!results || results.type === 'multiple-choice') return {answers: []}

    return {
      ...results,
      answers: results.answers.sort((prev, next) => {
        const [a, b] = currentSorting === 'desc' ? [next, prev] : [prev, next]
        return a[1].demographic.localeCompare(b[1].demographic)
      }),
    }
  }, [campaign.surveyResults.results, currentSorting])

  const paginatedResults = useMemo(() => {
    const results = sortedResults

    if (!results) return {answers: []}

    return {
      ...results,
      answers: results.answers.slice(
        currentPage * ITEMS_PER_PAGE,
        (currentPage + 1) * ITEMS_PER_PAGE,
      ),
    }
  }, [sortedResults, currentPage, currentQuestion])

  const getTableData = () => {
    const {answers} = paginatedResults

    if (!answers.length) return null

    return (
      <Table>
        <TableHead>
          <TableRow classes={{head: classes.reduceTableHeaderHeight}}>
            <TableCell style={tableHeaderCellCol1Style}>Answer</TableCell>
            <TableCell style={demographicCellTitleStyle} onClick={sortByDemographic}>
              Demographic Details
              <i
                className={getSortingIcon()}
                aria-hidden="true"
                style={{paddingLeft: 10, color: '#B0BAC9'}}
              />
            </TableCell>
            {paginatedResults.hasCompensation && (
              <TableCell style={tableHeaderCellCol1Style}>Compensation</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {answers.map((answer, index) => (
            <TableRow key={index} style={{backgroundColor: index % 2 ? '#F7F7F7' : '#FFFFFF'}}>
              <TableCell style={tableCellCol1Style}>{answer[1].answer}</TableCell>
              <TableCell style={tableCellCol2Style}>{answer[1].demographic}</TableCell>
              {paginatedResults.hasCompensation && (
                <TableCell style={tableCellCol2Style}>{answer[1].compensation}</TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }

  const getTotalAnswerCount = () => {
    return paginatedResults ? paginatedResults.totalResponses : 0
  }

  const changePage = (e, page) => {
    setCurrentPage(page)
  }

  const getPageLabel = (page, count, ITEMS_PER_PAGE) => {
    const minItem = page * ITEMS_PER_PAGE + 1
    const maxItem = (page + 1) * ITEMS_PER_PAGE

    return `Showing ${minItem} to ${maxItem > count ? count : maxItem} of ${count}`
  }

  if (!dropDownQuestions.length || _.isEmpty(currentQuestion)) return <NoDataAvailable />
  return (
    <>
      {campaign.surveyResults.isFetching ? (
        <Loading />
      ) : (
        <Grid container>
          <Grid item xs>
            {getTableData()}
          </Grid>
        </Grid>
      )}
      {getTotalAnswerCount() > ITEMS_PER_PAGE && (
        <Grid container>
          {' '}
          <Grid item style={{alignSelf: 'center', marginLeft: '24px'}}>
            <div style={{fontSize: '13px', color: 'rgba(54, 56, 83, 0.6)'}}>
              {getPageLabel(currentPage, getTotalAnswerCount(), ITEMS_PER_PAGE)}
            </div>
          </Grid>
          <Grid item xs />
          <Grid item>
            <ReactPaginate
              breakLabel="..."
              pageCount={Math.ceil(getTotalAnswerCount() / ITEMS_PER_PAGE)}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              nextLabel={<img src={circleNext} alt="next" />}
              previousLabel={<img src={circleBack} alt="back" />}
              containerClassName={classes.paginationContainer}
              pageClassName={classes.page}
              previousClassName={
                currentPage === 0 ? classes.buttonNextAndBackDisabled : classes.buttonNextAndBack
              }
              nextClassName={
                currentPage === Math.ceil(getTotalAnswerCount() / ITEMS_PER_PAGE) - 1
                  ? classes.buttonNextAndBackDisabled
                  : classes.buttonNextAndBack
              }
              breakClassName={classes.break}
              activeClassName={classes.active}
              onPageChange={page => {
                changePage(null, page.selected)
              }}
              forcePage={currentPage}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}

SurveyResultsOpenEndedAnswers.propTypes = {
  campaign: PropTypes.objectOf(PropTypes.any).isRequired,
}

const mapStateToProps = ({campaign}) => ({
  campaign,
  demographicsWithValues: campaign.demographicsWithValues.values,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSurveyResults,
    },
    dispatch,
  )

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(SurveyResultsOpenEndedAnswers)
