import React from 'react'
import Helmet from 'react-helmet'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Card, CardContent, Button, Typography, Divider} from '@material-ui/core'
import validatedComponent from '../common/ValidatedComponent.jsx'
import * as Actions from './redux/actions.changeEmail'

class EmailVerify extends React.Component {
  onVerifyClick(e) {
    e.preventDefault()
    const {id, token} = this.props.params
    this.props.verifyEmail(id, token)
  }

  componentDidMount() {
    const ReactPixel = require('react-facebook-pixel').default
    ReactPixel.pageView()
  }

  render() {
    return (
      <div className="registrationPage">
        <Helmet title="Confirm Your Email" />
        <Card className="registrationPage__card registrationPage__card--centered">
          <CardContent className="registrationPage__card__content">
            <Typography
              component="h2"
              variant="h2"
              className="registrationPage__title"
              gutterBottom
            >
              Click here to verify email
            </Typography>
            <Divider />
            <Button
              onClick={this.onVerifyClick.bind(this)}
              variant="contained"
              className="registrationPage__card__confirmButton"
            >
              Verify
            </Button>
          </CardContent>
        </Card>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

EmailVerify.displayName = 'EmailVerify'

export default connect(null, mapDispatchToProps)(validatedComponent(EmailVerify))
