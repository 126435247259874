import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {Chip, Grid, MenuItem, Typography, withStyles} from '@material-ui/core'
import Select from 'react-select'

import CloseIcon from '@material-ui/icons/Close'

import styles from './styles'

const QualifierOptionsSelector = React.memo(props => {
  const {classes, demographic, demographicHelpers, groupTempStateDemographics} = props
  const selectRef = useRef()

  const [options, setOptions] = useState([])

  const getSelectedOptions = () => {
    const foundDemographic = groupTempStateDemographics.find(({key}) => key === demographic.key)

    if (foundDemographic) {
      return foundDemographic.subDemographics.map(subDemographic => ({
        value: subDemographic,
        label: subDemographic.displayName,
      }))
    }

    return []
  }

  const selectedOptions = getSelectedOptions()

  useEffect(() => {
    if (demographic.subDemographics) {
      setOptions(
        Object.values(demographic.subDemographics).map(demographicOption => ({
          value: demographicOption,
          label: demographicOption.displayName,
          precode: demographicOption.precode,
        })),
      )
    }
  }, [demographic])

  const updateDemographic = selected => {
    const foundDemographic = groupTempStateDemographics.find(({key}) => key === demographic.key)

    if (selected.length && !foundDemographic) {
      demographicHelpers.addDemographic(
        demographic.key,
        selected.map(({value}) => value),
      )
    } else if (!selected.length) {
      demographicHelpers.removeDemographic(demographic.key)
    } else {
      demographicHelpers.updateDemographic(
        demographic.key,
        selected.map(({value}) => value),
      )
    }
  }

  const handleSelectChange = selected => {
    updateDemographic(selected)
  }

  const handleClearSelectionsClick = () => {
    const select = selectRef.current?.select
    if (select.clearValue) {
      select.clearValue()
    }
  }

  // eslint-disable-next-line
  const Option = props => {
    return (
      <MenuItem
        buttonRef={props.innerRef}
        selected={props.isFocused}
        classes={{root: classes.menuOption, selected: classes.selectedOption}}
        {...props.innerProps}
      >
        {props.children}
      </MenuItem>
    )
  }

  // eslint-disable-next-line
  const NoOptionsMessage = props => {
    return (
      <Typography className={classes.noOptionsMessage} {...props.innerProps}>
        {selectedOptions.length === props.options.length
          ? 'No options'
          : 'No results found... try a new search'}
      </Typography>
    )
  }

  // eslint-disable-next-line
  const MultiValue = props => {
    return (
      <Chip
        tabIndex={-1}
        label={props.children}
        className={classes.demographicChip}
        color="primary"
        onDelete={props.removeProps.onClick}
        deleteIcon={<CloseIcon className={classes.deleteIcon} {...props.removeProps} />}
      />
    )
  }

  const handleMenuOpen = () => {
    setTimeout(() => {
      const menuRef = selectRef.current?.select?.menuListRef

      if (menuRef) {
        menuRef.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        })
      }
    }, 100)
  }

  const handleInputChange = () => {
    const {menuListRef} = selectRef.current.select
    if (menuListRef) {
      menuListRef.scroll({top: 0})
    }
  }

  const selectStyles = {
    control: (baseStyles, state) => {
      return {
        ...baseStyles,
        position: 'relative',
        padding: `14px 4px ${selectedOptions.length ? '4px' : '14px'} 4px !important`,
        color: '#989898',
        boxShadow: 'none',
        borderColor: '#c0c0c0',
        '&:hover': {
          boxShadow: 'none',
          borderColor: '#87888A',
        },
        ...(state.menuIsOpen && {
          boxShadow: 'none',
          borderColor: '#87888A',
          borderBottomColor: 'transparent',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          '&:hover, &:focus-within, &:focus, &:active': {
            boxShadow: 'none',
            borderColor: '#87888A',
            borderBottomColor: 'transparent',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '90%',
            height: '1px',
            backgroundColor: '#E6E8EC',
            zIndex: 2,
          },
        }),
      }
    },
    menu: baseStyles => ({
      ...baseStyles,
      border: '1px solid #87888A',
      borderRadius: 0,
      boxShadow: 'none',
      borderTopColor: 'transparent',
      borderBottomRightRadius: '4px',
      borderBottomLeftRadius: '4px',
      marginTop: '-2px',
      overflow: 'hidden',
      '& > div': {
        paddingBottom: 0,
      },
    }),
    input: baseStyles => ({
      ...baseStyles,
      marginTop: selectedOptions.length ? '-5px' : 'initial',
      '& > div': {
        margin: 0,
        padding: 0,
      },
      '& input': {
        fontSize: '16px !important',
        fontFamily: 'Roboto !important',
        color: '#5C5E63 !important',
      },
    }),
  }

  return (
    <Grid>
      <Grid className={classes.header}>
        <Typography className={classes.demographicLabel}>{demographic.label}</Typography>
        {Boolean(selectedOptions?.length) && (
          <Typography onClick={handleClearSelectionsClick} className={classes.clearSelections}>
            Clear All Selections
          </Typography>
        )}
      </Grid>
      <Select
        ref={selectRef}
        components={{
          // reminder to avoid custom components in this version as much as possible
          Option,
          NoOptionsMessage,
          MultiValue,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          ClearIndicator: () => null,
        }}
        options={options}
        value={selectedOptions}
        onChange={handleSelectChange}
        onInputChange={handleInputChange}
        onMenuOpen={handleMenuOpen}
        blurInputOnSelect={false}
        placeholder="Type to search..."
        closeMenuOnSelect={false}
        isClearable={false}
        styles={selectStyles}
        isMulti
      />
    </Grid>
  )
})

QualifierOptionsSelector.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  demographic: PropTypes.objectOf(PropTypes.any).isRequired,
  demographicHelpers: PropTypes.shape({
    addDemographic: PropTypes.func.isRequired,
    removeDemographic: PropTypes.func.isRequired,
    updateDemographic: PropTypes.func.isRequired,
  }).isRequired,
  groupTempStateDemographics: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default withStyles(styles)(QualifierOptionsSelector)
