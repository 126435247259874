import React from 'react'
import PropTypes from 'prop-types'
import {Button, withStyles} from '@material-ui/core'
import clsx from 'clsx'

const styles = theme => ({
  tabSwitchContainer: {
    display: 'flex',
    border: '1px solid #414347',
    borderRadius: 4,
    width: 'max-content',
    marginLeft: theme.spacing.unit * 3,
    padding: '2px',
  },
  switchButton: {
    width: 147,
    height: 30,
    borderRadius: 3,
    position: 'relative',
    color: '#FFFFFF !important',
    transition: 'color 0.2s ease-in-out, background 0.2s ease-in-out',
  },
  selectedBackground: {
    background: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      background: theme.palette.primary.main,
      filter: 'brightness(1.1)',
    },
  },
  inactiveBackground: {
    color: '#414347 !important',
    background: 'transparent',
  },
})

const SurveyQuestionTypeSwitch = ({
  classes,
  showingMultipleChoiceAnswer,
  setShowingMultipleChoiceAnswer,
  isFetching,
}) => {
  const renderButton = (type, text) => {
    const isActive = showingMultipleChoiceAnswer === (type === 'multiple-choice')

    return (
      <Button
        className={clsx(
          classes.switchButton,
          isActive ? classes.selectedBackground : classes.inactiveBackground,
        )}
        onClick={() => setShowingMultipleChoiceAnswer(type === 'multiple-choice')}
        disabled={isFetching}
      >
        {text}
      </Button>
    )
  }

  return (
    <div className={classes.tabSwitchContainer}>
      {renderButton('multiple-choice', 'Multiple Choice')}
      {renderButton('open-ended', 'Open Ended')}
    </div>
  )
}

SurveyQuestionTypeSwitch.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  showingMultipleChoiceAnswer: PropTypes.bool.isRequired,
  setShowingMultipleChoiceAnswer: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
}

export default withStyles(styles)(SurveyQuestionTypeSwitch)
