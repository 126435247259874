import React, {useEffect, useState, useContext} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {
  Button,
  Dialog,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

// import IdentifyMoments from './IdentifyMoments'
import SwitchButton from '../../common/SwitchButton'
import DeleteButton from '../../common/DeleteButton'
import AddButton from '../../common/AddButton'

import NewTestContext from '../context/NewTestContext'
import NewTestErrorContext from '../context/NewTestErrorContext'

import {contextFields} from '../../constants/NewTest'
import utils from '../utils'
import pageUtils from '../../utils/PageUtils'

import emptyObjectivesIcon from '../../../assets/icons/empty-objectives.svg'

import styles from '../styles/ObjectivesMoments.styles'

const SelectIcon = withStyles(theme => ({
  root: {
    marginRight: theme.spacing.unit / 2,
    color: theme.palette.grey[800],
  },
}))(ExpandMoreIcon)

const ObjectivesMoments = ({classes, caseTypes, hideObjectivesMoments, configs}) => {
  const {
    videoFile,
    caseType,
    objectives,
    moments,
    setStateField,
    setStateFields,
    awardAnswers,
    setAwardAnswers,
    awardAnswersLength,
    setAwardAnswersLength,
    coreIssue,
  } = useContext(NewTestContext)

  const {
    caseTypeError,
    setCaseTypeError,
    momentNameErrors,
    setMomentNameErrors,
    momentStartTimeErrors,
    setMomentStartTimeErrors,
    momentEndTimeErrors,
    setMomentEndTimeErrors,
    setObjectivesMomentsError,
  } = useContext(NewTestErrorContext)

  const [warningModalOpen, setWarningModalOpen] = useState(false)
  const [videoDuration, setVideoDuration] = useState(0)
  const [prevState, setPrevState] = useState({})

  const [inputAwardErrors, setInputAwardErrors] = useState({1: false, 2: false})
  const [error, setError] = useState('')
  const [initialAwardAnswers, setInitialAwardAnswers] = useState({})

  const setStateAnswers = (key, value) => {
    setAwardAnswers(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  useEffect(() => {
    setPrevState({caseType, objectives, moments})
    setVideoDuration(videoFile.duration)
    pageUtils.scrollToTop()
  }, [])

  const handleAddQuestion = () => {
    setAwardAnswers(prevState => ({
      ...prevState,
      [awardAnswersLength + 1]: '',
    }))
    setInputAwardErrors(prevState => ({
      ...prevState,
      [awardAnswersLength + 1]: false,
    }))
    setAwardAnswersLength(awardAnswersLength + 1)
  }

  const removeQuestion = index => {
    const answers = awardAnswers
    delete answers[index + 1]
    const newAwardsAnswers = {}
    for (let index = 0; index < Object.values(answers).length; index++) {
      newAwardsAnswers[index + 1] = Object.values(answers)[index]
    }
    setAwardAnswers(newAwardsAnswers)
    setAwardAnswersLength(prevState => prevState - 1)
  }

  const validateAnswersInput = () => {
    const awardAnswersChoices = Object.values(awardAnswers)
    const duplicatedChoices = {}
    for (let i = 0; i < awardAnswersChoices.length; i++) {
      if (!duplicatedChoices[awardAnswersChoices[i]]) {
        duplicatedChoices[awardAnswersChoices[i]] = [i + 1]
      } else {
        duplicatedChoices[awardAnswersChoices[i]].push(i + 1)
      }
      if (!awardAnswersChoices[i]) {
        setInputAwardErrors(prevState => ({
          ...prevState,
          [i + 1]: true,
        }))
      }
    }

    if (awardAnswersChoices.some(choice => choice === '')) {
      setError('The selected case type requires you to fill out answers to this question.')

      utils.scrollToError('ObjectivesMoments')

      return 'The selected case type requires you to fill out answers to this question.'
    }

    if (Object.values(duplicatedChoices).length !== awardAnswersLength) {
      const duplicatedChoicesValues = Object.values(duplicatedChoices)
      for (let i = 0; i < duplicatedChoicesValues.length; i++) {
        if (duplicatedChoicesValues[i].length > 1) {
          duplicatedChoicesValues[i].forEach(t =>
            setInputAwardErrors(prevState => ({
              ...prevState,
              [t]: true,
            })),
          )
        }
      }
      setError('Duplicated choices')

      utils.scrollToError('ObjectivesMoments')

      return 'Duplicated choices'
    }

    setError('')

    return ''
  }

  useEffect(() => {
    const choices = Object.values(inputAwardErrors)
    if (!choices.some(choice => choice)) {
      setError('')
    }
  }, [inputAwardErrors])

  useEffect(() => {
    if (new Set(Object.values(awardAnswers)).size === Object.values(awardAnswers).length) {
      const newErrorObject = {}
      for (let index = 0; index < Object.keys(inputAwardErrors).length; index++) {
        newErrorObject[Object.keys(inputAwardErrors)[index]] = false
      }
      setInputAwardErrors(newErrorObject)
    }
  }, [awardAnswers])

  useEffect(() => {
    const foundCaseType = caseTypes.find(({name}) => name === caseType)

    if (foundCaseType && foundCaseType.objectives && !objectives.length) {
      setStateField(
        contextFields.objectives,
        foundCaseType.objectives.map(
          ({id, name, expandedQuestion, objectiveType, isRequired, openEndedQuestion}) => ({
            id,
            key: name,
            status: true,
            question: expandedQuestion,
            objectiveType,
            isRequired,
            openEndedQuestion,
          }),
        ),
      )
    }
  }, [caseTypes, caseType])

  const setInputError = {
    name: (error, momentIndex) => {
      setMomentNameErrors({
        ...momentNameErrors,
        [momentIndex]: error,
      })
    },
    startTime: error => {
      setMomentStartTimeErrors(error)
    },
    endTime: error => {
      setMomentEndTimeErrors(error)
    },
  }

  const updateTempError = (errorObject, index, text) => {
    return {
      ...errorObject,
      [index]: text,
    }
  }

  const validateMoments = () => {
    let tempStartTimeErrors = {...momentStartTimeErrors}
    let tempEndTimeErrors = {...momentEndTimeErrors}
    let hasNameError = false
    let hasStartTimeError = false
    let hasEndTimeError = false

    for (let i = 0; i < moments.length; i++) {
      const moment = moments[i]

      const startTime = utils.stringToSeconds(moment.startTime)
      const endTime = utils.stringToSeconds(moment.endTime)
      const prevEndTime = moments[i - 1] ? utils.stringToSeconds(moments[i - 1].endTime) : -1

      if (_.isNaN(startTime)) {
        tempStartTimeErrors = updateTempError(tempStartTimeErrors, i, 'Invalid time')
        hasStartTimeError = true
      } else if (_.isNaN(endTime)) {
        tempEndTimeErrors = updateTempError(tempEndTimeErrors, i, 'Invalid time')
        hasEndTimeError = true
      } else {
        if (endTime > videoDuration) {
          tempEndTimeErrors = updateTempError(
            tempEndTimeErrors,
            i,
            'End time should not exceed video length',
          )
          hasEndTimeError = true
        }

        if (startTime >= endTime) {
          tempStartTimeErrors = updateTempError(
            tempStartTimeErrors,
            i,
            'Start time should come before end time',
          )
          hasStartTimeError = true
        } else {
          tempStartTimeErrors = updateTempError(tempStartTimeErrors, i, '')
        }

        if (i !== 0 && startTime < prevEndTime) {
          tempEndTimeErrors = updateTempError(
            tempEndTimeErrors,
            i - 1,
            'Moment ranges should not overlap',
          )

          tempStartTimeErrors = updateTempError(
            tempStartTimeErrors,
            i,
            'Moment ranges should not overlap',
          )
          hasStartTimeError = true
          hasEndTimeError = true
        } else if (tempEndTimeErrors[i - 1] && tempEndTimeErrors[i - 1].includes('overlap')) {
          tempEndTimeErrors = updateTempError(tempEndTimeErrors, i - 1, '')
        }
      }

      if (!validateNameInput(i)) {
        hasNameError = true
      }
    }

    setInputError.startTime(tempStartTimeErrors)
    setInputError.endTime(tempEndTimeErrors)

    return !(hasNameError || hasStartTimeError || hasEndTimeError)
  }

  const validateNameInput = momentIndex => {
    if (!moments[momentIndex].name.length) {
      setInputError.name('Moment name should not be empty', momentIndex)
      return false
    }

    return true
  }

  const handleObjectiveChange = objectiveIndex => {
    setStateField(
      contextFields.objectives,
      objectives.map((objective, index) =>
        index === objectiveIndex
          ? {
              ...objectives[index],
              status: !objectives[index].status,
            }
          : objective,
      ),
    )
  }

  // const handleMomentChange = (key, inputValue, momentIndex) => {
  //   const newMoment = {
  //     ...moments[momentIndex],
  //     [key]: inputValue,
  //   }

  //   setStateField(
  //     contextFields.moments,
  //     moments.map((moment, index) => (index === momentIndex ? newMoment : moment)),
  //   )
  //   setInputError[key]('', momentIndex)
  // }

  // const addMoment = () => {
  //   if (moments.length < maxMoments) {
  //     const newMoments = [...moments, {name: '', startTime: '', endTime: ''}]
  //     setStateField(contextFields.moments, newMoments)
  //   }
  // }

  // const removeMoment = momentIndex => {
  //   let newEndTimeErrors = {...momentEndTimeErrors}

  //   if (
  //     momentEndTimeErrors[momentIndex - 1] &&
  //     momentEndTimeErrors[momentIndex - 1].includes('overlap')
  //   ) {
  //     newEndTimeErrors = {
  //       ...newEndTimeErrors,
  //       [momentIndex - 1]: '',
  //     }
  //   }

  //   const newMoments = moments.filter((_, index) => index !== momentIndex)
  //   setMomentStartTimeErrors({...momentStartTimeErrors, [momentIndex]: ''})
  //   setMomentEndTimeErrors({...newEndTimeErrors, [momentIndex]: ''})
  //   setMomentNameErrors({...momentNameErrors, [momentIndex]: ''})
  //   setStateField(contextFields.moments, newMoments)
  // }

  const checkObjectives = () => {
    return !!objectives.reduce((prev, {status}) => prev + status, 0)
  }

  // const checkMoments = () => {
  //   return !!moments.length
  // }

  const closeModal = () => setWarningModalOpen(false)

  const openModal = () => setWarningModalOpen(true)

  useEffect(() => {
    const answeres = {...awardAnswers}
    setInitialAwardAnswers({awardAnswers: answeres, awardAnswersLength})
  }, [])

  const handleCancel = () => {
    setAwardAnswersLength(initialAwardAnswers.awardAnswersLength)
    setAwardAnswers(initialAwardAnswers.awardAnswers)
    setStateFields(
      [contextFields.caseType, contextFields.objectives, contextFields.moments],
      [prevState.caseType, prevState.objectives, prevState.moments],
    )
    setCaseTypeError('')
    setObjectivesMomentsError('')
    setMomentNameErrors({})
    setMomentStartTimeErrors({})
    setMomentEndTimeErrors({})
    hideObjectivesMoments()
  }

  const createObjectivesRequired = () => {
    const choices = Object.values(awardAnswers)

    const newObjectives = []
    objectives
      .filter(objective => !objective.isRequired)
      .forEach(objective => newObjectives.push(objective))

    const objectiveRequired = objectives.filter(objective => objective.isRequired)[0]

    if (objectiveRequired) {
      newObjectives.push({...objectiveRequired, question: {...objectiveRequired.question, choices}})
    }

    setStateField(contextFields.objectives, newObjectives)
  }

  const handleContinue = () => {
    if (objectives.some(objective => objective.isRequired)) {
      createObjectivesRequired()
      if (validateAnswersInput()) return
    }
    if (validateMoments() && error === '') {
      if (!caseType) {
        setCaseTypeError('You must select a Case Type')
      } else if (!checkObjectives()) {
        openModal()
      } else {
        setObjectivesMomentsError('')
        hideObjectivesMoments()
      }
    }
  }

  const renderObjectiveEmptyState = () => {
    return (
      <Grid container className={classes.emptyObjectivesContainer}>
        <img src={emptyObjectivesIcon} alt="empty objectives" />
        <Typography variant="h6">There are no objectives</Typography>
        <Typography>
          Either this case type does not have any objectives or you have not selected a case type
          yet.
        </Typography>
      </Grid>
    )
  }

  return (
    <Grid container className={classes.objectivesMomentsContainer}>
      <Dialog
        PaperProps={{classes: {root: classes.warningModal}}}
        open={warningModalOpen}
        onClose={closeModal}
      >
        <Typography variant="h5" className={classes.warningTitle}>
          No Selections Made
        </Typography>
        <Typography className={classes.warningText}>
          Are you sure you don&rsquo;t want to select any Objectives?
        </Typography>
        <Grid container className={classes.warningButtonsContainer}>
          <Button variant="outlined" color="secondary" onClick={() => hideObjectivesMoments()}>
            Yes, I&rsquo;m Sure
          </Button>
          <Button variant="contained" color="primary" onClick={closeModal}>
            Make Selections
          </Button>
        </Grid>
      </Dialog>
      <Grid container className={classes.header}>
        <Typography variant="h5">Legal Case Objectives</Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleContinue}
        >
          Continue
        </Button>
      </Grid>
      <Paper className={classes.objectivesMomentsPaper} elevation={0}>
        <Typography className={classes.sectionTitle}>Case Type</Typography>
        <FormControl className={classes.caseTypesSelect} error={!!caseTypeError}>
          <Select
            input={
              <OutlinedInput
                labelWidth={0}
                classes={{
                  notchedOutline: caseTypeError ? classes.errorOutline : classes.notchedOutline,
                }}
              />
            }
            classes={{
              icon: classes.selectIcon,
            }}
            MenuProps={{classes: {paper: classes.selectMenu}}}
            IconComponent={SelectIcon}
            onChange={({target}) => {
              setStateFields([contextFields.caseType, contextFields.objectives], [target.value, []])
              setCaseTypeError('')
              setObjectivesMomentsError('')
            }}
            value={caseType}
            displayEmpty
          >
            <MenuItem value="" disabled>
              <span style={{color: '#a1a1a1'}}>Select</span>
            </MenuItem>
            {caseTypes.map((caseType, index) => (
              <MenuItem key={index} value={caseType.name}>
                {caseType.name}
              </MenuItem>
            ))}
          </Select>
          {caseTypeError && <FormHelperText>{caseTypeError}</FormHelperText>}
        </FormControl>

        <Typography className={classes.sectionTitle} style={{marginBottom: '12px'}}>
          Description
        </Typography>
        <OutlinedInput
          multiline
          classes={{notchedOutline: classes.notchedOutline}}
          rows="4"
          labelWidth={0}
          placeholder="Type your description..."
          onChange={({target}) => {
            setStateFields([contextFields.coreIssue], [target.value])
            setCaseTypeError('')
            setObjectivesMomentsError('')
          }}
          value={coreIssue}
          style={{marginBottom: '24px'}}
        />

        <Typography className={classes.sectionTitle}>Objectives</Typography>
        {!_.isEmpty(objectives)
          ? objectives
              .filter(objective => !objective.isRequired)
              .map(({key, status}, index) => (
                <Grid
                  container
                  key={index}
                  className={classes.objectiveContainer}
                  style={{
                    borderBottom:
                      index !== objectives.length - 1 ? '1px solid #292d3214' : 'initial',
                  }}
                >
                  <Typography>{key}</Typography>
                  <SwitchButton
                    offText="No"
                    onText="Yes"
                    checked={!!status}
                    onClick={() => handleObjectiveChange(index)}
                  />
                </Grid>
              ))
          : renderObjectiveEmptyState()}
        {!_.isEmpty(objectives) && objectives.some(objective => objective.isRequired) ? (
          <Grid>
            {objectives
              .filter(objective => objective.isRequired)
              .map(({question, key}, index) => (
                <Grid key={index} style={index > 0 ? {display: 'none'} : null}>
                  <Typography className={classes.sectionTitle} style={{margin: '24px 0'}}>
                    {key}
                  </Typography>
                  <Typography className={classes.awardCompensationQuestion}>
                    {question.question}
                  </Typography>
                  {error ? (
                    <ul style={{paddingLeft: '20px', marginBottom: '0'}}>
                      <li className={classes.awardCompensationError}>{error}</li>
                    </ul>
                  ) : null}
                </Grid>
              ))}

            <Grid>
              <Grid className={classes.awardAnswerContainer}>
                {Array.from({length: awardAnswersLength}, (_, index) => (
                  <Grid key={index}>
                    <Typography className={classes.answerLabel}>
                      {`Answer ${index + 1}*`}
                    </Typography>
                    <TextField
                      onChange={({target}) => {
                        setStateAnswers(target.name, target.value)
                        setInputAwardErrors(prevState => ({
                          ...prevState,
                          [index + 1]: false,
                        }))
                      }}
                      name={String(index + 1)}
                      variant="outlined"
                      className={classes.nameInput}
                      value={awardAnswers[index + 1]}
                      InputProps={{
                        classes: {
                          root: classes.nameInputOutline,
                          notchedOutline: inputAwardErrors[index + 1]
                            ? classes.errorOutline
                            : classes.notchedOutline,
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <DeleteButton
                              onClick={() => removeQuestion(index)}
                              disabled={index <= 1}
                            />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Enter Answer"
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid style={{display: 'flex', alignItems: 'center', paddingBottom: '26px'}}>
                <AddButton
                  disableRipple
                  disabled={awardAnswersLength === configs.maxSurveyQuestionOptions}
                  className={classes.disabledAddButton}
                  onClick={() => handleAddQuestion()}
                />
                <Typography>Add New Answer</Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        {/* <IdentifyMoments
          moments={moments}
          handleMomentChange={handleMomentChange}
          addMoment={addMoment}
          removeMoment={removeMoment}
          validateMoments={validateMoments}
          validateNameInput={validateNameInput}
          inputErrors={{momentNameErrors, momentStartTimeErrors, momentEndTimeErrors}}
          videoDuration={videoDuration}
        /> */}
      </Paper>
      <Grid container>
        <Button
          variant="outlined"
          color="secondary"
          className={classes.button}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleContinue}
          style={{marginLeft: 16}}
        >
          Continue
        </Button>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = ({campaign}) => ({
  isFetching: campaign.caseTypes.isFetching,
  caseTypes: campaign.caseTypes.results,
  configs: campaign.configs,
})

ObjectivesMoments.propTypes = {
  hideObjectivesMoments: PropTypes.func.isRequired,
}

export default compose(connect(mapStateToProps), withStyles(styles))(ObjectivesMoments)
