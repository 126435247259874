import reduceReducers from 'reduce-reducers'
import * as Types from './actions.types'
import * as ErrorUtils from '../../utils/ErrorUtils'

function getInitialState() {
  return {
    isFetching: false,
    result: false,
    errors: {},
  }
}

function demo(state = getInitialState(), action) {
  switch (action.type) {
    case Types.DETECT_ENGANGE:
      return {
        ...state,
        isFetching: true,
      }
    case Types.DETECT_ENGANGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        result: action.payload,
      }
    case Types.DETECT_ENGANGE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.DETECT_ENGANGE_CLEAR:
      return getInitialState()
    default:
      return state
  }
}

const reducer = reduceReducers((state, action) => demo(state, action))

export default reducer
