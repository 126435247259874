import {call, put, all, takeEvery} from 'redux-saga/effects'
import Api from '../api'
import * as Types from './actions.types'
import * as Actions from './actions'

export default function* demoFlow() {
  yield all([takeEvery(Types.DETECT_ENGANGE, detectEngage)])
}

function* detectEngage(action) {
  const {image} = action
  try {
    const response = yield call(Api.uploadImage, image)
    yield put(Actions.detectEngageSuccess(response.data))
  } catch (error) {
    yield put(Actions.detectEngageFailure(error))
  }
}
