import React from 'react'
import PropTypes from 'prop-types'
import {InputBase, MenuItem, Select, withStyles} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {sentimentColors, emotionDisplayNames} from './constants'

const styles = theme => ({
  menuItem: {
    textTransform: 'capitalize',
  },
  selectMenu: {
    width: theme.spacing.unit * 35,
    '& ul li': {
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        height: 1,
        width: '95%',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        background: '#F1F2F4',
      },
      '&:last-child::after': {
        width: 0,
      },
    },
  },
})

const SentimentInput = withStyles(theme => ({
  root: {
    borderRadius: theme.spacing.unit / 2,
    color: 'white',
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    width: theme.spacing.unit * 12,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
}))(InputBase)

const SelectIcon = withStyles(theme => ({
  root: {
    marginRight: theme.spacing.unit / 2,
    color: 'white',
  },
}))(ExpandMoreIcon)

const SentimentDropdown = ({classes, emotions, currentEmotion, setCurrentEmotion}) => {
  return (
    <Select
      className={classes.select}
      value={currentEmotion}
      label={emotionDisplayNames[currentEmotion]}
      onChange={({target}) => setCurrentEmotion(target.value)}
      input={
        <SentimentInput
          name="sentiment"
          style={{background: sentimentColors[currentEmotion], textTransform: 'capitalize'}}
        />
      }
      IconComponent={SelectIcon}
      MenuProps={{classes: {paper: classes.selectMenu}}}
    >
      {!emotions.length ? (
        <MenuItem value={currentEmotion} className={classes.menuItem}>
          {currentEmotion}
        </MenuItem>
      ) : (
        emotions.map(emotion => (
          <MenuItem key={emotion} value={emotion} className={classes.menuItem}>
            {emotionDisplayNames[emotion]}
          </MenuItem>
        ))
      )}
    </Select>
  )
}

SentimentDropdown.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  currentEmotion: PropTypes.string,
  emotions: PropTypes.arrayOf(PropTypes.string).isRequired,
  setCurrentEmotion: PropTypes.func.isRequired,
}

SentimentDropdown.defaultProps = {
  currentEmotion: '',
}

export default withStyles(styles)(SentimentDropdown)
