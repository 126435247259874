export const gaugeLowColorArray = [249, 87, 87]
export const gaugeMediumColorArray = [255, 217, 19]
export const gaugeHighColorArray = [4, 191, 123]

export const gaugeLowColor = `rgb(${gaugeLowColorArray[0]}, ${gaugeLowColorArray[1]}, ${gaugeLowColorArray[2]})`
export const gaugeMediumColor = `rgb(${gaugeMediumColorArray[0]}, ${gaugeMediumColorArray[1]}, ${gaugeMediumColorArray[2]})`
export const gaugeHighColor = `rgb(${gaugeHighColorArray[0]}, ${gaugeHighColorArray[1]}, ${gaugeHighColorArray[2]})`

const gaugeWidth = 240
const gaugeHeight = gaugeWidth / 2

const styles = theme => ({
  outterContainer: {
    display: 'grid',
    justifyContent: 'center',
  },
  titleContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: '4rem',
  },
  gaugeTitle: {
    textAlign: 'center',
    color: theme.palette.grey[700],
    fontWeight: 500,
    fontSize: '1.2rem',
  },
  gaugeBenchmark: {
    position: 'relative',
    color: '#2f80ed',
    marginTop: theme.spacing.unit / 2,
    marginBottom: theme.spacing.unit / 2,
    fontWeight: 500,
  },
  gaugeDescription: {
    width: '27ch',
    color: theme.palette.grey[700],
  },
  gaugeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    position: 'relative',
    height: gaugeHeight,
    color: '#828282',
  },
  gaugeInnerContainer: {
    position: 'relative',
    overflow: 'hidden',
    width: gaugeWidth,
    height: gaugeHeight,
  },
  gaugeCircle: {
    position: 'absolute',
    width: gaugeWidth,
    height: gaugeWidth,
    borderRadius: '50%',
    top: '100%',
    transform: 'translateY(-50%)',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '80%',
      height: '80%',
      background: 'white',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '50%',
    },
  },
  pointer: {
    position: 'absolute',
    width: '8%',
    aspectRatio: 1,
    background: '#bdbdbd66',
    bottom: 0,
    left: 'calc(50% - 10px)',
    borderRadius: '50%',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '40%',
      height: '400%',
      background: '#bdbdbd',
      clipPath: 'polygon(50% 0%, 0 100%, 100% 100%)',
      bottom: '40%',
      left: '50%',
      transform: 'translateX(-50%)',
      borderBottomLeftRadius: 50,
      borderBottomRightRadius: 50,
    },
  },
  benchmarkContainer: {
    position: 'absolute',
    color: '#2f80ed',
    left: 'calc(50% - 11px)',
    top: '-42px',
    transformOrigin: `50% ${gaugeHeight + 40}px`,
    '&::before': {
      content: '""',
      position: 'absolute',
      height: 8,
      width: 1,
      background: 'currentColor',
      left: '50%',
      bottom: '-13px',
    },
  },
  scoreContainer: {
    position: 'absolute',
    color: '#828282',
    left: 'calc(50% - 11px)',
    top: '-42px',
    transformOrigin: `50% ${gaugeHeight + 40}px`,
    '&::before': {
      content: '""',
      position: 'absolute',
      height: 8,
      width: 1,
      background: '#c4c4c4',
      left: '50%',
      bottom: '-13px',
    },
  },
  resultContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  resultTitle: {
    fontSize: '0.7rem',
    lineHeight: 3,
  },
  resultValue: {
    fontSize: '1.1rem',
    fontWeight: '600',
    lineHeight: '1',
    color: theme.palette.grey[800],
  },
})

export default styles
