const styles = theme => ({
  orderSummarySection: {
    padding: theme.spacing.unit * 3,
    display: 'flex',
    flexDirection: 'column',
  },
  testInfoSection: {
    display: 'grid',
    gridGap: `${theme.spacing.unit}px`,
  },
  sectionTitle: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#262C38',
  },
  sectionSubtitle: {
    fontSize: '16px',
    color: '#262C38',
    fontWeight: 500,
    position: 'relative',
  },
  sectionDetails: {
    color: '#5C5E63',
    fontWeight: 400,
    fontSize: '12px',
  },
  sectionDetailsValue: {
    marginLeft: 'auto',
  },
  sectionValue: {
    color: '#828282',
  },
  topAndBottomBorders: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    borderTop: `1px solid ${theme.palette?.border.main}`,
    borderBottom: `1px solid ${theme.palette?.border.main}`,
  },
  formControlLabelRoot: {
    marginLeft: theme.spacing.unit * -1,
  },
  formControlLabel: {
    color: 'rgba(38, 44, 56, 0.7)',
    fontWeight: 500,
    fontSize: '14px',
  },
  checkboxRoot: {
    padding: theme.spacing.unit,
  },
  costSection: {},
  costItem: {
    alignItems: 'center',
  },
  cost: {
    marginLeft: 'auto',
    color: '#262C38',
    fontSize: '14px',
    fontWeight: 500,
  },
  premiumReportSection: {
    marginBottom: `-${theme.spacing.unit}px`,
  },
  creditsSection: {
    marginTop: `-${theme.spacing.unit}px`,
  },
  creditsSpan: {
    fontWeight: 800,
  },
  totalSection: {
    display: 'grid',
    gridGap: '16px',
    margin: 'auto 0',
    marginBottom: '40px',
  },
  invalidDataModalBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 5}px`,
    borderRadius: theme.spacing.unit,
  },
  invalidDataModalText: {
    width: '35ch',
    textAlign: 'center',
    padding: `${theme.spacing.unit * 2}px 0`,
    fontWeight: 500,
    fontSize: '1.2em',
    color: theme.palette.grey[700],
  },
  invalidDataModalButton: {
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
  },
  successModalBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 7}px`,
    borderRadius: theme.spacing.unit,
  },
  successModalIcon: {
    fontSize: '120px',
    color: '#219653',
  },
  failModalIcon: {
    fontSize: '120px',
    color: theme.palette.secondary.main,
  },
  successModalHeader: {
    textAlign: 'center',
    padding: `${theme.spacing.unit * 5}px 0 ${theme.spacing.unit}px`,
    fontWeight: 500,
    fontSize: '1.7em',
    color: theme.palette.grey[800],
  },
  successModalText: {
    width: '35ch',
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '1.2em',
    color: theme.palette.grey[600],
  },
  successModalButton: {
    marginTop: theme.spacing.unit * 5,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 3}px`,
    fontSize: '1.2rem',
  },
  tooltipContent: {
    all: 'unset',
    cursor: 'pointer',
  },
  orderButtonTooltip: {
    fontSize: 16,
    padding: theme.spacing.unit * 2,
    lineHeight: 1.4,
    marginTop: theme.spacing.unit * 3,
    maxWidth: 'initial',
  },
  totalSummary: {
    color: 'rgba(38, 44, 56, 0.9)',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '15px',
  },
  totalSummaryValue: {
    color: 'rgba(38, 44, 56, 1)',
    marginLeft: 'auto',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '15px',
  },
  total: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '15px',
  },
  promoCodeInput: {
    '& p': {
      marginLeft: 0,
    },
    '& input': {
      padding: '10.5px 12px',
    },
  },
  closeIcon: {
    color: '#5c5e63',
    fontSize: '20px',
    marginLeft: '12px',
  },
  [theme.breakpoints.down(1365)]: {
    formControlLabel: {
      fontSize: '12px',
    },
  },
})

export default styles
