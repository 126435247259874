import React from 'react'
import PropTypes from 'prop-types'
import {Button, withStyles} from '@material-ui/core'

const styles = {
  tabSwitchContainer: {
    display: 'flex',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    padding: 2,
  },
  switchButton: {
    width: 63,
    height: 30,
    borderRadius: 3,
    position: 'relative',
  },
  selectedBackground: {
    position: 'absolute',
    display: 'block',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    borderRadius: 3,
    background: '#4F4F4F',
    color: '#FFFFFF',
    fontSize: 16,
    fontWeight: 400,
  },
  selectedBackgroundMale: {
    position: 'absolute',
    display: 'block',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    borderRadius: 3,
    background: '#017EFF',
    color: '#FFFFFF',
    fontSize: 16,
    fontWeight: 400,
  },
  selectedBackgroundFemale: {
    position: 'absolute',
    display: 'block',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    borderRadius: 3,
    background: '#FF0099',
    color: '#FFFFFF',
    fontSize: 16,
    fontWeight: 400,
  },
  inactiveBackground: {
    color: '#292D32',
    opacity: '0.3',
  },
}

const SurveyResultsGenderSwitch = ({classes, selectedGender, setSelectedGender, isFetching}) => {
  const isSelected = name => {
    return name === selectedGender
  }

  return (
    <div className={classes.tabSwitchContainer}>
      <Button
        className={classes.switchButton}
        onClick={() => setSelectedGender('all')}
        disabled={isFetching}
      >
        {isSelected('all') && <span className={classes.selectedBackground}>All</span>}
        {(isSelected('male') || isSelected('female')) && (
          <span className={classes.inactiveBackground}>All</span>
        )}
      </Button>
      <Button
        className={classes.switchButton}
        onClick={() => setSelectedGender('male')}
        disabled={isFetching}
      >
        {isSelected('male') && <span className={classes.selectedBackgroundMale}>Male</span>}
        {(isSelected('all') || isSelected('female')) && (
          <span className={classes.inactiveBackground}>Male</span>
        )}
      </Button>
      <Button
        className={classes.switchButton}
        onClick={() => setSelectedGender('female')}
        disabled={isFetching}
      >
        {isSelected('female') && <span className={classes.selectedBackgroundFemale}>Female</span>}
        {(isSelected('all') || isSelected('male')) && (
          <span className={classes.inactiveBackground}>Female</span>
        )}
      </Button>
    </div>
  )
}

SurveyResultsGenderSwitch.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  selectedGender: PropTypes.string.isRequired,
  setSelectedGender: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
}

export default withStyles(styles)(SurveyResultsGenderSwitch)
