import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {Grid, withStyles} from '@material-ui/core'
import _ from 'lodash'

import NoDataAvailable from '../common/NoDataAvailable'
import Loading from '../common/Loading'
import SurveyResultsCanNotGenerateResult from './SurveyResultsCanNotGenerateResult'

import utils from './utils'

import styles from './styles/NewTableData.styles'

let Chart = null

const colorPalette = {
  male: '#017EFF',
  female: '#FF0099',
  total: '#898989',
  others: [
    '#002459',
    '#864932',
    '#47CBD5',
    '#00712F',
    '#00716B',
    '#E3CE7F',
    '#A2A18F',
    '#FFB75B',
    '#8073F7',
    '#665555',
    '#CD5353',
    '#2DE3C2',
    '#05A675',
    '#344CCE',
    '#D20097',
  ],
}
const barColor = []

function SurveyResultsMultipleChoiceAnswers(props) {
  if (!Chart) Chart = require('react-apexcharts').default
  const {campaign, currentQuestion, demographicsWithValues} = props

  const [demographicLabelsTable, setDemographicLabelsTable] = useState({})

  useEffect(() => {
    setDemographicLabelsTable(utils.getDemographicLabelsTable(demographicsWithValues))
  }, [demographicsWithValues])

  const capitalizeFirstLetter = str => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }

  const splitByWordCount = (str, count) => {
    const arr = str.split(' ')
    const r = []
    while (arr.length) {
      r.push(arr.splice(0, count).join(' '))
    }
    return r
  }

  const handleQuestionsData = () => {
    const surveyAnswers = campaign.surveyResults.results.find(
      answers => answers.question === currentQuestion,
    )
    const seriesData = {}
    const graphCategories = []
    const uniqueSubDemographics = new Set()
    let maxYAxisValue = 0

    if (surveyAnswers) {
      const {answers, choices} = surveyAnswers

      for (let i = 0; i < choices.length; i++) {
        const choice = choices[i]

        const choiceKey = choice.toLowerCase()
        const currentChoice = answers.find(value => value.choice === choiceKey)

        if (currentChoice && currentChoice.answers) {
          const currentChoiceAnswers = currentChoice.answers
          if (Object.keys(currentChoiceAnswers).length > 15) return {canNotShowResult: true}

          const choiceAnswerValues = Object.values(currentChoiceAnswers)

          for (let j = 0; j < choiceAnswerValues.length; j++) {
            const {count, label} = choiceAnswerValues[j]

            const lowerCaseLabel = label.toLowerCase()
            uniqueSubDemographics.add(lowerCaseLabel)
            maxYAxisValue = Math.max(maxYAxisValue, count)

            if (!seriesData[lowerCaseLabel]) {
              seriesData[lowerCaseLabel] = [count]
            } else {
              seriesData[lowerCaseLabel].push(count)
            }
          }

          graphCategories.push(splitByWordCount(choice, 5))
        }
      }
    }

    return {seriesData, graphCategories, uniqueSubDemographics, maxYAxisValue}
  }

  const getCachedColors = () => {
    const cachedColorsJSON = localStorage.getItem('cachedColors')

    return cachedColorsJSON ? JSON.parse(cachedColorsJSON) : {}
  }

  const setCachedColors = cachedColors => {
    localStorage.setItem('cachedColors', JSON.stringify(cachedColors))
  }

  const getGraphData = () => {
    barColor.length = 0
    const series = []

    const {
      seriesData,
      graphCategories,
      uniqueSubDemographics,
      maxYAxisValue,
      canNotShowResult,
    } = handleQuestionsData()

    if (canNotShowResult) return <SurveyResultsCanNotGenerateResult />

    const cachedColors = getCachedColors()

    uniqueSubDemographics.forEach(item => {
      if (['male', 'female', 'total'].indexOf(item) >= 0) barColor.push(colorPalette[item])
      else if (cachedColors[item]) {
        barColor.push(cachedColors[item])
      } else {
        let color = colorPalette.others[Math.floor(Math.random() * colorPalette.others.length)]
        while (barColor.indexOf(color) >= 0)
          color = colorPalette.others[Math.floor(Math.random() * colorPalette.others.length)]
        barColor.push(color)
        cachedColors[item] = color
        setCachedColors(cachedColors)
      }
    })

    Object.entries(seriesData).forEach(([key, data]) => {
      const label = demographicLabelsTable[key]
      series.push({
        name: label || capitalizeFirstLetter(key),
        data,
      })
    })
    const options = {
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        offsetX: 13,
        style: {
          fontSize: '13px',
          fontFamily: 'Roboto',
          fontWeight: 500,
          colors: barColor,
        },
        formatter(value, {seriesIndex, dataPointIndex, w}) {
          const {series} = w.config
          const responseText = value > 1 ? 'Responses' : 'Response'

          const totalAnswers =
            series[seriesIndex].name !== 'Total'
              ? series[series.length - 1].data[dataPointIndex]
              : surveyAnswers[0].totalResponses

          const percentage = totalAnswers
            ? Math.round(((value * 100) / totalAnswers).toFixed(1))
            : 0

          return `${percentage}% (${value} ${responseText})`
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      tooltip: {
        y: {
          formatter(value, {seriesIndex, dataPointIndex, w}) {
            const {series} = w.config
            const responseText = value > 1 ? 'Responses' : 'Response'

            const totalAnswers = series[series.length - 1].data[dataPointIndex]
            const percentage = Math.round(((value * 100) / totalAnswers).toFixed(1))

            if (series[seriesIndex].name !== 'Total')
              return `${percentage}% (${value} ${responseText})`

            return `${value} ${responseText}`
          },
        },
      },
      xaxis: {
        categories: graphCategories,
        labels: {
          style: {
            fontSize: '12px',
            fontFamily: 'Roboto',
            color: '#92A1B9',
          },
        },
        tickAmount: 15,
        min: 0,
        max: maxYAxisValue + 15,
      },
      yaxis: {
        labels: {
          align: 'left',
          minWidth: 250,
          maxWidth: 250,
          style: {
            fontSize: '16px',
            fontFamily: 'Roboto',
            color: '#323232',
          },
        },
      },
      legend: {
        show: true,
        position: 'right',
        horizontalAlign: 'center',
        offsetX: -10,
        offsetY: 50,
        fontSize: '16px',
        fontFamily: 'Roboto',
        fontWeight: 400,
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      colors: barColor,
    }
    const chatHeight = series.length * 250
    return <Chart options={options} series={series} type="bar" height={chatHeight} width="100%" />
  }

  if (_.isEmpty(currentQuestion)) return <NoDataAvailable />

  const surveyAnswers = campaign.surveyResults.results.filter(
    answers => answers.question === currentQuestion,
  )

  if (campaign.surveyResults.isFetching) {
    return <Loading />
  }

  return (
    <>
      {surveyAnswers.length && !surveyAnswers[0].totalResponses ? (
        <NoDataAvailable />
      ) : (
        <Grid container>
          <Grid id="survey-results-multiple-choices-chart" item xs>
            {getGraphData()}
          </Grid>
        </Grid>
      )}
    </>
  )
}

SurveyResultsMultipleChoiceAnswers.propTypes = {
  campaign: PropTypes.objectOf(PropTypes.any).isRequired,
}

const mapStateToProps = ({campaign}) => ({
  campaign,
})

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(SurveyResultsMultipleChoiceAnswers)
