import React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import validatedComponent from '../common/ValidatedComponent.jsx'
import * as Actions from './redux/actions.changeEmail'

class ChangeEmailConfirm extends React.Component {
  componentDidMount() {
    const {id, token} = this.props.params
    this.props.confirmChangeEmail(id, token)
    const ReactPixel = require('react-facebook-pixel').default
    ReactPixel.pageView()
  }

  render() {
    return (
      <div className="panel">
        <div className="row">
          <div className="large-12 end columns">
            <h2>Change Email Confirmation</h2>
          </div>
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

ChangeEmailConfirm.displayName = 'ChangeEmailConfirm'

export default connect(null, mapDispatchToProps)(validatedComponent(ChangeEmailConfirm))
