import React from 'react'
import PropTypes from 'prop-types'
import {Grid, Typography, withStyles} from '@material-ui/core'
import {compose} from 'redux'
import {connect} from 'react-redux'

import Verticalchartgroup from './VerticalChartGroup'
import Loading from '../common/Loading'

import styles from './styles/VerticalChart.styles'

let Chart = null

const CompareVerticalCharts = ({classes, tabScoreComparison, labels, isFetching}) => {
  if (!Chart) Chart = require('react-apexcharts').default

  if (isFetching) {
    return <Loading />
  }

  return (
    <Grid container className={classes.compareChartsContainer}>
      {tabScoreComparison.map(
        ({tabscorexaxis, tabscoreyaxis, benchmarks, responseCount}, index) => (
          <Grid key={index} container>
            <Grid item xs={12} className={classes.filterResponseContainer}>
              <Typography>{labels[index]}</Typography>
              <Grid item xs />
              <Typography>
                {`${responseCount} ${responseCount === 1 ? 'Response' : 'Responses'}`}
              </Typography>
            </Grid>
            <Verticalchartgroup
              benchmarks={benchmarks}
              yaxis={tabscoreyaxis}
              xaxis={tabscorexaxis}
              Chart={Chart}
            />
          </Grid>
        ),
      )}
    </Grid>
  )
}

CompareVerticalCharts.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  tabScoreComparison: PropTypes.arrayOf(PropTypes.object).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  isFetching: PropTypes.bool.isRequired,
}

const mapStateToProps = ({campaign}) => ({
  tabScoreComparison: campaign.tabScoreComparison.results,
  isFetching: campaign.tabScoreComparison.isFetching,
  labels: campaign.tabScoreComparison.labels,
})

export default compose(connect(mapStateToProps), withStyles(styles))(CompareVerticalCharts)
