const svgColorFilters = {
  main: 'invert(92%) sepia(91%) saturate(3649%) hue-rotate(176deg) brightness(103%) contrast(101%)',
  secondary:
    'invert(97%) sepia(0%) saturate(7448%) hue-rotate(107deg) brightness(116%) contrast(101%)',
  white: 'invert(100%) sepia(0%) saturate(16%) hue-rotate(311deg) brightness(105%) contrast(104%)',
  whiteIsh:
    'invert(84%) sepia(4%) saturate(1170%) hue-rotate(192deg) brightness(108%) contrast(98%)',
}

const sidebarColor = '#292d32'
const sidebarTextColor = {
  main: '#CFE7FF',
  secondary: '#5C5E63',
}

const styles = theme => ({
  sidebarBody: {
    background: sidebarColor,
    padding: `${theme.spacing.unit * 4} 0`,
    '&::-webkit-scrollbar-track': {
      background: '#34383E',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#4F4F4F',
      border: `3px solid #34383E`,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#828282',
    },
  },
  drawer: {
    '& a': {
      textDecoration: 'none',
      color: 'currentColor',
    },
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: `0 ${theme.spacing.unit * 4}`,
    '& img': {
      width: '100%',
    },
  },
  topSection: {
    padding: `${theme.spacing.unit * 4} 0`,
  },
  sidebarText: {
    color: sidebarTextColor.main,
  },
  disabledItem: {
    opacity: '0.7',
  },
  sidebarItem: {
    padding: `0 ${theme.spacing.unit * 2}`,
    '& img': {
      filter: svgColorFilters.main,
      marginRight: theme.spacing.unit * 1.5,
    },
    '& div': {
      padding: 0,
    },
  },
  selectedItem: {
    position: 'relative',
    background: 'linear-gradient(267.25deg, #159395 -45%, #017EFF 115.06%)',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: '0',
      top: '0',
      width: theme.spacing.unit,
      height: '100%',
      background: 'white',
    },
    '& $sidebarText': {
      color: 'white',
    },
    '& img': {
      filter: svgColorFilters.white,
    },
  },
  bottomSection: {
    '& > *': {
      paddingLeft: theme.spacing.unit * 3,
      paddingRight: theme.spacing.unit * 3,
    },
  },
  newTestButton: {
    display: 'flex',
    justifyContent: 'center',
    color: 'white !important',
    textTransform: 'none',
    width: '100%',
    boxShadow: 'none',
  },
  logoutIcon: {
    filter: `${svgColorFilters.main} !important`,
  },
  logoutText: {
    color: sidebarTextColor.main,
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
  },
  copyrightText: {
    fontSize: '0.8rem',
    color: sidebarTextColor.secondary,
  },
  bottomLinks: {
    color: `${sidebarTextColor.main} !important`,
    '& a': {
      display: 'block !important',
    },
  },
  testNavigationItem: {
    paddingLeft: theme.spacing.unit * 4,
  },
  testNavigationTitle: {
    color: sidebarTextColor.main,
    fontWeight: 500,
    marginLeft: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * -2,
    fontSize: '1.1rem',
  },
})

export default styles
