const styles = theme => ({
  mainTitle: {
    margin: '0 0 40px 0',
    textTransform: 'capitalize',
    fontWeight: '300',
    color: '#545454',
    fontSize: '36px',
    letterSpacing: 'normal',
    lineHeight: 1.25,
    [theme.breakpoints.down('md')]: {
      fontSize: 30,
    },
  },
  title: {
    fontSize: '24px',
    color: '#37474F',
    margin: 0,
    fontWeight: 500,
    marginTop: 5,
    marginBottom: 12,
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px',
    },
    display: 'inline-block',
  },
  subtitle: {
    textTransform: 'capitalize',
    fontWeight: 500,
    color: '#a1a1a1',
    fontSize: '17px',
    letterSpacing: 'normal',
    lineHeight: 1.97,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
  },
  itemTitle: {
    fontSize: '31px',
    opcity: 0.87,
    fontWeight: 500,
    color: '#6a6a6a',
    margin: 10,
    textAlign: 'center',
  },
  widgetTitle: {
    fontSize: '18px',
    fontWeight: 500,
    paddingTop: '20px',
    paddingLeft: '20px',
    paddingBottom: '25px',
  },
  smallWidgetTitle: {
    fontSize: '18px',
    fontWeight: 500,
    paddingTop: '20px',
    paddingLeft: '20px',
    [theme.breakpoints.down('1401')]: {
      paddingLeft: '10px',
    },
  },
  statusText: {
    fontStyle: 'italic',
    fontSize: '31px',
    fontWeight: 500,
    opcity: 0.87,
    color: '#6a6a6a',
    margin: 10,
    textAlign: 'center',
  },
  button: {
    borderRadius: 21,
    textTransform: 'capitalize',
    height: '42px',
    minWidth: 243,
    backgroundColor: '#ce2126',
    boxShadow: 'none',
    color: '#fff',
    marginTop: 40,
    marginBottom: 20,
    '&:hover': {
      backgroundColor: '#b7292d',
    },
  },
  buttonBlue: {
    borderRadius: 21,
    textTransform: 'capitalize',
    height: '42px',
    minWidth: 243,
    backgroundColor: '#047aba !important',
    boxShadow: 'none',
    color: '#fff !important',
    marginTop: 40,
    marginBottom: 20,
  },
  buttonBlueDisabled: {
    color: '#8b8b8b !important',
    borderRadius: 21,
    textTransform: 'capitalize',
    height: '42px',
    minWidth: 243,
    marginTop: 40,
    marginBottom: 20,
    border: '1px solid #007BC0',
    backgroundColor: '#fff !important',
    boxShadow: 'none !important',
  },
  buttonShadow: {
    borderRadius: 20,
    textTransform: 'capitalize',
    minHeight: '25px',
    width: '100px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
    border: 'none',
  },
  buttonBlueHead: {
    borderRadius: 21,
    textTransform: 'capitalize',
    height: '42px',
    minWidth: 243,
    backgroundColor: '#047aba',
    boxShadow: 'none',
    color: '#fff',
    marginTop: 16,
    marginBottom: 20,
    float: 'left',
    marginRight: 25,
  },
  downloadPDF: {
    height: '42px',
    marginTop: 24,
    float: 'left',
  },
  link: {
    color: '#047aba',
    fontSize: '28px',
    textDecoration: 'none',
  },
  noResult: {
    padding: theme.spacing.unit * 3,
  },
  minimumApplied: {
    margin: '-16px 0 16px 0',
    fontSize: '14px',
    color: '#999999',
  },
  mainContainer: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100% - 32px)',
    },
  },
  totalCost: {
    textAlign: 'center',
    marginRight: 20,
    color: '#047aba',
    backgroundColor: '#efefef',
    padding: '22px 31px',
    borderRadius: '8px',
  },
  page: {
    padding: '0',
  },

  loginInfo: {
    width: '75%',
    fontSize: '52px',
    wordSpacing: 10,
    lineHeight: 1.37,
    color: '#fff',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      fontSize: 45,
    },
  },
  loginContainer: {
    // paddingTop: 70,
    height: 'calc(100vh - 100px)',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 200px)',
      minHeight: '400px',
      maxHeight: '500px',
      paddingTop: 30,
    },
  },
  registerContainer: {
    paddingTop: 50,
    height: 'calc(100vh - 100px)',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 200px)',
      minHeight: '750px',
      paddingTop: 30,
    },
  },
  loginButton: {
    width: '112px !important',
    height: '45px',
    borderRadius: '8px',
    fontSize: '16px',
    color: 'F2F2F2',
    padding: '0',
    fontWeight: 'normal',
    '&:disabled': {
      backgroundColor: '#CFD8DC',
      color: '#90A4AE',
    },
  },
  registerButton: {
    borderRadius: 21,
    textTransform: 'none',
    height: '42px',
    backgroundColor: '#d2363b',
    boxShadow: 'none',
    color: '#fff',
  },
  inputField: {
    border: '1px solid #e3e3e3',
    borderRadius: 21,
    padding: 13,
    height: '40px',
  },
  inputFieldSmall: {
    border: '1px solid #e3e3e3',
    borderRadius: 21,
    padding: 4,
    textAlign: 'center',
    fontWeight: 500,
    fontSize: 18,
    width: 60,
  },
  card: {
    maxWidth: '700px',
    margin: '0 auto',
  },
  cardCentered: {
    maxWidth: '700px',
    margin: '0 auto',
    justifyContent: 'center',
    textAlign: 'center',
  },
  cardActions: {
    marginTop: '-50px',
    borderTop: '1px solid #ccc',
    justifyContent: 'center',
  },
  cardContent: {
    padding: '100px',
  },
  confirmButton: {
    marginTop: '20px',
  },
  hintText: {
    color: '#37474F',
    fontSize: '16px',
    textAlign: 'center',
    width: '343px',
  },
  blackLink: {
    fontWeight: 'bold !important',
    textDecoration: 'none',
    color: '#a1a1a1 !important',
  },
  rowHeader: {
    fontSize: '12px',
    fontWeight: 600,
    opacity: 0.87,
    color: '#989797',
    textTransform: 'uppercase',
    display: 'block',
  },
  rowHeaderCenter: {
    fontSize: '12px',
    fontWeight: 600,
    opacity: 0.87,
    color: '#989797',
    textTransform: 'uppercase',
    textAlign: 'center',
    marginTop: '1.55em',
  },
  rowValue: {
    fontSize: '21px',
    color: '#545454',
    textTransform: 'capitalize',
  },
  exportButton: {
    color: '#222222',
    padding: '5px 10px',
    fontSize: '14px',
  },
  exportButtonLink: {
    color: '#222222',
    textDecoration: 'none',
  },
  discountApplyButton: {
    float: 'right',
    color: '#247ab5',
    margin: '22px 0',
    fontSize: '20px',
  },
  discountInputField: {
    border: '1px solid #e3e3e3',
    borderRadius: 2,
    padding: 13,
    height: '40px',
    margin: '15px 0',
    fontSize: '16px',
  },
  divider: {
    margin: '15px 0',
  },
  popupRectangle: {
    color: '#fff',
    backgroundColor: '#71AC47',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
  },
  popupMessage: {
    height: '108px',
    width: '240px',
    color: '#000',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: '300',
    letterSpacing: '1.02px',
    lineHeight: '13px',
    textAlign: 'center',
  },
  popupSuccess: {
    height: '29px',
    width: '104px',
    color: '#000',
    fontFamily: 'Roboto',
    fontSize: '21px',
    letterSpacing: '2.14px',
    lineHeight: '24px',
    fontWeight: '200',
  },
  checkbox: {
    padding: 0,
    marginLeft: 10,
  },
  smallGraySpan: {
    color: '#A8A6A6',
    fontSize: 13,
  },
  warningSpan: {
    color: '#F47D01',
    fontSize: 13,
    fontWeight: 'bold',
  },

  labelAsterisk: {
    color: '#CE2226',
  },
  submitDialogButton: {
    borderRadius: 21,
    textTransform: 'none',
    height: '32px',
    width: '25%',
    backgroundColor: 'secondary',
    boxShadow: 'none',
    color: '#fff',
  },
  cancelDialogButton: {
    borderRadius: 21,
    textTransform: 'none',
    height: '32px',
    width: '25%',
    backgroundColor: '#D6D6D6',
    boxShadow: 'none',
    color: '#000',
  },
  // campaign detail dashboard revamp
  campaingDetailBreadcrumb: {
    color: '#545454',
    fontFamily: 'Salesforce Sans Regular',
    fontSize: '18px',
    letterSpacing: '0.56px',
    lineHeight: '23px',
  },
  cardTitle: {
    fontFamily: 'Salesforce Sans Bold',
    fontSize: '18px',
    letterSpacing: '0.56px',
    lineHeight: '23px',
    textAlign: 'left',
    color: '#545454',
    paddingTop: '20px',
    paddingLeft: '20px',
    paddingBottom: '25px',
    textTransform: 'capitalize',
  },
  cardBody: {
    fontFamily: 'Salesforce Sans Regular',
    fontSize: '15px',
    letterSpacing: '0.47px',
    lineHeight: '19px',
    textAlign: 'left',
    color: '#545454',
    textTransform: 'capitalize',
  },
  campHeaderMenu: {
    margin: '30px 10px 10px 5px',
    float: 'right',
    display: 'inlie-block',
    fontWeight: 600,
    fontSize: '14px',
    letterSpacing: '0.42px',
    lineHeight: '16px',
    textAlign: 'center',
  },
  campHeaderMenuLink: {
    color: '#0651FF',
    paddingLeft: '10px',
  },
  campModalIconWrapper: {
    width: '85px',
    height: '85px',
    background: 'rgba(222, 222, 222, 0.53)',
    borderRadius: '50%',
    display: 'block',
  },
  campModalObjectiveIcon: {
    position: 'relative',
    left: '20px',
    top: '18px',
  },
  campModalVideoIcon: {
    position: 'relative',
    left: '20px',
    top: '27px',
  },
  campModalTitle: {
    color: 'rgba(51,50,56,0.84)',
    fontFamily: 'Salesforce Sans Bold',
    fontSize: '32px',
    letterSpacing: '-0.21px',
    lineHeight: '41px',
  },
  campRealtimeReportVideo: {
    float: 'right',
    marginTop: '-50px',
  },
  campDemographicFilters: {
    padding: '20px 40px 0 0',
    marginLeft: '-60px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  addMoreButton: {
    border: 'none',
    background: 'none',
    boxShadow: 'none',
    color: '#546E7A',
    fontSize: '12px',
    lineHeight: '16px',
    '&:hover': {border: 'none', background: 'none', boxShadow: 'none'},
  },
  listRemoveButton: {
    fontWeight: 500,
    color: '#999999',
    fontSize: '17px',
    cursor: 'pointer',
  },
  downloadSampleList: {
    fontWeight: 500,
    color: '#a1a1a1',
    fontSize: 13,
    margin: 10,
  },
  dashboardContainer: {
    display: 'grid',
    width: '100%',
    gridGap: `${theme.spacing.unit * 3}px`,
    padding: '30px',
  },
  processingStatus: {
    color: '#fff',
    backgroundColor: '#828282',
  },
  pendingStatus: {
    color: '#4F4F4F',
    backgroundColor: '#E0E0E0',
  },
  inProgressStatus: {
    color: '#fff',
    backgroundColor: '#FEB52C',
  },
  completedStatus: {
    color: '#fff',
    backgroundColor: '#219653',
  },
  statusAndOutcomeFlag: {
    width: '85px',
    height: '29px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  settledOutcome: {
    color: '#FFAF15',
    backgroundColor: 'rgba(255, 175, 21, 0.12)',
    fontWeight: 500,
  },
  inProcessOutcome: {
    color: '#34B4EB',
    backgroundColor: 'rgba(52, 180, 235, 0.12)',
    fontWeight: 500,
  },
  lostOutcome: {
    color: '#EB5757',
    backgroundColor: 'rgba(235, 87, 87, 0.12)',
    fontWeight: 500,
  },
  wonOutcome: {
    color: '#219653',
    backgroundColor: 'rgba(33, 150, 83, 0.12)',
    fontWeight: 500,
  },
  forgotButton: {
    fontWeight: 'normal',
    fontSize: '16px',
    borderRadius: '8px',
    '&:disabled': {
      backgroundColor: '#CFD8DC',
      color: '#90A4AE',
    },
  },
  inputSignupAndLogin: {
    padding: '0',
    margin: '16px 0',
    '& input': {
      color: '#455A64',
      borderRadius: '8px',
    },
    '& fieldset': {
      borderRadius: '8px',
    },
  },
})

export default styles
