import React from 'react'
import {Grid, Typography, withStyles} from '@material-ui/core'

import noDataImg from '../../assets/no-data.svg'

const styles = theme => ({
  noDataText: {
    marginTop: theme.spacing.unit * 3,
    color: theme.palette.grey[600],
    width: '50ch',
  },
})

const SurveyResultsCanNotGenerateResult = ({classes}) => {
  return (
    <Grid container justify="center">
      <Grid container justify="center" alignItems="center">
        <img src={noDataImg} alt="no data" style={{width: '20%'}} />
      </Grid>
      <Grid container justify="center" alignItems="center" className={classes.textContainer}>
        <Typography variant="body1" align="center" className={classes.noDataText}>
          Oops! We can not generate result for the demographic having more than 15 sub-demographic.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(SurveyResultsCanNotGenerateResult)
