import React, {useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {ListItem, ListItemText, withStyles} from '@material-ui/core'

import NavigationButton from './NavigationButton'
import ValuationReportIcon from './ValuationReportIcon'
import InfoTooltip from './InfoTooltip'

import enjoymentIcon from '../../assets/icons/enjoyment.svg'
import angstIcon from '../../assets/icons/angst.svg'
import conclusionsIcon from '../../assets/icons/conclusions.svg'
import curiosityIcon from '../../assets/icons/curiosity.svg'
import rapportIcon from '../../assets/icons/rapport.svg'
import sentimentsIcon from '../../assets/icons/sentiments.svg'
import shopIcon from '../../assets/icons/shop.svg'
import surveyIcon from '../../assets/icons/survey.svg'
import tabScoresIcon from '../../assets/icons/tab-scores.svg'
import humorIcon from '../../assets/icons/humorMap.svg'
import tutorialIcon from '../../assets/icons/videoIcon.svg'

import utils from '../campaign/utils'
import {getCampaign, getCaseTypes} from '../campaign/redux/actions'

import styles from './styles/Sidebar.styles'

const paths = {
  home: '/',
  summary: '/summary',
  tabScores: '/tab-scores',
  rapport: '/rapport',
  sentiments: '/sentiments',
  angst: '/angst',
  enjoyment: '/enjoyment',
  curiosity: '/curiosity',
  survey: '/survey-results',
  conclusions: '/conclusions',
  humor: '/humor',
  tutorials: '/tutorials',
  victory: '/victory',
}

const TestNavigation = ({
  classes,
  router,
  getCampaign,
  getCaseTypes,
  campaign,
  caseTypes,
  pathname,
}) => {
  const [campaignInfo, setCampaignInfo] = useState(null)
  const [caseTypeName, setCaseTypeName] = useState(null)

  const currentRoute = useMemo(() => {
    return `/${pathname.split('/')[3]}`
  }, [pathname])

  const basePath = useMemo(() => {
    const match = pathname.match(/\/tests\/\d+/)
    return match[0] || ''
  }, [pathname])

  useEffect(() => {
    const {id} = router.params

    if (!campaign || campaign.id !== Number(id)) {
      _.debounce(() => getCampaign(id, {expand: 'lists'}), 100)
    }

    getCaseTypes()
  }, [])

  useEffect(() => {
    if (caseTypes && caseTypes.length) {
      const caseTypeSelected = caseTypes.filter(caseType => caseType.id === campaign.caseType)

      setCaseTypeName(caseTypeSelected[0] ? caseTypeSelected[0].name : null)
    }
  }, [caseTypes, campaign])

  useEffect(() => {
    if (campaign.title) {
      setCampaignInfo(campaign)
    }
  }, [campaign])

  const shouldShowConclusionButton = () => {
    return !utils.isLegalCampaign(campaign)
  }

  const getValuationReportButton = () => {
    if (!utils.isLegalCampaign(campaign) || utils.isOldCampaign(campaign)) {
      return null
    }

    if (!campaign.demandValuationReport) {
      return (
        <InfoTooltip
          text="The valuation report will be available as soon as the test is completed."
          placement="right"
          showIcon={false}
        >
          <div>
            <NavigationButton
              path=""
              isDisabled={true}
              isSelected={false}
              icon={<ValuationReportIcon isDisabled={true} />}
              text="Valuation Report"
              containerClass={classes.testNavigationItem}
            />
          </div>
        </InfoTooltip>
      )
    }

    return (
      <NavigationButton
        target="_blank"
        path={campaign.demandValuationReport}
        isDisabled={false}
        isSelected={false}
        icon={<ValuationReportIcon isDisabled={false} />}
        text="Valuation Report"
        containerClass={classes.testNavigationItem}
      />
    )
  }

  return campaignInfo ? (
    <div>
      <ListItem style={{paddingTop: 0, paddingBottom: 0}}>
        <ListItemText
          classes={{primary: classes.testNavigationTitle}}
          primary={campaignInfo.title}
        />
      </ListItem>
      <NavigationButton
        path={paths.home}
        isSelected={pathname === paths.home}
        icon={shopIcon}
        text="My Tests"
      />
      {shouldShowConclusionButton() && (
        <NavigationButton
          path={`${basePath}${paths.conclusions}`} // SUMMARY RESULTS
          isSelected={currentRoute === paths.conclusions}
          icon={conclusionsIcon}
          text={utils.isLegalCampaign(campaign) ? 'Conclusions' : 'Key Metrics'}
          containerClass={classes.testNavigationItem}
        />
      )}
      {utils.isLegalCampaign(campaign) && (
        <NavigationButton
          path={`${basePath}${paths.tabScores}`} // TABSCORES
          isSelected={currentRoute === paths.tabScores}
          icon={tabScoresIcon}
          text="TAB Scores"
          containerClass={classes.testNavigationItem}
        />
      )}
      <NavigationButton
        path={`${basePath}${paths.curiosity}`} // CURIOSITY
        isSelected={currentRoute === paths.curiosity}
        icon={curiosityIcon}
        text="Curiosity Map"
        containerClass={classes.testNavigationItem}
      />
      {utils.isLegalCampaign(campaign) ? (
        <>
          <NavigationButton
            path={`${basePath}${paths.angst}`} // ANGST
            isSelected={currentRoute === paths.angst}
            icon={angstIcon}
            text="Angst Map"
            containerClass={classes.testNavigationItem}
          />
          <NavigationButton
            path={`${basePath}${paths.rapport}`} // RAPPORT
            isSelected={currentRoute === paths.rapport}
            icon={rapportIcon}
            text="Rapport Map"
            containerClass={classes.testNavigationItem}
          />
          <NavigationButton
            path={`${basePath}${paths.victory}`} // VICTORY
            isSelected={currentRoute === paths.victory}
            icon={sentimentsIcon}
            text="Victory Map"
            containerClass={classes.testNavigationItem}
          />
        </>
      ) : (
        <>
          <NavigationButton
            path={`${basePath}${paths.enjoyment}`} // ENJOYMENT
            isSelected={currentRoute === paths.enjoyment}
            icon={enjoymentIcon}
            text="Enjoyment Map"
            containerClass={classes.testNavigationItem}
          />
          <NavigationButton
            path={`${basePath}${paths.humor}`} // HUMOR
            isSelected={currentRoute === paths.humor}
            icon={humorIcon}
            text="Humor Map"
            containerClass={classes.testNavigationItem}
          />
          <NavigationButton
            path={`${basePath}${paths.sentiments}`} // SENTIMENTS
            isSelected={currentRoute === paths.sentiments}
            icon={sentimentsIcon}
            text="Sentiment Moods"
            containerClass={classes.testNavigationItem}
          />
        </>
      )}
      <NavigationButton
        path={`${basePath}${paths.survey}`} // SURVEY
        isSelected={currentRoute === paths.survey}
        icon={surveyIcon}
        text="Survey Results"
        containerClass={classes.testNavigationItem}
      />
      {caseTypeName === 'Valuation' && getValuationReportButton()}
      <NavigationButton
        path={`${basePath}${paths.tutorials}`}
        isSelected={currentRoute === paths.tutorials}
        icon={tutorialIcon}
        text="Tutorials"
      />
    </div>
  ) : null
}

TestNavigation.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  getCampaign: PropTypes.func.isRequired,
  router: PropTypes.objectOf(PropTypes.any).isRequired,
  campaign: PropTypes.objectOf(PropTypes.any).isRequired,
}

const mapStateToProps = ({campaign}) => ({
  campaign: campaign.campaign,
  caseTypes: campaign.caseTypes.results,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCampaign,
      getCaseTypes,
    },
    dispatch,
  )

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(TestNavigation)
