import {call, put, takeEvery, all} from 'redux-saga/effects'
import {push} from 'react-router-redux'

import AuthApi from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'
import * as ProfileActions from '../../profile/redux/actions'
import * as NotificationActions from '../../common/redux/actions.notifications'

import UserSession from '../UserSession'

export default function* authFlow() {
  yield all([
    takeEvery(Types.LOGIN, login),
    takeEvery(Types.AUTO_LOGIN, autoLogin),
    takeEvery(Types.SOCIAL_LOGIN_OAUTH2, socialLogin),
    takeEvery(Types.SOCIAL_LOGIN_OAUTH1_STEP1, socialLoginOAuth1Step1),
    takeEvery(Types.SOCIAL_LOGIN_OAUTH1_STEP2, socialLoginOAuth1Step2),
    takeEvery(Types.LOGOUT, logout),
  ])
}

function* login(action) {
  const {email, password} = action
  try {
    const response = yield call(AuthApi.login, email, password)
    UserSession.setToken(response.token)

    yield put(Actions.loginSuccess(response.token))
    yield put(ProfileActions.getProfile())
  } catch (error) {
    yield put(Actions.loginFailure(error))
  }
}

function* autoLogin(action) {
  const {id, token} = action
  try {
    const response = yield call(AuthApi.autoLogin, id, token)
    UserSession.setToken(response.token)

    yield put(Actions.loginSuccess(response.token))
    yield put(ProfileActions.getProfile())
  } catch (error) {
    yield put(Actions.loginFailure(error))
    yield put(push('/login'))
  }
}

// function* socialLogin(action) {
//   const {provider, code, redirectUri, email} = action
//   try {
//     const response = yield call(AuthApi.socialLoginOAuth2, provider, code, redirectUri, email)
//     UserSession.setToken(response.token)
//     yield put(Actions.loginSuccess(response.token))
//     yield put(ProfileActions.getProfile())
//   } catch (error) {
//     yield put(Actions.loginFailure(error))
//   }
// }

function* socialLogin(action) {
  const {provider, code, redirectUri, email} = action
  try {
    const response = yield call(AuthApi.socialLoginOAuth2, provider, code, redirectUri, email)
    UserSession.setToken(response.token)
    yield put(Actions.loginSuccess(response.token))
    yield put(ProfileActions.getProfile())
    yield put(push('/'))
    yield put(
      NotificationActions.createSuccess(
        response.isNew
          ? 'You have successfully created an account'
          : 'You have successfully logged in',
      ),
    )
  } catch (error) {
    yield put(Actions.loginFailure(error))
  }
}

function* socialLoginOAuth1Step1(action) {
  const {provider, redirectUri} = action
  try {
    const response = yield call(AuthApi.socialLoginOAuth1ObtainRequestToken, provider, redirectUri)
    yield put(Actions.socialLoginOAuth1Step1Success(response))
  } catch (error) {
    yield put(Actions.loginFailure(error))
  }
}

function* socialLoginOAuth1Step2(action) {
  const {payload} = action
  try {
    const response = yield call(AuthApi.socialLoginOAuth1, payload)
    UserSession.setToken(response.token)
    yield put(Actions.loginSuccess(response.token))
    yield put(ProfileActions.getProfile())
  } catch (error) {
    yield put(Actions.loginFailure(error))
  }
}

function* logout() {
  yield UserSession.setToken(null)
  yield put(push('/login'))
}
