import React from 'react'
import {Button} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'
import * as Actions from './redux/actions'
import camara from '../../assets/camara.svg'
import {getVideoStream} from '../utils/MediaUtils'
import {createError} from '../common/redux/actions.notifications'

const styles = theme => ({
  container: {
    padding: '20px 45px',
    margin: '40px 0 20px 0',
    backgroundColor: '#F7F7F7',
    textAlign: 'center',
    maxHeight: 470,
    '& p': {
      margin: '0 0 25px 0',
      fontSize: 14,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0',
      marginLeft: -20,
      width: 'calc(100% - 30px)',
    },
  },
  videoPreview: {
    width: 364,
    height: 273,
    backgroundColor: '#D8D8D8',
    margin: '0 0 25px 0',
    backgroundImage: `url(${camara})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  startButton: {
    borderRadius: 21,
    height: '42px',
    backgroundColor: '#007bc0',
    color: '#fff',
    padding: '0px 30px',
  },
  spanUppercase: {
    textTransform: 'uppercase',
    fontWeight: 500,
    letterSpacing: '0.3px',
  },
})

const COLOR_MAP = {
  happy: '#ffbf00',
  neutral: '#a6a6a6',
  surprised: '#ec7d32',
  angry: '#c00000',
  sad: '#4472c4',
  disgusted: '#71ac47',
  fear: '#404040',
  confused: '#833b0b',
}

class Demo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stream: null,
      capturing: false,
    }
    this.captureVideoSnapshot = this.captureVideoSnapshot.bind(this)
  }

  componentDidMount() {
    window.AudioContext = window.AudioContext || window.webkitAudioContext
    const ReactPixel = require('react-facebook-pixel').default
    ReactPixel.pageView()
  }

  captureVideoSnapshot() {
    const {video} = this
    const {detectEngage, isFetching, detectEngageClear} = this.props
    const {capturing} = this.state
    if (video && capturing && !isFetching) {
      const canvas = document.createElement('canvas')
      if (video.videoWidth <= 0) {
        video.addEventListener('loadedmetadata', this.captureSnapshot)
        return
      }
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      const ctx = canvas.getContext('2d')
      ctx.drawImage(video, 0, 0)
      canvas.toBlob(
        blob => {
          detectEngage(blob)
        },
        'image/jpeg',
        0.9,
      )
    }
    if (!capturing && this.uploadInterval) {
      clearInterval(this.uploadInterval)
      this.uploadInterval = null
      detectEngageClear()
    }
  }

  componentDidUpdate() {
    const {capturing} = this.state
    const {detectEngageClear, result} = this.props
    if (!capturing && result.engaged != undefined) {
      detectEngageClear()
    }
  }

  componentWillUnmount() {
    const {stream} = this.state
    if (this.video) {
      this.video.srcObject = null
      this.video = null
    }
    if (stream) {
      for (const track of stream.getTracks()) {
        track.stop()
      }
    }
  }

  render() {
    const {classes, result, detectEngageClear} = this.props
    const {stream, capturing} = this.state
    let emotionColor = COLOR_MAP.neutral
    if (result.emotion) {
      Object.keys(COLOR_MAP).forEach(emotion => {
        if (result.emotion.toLowerCase() === emotion) {
          emotionColor = COLOR_MAP[emotion]
        }
      })
    }
    return (
      <div className={classes.container}>
        <p>
          <strong>Engagement: </strong>
          {capturing && result.engaged != undefined && result.engaged === false ? (
            <span className={classes.spanUppercase} style={{color: '#CE2126'}}>
              Not Engaged
            </span>
          ) : capturing && result.engaged === true ? (
            <span className={classes.spanUppercase} style={{color: '#71AC47'}}>
              Engaged
            </span>
          ) : (
            <span>N/A</span>
          )}
        </p>
        <video
          muted
          className={classes.videoPreview}
          ref={video => {
            if (stream && video && !video.srcObject) {
              this.video = video
              video.srcObject = stream
              video.play()
            }
          }}
        />
        <p>
          <strong>Current Emotion: </strong>
          {capturing && result.emotion ? (
            <span className={classes.spanUppercase} style={{color: emotionColor}}>
              {result.emotion}
            </span>
          ) : (
            <span>N/A</span>
          )}
        </p>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.startButton}
          enabled={stream}
          style={{backgroundColor: capturing ? '#ce2126' : '#007bc0'}}
          onClick={() => {
            if (this.uploadInterval) {
              clearInterval(this.uploadInterval)
              this.uploadInterval = null
            }
            if (!capturing) {
              getVideoStream()
                .then(stream => {
                  this.setState({stream, capturing: true}, () => {
                    this.captureVideoSnapshot()
                    this.uploadInterval = setInterval(this.captureVideoSnapshot, 1000)
                  })
                })
                .catch(() => {
                  this.props.createError('No video access.')
                })
            } else {
              if (this.video) {
                this.video.srcObject = null
                this.video = null
              }
              if (stream) {
                for (const track of stream.getTracks()) {
                  track.stop()
                }
              }
              this.setState({capturing: false, stream: null}, () => {
                detectEngageClear()
              })
            }
          }}
        >
          {capturing ? 'STOP DEMO' : 'START DEMO'}
        </Button>
      </div>
    )
  }
}

Demo.displayName = 'Demo'

function mapStateToProps(state) {
  return {...state.demo}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions, createError}, dispatch)
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Demo)
