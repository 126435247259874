import React from 'react'
import PropTypes from 'prop-types'
import {Button, Dialog, Typography, withStyles} from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'

const styles = theme => ({
  paperRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 5}px`,
    borderRadius: theme.spacing.unit,
  },
  icon: {
    fontSize: '48px',
  },
  text: {
    width: '30ch',
    textAlign: 'center',
    padding: `${theme.spacing.unit * 2}px 0`,
    fontWeight: 500,
    fontSize: '1.2rem',
    color: theme.palette.surface[800],
  },
  secondaryText: {
    width: '36ch',
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '0.9rem',
    color: theme.palette.surface[700],
    marginBottom: theme.spacing.unit * 2,
  },
  button: {
    padding: `${theme.spacing.unit} ${theme.spacing.unit * 5}`,
  },
})

const AudienceErrorModal = ({classes, open, onClose, text, secondaryText, buttonText}) => {
  return (
    <Dialog PaperProps={{classes: {root: classes.paperRoot}}} open={open} onClose={onClose}>
      <WarningIcon color="secondary" className={classes.icon} />
      <Typography className={classes.text} variant="body1">
        {text}
      </Typography>
      {secondaryText && (
        <Typography className={classes.secondaryText} variant="body1">
          {secondaryText}
        </Typography>
      )}
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        fullWidth={false}
        onClick={onClose}
      >
        {buttonText}
      </Button>
    </Dialog>
  )
}

AudienceErrorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  buttonText: PropTypes.string,
}

AudienceErrorModal.defaultProps = {
  secondaryText: '',
  buttonText: 'Update',
}

export default withStyles(styles)(AudienceErrorModal)
