import React, {useEffect, useState} from 'react'

import {withStyles, Modal, Paper, IconButton, Tabs, Tab} from '@material-ui/core'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'

import DemographicGroupDetailsModalTabContent from './DemographicGroupDetailsModalTabContent'

const styles = theme => ({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.grey[700],
    userSelect: 'none',
  },
  buttonIcon: {
    color: theme.palette.grey[500],
  },
  paperProps: {
    width: '600px',
    maxHeight: 300,
    minHeight: 300,
  },
  modalTitle: {
    fontSize: 28,
    fontWeight: 600,
    color: '#262C38',
    margin: '0 0 32px',
  },
  modalBody: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing.unit * 2,
    overflowY: 'auto',
  },
  infoModalContainer: {
    position: 'relative',
    padding: theme.spacing.unit * 5,
    margin: 'auto',
    width: 785,
    height: 553,
    '&:focus-visible': {
      outline: 'none',
    },
    '& p': {
      color: theme.palette.grey[800],
      fontWeight: 500,
      maxWidth: '55ch',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit * 2,
    top: theme.spacing.unit * 4,
    color: theme.palette.grey[800],
  },
})

const DemographicGroupDetailsModal = ({
  classes,
  detailsModalOpen,
  closeDetailsModal,
  modalContent,
  demographicLabelsTable,
}) => {
  const [currentTab, setCurrentTab] = useState(0)

  useEffect(() => {
    setCurrentTab(0)
  }, [modalContent])

  const handleTabChange = (event, value) => {
    setCurrentTab(value)
  }

  return (
    <Modal
      open={detailsModalOpen}
      onClose={closeDetailsModal}
      aria-labelledby="info-modal"
      aria-describedby="info-modal"
      className={classes.modalBody}
    >
      <Paper elevation={1} className={classes.infoModalContainer} {...classes.paperProp}>
        <IconButton onClick={closeDetailsModal} className={classes.closeButton} aria-label="close">
          <CloseRoundedIcon />
        </IconButton>

        <h3 className={classes.modalTitle}>Filter Details</h3>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          variant={modalContent.length > 4 ? 'scrollable' : 'standard'}
          scrollButtons={modalContent.length ? 'on' : 'off'}
          style={{borderBottom: '1px solid #D6D8DC', marginBottom: 32}}
          TabIndicatorProps={{
            style: {
              backgroundColor: '#131822',
              borderBottom: '2px solid #131822',
            },
          }}
          ScrollButtonComponent={({direction, visible, ...props}) => {
            const icon = {
              left: <ChevronLeft />,
              right: <ChevronRight />,
            }
            return (
              <div
                {...props}
                style={{
                  opacity: visible ? 1 : 0.3,
                  cursor: visible ? 'pointer' : 'default',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {icon[direction]}
              </div>
            )
          }}
        >
          {modalContent.map((group, index) => (
            <Tab
              key={group.groupName}
              value={index}
              label={group.groupName}
              style={{
                textTransform: 'none',
                color: currentTab === index ? '#131822' : '#A6A7A9',
                fontWeight: 500,
                fontSize: 16,
              }}
            />
          ))}
        </Tabs>
        {modalContent[currentTab] && (
          <DemographicGroupDetailsModalTabContent
            groupTitle={modalContent[currentTab].groupName}
            tabContent={modalContent[currentTab].groupDetails}
            demographicLabelsTable={demographicLabelsTable}
            panelistCount={modalContent[currentTab].panelistCount}
          />
        )}
      </Paper>
    </Modal>
  )
}

export default withStyles(styles)(DemographicGroupDetailsModal)
