const options = seek => ({
  chart: {
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
    redrawOnParentResize: true,
    redrawOnWindowResize: true,
    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 100,
      animateGradually: {
        enabled: false,
      },
      dynamicAnimation: {
        enabled: false,
      },
    },
    fontFamily: 'Roboto',
    events: {
      click: (_event, _chartContext, config) => {
        if (config.dataPointIndex !== -1) seek(config.dataPointIndex)
      },
    },
  },
  stroke: {
    width: 2,
  },
  yaxis: {
    min: 0,
    max: 100,
    show: false,
  },
  xaxis: {
    type: 'numeric',
    floating: true,
    tickPlacement: 'between',
    axisTicks: {
      show: false,
    },
  },
  grid: {
    show: true,
    borderColor: 'rgba(38, 50, 56, 0.05)',
    strokeDashArray: 0,
    position: 'back',
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  annotations: {
    yaxis: [
      {
        y: 100,
        y2: 50,
        fillColor: '#04BF7B',
        opacity: 0.1,
        label: {
          borderWidth: 0,
          text: 'High Rapport',
          textAnchor: 'end',
          offsetY: 14,
          offsetX: -5,
          style: {
            background: '#04BF7B',
            color: '#fff',
            fontWeight: 500,
          },
        },
      },
      {
        y: 0,
        y2: 50,
        fillColor: '#FF0000',
        opacity: 0.1,
        label: {
          borderWidth: 0,
          text: 'Low Rapport',
          textAnchor: 'end',
          offsetY: 98,
          offsetX: -5,
          style: {
            background: '#EB5757',
            color: '#fff',
            fontWeight: 500,
          },
        },
      },
    ],
  },
})

export default options
