import * as Types from './actions.types'

export function createCampaign(campaign, params) {
  return {type: Types.CREATE_CAMPAIGN, campaign, params}
}

export function createCampaignSuccess(payload) {
  return {type: Types.CREATE_CAMPAIGN_SUCCESS, payload}
}

export function createCampaignFailure(error) {
  return {type: Types.CREATE_CAMPAIGN_FAILURE, error}
}

export function resetCampaign() {
  return {type: Types.RESET_CAMPAIGN}
}

export function getCaseTypes() {
  return {type: Types.GET_CASE_TYPES}
}

export function getCampaignConfigs() {
  return {type: Types.GET_CAMPAIGN_CONFIGS}
}

export function getCampaignConfigsSuccess(payload) {
  return {type: Types.GET_CAMPAIGN_CONFIGS_SUCCESS, payload}
}

export function getCampaignConfigsFailure(error) {
  return {type: Types.GET_CAMPAIGN_CONFIGS_FAILURE, error}
}

export function getCaseTypesSuccess(payload) {
  return {type: Types.GET_CASE_TYPES_SUCCESS, payload}
}

export function getCaseTypesFailure(error) {
  return {type: Types.GET_CASE_TYPES_FAILURE, error}
}

export function getCampaigns(params) {
  return {type: Types.GET_CAMPAIGNS, params}
}

export function getCampaignsSuccess(payload) {
  return {type: Types.GET_CAMPAIGNS_SUCCESS, payload}
}

export function getCampaignsFailure(error) {
  return {type: Types.GET_CAMPAIGNS_FAILURE, error}
}

export function getCompletedCampaigns(params) {
  return {type: Types.GET_COMPLETED_CAMPAIGNS, params}
}

export function getCompletedCampaignsSuccess(payload) {
  return {type: Types.GET_COMPLETED_CAMPAIGNS_SUCCESS, payload}
}

export function getCompletedCampaignsFailure(error) {
  return {type: Types.GET_COMPLETED_CAMPAIGNS_FAILURE, error}
}

export function getCampaign(id, params) {
  return {type: Types.GET_CAMPAIGN, id, params}
}

export function getCampaignSuccess(payload) {
  return {type: Types.GET_CAMPAIGN_SUCCESS, payload}
}

export function getCampaignFailure(error) {
  return {type: Types.GET_CAMPAIGN_FAILURE, error}
}

export function getRewardStores() {
  return {type: Types.GET_REWARD_STORES}
}

export function getRewardStoresSuccess(payload) {
  return {type: Types.GET_REWARD_STORES_SUCCESS, payload}
}

export function getRewardStoresFailure(error) {
  return {type: Types.GET_REWARD_STORES_FAILURE, error}
}

export function updateCampaign(id, campaign, redirectOnSuccess = true) {
  return {type: Types.UPDATE_CAMPAIGN, id, campaign, redirectOnSuccess}
}

export function updateCampaignSuccess(payload) {
  return {type: Types.UPDATE_CAMPAIGN_SUCCESS, payload}
}

export function updateCampaignFailure(error) {
  return {type: Types.UPDATE_CAMPAIGN_FAILURE, error}
}

export function publishCampaign(id, params = {}) {
  return {type: Types.PUBLISH_CAMPAIGN, id, params}
}

export function publishCampaignSuccess() {
  return {type: Types.PUBLISH_CAMPAIGN_SUCCESS}
}

export function publishCampaignFailure(error) {
  return {type: Types.PUBLISH_CAMPAIGN_FAILURE, error}
}

export function saveCampaignList(id, campaign) {
  return {type: Types.SAVE_CAMPAIGN_LIST, id, campaign}
}

export function saveCampaignListSuccess(payload) {
  return {type: Types.SAVE_CAMPAIGN_LIST_SUCCESS, payload}
}

export function saveCampaignListFailure(error) {
  return {type: Types.SAVE_CAMPAIGN_LIST_FAILURE, error}
}

export function campaignPayment(id, campaign, params = {}) {
  return {type: Types.CAMPAIGN_PAYMENT, id, campaign, params}
}

export function campaignPaymentSuccess(payload) {
  return {type: Types.CAMPAIGN_PAYMENT_SUCCESS, payload}
}

export function campaignPaymentFailure(error) {
  return {type: Types.CAMPAIGN_PAYMENT_FAILURE, error}
}

export function applyDiscount(id, code) {
  return {type: Types.APPLY_DISCOUNT, id, code}
}

export function applyDiscountSuccess(payload) {
  return {type: Types.APPLY_DISCOUNT_SUCCESS, payload}
}

export function applyDiscountFailure(error) {
  return {type: Types.APPLY_DISCOUNT_FAILURE, error}
}

export function getEngagementEvent(videoId, params) {
  return {type: Types.GET_ENGAGEMENT_EVENT, videoId, params}
}

export function getEngagementEventSuccess(payload, videoId) {
  return {type: Types.GET_ENGAGEMENT_EVENT_SUCCESS, payload, videoId}
}

export function getEngagementEventFailure(error) {
  return {type: Types.GET_ENGAGEMENT_EVENT_FAILURE, error}
}

export function getEngagementWithDemographics(videoId, params) {
  return {type: Types.GET_ENGAGEMENT_WITH_DEMOGRAPHICS, videoId, params}
}

export function getEngagementWithDemographicsSuccess(payload, videoId) {
  return {type: Types.GET_ENGAGEMENT_WITH_DEMOGRAPHICS_SUCCESS, payload, videoId}
}

export function getEngagementWithDemographicsFailure(error) {
  return {type: Types.GET_ENGAGEMENT_WITH_DEMOGRAPHICS_FAILURE, error}
}

export function getEmotionEvent(videoId, params) {
  return {type: Types.GET_EMOTION_EVENT, videoId, params}
}

export function getEmotionEventSuccess(payload, videoId) {
  return {type: Types.GET_EMOTION_EVENT_SUCCESS, payload, videoId}
}

export function getEmotionEventFailure(error) {
  return {type: Types.GET_EMOTION_EVENT_FAILURE, error}
}

export function getEmotionsWithDemographics(videoId, params) {
  return {type: Types.GET_EMOTIONS_WITH_DEMOGRAPHICS, videoId, params}
}

export function getEmotionsWithDemographicsSuccess(payload, videoId) {
  return {type: Types.GET_EMOTIONS_WITH_DEMOGRAPHICS_SUCCESS, payload, videoId}
}

export function getEmotionsWithDemographicsFailure(error) {
  return {type: Types.GET_EMOTIONS_WITH_DEMOGRAPHICS_FAILURE, error}
}

export function getLegalProfile(videoId) {
  return {type: Types.GET_LEGAL_PROFILE, videoId}
}

export function getLegalProfileSuccess(payload) {
  return {type: Types.GET_LEGAL_PROFILE_SUCCESS, payload}
}

export function getLegalProfileFailure(error) {
  return {type: Types.GET_LEGAL_PROFILE_FAILURE, error}
}

export function getLegalBenchmark(videoId, params) {
  return {type: Types.GET_LEGAL_BENCHMARK, videoId, params}
}

export function getLegalBenchmarkSuccess(payload, videoId) {
  return {type: Types.GET_LEGAL_BENCHMARK_SUCCESS, payload, videoId}
}

export function getLegalBenchmarkFailure(error) {
  return {type: Types.GET_LEGAL_BENCHMARK_FAILURE, error}
}

export function getEmotionEventByTime(videoId, params) {
  return {type: Types.GET_EMOTION_EVENT_BY_TIME, videoId, params}
}

export function getEmotionEventByTimeSuccess(payload, videoId) {
  return {type: Types.GET_EMOTION_EVENT_BY_TIME_SUCCESS, payload, videoId}
}

export function getEmotionEventByTimeFailure(error) {
  return {type: Types.GET_EMOTION_EVENT_BY_TIME_FAILURE, error}
}

export function getGroupEmotionEventByTime(videoId, params) {
  return {type: Types.GET_GROUP_EMOTION_EVENT_BY_TIME, videoId, params}
}

export function getGroupEmotionEventByTimeSuccess(payload, videoId) {
  return {type: Types.GET_GROUP_EMOTION_EVENT_BY_TIME_SUCCESS, payload, videoId}
}

export function getGroupEmotionEventByTimeFailure(error) {
  return {type: Types.GET_GROUP_EMOTION_EVENT_BY_TIME_FAILURE, error}
}

export function getEngagementEventByTime(videoId, params) {
  return {type: Types.GET_ENGAGEMENT_EVENT_BY_TIME, videoId, params}
}

export function getEngagementEventByTimeSuccess(payload) {
  return {type: Types.GET_ENGAGEMENT_EVENT_BY_TIME_SUCCESS, payload}
}

export function getEngagementEventByTimeFailure(error) {
  return {type: Types.GET_ENGAGEMENT_EVENT_BY_TIME_FAILURE, error}
}

export function getEmotionEventByTimeItems(videoId, params) {
  return {type: Types.GET_EMOTION_EVENT_BY_TIME_ITEMS, videoId, params}
}

export function getEmotionEventByTimeItemsSuccess(payload) {
  return {type: Types.GET_EMOTION_EVENT_BY_TIME_ITEMS_SUCCESS, payload}
}

export function getEmotionEventByTimeItemsFailure(error) {
  return {type: Types.GET_EMOTION_EVENT_BY_TIME_ITEMS_FAILURE, error}
}

export function getDefaultEmotions(videoId) {
  return {type: Types.GET_DEFAULT_EMOTIONS, videoId}
}

export function getDefaultEmotionsSuccess(payload) {
  return {type: Types.GET_DEFAULT_EMOTIONS_SUCCESS, payload}
}

export function getDefaultEmotionsFailure(error) {
  return {type: Types.GET_DEFAULT_EMOTIONS_FAILURE, error}
}

export function getDefaultEmotionsScore(videoId) {
  return {type: Types.GET_DEFAULT_EMOTIONS_SCORE, videoId}
}

export function getDefaultEmotionsScoreSuccess(payload) {
  return {type: Types.GET_DEFAULT_EMOTIONS_SCORE_SUCCESS, payload}
}

export function getDefaultEmotionsScoreFailure(error) {
  return {type: Types.GET_DEFAULT_EMOTIONS_SCORE_FAILURE, error}
}

export function getDefaultEngagementScore(videoId) {
  return {type: Types.GET_DEFAULT_ENGAGEMENT_SCORE, videoId}
}

export function getDefaultEngagementScoreSuccess(payload) {
  return {type: Types.GET_DEFAULT_ENGAGEMENT_SCORE_SUCCESS, payload}
}

export function getDefaultEngagementScoreFailure(error) {
  return {type: Types.GET_DEFAULT_ENGAGEMENT_SCORE_FAILURE, error}
}

export function getDefaultRapport(videoId) {
  return {type: Types.GET_DEFAULT_RAPPORT, videoId}
}

export function getDefaultRapportSuccess(payload) {
  return {type: Types.GET_DEFAULT_RAPPORT_SUCCESS, payload}
}

export function getDefaultRapportFailure(error) {
  return {type: Types.GET_DEFAULT_RAPPORT_FAILURE, error}
}

export function getEngagementEventByTimeItems(videoId, params) {
  return {type: Types.GET_ENGAGEMENT_EVENT_BY_TIME_ITEMS, videoId, params}
}

export function getEngagementEventByTimeItemsSuccess(payload) {
  return {type: Types.GET_ENGAGEMENT_EVENT_BY_TIME_ITEMS_SUCCESS, payload}
}

export function getEngagementEventByTimeItemsFailure(error) {
  return {type: Types.GET_ENGAGEMENT_EVENT_BY_TIME_ITEMS_FAILURE, error}
}

export function getDemographics(videoId) {
  return {type: Types.GET_DEMOGRAPHICS, videoId}
}

export function getDemographicsSuccess(payload) {
  return {type: Types.GET_DEMOGRAPHICS_SUCCESS, payload}
}

export function getDemographicsFailure(error) {
  return {type: Types.GET_DEMOGRAPHICS_FAILURE, error}
}

export function getDemographicValues(videoId, field) {
  return {type: Types.GET_DEMOGRAPHIC_VALUES, videoId, field}
}

export function getDemographicValuesSuccess(field, payload) {
  return {type: Types.GET_DEMOGRAPHIC_VALUES_SUCCESS, field, payload}
}

export function getDemographicValuesFailure(error) {
  return {type: Types.GET_DEMOGRAPHIC_VALUES_FAILURE, error}
}

export function setDemographicFilter(payload) {
  return {type: Types.SET_DEMOGRAPHIC_FILTER, payload}
}

export function progressIndicator(indicator) {
  return {type: Types.PROGRESS_INDICATOR, indicator}
}

export function getIdexScoringResults(campaignId, field) {
  return {type: Types.GET_INDEX_SCORING_RESULTS, campaignId, field}
}

export function getIdexScoringResultsSuccess(field, payload) {
  return {type: Types.GET_INDEX_SCORING_RESULTS_SUCCESS, field, payload}
}

export function getIdexScoringResultsFailure(error) {
  return {type: Types.GET_INDEX_SCORING_RESULTS_FAILURE, error}
}
export function getDemographicsWithValues(videoId) {
  return {type: Types.GET_DEMOGRAPHICS_WITH_VALUES, videoId}
}

export function getDemographicsWithValuesSuccess(payload) {
  return {type: Types.GET_DEMOGRAPHICS_WITH_VALUES_SUCCESS, payload}
}

export function getDemographicsWithValuesFailure(error) {
  return {type: Types.GET_DEMOGRAPHICS_WITH_VALUES_FAILURE, error}
}

export function getDefaultSurveyQuestions(questionType) {
  return {type: Types.GET_DEFAULT_SURVEY_QUESTIONS, questionType}
}

export function getDefaultSurveyQuestionsSuccess(payload) {
  return {type: Types.GET_DEFAULT_SURVEY_QUESTIONS_SUCCESS, payload}
}

export function getDefaultSurveyQuestionsFailure(error) {
  return {type: Types.GET_DEFAULT_SURVEY_QUESTIONS_FAILURE, error}
}

export function getQuestionsFromCsv(csvFile) {
  return {type: Types.GET_QUESTIONS_FROM_CSV, csvFile}
}

export function getQuestionsFromCsvSuccess(payload) {
  return {type: Types.GET_QUESTIONS_FROM_CSV_SUCCESS, payload}
}

export function getQuestionsFromCsvFailure(error) {
  return {type: Types.GET_QUESTIONS_FROM_CSV_FAILURE, error}
}

export function saveCampaignSurvey(id, campaign) {
  return {type: Types.SAVE_CAMPAIGN_SURVEY, id, campaign}
}

export function saveCampaignSurveySuccess(payload) {
  return {type: Types.SAVE_CAMPAIGN_SURVEY_SUCCESS, payload}
}

export function saveCampaignSurveyFailure(error) {
  return {type: Types.SAVE_CAMPAIGN_SURVEY_FAILURE, error}
}

export function getDefaultSurveyResults(campaignId, params) {
  return {type: Types.GET_DEFAULT_SURVEY_RESULTS, campaignId, params}
}

export function getDefaultSurveyResultsSuccess(payload) {
  return {type: Types.GET_DEFAULT_SURVEY_RESULTS_SUCCESS, payload}
}

export function getDefaultSurveyResultsFailure(error) {
  return {type: Types.GET_DEFAULT_SURVEY_RESULTS_FAILURE, error}
}

export function getSurveyResults(campaignId, params = {}) {
  return {type: Types.GET_SURVEY_RESULTS, campaignId, params}
}

export function getSurveyResultsSuccess(payload) {
  return {type: Types.GET_SURVEY_RESULTS_SUCCESS, payload}
}

export function getSurveyResultsFailure(error) {
  return {type: Types.GET_SURVEY_RESULTS_FAILURE, error}
}

export function getAudienceDetail(campaignId) {
  return {type: Types.GET_AUDIENCE_DETAIL, campaignId}
}

export function getAudienceDetailSuccess(payload) {
  return {type: Types.GET_AUDIENCE_DETAIL_SUCCESS, payload}
}

export function getAudienceDetailFailure(error) {
  return {type: Types.GET_AUDIENCE_DETAIL_FAILURE, error}
}

export function getAudienceDemographics() {
  return {type: Types.GET_AUDIENCE_DEMOGRAPHICS}
}

export function getAudienceDemographicsSuccess(payload) {
  return {type: Types.GET_AUDIENCE_DEMOGRAPHICS_SUCCESS, payload}
}

export function getAudienceDemographicsFailure(error) {
  return {type: Types.GET_AUDIENCE_DEMOGRAPHICS_FAILURE, error}
}

export function getAudienceLocales() {
  return {type: Types.GET_AUDIENCE_LOCALES}
}

export function getAudienceLocalesSuccess(payload) {
  return {type: Types.GET_AUDIENCE_LOCALES_SUCCESS, payload}
}

export function getAudienceLocalesFailure(error) {
  return {type: Types.GET_AUDIENCE_LOCALES_FAILURE, error}
}

export function setTargetAudience(campaignId, params) {
  return {type: Types.SET_TARGET_AUDIENCE, campaignId, params}
}

export function setTargetAudienceSuccess(payload) {
  return {type: Types.SET_TARGET_AUDIENCE_SUCCESS, payload}
}

export function setTargetAudienceFailure(error) {
  return {type: Types.SET_TARGET_AUDIENCE_FAILURE, error}
}

export function resetTargetAudience(campaignId) {
  return {type: Types.RESET_TARGET_AUDIENCE, campaignId}
}

export function resetTargetAudienceSuccess(payload) {
  return {type: Types.RESET_TARGET_AUDIENCE_SUCCESS, payload}
}

export function resetTargetAudienceFailure(error) {
  return {type: Types.RESET_TARGET_AUDIENCE_FAILURE, error}
}

export function getETracRapport(videoId, params) {
  return {type: Types.GET_E_TRAC_RAPPORT, videoId, params}
}

export function getETracRapportSuccess(payload) {
  return {type: Types.GET_E_TRAC_RAPPORT_SUCCESS, payload}
}

export function getETracRapportFailure(error) {
  return {type: Types.GET_E_TRAC_RAPPORT_FAILURE, error}
}

export function getTabScore(videoId, params) {
  return {type: Types.GET_TAB_SCORE, videoId, params}
}

export function getTabScoreSuccess(payload) {
  return {type: Types.GET_TAB_SCORE_SUCCESS, payload}
}

export function getTabScoreFailure(error) {
  return {type: Types.GET_TAB_SCORE_FAILURE, error}
}

export function getTabScoreComparison(videoId, params, labels, focusTimeTrackFilter) {
  return {type: Types.GET_TAB_SCORE_COMPARISON, videoId, params, labels, focusTimeTrackFilter}
}

export function getTabScoreComparisonSuccess(payload) {
  return {type: Types.GET_TAB_SCORE_COMPARISON_SUCCESS, payload}
}

export function getTabScoreComparisonFailure(error) {
  return {type: Types.GET_TAB_SCORE_COMPARISON_FAILURE, error}
}

export function setFocusTimeTrackFilter(payload) {
  return {type: Types.SET_FOCUS_TIME_TRACK_FILTER, payload}
}

export function getSentimentBenchmarks() {
  return {type: Types.GET_SENTIMENT_BENCHMARKS}
}

export function getSentimentBenchmarksSuccess(payload) {
  return {type: Types.GET_SENTIMENT_BENCHMARKS_SUCCESS, payload}
}

export function getSentimentBenchmarksFailure(error) {
  return {type: Types.GET_SENTIMENT_BENCHMARKS_FAILURE, error}
}

//Videos

export function uploadVideo(file) {
  return {type: Types.UPLOAD_VIDEO, file}
}

export function uploadVideoSuccess(payload) {
  return {type: Types.UPLOAD_VIDEO_SUCCESS, payload}
}

export function uploadVideoFailure(error) {
  return {type: Types.UPLOAD_VIDEO_FAILURE, error}
}

export function getVideos(params) {
  return {type: Types.GET_VIDEOS, params}
}

export function getVideosSuccess(payload) {
  return {type: Types.GET_VIDEOS_SUCCESS, payload}
}

export function getVideosFailure(error) {
  return {type: Types.GET_VIDEOS_FAILURE, error}
}

export function deleteVideo(id, video) {
  return {type: Types.DELETE_VIDEO, id, video}
}

export function deleteVideoSuccess(payload) {
  return {type: Types.DELETE_VIDEO_SUCCESS, payload}
}

export function deleteVideoFailure(error) {
  return {type: Types.DELETE_VIDEO_FAILURE, error}
}

export function selectVideo(video) {
  return {type: Types.SELECT_VIDEO, video}
}

export function deselectVideo(video) {
  return {type: Types.DESELECT_VIDEO, video}
}

export function clearSelection() {
  return {type: Types.CLEAR_SELECTION}
}

export function acceptSelections() {
  return {type: Types.ACCEPT_SELECTIONS}
}

export function removeSelection(id) {
  return {type: Types.REMOVE_SELECTION, id}
}

export function resetState(keyList) {
  return {type: Types.RESET_STATE, keyList}
}
