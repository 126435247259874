import React from 'react'
import PropTypes from 'prop-types'
import {IconButton} from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

const DeleteButton = ({onClick, disabled}) => {
  return (
    <IconButton
      disableRipple
      color="secondary"
      aria-label="remove"
      onClick={onClick}
      disabled={disabled}
    >
      <HighlightOffIcon style={{fontSize: '0.8em'}} />
    </IconButton>
  )
}

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

DeleteButton.defaultProps = {
  disabled: false,
}

export default DeleteButton
