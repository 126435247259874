import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import Helmet from 'react-helmet'
import {Paper, Typography, Grid, Toolbar, Input, InputAdornment, Icon} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import * as Actions from './redux/actions'
import Loading from '../common/Loading.jsx'
import UserUtils from '../utils/UserUtils'
import NotFoundView from '../common/NotFound.Component.jsx'
import * as ShareButtons from '../common/ShareButtons.jsx'
import {socialMedia} from '../constants/SocialMedia'

const styles = theme => ({
  container: {
    background: theme.palette.grey['100'],
    marginTop: theme.spacing.unit * 3,
    marginRight: 'auto',
    marginBottom: theme.spacing.unit * 3,
    marginLeft: 'auto',
    maxWidth: '62.5rem',
  },
  toolbar: {
    flexFlow: 'column nowrap',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  title: {
    margin: theme.spacing.unit * 1,
  },
  socialIcon: {
    margin: theme.spacing.unit * 1,
  },
  avatarContainer: {
    padding: theme.spacing.unit * 3,
  },
  avatar: {
    width: '100%',
    borderRadius: '50%',
    padding: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    boxShadow: theme.shadows[1],
  },
  infoContainer: {
    padding: theme.spacing.unit * 3,
  },
  infoField: {
    width: '100%',
    margin: theme.spacing.unit * 1,
  },
})

class PublicUser extends React.Component {
  componentWillMount() {
    const {getPublicProfile, params, user, notFound} = this.props

    if (!user && !notFound) {
      getPublicProfile(params.id)
    }
  }

  componentWillUnmount() {
    this.props.initPublicProfile()
  }

  render() {
    const {user, isFetching, notFound, location, classes} = this.props

    if (notFound) {
      return <NotFoundView />
    }

    const avatarUrl = UserUtils.getImageUrl(user)

    return (
      <Paper className={classes.container}>
        <Grid container>
          {isFetching ? <Loading /> : null}
          {!!user && !isFetching && (
            <Grid item xs={12}>
              <Toolbar className={classes.toolbar}>
                <Typography variant="h6" className={classes.title}>
                  User Profile
                </Typography>
                <div>
                  <ShareButtons.FacebookButton location={location} className={classes.socialIcon} />
                  <ShareButtons.TwitterButton location={location} className={classes.socialIcon} />
                  <ShareButtons.LinkedInButton location={location} className={classes.socialIcon} />
                  <ShareButtons.GooglePlusButton
                    location={location}
                    className={classes.socialIcon}
                  />
                  <ShareButtons.PinterestButton
                    location={location}
                    className={classes.socialIcon}
                    mediaUrl={avatarUrl}
                  />
                  <ShareButtons.TumblrButton location={location} className={classes.socialIcon} />
                </div>
              </Toolbar>
            </Grid>
          )}
          {!!user && (
            <Grid container className={classes.infoContainer}>
              <Helmet
                title={`${UserUtils.getFullName(user)} - Profile Page`}
                meta={[
                  {property: 'og:image', content: avatarUrl},
                  {
                    property: 'og:description',
                    content: `Public Profile of ${UserUtils.getFullName(user)}`,
                  },
                ]}
              />
              <Grid item xs={12} sm={4} md={3}>
                <div className={classes.avatarContainer}>
                  <img
                    className={classes.avatar}
                    src={avatarUrl}
                    alt=""
                    onError={e => {
                      e.currentTarget.style.display = 'none'
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <Input
                  className={classes.infoField}
                  readOnly={true}
                  placeholder="First Name"
                  value={user.firstName}
                />
                <Input
                  className={classes.infoField}
                  readOnly={true}
                  placeholder="Last Name"
                  value={user.lastName}
                />
                {['email', 'website', 'facebook', 'instagram', 'linkedin', 'twitter'].map(media => (
                  <Input
                    key={media}
                    value={user[media]}
                    className={classes.infoField}
                    placeholder={socialMedia[media] && socialMedia[media].label}
                    readOnly={true}
                    startAdornment={
                      <InputAdornment position="start">
                        <Icon
                          className={`fa ${socialMedia[media] && socialMedia[media].fontAwesome}`}
                        />
                      </InputAdornment>
                    }
                  />
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    )
  }
}

function mapStateToProps(state) {
  return {
    ...state.publicProfile,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

PublicUser.displayName = 'PublicUser'
PublicUser.contextTypes = {
  router: PropTypes.object.isRequired,
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(PublicUser)
