import React, {useState, useEffect, useContext} from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Button, Dialog, Grid, Typography, withStyles} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import useUpdateEffect from '../../../hooks/useUpdateEffect'
import useModalState from '../../../hooks/useModalState'

import NewTestContext from '../context/NewTestContext'
import NewTestErrorContext from '../context/NewTestErrorContext'

import QuestionForm from './QuestionForm'
import StepPagination from './StepPagination'
import SaveSetupChangesButton from '../SaveSetupChangesButton'
import SecondaryButton from '../../common/SecondaryButton'
import Loading from '../../common/Loading'
import InfoTooltip from '../../common/InfoTooltip'

import {getDefaultSurveyQuestions} from '../redux/actions'
import {createError} from '../../common/redux/actions.notifications'
import CampaignApi from '../api'

import pageUtil from '../../utils/PageUtils'
import utils from '../utils'
import tagManager, {eventTypes} from '../../utils/GTMUtils'
import {choiceTypes, minQuestionLength, testTypes, contextFields} from '../../constants/NewTest'

import styles from '../styles/TestSetup.styles'
import SurveyCsv from './SurveyCsv'
import WarningModal from './WarningModal'

const SurveySetup = props => {
  const {
    classes,
    isActive,
    defaultSurveyQuestions,
    getDefaultSurveyQuestions,
    isFetching,
    isQuestionsFromCsvFetching,
    nextStep,
    configs,
    createError,
  } = props

  const {
    testType,
    questions,
    audienceGroups,
    setIsCheckingAudienceGroups,
    videoFile,
    setStateField,
    isEditing,
    setIsEditing,
    objectiveQuestions,
    hasPassed,
    setHasPassed,
    hasOpenedDefaultTooltip,
    setHasOpenedDefaultTooltip,
    getSurveyQuestionsObject,
  } = useContext(NewTestContext)
  const {
    surveyQuestionError,
    setSurveyQuestionError,
    surveyChoiceErrors,
    setSurveyChoiceErrors,
    handleAudienceGroupErrors,
  } = useContext(NewTestErrorContext)

  const [currentQuestion, setCurrentQuestion] = useState(-1)
  const [hasCalledSurveyQuestions, setHasCalledSurveyQuestions] = useState(false)
  const [stepInfoTooltipOpen, setStepInfoTooltipOpen] = useState(false)
  const [confirmEditingModal, openConfirmEditingModal, closeConfirmEditingModal] = useModalState(
    false,
  )

  useEffect(() => {
    if (testType === testTypes.creative && !hasCalledSurveyQuestions) {
      getDefaultSurveyQuestions(testTypes.creative)
      setHasCalledSurveyQuestions(true)
    }
    if (!hasOpenedDefaultTooltip.surveySetup) {
      setStepInfoTooltipOpen(true)
      setHasOpenedDefaultTooltip({...hasOpenedDefaultTooltip, surveySetup: true})
    }
  }, [])

  useEffect(() => {
    if (hasPassed.surveySetup && !isEditing.surveySetup) {
      setIsEditing({...isEditing, surveySetup: true})
    }
  }, [questions])

  useUpdateEffect(() => {
    if (
      testType === testTypes.creative &&
      defaultSurveyQuestions &&
      defaultSurveyQuestions.length
    ) {
      const filteredQuestions = questions.filter(({objectiveType}) => !objectiveType)
      const newQuestions = [
        ...defaultSurveyQuestions.map(question => ({
          ...question,
          choiceType: 0,
          objectiveType: 'creative',
        })),
        ...filteredQuestions,
      ]

      setStateField(contextFields.questions, newQuestions)
      setCurrentQuestion(newQuestions.length ? 0 : -1)
    }
  }, [defaultSurveyQuestions, testType])

  useEffect(() => {
    if (testType === testTypes.legal) {
      const filteredQuestions = questions.filter(
        ({objectiveType}) => !objectiveType || objectiveType === '',
      )

      const newQuestions = [...objectiveQuestions, ...filteredQuestions]
      setStateField(contextFields.questions, newQuestions)
      setCurrentQuestion(newQuestions.length ? 0 : -1)
    }
  }, [testType, objectiveQuestions])

  const setQuestion = question => {
    setStateField(
      contextFields.questions,
      questions.map((q, index) => (index === currentQuestion ? {...q, question} : q)),
    )
  }

  const addQuestion = () => {
    const newQuestions = [...questions, {question: '', choiceType: 0, choices: ['', '']}]
    setStateField(contextFields.questions, newQuestions)
    setCurrentQuestion(newQuestions.length - 1)
  }

  const removeQuestion = () => {
    const newQuestions = questions.filter((_, index) => index !== currentQuestion)

    if (currentQuestion > newQuestions.length - 1) {
      setCurrentQuestion(newQuestions.length - 1)
    }

    setStateField(contextFields.questions, newQuestions)
    setSurveyQuestionError('')
    setSurveyChoiceErrors({})
  }

  const setChoiceType = choiceType => {
    setStateField(
      contextFields.questions,
      questions.map((question, index) =>
        index === currentQuestion ? {...question, choiceType} : question,
      ),
    )
  }

  const setChoice = (choice, choiceIndex) => {
    setStateField(
      contextFields.questions,
      questions.map((question, qIndex) =>
        qIndex === currentQuestion
          ? {
              ...question,
              choices: question.choices.map((c, index) => (index === choiceIndex ? choice : c)),
            }
          : question,
      ),
    )
  }

  const addChoice = () => {
    setStateField(
      contextFields.questions,
      questions.map((question, qIndex) =>
        qIndex === currentQuestion
          ? {
              ...question,
              choices: [...question.choices, ''],
            }
          : question,
      ),
    )
  }

  const removeChoice = choiceIndex => {
    if (questions[currentQuestion].choices.length > 2) {
      setStateField(
        contextFields.questions,
        questions.map((question, qIndex) =>
          qIndex === currentQuestion
            ? {
                ...question,
                choices: question.choices.filter((_, cIndex) => choiceIndex !== cIndex),
              }
            : question,
        ),
      )
    }
  }

  const validateQuestion = questionIndex => {
    if (!questions[questionIndex].question) {
      setSurveyQuestionError('Question is required')
      return 'Question is required'
    }

    if (isQuestionDuplicated(questionIndex)) {
      setSurveyQuestionError('Question is duplicated')
      return 'Question is duplicated'
    }

    if (isQuestionTooShort(questionIndex)) {
      setSurveyQuestionError('Question is too short (min: 10 characters)')
      return 'Question is too short (min: 10 characters)'
    }

    setSurveyQuestionError('')
    return ''
  }

  const isQuestionDuplicated = (questionIndex, questionsToVerify = questions) => {
    const patternedQuestions = questionsToVerify.map(question =>
      question.question.toLowerCase().trim(),
    )
    for (let i = 0; i < questionsToVerify.length; i++) {
      if (
        questionIndex !== i &&
        !patternedQuestions[i].id &&
        patternedQuestions[i] === patternedQuestions[questionIndex]
      ) {
        return true
      }
    }
    return false
  }

  const isQuestionTooShort = (questionIndex, questionsToVerify = questions) => {
    if (questionsToVerify[questionIndex].question.length < minQuestionLength) {
      return true
    }
    return false
  }

  const validateChoice = (questionIndex, choiceIndex) => {
    if (!questions[questionIndex].choices[choiceIndex]) {
      setSurveyChoiceErrors({
        ...surveyChoiceErrors,
        [choiceIndex]: 'Answer is required',
      })

      return 'Answer is required'
    }

    setSurveyChoiceErrors({
      ...surveyChoiceErrors,
      [choiceIndex]: '',
    })

    return ''
  }

  const validateFields = questionIndex => {
    if (questionIndex > -1) {
      const question = questions[questionIndex]
      const surveyChoiceErrors = {}
      const choiceTable = {}
      let errorStrings = validateQuestion(questionIndex)

      if (question.choiceType === choiceTypes.multipleChoice) {
        for (let i = 0; i < question.choices.length; i++) {
          const currentChoice = question.choices[i].toLowerCase()
          const errorString = validateChoice(questionIndex, i)

          choiceTable[currentChoice] = choiceTable[currentChoice] || []
          choiceTable[currentChoice].push(i)

          if (errorString) {
            surveyChoiceErrors[i] = errorString
            errorStrings += errorString
          }
        }

        if (_.isEmpty(surveyChoiceErrors)) {
          for (const choiceKey in choiceTable) {
            if (choiceTable[choiceKey].length > 1) {
              for (let i = 0; i < choiceTable[choiceKey].length; i++) {
                surveyChoiceErrors[choiceTable[choiceKey][i]] = 'Answer is duplicated'
                errorStrings += 'Answer is duplicated'
              }
            }
          }
        }

        setSurveyChoiceErrors(surveyChoiceErrors)
      }

      return !errorStrings
    }

    return true
  }

  const handleAddQuestion = () => {
    if (validateFields(currentQuestion)) {
      addQuestion()
      pageUtil.scrollToTop()
    } else {
      utils.scrollToError('SurveySetup')
    }
  }

  const handleNextClick = () => {
    for (let i = 0; i < questions.length; i++) {
      if (!validateFields(i)) {
        setCurrentQuestion(i)
        utils.scrollToError('SurveySetup')
        return
      }
    }

    tagManager.fireEvent(eventTypes.orderSummary)
    pageUtil.scrollToTop()
    setHasPassed({...hasPassed, surveySetup: true})
    nextStep()
  }

  const checkAudienceGroups = async () => {
    setIsCheckingAudienceGroups(true)

    const newAudienceGroups = []

    await Promise.all(
      audienceGroups.map(async group => {
        try {
          handleAudienceGroupErrors({[group.id]: false})
          const {groupCost, lucidWebappLink, price} = await CampaignApi.checkAudienceGroups({
            audienceGroups: [{...group, videoLength: videoFile.duration}],
            surveyQuestions: getSurveyQuestionsObject(),
          })
          newAudienceGroups.push({...group, groupCost, lucidWebappLink, price})
        } catch (error) {
          const {data} = error.response
          if (data?.error) {
            handleAudienceGroupErrors({[group.id]: data.error})
          } else {
            handleAudienceGroupErrors({[group.id]: error.message})
            createError(error.message)
          }
          newAudienceGroups.push(group)
        }
      }),
    )

    setStateField(contextFields.audienceGroups, newAudienceGroups)
    setIsCheckingAudienceGroups(false)
  }

  const handleEditingClick = () => {
    for (let i = 0; i < questions.length; i++) {
      if (!validateFields(i)) {
        setCurrentQuestion(i)
        utils.scrollToError('SurveySetup')
        return
      }
    }

    checkAudienceGroups()

    setIsEditing({...isEditing, surveySetup: false})
  }

  const getDefaultQuestionsCount = () => {
    if (testType === testTypes.legal) {
      return objectiveQuestions.length
    }

    return defaultSurveyQuestions.length
  }

  const renderDefaultQuestionsTooltip = () => {
    const count = getDefaultQuestionsCount()

    if (!count || count <= currentQuestion) return null

    return (
      <InfoTooltip
        placement="right"
        text={
          <span>
            {`The audience will receive ${count} standard survey ${
              count === 1 ? 'question' : 'questions'
            } as part of the test.`}
            <br />
            <br />
            These are not editable as they are default questions to deliver post-test metrics.
            <br />
            <br />
            {`You may also add additional survey questions by clicking "Add New Question".`}
          </span>
        }
        open={stepInfoTooltipOpen}
        onOpen={() => setStepInfoTooltipOpen(true)}
        onClose={() => setStepInfoTooltipOpen(false)}
      />
    )
  }

  return (
    <Grid item lg={3} md={6} sm={12} className={classes.newTestSection} style={{height: 'auto'}}>
      {isFetching ? (
        <Loading />
      ) : (
        <>
          <WarningModal
            classes={classes}
            warnings={[
              'Adding or modifying survey questions may result in revised costs. Review feasibility of the selected audience and adjust accordingly.',
            ]}
            openModal={confirmEditingModal}
            handleCloseModal={() => {
              handleEditingClick()
              closeConfirmEditingModal()
            }}
          />
          <Grid container className={classes.formSection}>
            <Grid className={classes.titleSection}>
              <Typography variant="h6" className={classes.sectionTitle}>
                Surveys
                {renderDefaultQuestionsTooltip()}
              </Typography>
              <SaveSetupChangesButton
                hasPassed={hasPassed.surveySetup}
                isEditing={isEditing.surveySetup}
                onClick={openConfirmEditingModal}
              />
            </Grid>
            {currentQuestion > -1 && (
              <QuestionForm
                question={questions[currentQuestion]}
                setQuestion={setQuestion}
                removeQuestion={removeQuestion}
                setChoiceType={setChoiceType}
                setChoice={setChoice}
                addChoice={addChoice}
                removeChoice={removeChoice}
                currentQuestion={currentQuestion}
                questionError={surveyQuestionError}
                validateQuestion={() => validateQuestion(currentQuestion)}
                choiceErrors={surveyChoiceErrors}
                validateChoice={choiceIndex => validateChoice(currentQuestion, choiceIndex)}
                validateFields={validateFields}
                disableInputs={!!questions[currentQuestion].id}
                maxAnswers={configs.maxSurveyQuestionOptions}
              />
            )}
          </Grid>
          {currentQuestion > -1 && (
            <StepPagination
              text="Question"
              current={currentQuestion}
              total={questions.length}
              prev={() => {
                setCurrentQuestion(utils.getPrevIndex(currentQuestion, questions.length))
                setSurveyQuestionError('')
                setSurveyChoiceErrors({})
              }}
              next={() => {
                setCurrentQuestion(utils.getNextIndex(currentQuestion, questions.length))
                setSurveyQuestionError('')
                setSurveyChoiceErrors({})
              }}
              style={{margin: 'auto 0 8px'}}
            />
          )}

          <SurveyCsv
            classes={classes}
            questions={questions}
            setCurrentQuestion={setCurrentQuestion}
            questionsValidators={{isQuestionDuplicated, isQuestionTooShort}}
          />
          <Grid
            container
            className={classes.surveysButtonsContainers}
            style={{
              marginBottom: isActive || isEditing.surveySetup ? 16 : 0,
            }}
          >
            <Typography variant="h6" className={classes.sectionSubtitle2}>
              Add Questions
            </Typography>
            <Grid className={classes.surveysButtonsRow}>
              <SecondaryButton
                onClick={handleAddQuestion}
                className={classes.surveysButtonsBtn}
                disabled={isQuestionsFromCsvFetching}
              >
                <AddIcon fontSize="small" style={{marginRight: '8px'}} />
                Add New Question
              </SecondaryButton>
            </Grid>
          </Grid>

          {isActive && (
            <Button
              variant="contained"
              color="primary"
              className={classes.nextButton}
              onClick={handleNextClick}
              style={{
                marginTop: questions.length ? '0' : 'auto',
              }}
            >
              Next
              <ChevronRightIcon fontSize="small" style={{marginLeft: 8}} />
            </Button>
          )}
          <SaveSetupChangesButton
            hasPassed={hasPassed.surveySetup}
            isEditing={isEditing.surveySetup}
            onClick={openConfirmEditingModal}
            variant="secondary"
            style={{
              marginTop: questions.length ? '0' : 'auto',
              alignSelf: 'flex-end',
            }}
          />
        </>
      )}
    </Grid>
  )
}

SurveySetup.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isActive: PropTypes.bool.isRequired,
  defaultSurveyQuestions: PropTypes.arrayOf(PropTypes.object).isRequired,
  getDefaultSurveyQuestions: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  configs: PropTypes.objectOf(PropTypes.any),
}

SurveySetup.defaultProps = {
  configs: {
    maxSurveyQuestionOptions: 10,
  },
}

const mapStateToProps = ({campaign}) => ({
  isQuestionsFromCsvFetching: campaign.questionsFromCsv.isFetching,
  isFetching: campaign.defaultSurveyQuestions.isFetching,
  defaultSurveyQuestions: campaign.defaultSurveyQuestions.results,
  configs: campaign.configs,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDefaultSurveyQuestions,
      createError,
    },
    dispatch,
  )

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(SurveySetup)
