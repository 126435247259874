import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

/**
 * Higher ordered component for pages requiring authentication.
 */
const AuthenticatedPage = PageComponent => {
  class AuthenticatedPageHOC extends React.Component {
    constructor() {
      super()

      this.redirectOnLogout = this.redirectOnLogout.bind(this)
    }

    componentWillReceiveProps(nextProps) {
      this.redirectOnLogout(nextProps)
    }

    redirectOnLogout(props) {
      const {auth, location} = props

      if (!auth.token && location.pathname !== '/login') {
        this.context.router.push('/login')
      }
    }

    render() {
      const {auth} = this.props
      if (!auth.token) {
        return null
      }

      return <PageComponent {...this.props} />
    }
  }

  AuthenticatedPageHOC.displayName = 'AuthenticatedPageHOC'
  AuthenticatedPageHOC.contextTypes = {
    router: PropTypes.object.isRequired,
  }

  function mapStateToProps(state) {
    return {
      auth: state.auth,
    }
  }

  // Wrap the component to inject dispatch and state into it
  return connect(mapStateToProps)(AuthenticatedPageHOC)
}

export default AuthenticatedPage
