import reduceReducers from 'reduce-reducers'
import * as Types from './actions.types'
import * as ErrorUtils from '../../utils/ErrorUtils'

function getInitialState() {
  return {
    isFetching: false,
    isSaving: false,
    paymentStatus: '',
    indicator: 'campaign',
    campaign: {videos: []},
    caseTypes: {
      isFetching: false,
      results: [],
    },
    configs: {},
    demographics: {
      isFetching: false,
      fields: [],
      values: {},
      filters: {},
    },
    defaultSurveyResults: {
      isFetching: false,
      results: {},
    },
    engagements: {
      isFetching: false,
      events: {},
      multiEvents: {},
    },
    engagementsByTime: {
      isFetching: false,
      events: {},
      items: [],
    },
    engagementWithDemographics: {
      isFetching: false,
      engagements: [],
    },
    emotions: {
      isFetching: false,
      events: {},
      multiEvents: {},
      items: [],
    },
    emotionsByTime: {
      isFetching: false,
      events: {},
    },
    groupEmotionsByTime: {
      isFetching: false,
      groups: [],
    },
    emotionsWithDemographics: {
      isFetching: false,
      emotions: [],
    },
    defaultEmotions: {
      isFetching: false,
      results: {},
    },
    defaultEmotionsScore: {
      isFetching: false,
    },
    defaultEngagementScore: {
      isFetching: false,
    },
    defaultRapport: {
      isFetching: false,
      results: {total: {}, groups: []},
    },
    legalProfile: {
      isFetching: false,
      profile: {},
    },
    legalBenchmark: {
      isFetching: false,
      benchmark: {},
    },
    count: 0,
    next: null,
    previous: null,
    results: [],
    stores: [],
    errors: {},
    availableVideos: [],
    uploadProgress: 0,
    completedCampaigns: {
      results: [],
    },
    defaultSurveyQuestions: {
      isFetching: false,
      results: [],
    },
    questionsFromCsv: {
      isFetching: false,
      results: [],
    },
    demographicsWithValues: {
      isFetching: false,
      fields: [],
      values: {},
    },
    indexScoringResults: {
      isFetching: false,
      indexes: {},
      filters: {},
    },
    surveyResults: {
      isFetching: false,
      results: [],
    },
    audienceDetail: {
      isFetching: false,
      results: [],
    },
    audienceDemographics: {
      isFetching: false,
      results: {},
    },
    audienceLocales: {
      isFecthing: false,
      results: [],
    },
    targetAudience: {
      response: {},
    },
    eTracRapport: {
      isFetching: false,
      results: {groups: []},
    },
    tabScore: {
      isFetching: false,
      results: [],
    },
    tabScoreComparison: {
      isFetching: false,
      results: [],
      labels: [],
    },
    focusTimeTrackFilter: {},
    sentimentBenchmarks: {},
  }
}

function campaign(state = getInitialState(), action) {
  switch (action.type) {
    case Types.GET_CAMPAIGN_CONFIGS:
      return {
        ...state,
        isFetching: true,
      }

    case Types.GET_CAMPAIGN_CONFIGS_SUCCESS:
      return {
        ...state,
        configs: action.payload,
      }

    case Types.GET_CAMPAIGN_CONFIGS_FAILURE:
      return {
        ...state,
        errors: ErrorUtils.getApiErrors(action.error),
      }

    case Types.RESET_CAMPAIGN:
      return {
        ...getInitialState(),
      }

    case Types.SAVE_CAMPAIGN_LIST:
    case Types.UPDATE_CAMPAIGN:
    case Types.APPLY_DISCOUNT:
    case Types.PUBLISH_CAMPAIGN:
    case Types.SAVE_CAMPAIGN_SURVEY:
    case Types.SET_TARGET_AUDIENCE:
    case Types.RESET_TARGET_AUDIENCE:
      return {
        ...state,
        isSaving: true,
      }

    case Types.CREATE_CAMPAIGN:
      return {
        ...state,
        isSaving: true,
        paymentStatus: '',
      }

    case Types.CAMPAIGN_PAYMENT:
      return {
        ...state,
        isSaving: true,
      }

    case Types.CAMPAIGN_PAYMENT_SUCCESS:
      return {
        ...state,
        isSaving: false,
        campaign: action.payload,
        paymentStatus: 'success',
      }

    case Types.UPLOAD_VIDEO_PROGRESS:
      return {
        ...state,
        uploadProgress: action.progress,
      }

    case Types.CREATE_CAMPAIGN_SUCCESS:
    case Types.UPDATE_CAMPAIGN_SUCCESS:
    case Types.APPLY_DISCOUNT_SUCCESS:
      return {
        ...state,
        isSaving: false,
        campaign: action.payload,
      }

    case Types.PUBLISH_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isSaving: false,
        paymentStatus: 'success',
      }

    case Types.SAVE_CAMPAIGN_LIST_SUCCESS:
      return {
        ...state,
        isSaving: false,
        campaign: action.payload,
        indicator: 'survey',
      }

    case Types.GET_DEFAULT_SURVEY_QUESTIONS:
      return {
        ...state,
        defaultSurveyQuestions: {
          ...state.defaultSurveyQuestions,
          results: [],
          isFetching: true,
        },
      }

    case Types.GET_DEFAULT_SURVEY_QUESTIONS_SUCCESS:
      return {
        ...state,
        defaultSurveyQuestions: {
          ...state.defaultSurveyQuestions,
          isFetching: false,
          results: [...action.payload.questions],
        },
      }

    case Types.GET_DEFAULT_SURVEY_QUESTIONS_FAILURE:
      return {
        ...state,
        errors: ErrorUtils.getApiErrors(action.error),
        defaultSurveyQuestions: {
          ...state.defaultSurveyQuestions,
          results: [],
          isFetching: false,
        },
      }

    case Types.GET_QUESTIONS_FROM_CSV:
      return {
        ...state,
        questionsFromCsv: {
          ...state.questionsFromCsv,
          results: [],
          isFetching: true,
        },
      }

    case Types.GET_QUESTIONS_FROM_CSV_SUCCESS:
      return {
        ...state,
        questionsFromCsv: {
          ...state.questionsFromCsv,
          isFetching: false,
          results: [...action.payload],
        },
      }

    case Types.GET_QUESTIONS_FROM_CSV_FAILURE:
      return {
        ...state,
        errors: ErrorUtils.getApiErrors(action.error),
        questionsFromCsv: {
          ...state.questionsFromCsv,
          results: [],
          isFetching: false,
        },
      }

    case Types.SAVE_CAMPAIGN_SURVEY_SUCESS:
      return {
        ...state,
        isSaving: false,
        campaign: action.payload,
        indicator: 'payement',
      }

    case Types.GET_CASE_TYPES:
      return {
        ...state,
        caseTypes: {
          isFetching: true,
          results: [],
        },
      }

    case Types.GET_CASE_TYPES_SUCCESS:
      return {
        ...state,
        caseTypes: {
          isFetching: false,
          results: action.payload,
        },
      }

    case Types.GET_CASE_TYPES_FAILURE:
      return {
        ...state,
        caseTypes: {
          isFetching: false,
          results: [],
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }

    case Types.UPDATE_CAMPAIGN_FAILURE:
    case Types.SAVE_CAMPAIGN_LIST_FAILURE:
    case Types.CREATE_CAMPAIGN_FAILURE:
    case Types.APPLY_DISCOUNT_FAILURE:
    case Types.SAVE_CAMPAIGN_SURVEY_FAILURE:
    case Types.SET_TARGET_AUDIENCE_FAILURE:
    case Types.RESET_TARGET_AUDIENCE_FAILURE:
      return {
        ...state,
        isSaving: false,
        errors: ErrorUtils.getApiErrors(action.error),
      }

    case Types.PUBLISH_CAMPAIGN_FAILURE:
      return {
        ...state,
        isSaving: false,
        errors: ErrorUtils.getApiErrors(action.error),
        paymentStatus: 'failure',
      }

    case Types.CAMPAIGN_PAYMENT_FAILURE:
      return {
        ...state,
        isSaving: false,
        errors: ErrorUtils.getApiErrors(action.error),
        paymentStatus: 'failure',
      }

    case Types.GET_CAMPAIGNS:
    case Types.GET_CAMPAIGN:
    case Types.GET_REWARD_STORES:
      return {
        ...state,
        isFetching: true,
      }
    case Types.GET_COMPLETED_CAMPAIGNS:
      return {
        ...state,
        completedCampaigns: {
          isFetching: true,
          results: [],
        },
      }
    case Types.GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.payload,
      }

    case Types.GET_COMPLETED_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        completedCampaigns: {...action.payload, isFetching: false},
      }

    case Types.GET_REWARD_STORES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        stores: [...action.payload],
      }

    case Types.GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        campaign: {...action.payload},
        defaultSurveyResults: {...state.defaultSurveyResults, results: {}},
      }

    case '@@router/LOCATION_CHANGE': {
      if (action.payload.pathname === '/new-test') {
        return {
          ...state,
          campaign: {videos: []},
          errors: {},
          indicator: 'campaign',
        }
      }
      return {
        ...state,
      }
    }

    case Types.PROGRESS_INDICATOR:
      return {
        ...state,
        indicator: action.indicator,
      }

    case Types.GET_CAMPAIGNS_FAILURE:
    case Types.GET_CAMPAIGN_FAILURE:
    case Types.GET_COMPLETED_CAMPAIGNS_FAILURE:
    case Types.GET_REWARD_STORES_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.GET_ENGAGEMENT_EVENT:
      return {
        ...state,
        engagements: {
          ...state.engagements,
          isFetching: true,
        },
      }
    case Types.GET_ENGAGEMENT_EVENT_SUCCESS:
      return {
        ...state,
        engagements: {
          ...state.engagements,
          isFetching: false,
          events: {...action.payload},
          multiEvents: {...state.engagements.multiEvents, [action.videoId]: action.payload},
        },
      }
    case Types.GET_ENGAGEMENT_EVENT_FAILURE:
      return {
        ...state,
        engagements: {
          isFetching: false,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.GET_ENGAGEMENT_WITH_DEMOGRAPHICS:
      return {
        ...state,
        engagementWithDemographics: {
          isFetching: true,
        },
      }
    case Types.GET_ENGAGEMENT_WITH_DEMOGRAPHICS_SUCCESS:
      return {
        ...state,
        engagementWithDemographics: {
          isFetching: false,
          engagements: action.payload.results,
        },
      }
    case Types.GET_ENGAGEMENT_WITH_DEMOGRAPHICS_FAILURE:
      return {
        ...state,
        engagementWithDemographics: {
          isFetching: false,
          ...state.engagementWithDemographics,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.GET_ENGAGEMENT_EVENT_BY_TIME:
    case Types.GET_ENGAGEMENT_EVENT_BY_TIME_ITEMS:
      return {
        ...state,
        engagementsByTime: {
          ...state.engagementsByTime,
          isFetching: true,
        },
      }
    case Types.GET_EMOTION_EVENT_BY_TIME:
    case Types.GET_EMOTION_EVENT_BY_TIME_ITEMS:
      return {
        ...state,
        emotionsByTime: {
          ...state.emotionsByTime,
          isFetching: true,
        },
      }
    case Types.GET_GROUP_EMOTION_EVENT_BY_TIME:
      return {
        ...state,
        groupEmotionsByTime: {
          ...state.groupEmotionsByTime,
          isFetching: true,
        },
      }
    case Types.GET_EMOTIONS_WITH_DEMOGRAPHICS:
      return {
        ...state,
        emotionsWithDemographics: {
          isFetching: true,
        },
      }
    case Types.GET_EMOTIONS_WITH_DEMOGRAPHICS_SUCCESS:
      return {
        ...state,
        emotionsWithDemographics: {
          isFetching: false,
          emotions: action.payload.results,
        },
      }
    case Types.GET_EMOTIONS_WITH_DEMOGRAPHICS_FAILURE:
      return {
        ...state,
        emotionsWithDemographics: {
          isFetching: false,
          ...state.emotionsWithDemographics,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.GET_DEFAULT_EMOTIONS:
      return {
        ...state,
        defaultEmotions: {
          ...state.defaultEmotions,
          isFetching: true,
        },
      }
    case Types.GET_DEFAULT_EMOTIONS_SUCCESS:
      return {
        ...state,
        defaultEmotions: {
          isFetching: false,
          results: action.payload,
        },
      }
    case Types.GET_DEFAULT_EMOTIONS_FAILURE:
      return {
        ...state,
        defaultEmotions: {
          isFetching: false,
          ...state.defaultEmotions,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.GET_DEFAULT_EMOTIONS_SCORE:
      return {
        ...state,
        defaultEmotionsScore: {
          isFetching: true,
        },
      }
    case Types.GET_DEFAULT_EMOTIONS_SCORE_SUCCESS:
      return {
        ...state,
        defaultEmotionsScore: {
          isFetching: false,
          results: action.payload,
        },
      }
    case Types.GET_DEFAULT_EMOTIONS_SCORE_FAILURE:
      return {
        ...state,
        defaultEmotionsScore: {
          isFetching: false,
          ...state.defaultEmotionsScore,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.GET_DEFAULT_ENGAGEMENT_SCORE:
      return {
        ...state,
        defaultEngagementScore: {
          isFetching: true,
        },
      }
    case Types.GET_DEFAULT_ENGAGEMENT_SCORE_SUCCESS:
      return {
        ...state,
        defaultEngagementScore: {
          isFetching: false,
          results: action.payload,
        },
      }
    case Types.GET_DEFAULT_ENGAGEMENT_SCORE_FAILURE:
      return {
        ...state,
        defaultEngagementScore: {
          isFetching: false,
          ...state.defaultEngagementScore,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.GET_DEFAULT_RAPPORT:
      return {
        ...state,
        defaultRapport: {
          isFetching: true,
        },
      }
    case Types.GET_DEFAULT_RAPPORT_SUCCESS:
      return {
        ...state,
        defaultRapport: {
          isFetching: false,
          results: action.payload,
        },
      }
    case Types.GET_DEFAULT_RAPPORT_FAILURE:
      return {
        ...state,
        defaultRapport: {
          isFetching: false,
          ...state.defaultRapport,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.GET_LEGAL_PROFILE:
      return {
        ...state,
        legalProfile: {
          isFetching: true,
        },
      }
    case Types.GET_LEGAL_PROFILE_SUCCESS:
      return {
        ...state,
        legalProfile: {
          isFetching: false,
          profile: action.payload,
        },
      }
    case Types.GET_LEGAL_PROFILE_FAILURE:
      return {
        ...state,
        legalProfile: {
          isFetching: false,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }

    case Types.GET_LEGAL_BENCHMARK:
      return {
        ...state,
        legalBenchmark: {
          isFetching: true,
        },
      }
    case Types.GET_LEGAL_BENCHMARK_SUCCESS:
      return {
        ...state,
        legalBenchmark: {
          isFetching: false,
          benchmark: action.payload.results,
        },
      }
    case Types.GET_LEGAL_BENCHMARK_FAILURE:
      return {
        ...state,
        legalBenchmark: {
          isFetching: false,
          ...state.legalBenchmark,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.GET_ENGAGEMENT_EVENT_BY_TIME_SUCCESS:
      return {
        ...state,
        engagementsByTime: {
          ...state.engagementsByTime,
          isFetching: false,
          events: {...action.payload},
        },
      }
    case Types.GET_ENGAGEMENT_EVENT_BY_TIME_ITEMS_SUCCESS:
      return {
        ...state,
        engagementsByTime: {
          ...state.engagementsByTime,
          isFetching: false,
          items: action.payload,
        },
      }
    case Types.GET_EMOTION_EVENT_BY_TIME_SUCCESS:
      return {
        ...state,
        emotionsByTime: {
          ...state.emotionsByTime,
          isFetching: false,
          events: {...action.payload.events},
          responseCount: action.payload.responseCount,
        },
      }
    case Types.GET_EMOTION_EVENT_BY_TIME_ITEMS_SUCCESS:
      return {
        ...state,
        emotionsByTime: {
          ...state.emotionsByTime,
          isFetching: false,
          items: action.payload,
        },
      }
    case Types.GET_GROUP_EMOTION_EVENT_BY_TIME_SUCCESS:
      return {
        ...state,
        groupEmotionsByTime: {
          ...action.payload,
          isFetching: false,
        },
      }
    case Types.GET_ENGAGEMENT_EVENT_BY_TIME_FAILURE:
    case Types.GET_ENGAGEMENT_EVENT_BY_TIME_ITEMS_FAILURE:
      return {
        ...state,
        engagementsByTime: {
          ...state.engagementsByTime,
          isFetching: false,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.GET_GROUP_EMOTION_EVENT_BY_TIME_FAILURE:
      return {
        ...state,
        groupEmotionsByTime: {
          ...state.groupEmotionsByTime,
          isFetching: false,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.GET_EMOTION_EVENT_BY_TIME_FAILURE:
    case Types.GET_EMOTION_EVENT_BY_TIME_ITEMS_FAILURE:
      return {
        ...state,
        emotionsByTime: {
          ...state.emotionsByTime,
          isFetching: false,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.GET_EMOTION_EVENT:
      return {
        ...state,
        emotions: {
          ...state.emotions,
          isFetching: true,
        },
      }
    case Types.GET_EMOTION_EVENT_SUCCESS:
      return {
        ...state,
        emotions: {
          ...state.emotions,
          isFetching: false,
          events: {...action.payload},
          multiEvents: {...state.emotions.multiEvents, [action.videoId]: action.payload},
        },
      }
    case Types.GET_EMOTION_EVENT_FAILURE:
      return {
        ...state,
        emotions: {
          isFetching: false,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.GET_DEMOGRAPHICS:
      return {
        ...state,
        demographics: {
          ...state.demographics,
          isFetching: true,
        },
      }
    case Types.GET_DEMOGRAPHICS_SUCCESS:
      return {
        ...state,
        demographics: {
          ...state.demographics,
          fields: action.payload,
          isFetching: false,
        },
      }
    case Types.GET_DEMOGRAPHICS_FAILURE:
      return {
        ...state,
        demographics: {
          ...state.demographics,
          isFetching: false,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.GET_DEMOGRAPHIC_VALUES:
      return {
        ...state,
        demographics: {
          ...state.demographics,
          isFetching: true,
        },
      }
    case Types.GET_DEMOGRAPHIC_VALUES_SUCCESS:
      return {
        ...state,
        demographics: {
          ...state.demographics,
          values: {...state.demographics.values, [action.field]: action.payload},
          isFetching: false,
        },
      }
    case Types.GET_DEMOGRAPHIC_VALUES_FAILURE:
      return {
        ...state,
        demographics: {
          ...state.demographics,
          isFetching: false,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.SET_DEMOGRAPHIC_FILTER:
      return {
        ...state,
        demographics: {
          ...state.demographics,
          filters: action.payload,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.GET_INDEX_SCORING_RESULTS:
      return {
        ...state,
        indexScoringResults: {
          ...state.indexScoringResults,
          isFetching: true,
        },
      }
    case Types.GET_INDEX_SCORING_RESULTS_SUCCESS:
      return {
        ...state,
        indexScoringResults: {
          ...state.indexScoringResults,
          indexes: action.payload,
          isFetching: false,
        },
      }
    case Types.GET_INDEX_SCORING_RESULTS_FAILURE:
      return {
        ...state,
        indexScoringResults: {
          ...state.indexScoringResults,
          isFetching: false,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.GET_DEMOGRAPHICS_WITH_VALUES:
      return {
        ...state,
        demographicsWithValues: {
          ...state.demographicsWithValues,
          isFetching: true,
        },
      }
    case Types.GET_DEMOGRAPHICS_WITH_VALUES_SUCCESS:
      return {
        ...state,
        demographicsWithValues: {
          ...state.demographicsWithValues,
          values: {...action.payload},
          isFetching: false,
        },
      }
    case Types.GET_DEMOGRAPHICS_WITH_VALUES_FAILURE:
      return {
        ...state,
        demographicsWithValues: {
          ...state.demographicsWithValues,
          isFetching: false,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.GET_DEFAULT_SURVEY_RESULTS:
      return {
        ...state,
        defaultSurveyResults: {
          isFetching: true,
        },
      }
    case Types.GET_DEFAULT_SURVEY_RESULTS_SUCCESS:
      return {
        ...state,
        defaultSurveyResults: {
          isFetching: false,
          results: action.payload.results,
        },
      }
    case Types.GET_DEFAULT_SURVEY_RESULTS_FAILURE:
      return {
        ...state,
        defaultSurveyResults: {
          isFetching: false,
          ...state.defaultSurveyResults,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.GET_SURVEY_RESULTS:
      return {
        ...state,
        surveyResults: {
          ...state.surveyResults,
          isFetching: true,
        },
      }
    case Types.GET_SURVEY_RESULTS_SUCCESS:
      return {
        ...state,
        surveyResults: {
          ...state.surveyResults,
          results: action.payload,
          isFetching: false,
        },
      }
    case Types.GET_SURVEY_RESULTS_FAILURE:
      return {
        ...state,
        surveyResults: {
          ...state.surveyResults,
          isFetching: false,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.GET_AUDIENCE_DETAIL:
      return {
        ...state,
        audienceDetail: {
          ...state.audienceDetail,
          isFetching: true,
        },
      }
    case Types.GET_AUDIENCE_DETAIL_SUCCESS:
      return {
        ...state,
        audienceDetail: {
          ...state.audienceDetail,
          results: action.payload,
          isFetching: false,
        },
      }
    case Types.GET_AUDIENCE_DETAIL_FAILURE:
      return {
        ...state,
        audienceDetail: {
          ...state.audienceDetail,
          isFetching: false,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.GET_AUDIENCE_DEMOGRAPHICS:
      return {
        ...state,
        audienceDemographics: {
          ...state.audienceDemographics,
          isFetching: true,
        },
      }
    case Types.GET_AUDIENCE_DEMOGRAPHICS_SUCCESS:
      return {
        ...state,
        audienceDemographics: {
          ...state.audienceDemographics,
          ...action.payload,
          isFetching: false,
        },
      }
    case Types.GET_AUDIENCE_DEMOGRAPHICS_FAILURE:
      return {
        ...state,
        audienceDemographics: {
          ...state.audienceDemographics,
          isFetching: false,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.GET_AUDIENCE_LOCALES:
      return {
        ...state,
        audienceLocales: {
          ...state.audienceLocales,
          isFetching: true,
        },
      }
    case Types.GET_AUDIENCE_LOCALES_SUCCESS:
      return {
        ...state,
        audienceLocales: {
          ...state.audienceLocales,
          ...action.payload,
          isFetching: false,
        },
      }
    case Types.GET_AUDIENCE_LOCALES_FAILURE:
      return {
        ...state,
        audienceLocales: {
          ...state.audienceLocales,
          isFetching: false,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.SET_TARGET_AUDIENCE_SUCCESS:
      return {
        ...state,
        isSaving: false,
        targetAudience: {
          ...state.targetAudience,
          response: action.payload,
        },
        indicator: state.indicator === 'payment' ? 'payment' : 'survey',
      }
    case Types.RESET_TARGET_AUDIENCE_SUCCESS:
      return {
        ...state,
        isSaving: false,
      }

    case Types.GET_E_TRAC_RAPPORT:
      return {
        ...state,
        eTracRapport: {
          ...state.eTracRapport,
          isFetching: true,
        },
      }
    case Types.GET_E_TRAC_RAPPORT_SUCCESS:
      return {
        ...state,
        eTracRapport: {
          ...state.eTracRapport,
          results: action.payload,
          isFetching: false,
        },
      }
    case Types.GET_E_TRAC_RAPPORT_FAILURE:
      return {
        ...state,
        eTracRapport: {
          ...state.eTracRapport,
          isFetching: false,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.GET_TAB_SCORE:
      return {
        ...state,
        tabScore: {
          ...state.tabScore,
          isFetching: true,
        },
      }
    case Types.GET_TAB_SCORE_SUCCESS:
      return {
        ...state,
        tabScore: {
          ...state.tabScore,
          results: action.payload,
          isFetching: false,
        },
      }
    case Types.GET_TAB_SCORE_FAILURE:
      return {
        ...state,
        tabScore: {
          ...state.tabScore,
          isFetching: false,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.GET_TAB_SCORE_COMPARISON:
      return {
        ...state,
        tabScoreComparison: {
          ...state.tabScoreComparison,
          isFetching: true,
        },
      }
    case Types.GET_TAB_SCORE_COMPARISON_SUCCESS:
      return {
        ...state,
        tabScoreComparison: {
          ...state.tabScoreComparison,
          isFetching: false,
          results: action.payload.results,
          labels: action.payload.labels,
        },
      }
    case Types.GET_TAB_SCORE_COMPARISON_FAILURE:
      return {
        ...state,
        tabScoreComparison: {
          ...state.tabScoreComparison,
          isFetching: false,
          errors: ErrorUtils.getApiErrors(action.error),
        },
      }
    case Types.SET_FOCUS_TIME_TRACK_FILTER:
      return {
        ...state,
        focusTimeTrackFilter: action.payload,
      }

    case Types.GET_SENTIMENT_BENCHMARKS:
      return {
        ...state,
        sentimentBenchmarks: {
          isFetching: true,
        },
      }
    case Types.GET_SENTIMENT_BENCHMARKS_SUCCESS:
      return {
        ...state,
        sentimentBenchmarks: {
          isFetching: false,
          ...action.payload,
        },
      }
    case Types.GET_SENTIMENT_BENCHMARKS_FAILURE:
      return {
        ...state,
        sentimentBenchmarks: {
          isFetching: false,
        },
        errors: ErrorUtils.getApiErrors(action.error),
      }

    //Videos
    case Types.UPLOAD_VIDEO:
    case Types.DELETE_VIDEO:
      return {
        ...state,
        isSaving: true,
      }

    case Types.UPLOAD_VIDEO_SUCCESS:
      return {
        ...state,
        isSaving: false,
        campaign: {
          ...state.campaign,
          videos: state.campaign.id
            ? [...state.campaign.videos, action.payload]
            : [...state.campaign.videos, action.payload.id],
        },
        availableVideos: [...state.availableVideos, action.payload],
      }

    case Types.DELETE_VIDEO_SUCCESS:
      return {
        ...state,
        isSaving: false,
        campaign: {
          ...state.campaign,
          videos: state.campaign.videos.filter(video => {
            return state.campaign.id ? video.id !== action.id : video !== action.id
          }),
        },
        availableVideos: state.availableVideos.filter(video => {
          return video.id !== action.id
        }),
      }

    case Types.REMOVE_SELECTION:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          videos: state.campaign.videos.filter(video => {
            return state.campaign.id ? video.id !== action.video.id : video !== action.video.id
          }),
        },
      }

    case Types.DELETE_VIDEO_FAILURE:
    case Types.UPLOAD_VIDEO_FAILURE:
      return {
        ...state,
        isSaving: false,
        errors: ErrorUtils.getApiErrors(action.error),
      }

    case Types.GET_VIDEOS:
      return {
        ...state,
        isFetching: true,
      }

    case Types.GET_VIDEOS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        availableVideos: action.payload.results,
        count: action.payload.count,
      }

    case Types.GET_VIDEOS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: ErrorUtils.getApiErrors(action.error),
      }

    case Types.SELECT_VIDEO:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          videos: state.campaign.id
            ? [...state.campaign.videos, action.video]
            : [...new Set([...state.campaign.videos, action.video.id])],
        },
      }

    case Types.DESELECT_VIDEO:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          videos: state.campaign.videos.filter(video => {
            return state.campaign.id ? video.id !== action.video.id : video !== action.video.id
          }),
        },
      }

    case Types.CLEAR_SELECTION:
      return {
        ...state,
        campaign: {...state.campaign, videos: []},
      }

    case Types.RESET_STATE: {
      const initialState = getInitialState()
      const newState = {...state}
      action.keyList.forEach(key => {
        if (Object.hasOwnProperty.call(initialState, key)) {
          newState[key] = {...initialState[key]}
        }
      })
      return newState
    }
    default:
      return state
  }
}

const reducer = reduceReducers((state, action) => campaign(state, action))

export default reducer
