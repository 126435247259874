import React, {useState, useEffect, useContext} from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import {compose, bindActionCreators} from 'redux'
import {Button, Grid, Typography, withStyles} from '@material-ui/core'
import {connect} from 'react-redux'
import AddIcon from '@material-ui/icons/Add'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import useModalState from '../../../hooks/useModalState'

import AudienceErrorModal from './AudienceErrorModal'
import AudienceSelectionModal from './AudienceSelectionModal'
import AudienceGroupCard from './AudienceGroupCard'
import ConfirmationModal from '../../common/ConfirmationModal'
import Loading from '../../common/Loading'
import SecondaryButton from '../../common/SecondaryButton'
import InfoTooltip from '../../common/InfoTooltip'

import NewTestContext from '../context/NewTestContext'

import pageUtil from '../../utils/PageUtils'

import {contextFields} from '../../constants/NewTest'

import {getAudienceDemographics, getAudienceLocales} from '../redux/actions'
import {createError} from '../../common/redux/actions.notifications'

import utils from '../utils'

import styles from '../styles/TestSetup.styles'
import NewTestErrorContext from '../context/NewTestErrorContext'

const AudienceSetup = props => {
  const {
    classes,
    isActive,
    getAudienceDemographics,
    getAudienceLocales,
    audienceLocales,
    audienceDemographics,
    isFetching,
    nextStep,
    configs,
    registeredFrom,
  } = props

  const {
    audienceGroups,
    setStateField,
    hasPassed,
    setHasPassed,
    hasOpenedDefaultTooltip,
    setHasOpenedDefaultTooltip,
    isCheckingAudienceGroups,
  } = useContext(NewTestContext)

  const {hasAudienceGroupErrors} = useContext(NewTestErrorContext)

  const [currentGroup, setCurrentGroup] = useState(-1)
  const [hasCalledAudienceDemographics, setHasCalledAudienceDemographics] = useState(false)
  const [hasCalledAudienceLocales, setHasCalledAudienceLocales] = useState(false)
  const [demographicFields, setDemographicFields] = useState({})
  const [totalPanelists, setTotalPanelists] = useState(configs.campaignMinimumPanelists || 0)
  const [stepInfoTooltipOpen, setStepInfoTooltipOpen] = useState(false)
  const [groupToDelete, setGroupToDelete] = useState(null)

  const isLucidUser = utils.isLucidUser(registeredFrom)

  const [
    panelistsErrorModalOpen,
    openPanelistsErrorModal,
    closePanelistsErrorModal,
  ] = useModalState(false)
  const [audienceGroupModal, openAudienceGroupModal, closeAudienceGroupModal] = useModalState(false)
  const [confirmDeleteModal, openConfirmDeleteModal, closeConfirmDeleteModal] = useModalState(false)

  useEffect(() => {
    if (!hasCalledAudienceDemographics) {
      getAudienceDemographics()
      setHasCalledAudienceDemographics(true)
    }

    if (!hasCalledAudienceLocales && isLucidUser) {
      getAudienceLocales()
      setHasCalledAudienceLocales(true)
    }

    if (!hasOpenedDefaultTooltip.audienceSetup) {
      setStepInfoTooltipOpen(true)
      setHasOpenedDefaultTooltip({...hasOpenedDefaultTooltip, audienceSetup: true})
    }
  }, [isLucidUser])

  useEffect(() => {
    setTotalPanelists(audienceGroups.reduce((acc, curr) => acc + curr.totalCompletes, 0))
  }, [audienceGroups])

  useEffect(() => {
    setDemographicFields(utils.decamelizeAudienceDemographicKeys(audienceDemographics))
  }, [audienceDemographics])

  useEffect(() => {
    if (audienceGroups.length) {
      setStateField(
        contextFields.audienceGroups,
        audienceGroups.map(group => ({
          ...group,
          totalCompletes: configs.panelistsGroupDefaultValue,
        })),
      )
    }
  }, [configs.panelistsGroupDefaultValue]) // CHECK THIS METHOD

  const removeGroup = index => {
    const newGroups = audienceGroups.filter((_, groupIndex) => index !== groupIndex)

    setStateField(contextFields.audienceGroups, newGroups)
  }

  const handleAddGroup = () => {
    setCurrentGroup(audienceGroups.length + 1)
    openAudienceGroupModal()
  }

  const validatePanelists = () => {
    return totalPanelists >= configs.campaignMinimumPanelists
  }

  const handleNextClick = async () => {
    if (!validatePanelists()) {
      openPanelistsErrorModal()
    } else {
      pageUtil.scrollToTop()
      setHasPassed({...hasPassed, audienceSetup: true})
      nextStep()
    }
  }

  const handleEditGroup = groupIndex => {
    setCurrentGroup(groupIndex)
    openAudienceGroupModal()
  }

  const getTotalPanelistsClasses = () => {
    let totalPanelistsClass = classes.totalPanelists

    if (!validatePanelists()) {
      totalPanelistsClass += ` ${classes.invalidPanelists}`
    }

    return totalPanelistsClass
  }

  const renderNextButton = () => {
    if (isActive && !hasAudienceGroupErrors()) {
      return (
        <Button
          variant="contained"
          color="primary"
          className={classes.nextButton}
          onClick={handleNextClick}
          style={{
            marginTop: '0',
          }}
        >
          Next
          <ChevronRightIcon fontSize="small" style={{marginLeft: 8}} />
        </Button>
      )
    }
    return null
  }

  return (
    <Grid item lg={3} md={6} sm={12} className={classes.newTestSection}>
      {isFetching || isCheckingAudienceGroups ? (
        <Loading />
      ) : (
        <>
          <AudienceErrorModal
            open={panelistsErrorModalOpen}
            onClose={closePanelistsErrorModal}
            text={`In order to run a successful test we require at least ${configs.campaignMinimumPanelists} panelists.`}
          />
          <ConfirmationModal
            open={confirmDeleteModal}
            text="Are you sure you want to delete?"
            onCancel={() => {
              setGroupToDelete(null)
              closeConfirmDeleteModal()
            }}
            onConfirm={() => {
              removeGroup(groupToDelete)
              closeConfirmDeleteModal()
            }}
          />
          {audienceGroupModal && (
            <AudienceSelectionModal
              isOpen={audienceGroupModal}
              audienceLocales={audienceLocales}
              closeModal={closeAudienceGroupModal}
              demographicFields={demographicFields}
              group={audienceGroups[currentGroup]}
              currentGroup={currentGroup}
              configs={configs}
            />
          )}

          <Grid className={classes.titleSection} style={{marginBottom: 8}}>
            <Typography variant="h6" className={classes.sectionTitle}>
              Select Audience
              <InfoTooltip
                text={
                  <span>
                    {`Your test includes the selection of ${configs.campaignMinimumPanelists} panelists.`}
                    <br />
                    <br />
                    {`You can create one group of ${configs.campaignMinimumPanelists} and the responses will fall as they may
                        based on your demographic selections.`}
                    <br />
                    <br />
                    To compare results by demographics, you can separate your panelists into
                    multiple groups.
                    <br />
                    <br />
                    {`Each group must have a minimum of ${configs.minimumPanelistsPerGroup} panelists.`}
                    <br />
                    <br />
                    {`An additional cost of ${utils.formatValue(
                      configs.pricePerMinute,
                    )} per panelist, per minute of video, is added if
                        selecting more than ${configs.campaignMinimumPanelists} total panelists.`}
                  </span>
                }
                placement="right"
                open={stepInfoTooltipOpen}
                onOpen={() => setStepInfoTooltipOpen(true)}
                onClose={() => setStepInfoTooltipOpen(false)}
              />
            </Typography>
          </Grid>

          {/* group cards */}
          {!_.isEmpty(audienceGroups) ? (
            <Grid className={classes.audienceGroupsContainer}>
              {audienceGroups.map((group, index) => (
                <AudienceGroupCard
                  key={index}
                  group={group}
                  showGroupCost={utils.isLucidUser(registeredFrom)}
                  editGroup={() => handleEditGroup(index)}
                  deleteGroup={() => {
                    openConfirmDeleteModal()
                    setGroupToDelete(index)
                  }}
                />
              ))}
            </Grid>
          ) : (
            <Typography className={classes.emptyGroupsMessage}>
              To test and compare specific demographics create your individual groups.
            </Typography>
          )}

          <SecondaryButton
            onClick={handleAddGroup}
            style={{
              width: 'max-content',
              alignSelf: _.isEmpty(audienceGroups) ? 'flex-start' : 'center',
              marginBottom: isActive ? 16 : 0,
              marginTop: 16,
              backgroundColor: '#017eff14',
            }}
          >
            <AddIcon fontSize="small" style={{marginRight: '8px'}} />
            Add New Group
          </SecondaryButton>

          <Grid
            container
            className={classes.audienceBottomSection}
            style={isActive ? {} : {justifyContent: 'center'}}
          >
            <p
              className={classes.totalPanelists}
              style={!isActive ? {marginTop: 16} : {marginTop: 0}}
            >
              {`Total Panelists: `}
              <span className={getTotalPanelistsClasses()}>{totalPanelists}</span>
              <InfoTooltip
                text={`In order to create a successful test, you need at least ${configs.campaignMinimumPanelists} total panelists.`}
                placement="top"
              />
            </p>
            {renderNextButton()}
          </Grid>
        </>
      )}
    </Grid>
  )
}

AudienceSetup.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isActive: PropTypes.bool.isRequired,
  getAudienceDemographics: PropTypes.func.isRequired,
  audienceDemographics: PropTypes.objectOf(PropTypes.object),
  audienceLocales: PropTypes.arrayOf(PropTypes.object),
  isFetching: PropTypes.bool.isRequired,
  nextStep: PropTypes.func.isRequired,
  configs: PropTypes.objectOf(PropTypes.number),
}

AudienceSetup.defaultProps = {
  audienceDemographics: {},
  audienceLocales: [],
  configs: {
    campaignMinimumPanelists: 50,
    panelistsGroupDefaultValue: 50,
    minimumPanelistsPerGroup: 50,
    groupPanelistsIncrementDecrementOffset: 25,
    pricePerMinute: 6,
  },
}

const mapStateToProps = ({profile, campaign}) => ({
  registeredFrom: profile?.profile?.registeredFrom,
  audienceDemographics: campaign.audienceDemographics.results,
  audienceLocales: campaign.audienceLocales.results,
  isFetching: campaign.audienceDemographics.isFetching,
  configs: campaign.configs,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAudienceDemographics,
      getAudienceLocales,
      createError,
    },
    dispatch,
  )

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(AudienceSetup)
