import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Typography} from '@material-ui/core'
import styles from '../styles/EmptyCampaign.styles'

import {emotionDisplayNames} from '../SentimentMaps/constants'
import sentimentInfo from '../SentimentMaps/sentimentInfo'

const SentimentsTutorial = ({sentiment}) => {
  console.log('sentiment', sentiment)
  const emotionDisplayName = emotionDisplayNames[sentiment]
  const {
    about,
    qualityCriteria,
    image,
    onboarding,
    trajectory,
    peakEndRule,
    conclusion,
  } = sentimentInfo[sentiment]

  return (
    <div style={{paddingLeft: '32px'}}>
      <Typography variant="h4">{emotionDisplayName} Explained</Typography>
      <Typography variant="body1">
        <strong>What is the {emotionDisplayName} Map?</strong> {about}
        <br />
        <br />
        <strong>What are maps?</strong> Maps illustrate the pattern of emotional reactions elicited
        by the audience while watching the content. Maps can be filtered to view data by a variety
        of audience profiles.
        <br />
        <br />
        <strong>What is a good map?</strong> {qualityCriteria}
        <br />
        <br />
        <strong>How to read this map:</strong>
        <img src={image} alt={`${emotionDisplayName} Map`} style={{width: '100%'}} />
        <br />
        <br />
        <strong>1. Onboarding:</strong> {onboarding}
        <br />
        <br />
        <strong>2. Understanding the trajectory:</strong> {trajectory}
        <br />
        <br />
        <strong>3. Peak End Rule:</strong> {peakEndRule}
        <br />
        <br />
        <strong>What do I do with this data?</strong> {conclusion}
      </Typography>
    </div>
  )
}

export default withStyles(styles)(SentimentsTutorial)
