import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {Drawer, Grid, List, ListItem, ListItemText, Button, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import TestNavigation from './TestNavigation'
import NavigationButton from './NavigationButton'

import {logout} from '../auth/redux/actions'

import logo from '../../assets/ET-Logo-White.svg'
import shopIcon from '../../assets/icons/shop.svg'
import faqIcon from '../../assets/icons/faq.svg'
import contactIcon from '../../assets/icons/contact.svg'
import logoutIcon from '../../assets/icons/logout.svg'
import flagIcon from '../../assets/icons/flag.svg'
import tutorialIcon from '../../assets/icons/videoIcon.svg'

import styles from './styles/Sidebar.styles'

const paths = {
  home: '/',
  newTest: '/new-test',
  faq: '/faq',
  contact: '/contact',
  tutorials: '/tutorials',
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const {classes, logout, location, isSidebarVisible, sidebarWidth} = this.props
    const {pathname} = location
    return (
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={isSidebarVisible}
        classes={{paper: classes.sidebarBody}}
        PaperProps={{style: {width: sidebarWidth}}}
      >
        <div className={classes.drawerHeader}>
          <Link to="/">
            <img src={logo} alt="Emotion Trac" />
          </Link>
        </div>
        <List className={classes.topSection}>
          {pathname.includes('/tests/') ? (
            <TestNavigation pathname={pathname} router={this.context.router} />
          ) : (
            <>
              <NavigationButton
                path={paths.home}
                isSelected={pathname === paths.home}
                icon={shopIcon}
                text="My Tests"
              />
              <NavigationButton
                path={paths.tutorials}
                isSelected={pathname === paths.tutorials}
                icon={tutorialIcon}
                text="Tutorials"
              />
            </>
          )}
          {pathname.includes('/new-test') && (
            <NavigationButton
              path={paths.newTest}
              isSelected={true}
              icon={flagIcon}
              text="Create New Test"
            />
          )}
          <a
            href="https://creative.emotiontrac.com/resources/faqs/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <ListItem button>
              <Grid container className={classes.sidebarItem}>
                <img src={faqIcon} alt="faq" />
                <ListItemText classes={{primary: classes.sidebarText}} primary="FAQ" />
              </Grid>
            </ListItem>
          </a>
          <a href="https://emotiontrac.com/contact/" rel="noopener noreferrer" target="_blank">
            <ListItem button>
              <Grid container className={classes.sidebarItem}>
                <img src={contactIcon} alt="contact" />
                <ListItemText classes={{primary: classes.sidebarText}} primary="Contact Us" />
              </Grid>
            </ListItem>
          </a>
        </List>
        <Grid item xs />
        <List className={classes.bottomSection}>
          <ListItem>
            <Button
              component={Link}
              to="/new-test"
              className={classes.newTestButton}
              variant="contained"
              color="primary"
            >
              <AddIcon fontSize="small" className={classes.buttonIcon} />
              Create New Test
            </Button>
          </ListItem>
          <ListItem button onClick={() => logout()}>
            <Grid container className={classes.sidebarItem} style={{padding: 0}}>
              <img className={classes.logoutIcon} src={logoutIcon} alt="logout" />
              <ListItemText classes={{primary: classes.logoutText}} primary="Logout" />
            </Grid>
          </ListItem>
          <ListItem>
            <Typography className={classes.copyrightText}>
              Copyright &copy; {new Date().getFullYear()} EmotionTrac
              <br />
              All Rights Reserved.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography className={classes.bottomLinks}>
              <a
                href="https://emotiontrac.com/pages/privacy/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Privacy Policy
              </a>
              <a
                href="https://emotiontrac.com/pages/terms/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Terms & Conditions
              </a>
              <a href="mailto:support@emotiontrac.com">Support</a>
            </Typography>
          </ListItem>
        </List>
      </Drawer>
    )
  }
}

Sidebar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  logout: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  isSidebarVisible: PropTypes.bool.isRequired,
  sidebarWidth: PropTypes.number.isRequired,
}

Sidebar.contextTypes = {
  router: PropTypes.objectOf(PropTypes.any).isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({logout}, dispatch)
}

export default compose(connect(null, mapDispatchToProps), withStyles(styles))(Sidebar)
