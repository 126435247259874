import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router'
import {ListItem, ListItemText, Grid, withStyles} from '@material-ui/core'

import styles from './styles/Sidebar.styles'

const NavigationButton = ({
  classes,
  path,
  onClick,
  isDisabled,
  isSelected,
  icon,
  text,
  containerClass,
  ...rest
}) => {
  return (
    <ListItem
      button
      disabled={isDisabled}
      component={Link}
      to={path}
      onClick={onClick}
      classes={{disabled: classes.disabledItem}}
      className={isSelected ? classes.selectedItem : ''}
      {...rest}
    >
      <Grid container className={`${classes.sidebarItem} ${containerClass}`}>
        {typeof icon === 'object' ? icon : <img src={icon} alt={text} />}
        <ListItemText classes={{primary: classes.sidebarText}} primary={text} />
      </Grid>
      <span className={classes.whiteStripe} />
    </ListItem>
  )
}

NavigationButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  path: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  text: PropTypes.string.isRequired,
  containerClass: PropTypes.string,
}

NavigationButton.defaultProps = {
  onClick: () => {},
  containerClass: '',
  isDisabled: false,
}

export default withStyles(styles)(NavigationButton)
