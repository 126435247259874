import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'

import DemographicSelect from './DemographicSelect'

import {getTabScoreComparison} from '../campaign/redux/actions'

import utils from '../campaign/utils'

import styles from './styles/CompareDemographics.styles'

const initialState = {
  group1: {},
  group2: {},
  group3: {},
}

const CompareDemographicsModal = ({
  classes,
  selectParents,
  selectChildren,
  open,
  onClose,
  getTabScoreComparison,
  campaignVideos,
  isComparing,
  setIsComparing,
  focusTimeTrackFilter,
}) => {
  const [groupStates, setGroupStates] = useState(initialState)
  const [group3Visible, setGroup3Visible] = useState(false)

  const group1Key = 'group1'
  const group2Key = 'group2'
  const group3Key = 'group3'

  useEffect(() => {
    if (!isComparing) {
      setGroupStates(initialState)
    }
  }, [isComparing])

  useEffect(() => {
    if (isComparing) handleClick()
  }, [focusTimeTrackFilter])

  const handleSelectAllChange = groupKey => {
    const newFilters = {}

    if (areAllSelected(groupKey)) {
      setGroupStates({...groupStates, [groupKey]: {}})
    } else {
      selectParents.forEach(parent => {
        newFilters[parent.label] = _.map(selectChildren[parent.label], 'label')
      })

      setGroupStates({...groupStates, [groupKey]: {...newFilters}})
    }
  }

  const areAllSelected = groupKey => {
    const selectedFilters = groupStates[groupKey]
    const filterKeys = Object.keys(selectedFilters)

    if (filterKeys.length !== selectParents.length) return false

    for (let i = 0; i < filterKeys.length; i++) {
      const parent = filterKeys[i]

      if (selectedFilters[parent].length !== selectChildren[parent].length) return false
    }

    return true
  }

  const handleParentChange = (groupKey, label) => {
    const selectedFilters = groupStates[groupKey]
    const children = selectedFilters[label]

    setGroupStates({
      ...groupStates,
      [groupKey]: {
        ...groupStates[groupKey],
        [label]: children && children.length ? [] : _.map(selectChildren[label], 'label'),
      },
    })
  }

  const isParentSelected = (groupKey, label) => {
    const selectedFilters = groupStates[groupKey]

    if (!selectedFilters[label]) return false
    return selectedFilters[label].length === selectChildren[label].length
  }

  const handleChildChange = (groupKey, parent, label) => {
    const selectedFilters = groupStates[groupKey]
    const children = selectedFilters[parent]

    if (isChildSelected(groupKey, parent, label)) {
      setGroupStates({
        ...groupStates,
        [groupKey]: {
          ...groupStates[groupKey],
          [parent]: _.pull(children, label),
        },
      })
    } else {
      setGroupStates({
        ...groupStates,
        [groupKey]: {
          ...groupStates[groupKey],
          [parent]: children ? _.concat(children, label) : [label],
        },
      })
    }
  }

  const isChildSelected = (groupKey, parent, label) => {
    const selectedFilters = groupStates[groupKey]
    const children = selectedFilters[parent]

    if (!children) return false
    return _.indexOf(children, label) >= 0
  }

  const handleGroup3Close = () => {
    setGroup3Visible(false)
    setGroupStates({...groupStates, [group3Key]: {}})
  }

  const checkEmptyGroups = () => {
    const groupStateKeys = Object.keys(groupStates)

    for (let i = 0; i < groupStateKeys.length; i++) {
      const currentGroup = groupStates[groupStateKeys[i]]
      const groupKeys = Object.keys(currentGroup)
      let isGroupEmpty = true

      if (groupStateKeys[i] === group3Key && !group3Visible) {
        return false
      }

      for (let j = 0; j < groupKeys.length; j++) {
        const currentKey = groupKeys[j]

        if (currentKey && currentGroup[currentKey].length) {
          isGroupEmpty = false
          break
        }
      }

      if (isGroupEmpty) return true
    }

    return false
  }

  const generateQueries = () => {
    const groupArray = [groupStates.group1, groupStates.group2]

    if (group3Visible) groupArray.push(groupStates.group3)

    return groupArray.map(group => utils.getQueryFromFilters(group))
  }

  const getGroupLabels = () => {
    const groupArray = [groupStates.group1, groupStates.group2]

    if (group3Visible) groupArray.push(groupStates.group3)

    return groupArray.map(group => {
      const groupValues = []

      for (let i = 0; i < selectParents.length; i++) {
        const current = group[selectParents[i].label]

        if (current && current.length) groupValues.push(...current)
      }

      return groupValues.join(', ')
    })
  }

  const handleClick = () => {
    if (campaignVideos) {
      setIsComparing(true)

      const {campaignVideoId} = campaignVideos[0]
      const queries = generateQueries()
      const labels = getGroupLabels()

      getTabScoreComparison(campaignVideoId, queries, labels, focusTimeTrackFilter)
      onClose()
    }
  }

  return (
    <Dialog open={open} onClose={onClose} classes={{paper: classes.dialogPaper}}>
      <Grid container className={classes.dialogHeader}>
        <Typography variant="h6">Compare Demographics</Typography>
        <Grid item xs />
        <Button
          onClick={() => setGroup3Visible(true)}
          className={classes.dialogButton}
          variant="outlined"
          color="primary"
          disabled={group3Visible}
          disableRipple
        >
          <AddIcon fontSize="small" className={classes.buttonIcon} />
          New Group
        </Button>
      </Grid>
      <DialogContent className={classes.dialogContent}>
        <Grid container>
          <Grid item xs={4}>
            <Typography className={classes.groupTitle}>Group 1</Typography>
            <DemographicSelect
              selectParents={selectParents}
              selectChildren={selectChildren}
              handleSelectAllChange={() => handleSelectAllChange(group1Key)}
              areAllSelected={() => areAllSelected(group1Key)}
              handleParentChange={label => handleParentChange(group1Key, label)}
              isParentSelected={label => isParentSelected(group1Key, label)}
              handleChildChange={(parent, label) => handleChildChange(group1Key, parent, label)}
              isChildSelected={(parent, label) => isChildSelected(group1Key, parent, label)}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.groupTitle}>Group 2</Typography>
            <DemographicSelect
              selectParents={selectParents}
              selectChildren={selectChildren}
              handleSelectAllChange={() => handleSelectAllChange(group2Key)}
              areAllSelected={() => areAllSelected(group2Key)}
              handleParentChange={label => handleParentChange(group2Key, label)}
              isParentSelected={label => isParentSelected(group2Key, label)}
              handleChildChange={(parent, label) => handleChildChange(group2Key, parent, label)}
              isChildSelected={(parent, label) => isChildSelected(group2Key, parent, label)}
            />
          </Grid>
          {group3Visible && (
            <Grid item xs={4} style={{position: 'relative'}}>
              <IconButton
                size="small"
                color="secondary"
                className={classes.closeGroupButton}
                aria-label="remove"
                disableRipple
                onClick={handleGroup3Close}
              >
                <CloseIcon />
              </IconButton>
              <Typography className={classes.groupTitle}>Group 3</Typography>
              <DemographicSelect
                selectParents={selectParents}
                selectChildren={selectChildren}
                handleSelectAllChange={() => handleSelectAllChange(group3Key)}
                areAllSelected={() => areAllSelected(group3Key)}
                handleParentChange={label => handleParentChange(group3Key, label)}
                isParentSelected={label => isParentSelected(group3Key, label)}
                handleChildChange={(parent, label) => handleChildChange(group3Key, parent, label)}
                isChildSelected={(parent, label) => isChildSelected(group3Key, parent, label)}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.dialogButton}
          variant="outlined"
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          className={classes.dialogButton}
          variant="contained"
          color="primary"
          disabled={checkEmptyGroups()}
          onClick={handleClick}
        >
          Run Comparison
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CompareDemographicsModal.propTypes = {
  campaignVideos: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  getTabScoreComparison: PropTypes.func.isRequired,
  isComparing: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectChildren: PropTypes.objectOf(PropTypes.array).isRequired,
  selectParents: PropTypes.arrayOf(PropTypes.object).isRequired,
  setIsComparing: PropTypes.func.isRequired,
}

CompareDemographicsModal.defaultProps = {
  campaignVideos: [],
}

const mapStateToProps = ({campaign}) => ({
  campaignVideos: campaign.campaign.campaignVideos,
  focusTimeTrackFilter: campaign.focusTimeTrackFilter,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTabScoreComparison,
    },
    dispatch,
  )

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(CompareDemographicsModal)
