import {creativeSentiments, emotionDisplayNames, legalSentiments} from '../SentimentMaps/constants'

export const tutorialVideos = [
  {
    title: 'Testing Content with EmotionTrac',
    time: '02 min 44 sec',
    videoThumb: require('../../../assets/tutorials/Testing_Content_with_EmotionTrac.png'),
    videoUrl: 'https://player.vimeo.com/video/764341009?h=3595257795&app_id=122963',
  },
  {
    title: 'Navigating Test Results',
    time: '05 min 14 sec',
    videoThumb: require('../../../assets/tutorials/Navigating_Test_Results.png'),
    videoUrl: 'https://player.vimeo.com/video/764339750?h=ce1b682cd8&app_id=122963',
  },
  {
    title: 'Naming Your Test, Selecting a Test Type & Uploading Your Video',
    time: '01 min 18 sec',
    videoThumb: require('../../../assets/tutorials/Naming_Your_Test.png'),
    videoUrl: 'https://player.vimeo.com/video/816594305?h=4b80eaae1d',
  },
  {
    title: 'Selecting Your Audience',
    time: '03 min 13 sec',
    videoThumb: require('../../../assets/tutorials/Selecting_an_Audience.png'),
    videoUrl: 'https://player.vimeo.com/video/816603416?h=86e47241fa',
  },
  {
    title: 'Adding Survey Questions',
    time: '01 min 33 sec',
    videoThumb: require('../../../assets/tutorials/Adding_Survey_Question.png'),
    videoUrl: 'https://player.vimeo.com/video/816600891?h=f614e33448',
  },
  {
    title: 'Reviewing Selections & Publishing Test',
    time: '51 sec',
    videoThumb: require('../../../assets/tutorials/Reviewing_Selections.png'),
    videoUrl: 'https://player.vimeo.com/video/816604772?h=64d52a432f',
  },
  {
    title: 'Viewing Results',
    time: '04 min 12 sec',
    videoThumb: require('../../../assets/tutorials/Viewing_Results.png'),
    videoUrl: 'https://player.vimeo.com/video/816611078?h=0cd24e738e',
  },
]

export const tutorialDocumentation = [
  {
    title: 'Rapport Map',
    slug: 'rapportMap',
    icon: require('../../../assets/icons/rapport.svg'),
    subMenu: [{title: 'Map Explained', sentiment: 'rapport'}],
  },
  {
    title: 'Engagement Map',
    slug: 'engagementMap',
    icon: require('../../../assets/icons/curiosity.svg'),
    subMenu: [{title: 'Map Explained', sentiment: 'curious'}],
  },
  {
    title: 'Angst Map',
    slug: 'angstMap',
    icon: require('../../../assets/icons/angst.svg'),
    subMenu: [{title: 'Map Explained', sentiment: 'angst'}],
  },
  {
    title: 'Humor Map',
    slug: 'humorMap',
    icon: require('../../../assets/icons/humorMap.svg'),
    subMenu: [{title: 'Map Explained', sentiment: 'humor'}],
  },
  {
    title: 'Sentiment Maps',
    slug: 'sentimentMap',
    icon: require('../../../assets/icons/sentiments.svg'),
    subMenu: [...creativeSentiments, ...legalSentiments].map(sentiment => ({
      title: emotionDisplayNames[sentiment],
      sentiment,
    })),
  },
]
