import React from 'react'
import {withStyles} from '@material-ui/core'

import reportIcon from '../../assets/icons/line-chart.svg'
import pendingReportIcon from '../../assets/icons/cached.svg'

const styles = {
  '@global @keyframes rotate': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(-360deg)',
    },
  },
  pendingReport: {
    color: 'red',
    animation: 'rotate 20s linear infinite',
  },
}

const ValuationReportIcon = ({classes, isDisabled}) => {
  if (!isDisabled) {
    return <img src={reportIcon} alt="Valuation Report" />
  }

  return <img src={pendingReportIcon} alt="ValuationReport" className={classes.pendingReport} />
}

export default withStyles(styles)(ValuationReportIcon)
