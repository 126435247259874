const styles = theme => ({
  newTestSection: {
    padding: theme.spacing.unit * 3,
    display: 'flex',
    flexDirection: 'column',
  },
  nameSection: {
    display: 'grid',
    gridGap: `${theme.spacing.unit}px`,
    paddingBottom: theme.spacing.unit * 2,
    position: 'relative',
    '&::after': {
      content: '""',
      height: 1,
      width: '100%',
      background: theme.palette.border.main,
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  titleSection: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  typeSection: {
    display: 'grid',
    gridGap: `${theme.spacing.unit}px`,
    marginTop: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 2,
    position: 'relative',
    '&::after': {
      content: '""',
      height: 1,
      width: '100%',
      background: theme.palette.border.main,
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
    },
    '& $sectionSubtitle': {
      opacity: 0.6,
      color: '#292D32',
    },
  },
  sectionTitle: {
    width: 'max-content',
    fontSize: '1.1em',
    color: '#292D32',
    position: 'relative',
    marginRight: 24,
  },
  sectionSubtitle: {
    fontSize: '1em',
  },
  sectionSubtitle2: {
    fontSize: '14px',
    lineHeight: '15px',
  },
  nameInput: {
    width: '100%',
  },
  nameLengthIndicator: {
    fontSize: '0.75rem',
    color: theme.palette.surface[600],
    marginLeft: 'auto',
    marginTop: theme.spacing.unit * 0.5,
  },
  notchedOutline: {
    borderColor: `${theme.palette.border ? theme.palette.border.main : 'initial'} !important`,
  },
  errorOutline: {
    borderColor: `${theme.palette.secondary.main} !important`,
  },
  inputErrorText: {
    marginLeft: 0,
    color: theme.palette.secondary.main,
  },
  nextButton: {
    alignSelf: 'flex-end',
    marginTop: 'auto',
  },
  uploadSection: {
    display: 'grid',
    gridGap: `${theme.spacing.unit}px`,
    paddingTop: theme.spacing.unit * 1.5,
    paddingBottom: theme.spacing.unit * 2,
    position: 'relative',
    '& $sectionSubtitle': {
      opacity: 0.6,
      color: '#292D32',
      fontSize: '0.75em',
      paddingTop: 10,
    },
  },
  scheduleSection: {
    display: 'grid',
    gridGap: `${theme.spacing.unit}px`,
    paddingTop: theme.spacing.unit * 1.5,
    paddingBottom: theme.spacing.unit * 3,
  },
  selectRoot: {
    overflow: 'hidden',
    width: '100%',
  },
  selectIcon: {
    marginRight: theme.spacing.unit * 3,
  },
  disabledIcon: {
    display: 'none',
  },
  selectMenu: {
    fontSize: '0.9em',
    paddingRight: theme.spacing.unit * 6,
  },
  dateTimeInput: {
    fontSize: '0.9em',
  },
  calendarIcon: {
    width: 20,
    filter:
      'invert(11%) sepia(4%) saturate(1906%) hue-rotate(173deg) brightness(102%) contrast(85%)',
  },
  questionFormContainer: {
    display: 'grid',
    gridGap: `${theme.spacing.unit}px`,
    maxHeight: '60vh',
    overflowY: 'auto',
    marginBottom: theme.spacing.unit,
  },
  surveysButtonsContainers: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },
  surveysButtonsRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
  },
  surveysButtonsBtn: {
    width: 'max-content',
    alignSelf: 'initial',
    marginRight: '12px',
    fontWeight: 400,
    fontSize: '14px',
  },
  surveysButtonsBtnDownloadTemplate: {
    textDecoration: 'underline',
    textTransform: 'none',
    color: '#017EFF',
    padding: 0,
    minWidth: 'auto',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: 'Roboto',
    lineHeight: '24.5px',
  },
  disabledAddButton: {
    cursor: 'initial',
    color: '#BDBFC2',
    '&:hover': {
      background: 'initial',
    },
  },
  formSection: {
    display: 'grid',
    gridGap: `${theme.spacing.unit}px`,
  },
  questionHeader: {
    alignItems: 'center',
    '& button': {
      padding: '0 !important',
      marginLeft: theme.spacing.unit,
    },
  },
  demographicGroupHeader: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  duplicateGroupsModalBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 5}px`,
    borderRadius: theme.spacing.unit,
  },
  duplicateGroupsModalText: {
    width: '30ch',
    textAlign: 'center',
    padding: `${theme.spacing.unit * 2}px 0`,
    fontWeight: 500,
    fontSize: '1.2em',
    color: theme.palette.grey[700],
  },
  duplicateGroupsModalButton: {
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
  },
  totalCompleteSection: {
    alignItems: 'center',
    gridGap: `${theme.spacing.unit}px`,
    '& > p': {
      color: theme.palette.grey[600],
    },
  },
  audienceFormControl: {
    width: '100%',
  },
  audienceSelect: {
    overflow: 'initial',
    whiteSpace: 'initial',
    textOverflow: 'initial',
    paddingRight: '48px',
    display: 'flex',
    flexFlow: 'wrap',
    gridGap: '8px',
  },
  selectedSubDemographic: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: 'white',
  },
  subDemographicChip: {
    borderRadius: theme.spacing.unit,
    color: 'white',
    textTransform: 'capitalize',
    background: theme.palette.grey[500],
    maxWidth: '15ch',
  },
  chipLabel: {
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  audienceSectionSubtitle: {
    fontSize: '1em',
    textTransform: 'capitalize',
  },
  demographicRemoveButton: {
    fontWeight: 400,
    '&:hover': {
      textDecoration: 'underline',
      background: 'initial',
    },
  },
  nextLoadingWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing.unit * 4,
  },
  totalPanelists: {
    margin: 0,
    position: 'relative',
    '& > span': {
      fontWeight: 500,
    },
  },
  invalidPanelists: {
    color: theme.palette.error.main,
  },
  audienceBottomSection: {
    gridGap: '32px',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 'auto',
    [theme.breakpoints.down(1730)]: {
      flexDirection: 'column',
      alignItems: 'center',
      gridGap: '16px',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      gridGap: '32px',
    },
  },
  newAudienceGroupTooltip: {
    maxWidth: 400,
    padding: theme.spacing.unit * 2,
  },
  emptyGroupsMessage: {
    color: theme.palette.surface[700],
    fontSize: '0.9rem',
  },
  audienceGroupsContainer: {
    width: '100%',
    maxHeight: '60vh',
    overflow: 'auto',
  },
  confirmEditingModal: {
    padding: theme.spacing.unit * 4,
    maxWidth: 500,
  },
  confirmEditingTitle: {
    fontWeight: 600,
    fontSize: '1.8rem',
    textAlign: 'center',
  },
  confirmEditingTextContainer: {
    margin: `${theme.spacing.unit * 4} 0`,
  },
  confirmEditingText: {
    fontSize: '1.1rem',
    margin: `${theme.spacing.unit} 0`,
    textAlign: 'center',
  },
  confirmEditingButton: {
    flexGrow: 1,
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
})

export default styles
