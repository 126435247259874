export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN'
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS'
export const CREATE_CAMPAIGN_FAILURE = 'CREATE_CAMPAIGN_FAILURE'

export const UPLOAD_VIDEO_PROGRESS = 'UPLOAD_VIDEO_PROGRESS'

export const GET_CAMPAIGNS = 'GET_CAMPAIGNS'
export const GET_CAMPAIGNS_SUCCESS = 'GET_CAMPAIGNS_SUCCESS'
export const GET_CAMPAIGNS_FAILURE = 'GET_CAMPAIGNS_FAILURE'

export const RESET_CAMPAIGN = 'RESET_CAMPAIGN'

export const GET_COMPLETED_CAMPAIGNS = 'GET_COMPLETED_CAMPAIGNS'
export const GET_COMPLETED_CAMPAIGNS_SUCCESS = 'GET_COMPLETED_CAMPAIGNS_SUCCESS'
export const GET_COMPLETED_CAMPAIGNS_FAILURE = 'GET_COMPLETED_CAMPAIGNS_FAILURE'

export const GET_CAMPAIGN = 'GET_CAMPAIGN'
export const GET_CAMPAIGN_SUCCESS = 'GET_CAMPAIGN_SUCCESS'
export const GET_CAMPAIGN_FAILURE = 'GET_CAMPAIGN_FAILURE'

export const GET_CAMPAIGN_CONFIGS = 'GET_CAMPAIGN_CONFIGS'
export const GET_CAMPAIGN_CONFIGS_SUCCESS = 'GET_CAMPAIGN_CONFIGS_SUCCESS'
export const GET_CAMPAIGN_CONFIGS_FAILURE = 'GET_CAMPAIGN_CONFIGS_FAILURE'

export const GET_CASE_TYPES = 'GET_CASE_TYPES'
export const GET_CASE_TYPES_SUCCESS = 'GET_CASE_TYPES_SUCCCESS'
export const GET_CASE_TYPES_FAILURE = 'GET_CASE_TYPES_FAILURE'

export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN'
export const UPDATE_CAMPAIGN_SUCCESS = 'UPDATE_CAMPAIGN_SUCCESS'
export const UPDATE_CAMPAIGN_FAILURE = 'UPDATE_CAMPAIGN_FAILURE'

export const PUBLISH_CAMPAIGN = 'PUBLISH_CAMPAIGN'
export const PUBLISH_CAMPAIGN_SUCCESS = 'PUBLISH_CAMPAIGN_SUCCESS'
export const PUBLISH_CAMPAIGN_FAILURE = 'PUBLISH_CAMPAIGN_FAILURE'

export const SAVE_CAMPAIGN_LIST = 'SAVE_CAMPAIGN_LIST'
export const SAVE_CAMPAIGN_LIST_SUCCESS = 'SAVE_CAMPAIGN_LIST_SUCCESS'
export const SAVE_CAMPAIGN_LIST_FAILURE = 'SAVE_CAMPAIGN_LIST_FAILURE'

export const CAMPAIGN_PAYMENT = 'CAMPAIGN_PAYMENT'
export const CAMPAIGN_PAYMENT_SUCCESS = 'CAMPAIGN_PAYMENT_SUCCESS'
export const CAMPAIGN_PAYMENT_FAILURE = 'CAMPAIGN_PAYMENT_FAILURE'

export const APPLY_DISCOUNT = 'APPLY_DISCOUNT'
export const APPLY_DISCOUNT_SUCCESS = 'APPLY_DISCOUNT_SUCCESS'
export const APPLY_DISCOUNT_FAILURE = 'APPLY_DISCOUNT_FAILURE'

export const GET_REWARD_STORES = 'GET_REWARD_STORES'
export const GET_REWARD_STORES_SUCCESS = 'GET_REWARD_STORES_SUCCESS'
export const GET_REWARD_STORES_FAILURE = 'GET_REWARD_STORES_FAILURE'

export const GET_ENGAGEMENT_EVENT = 'GET_ENGAGEMENT_EVENT'
export const GET_ENGAGEMENT_EVENT_SUCCESS = 'GET_ENGAGEMENT_EVENT_SUCCESS'
export const GET_ENGAGEMENT_EVENT_FAILURE = 'GET_ENGAGEMENT_EVENT_FAILURE'

export const GET_EMOTION_EVENT = 'GET_EMOTION_EVENT'
export const GET_EMOTION_EVENT_SUCCESS = 'GET_EMOTION_EVENT_SUCCESS'
export const GET_EMOTION_EVENT_FAILURE = 'GET_EMOTION_EVENT_FAILURE'

export const GET_ENGAGEMENT_EVENT_BY_TIME = 'GET_ENGAGEMENT_EVENT_BY_TIME'
export const GET_ENGAGEMENT_EVENT_BY_TIME_SUCCESS = 'GET_ENGAGEMENT_EVENT_BY_TIME_SUCCESS'
export const GET_ENGAGEMENT_EVENT_BY_TIME_FAILURE = 'GET_ENGAGEMENT_EVENT_BY_TIME_FAILURE'

export const GET_ENGAGEMENT_WITH_DEMOGRAPHICS = 'GET_ENGAGEMENT_WITH_DEMOGRAPHICS'
export const GET_ENGAGEMENT_WITH_DEMOGRAPHICS_SUCCESS = 'GET_ENGAGEMENT_WITH_DEMOGRAPHICS_SUCCESS'
export const GET_ENGAGEMENT_WITH_DEMOGRAPHICS_FAILURE = 'GET_ENGAGEMENT_WITH_DEMOGRAPHICS_FAILURE'

export const GET_EMOTIONS_WITH_DEMOGRAPHICS = 'GET_EMOTIONS_WITH_DEMOGRAPHICS'
export const GET_EMOTIONS_WITH_DEMOGRAPHICS_SUCCESS = 'GET_EMOTIONS_WITH_DEMOGRAPHICS_SUCCESS'
export const GET_EMOTIONS_WITH_DEMOGRAPHICS_FAILURE = 'GET_EMOTIONS_WITH_DEMOGRAPHICS_FAILURE'

export const GET_DEFAULT_EMOTIONS = 'GET_DEFAULT_EMOTIONS'
export const GET_DEFAULT_EMOTIONS_SUCCESS = 'GET_DEFAULT_EMOTIONS_SUCCESS'
export const GET_DEFAULT_EMOTIONS_FAILURE = 'GET_DEFAULT_EMOTIONS_FAILURE'

export const GET_DEFAULT_EMOTIONS_SCORE = 'GET_DEFAULT_EMOTIONS_SCORE'
export const GET_DEFAULT_EMOTIONS_SCORE_SUCCESS = 'GET_DEFAULT_EMOTIONS_SCORE_SUCCESS'
export const GET_DEFAULT_EMOTIONS_SCORE_FAILURE = 'GET_DEFAULT_EMOTIONS_SCORE_FAILURE'

export const GET_DEFAULT_ENGAGEMENT_SCORE = 'GET_DEFAULT_ENGAGEMENT_SCORE'
export const GET_DEFAULT_ENGAGEMENT_SCORE_SUCCESS = 'GET_DEFAULT_ENGAGEMENT_SCORE_SUCCESS'
export const GET_DEFAULT_ENGAGEMENT_SCORE_FAILURE = 'GET_DEFAULT_ENGAGEMENT_SCORE_FAILURE'

export const GET_DEFAULT_RAPPORT = 'GET_DEFAULT_RAPPORT'
export const GET_DEFAULT_RAPPORT_SUCCESS = 'GET_DEFAULT_RAPPORT_SUCCESS'
export const GET_DEFAULT_RAPPORT_FAILURE = 'GET_DEFAULT_RAPPORT_FAILURE'

export const GET_EMOTION_EVENT_BY_TIME = 'GET_EMOTION_EVENT_BY_TIME'
export const GET_EMOTION_EVENT_BY_TIME_SUCCESS = 'GET_EMOTION_EVENT_BY_TIME_SUCCESS'
export const GET_EMOTION_EVENT_BY_TIME_FAILURE = 'GET_EMOTION_EVENT_BY_TIME_FAILURE'

export const GET_GROUP_EMOTION_EVENT_BY_TIME = 'GET_GROUP_EMOTION_EVENT_BY_TIME'
export const GET_GROUP_EMOTION_EVENT_BY_TIME_SUCCESS = 'GET_GROUP_EMOTION_EVENT_BY_TIME_SUCCESS'
export const GET_GROUP_EMOTION_EVENT_BY_TIME_FAILURE = 'GET_GROUP_EMOTION_EVENT_BY_TIME_FAILURE'

export const GET_ENGAGEMENT_EVENT_BY_TIME_ITEMS = 'GET_ENGAGEMENT_EVENT_BY_TIME_ITEMS'
export const GET_ENGAGEMENT_EVENT_BY_TIME_ITEMS_SUCCESS =
  'GET_ENGAGEMENT_EVENT_BY_TIME_ITEMS_SUCCESS'
export const GET_ENGAGEMENT_EVENT_BY_TIME_ITEMS_FAILURE =
  'GET_ENGAGEMENT_EVENT_BY_TIME_ITEMS_FAILURE'

export const GET_EMOTION_EVENT_BY_TIME_ITEMS = 'GET_EMOTION_EVENT_BY_TIME_ITEMS'
export const GET_EMOTION_EVENT_BY_TIME_ITEMS_SUCCESS = 'GET_EMOTION_EVENT_BY_TIME_ITEMS_SUCCESS'
export const GET_EMOTION_EVENT_BY_TIME_ITEMS_FAILURE = 'GET_EMOTION_EVENT_BY_TIME_ITEMS_FAILURE'

export const GET_DEMOGRAPHICS = 'GET_DEMOGRAPHICS'
export const GET_DEMOGRAPHICS_SUCCESS = 'GET_DEMOGRAPHICS_SUCCESS'
export const GET_DEMOGRAPHICS_FAILURE = 'GET_DEMOGRAPHICS_FAILURE'

export const GET_DEMOGRAPHIC_VALUES = 'GET_DEMOGRAPHIC_VALUES'
export const GET_DEMOGRAPHIC_VALUES_SUCCESS = 'GET_DEMOGRAPHIC_VALUES_SUCCESS'
export const GET_DEMOGRAPHIC_VALUES_FAILURE = 'GET_DEMOGRAPHIC_VALUES_FAILURE'

export const SET_DEMOGRAPHIC_FILTER = 'SET_DEMOGRAPHIC_FILTER'

export const PROGRESS_INDICATOR = 'PROGRESS_INDICATOR'

export const GET_INDEX_SCORING_RESULTS = 'GET_INDEX_SCORING_RESULTS'
export const GET_INDEX_SCORING_RESULTS_SUCCESS = 'GET_INDEX_SCORING_RESULTS_SUCCESS'
export const GET_INDEX_SCORING_RESULTS_FAILURE = 'GET_INDEX_SCORING_RESULTS_FAILURE'

export const GET_DEMOGRAPHICS_WITH_VALUES = 'GET_DEMOGRAPHICS_WITH_VALUES'
export const GET_DEMOGRAPHICS_WITH_VALUES_SUCCESS = 'GET_DEMOGRAPHICS_WITH_VALUES_SUCCESS'
export const GET_DEMOGRAPHICS_WITH_VALUES_FAILURE = 'GET_DEMOGRAPHICS_WITH_VALUES_FAILURE'

export const GET_DEFAULT_SURVEY_QUESTIONS = 'GET_DEFAULT_SURVEY_QUESTIONS'
export const GET_DEFAULT_SURVEY_QUESTIONS_SUCCESS = 'GET_DEFAULT_SURVEY_QUESTIONS_SUCCESS'
export const GET_DEFAULT_SURVEY_QUESTIONS_FAILURE = 'GET_DEFAULT_SURVEY_QUESTIONS_FAILURE'

export const GET_QUESTIONS_FROM_CSV = 'GET_QUESTIONS_FROM_CSV'
export const GET_QUESTIONS_FROM_CSV_SUCCESS = 'GET_QUESTIONS_FROM_CSV_SUCCESS'
export const GET_QUESTIONS_FROM_CSV_FAILURE = 'GET_QUESTIONS_FROM_CSV_FAILURE'

export const SAVE_CAMPAIGN_SURVEY = 'SAVE_CAMPAIGN_LIST'
export const SAVE_CAMPAIGN_SURVEY_SUCCESS = 'SAVE_CAMPAIGN_LIST_SUCCESS'
export const SAVE_CAMPAIGN_SURVEY_FAILURE = 'SAVE_CAMPAIGN_LIST_FAILURE'

export const GET_DEFAULT_SURVEY_RESULTS = 'GET_DEFAULT_SURVEY_RESULTS'
export const GET_DEFAULT_SURVEY_RESULTS_SUCCESS = 'GET_DEFAULT_SURVEY_RESULTS_SUCCESS'
export const GET_DEFAULT_SURVEY_RESULTS_FAILURE = 'GET_DEFAULT_SURVEY_RESULTS_FAILURE'

export const GET_SURVEY_RESULTS = 'GET_SURVEY_RESULTS'
export const GET_SURVEY_RESULTS_SUCCESS = 'GET_SURVEY_RESULTS_SUCCESS'
export const GET_SURVEY_RESULTS_FAILURE = 'GET_SURVEY_RESULTS_FAILURE'

export const GET_AUDIENCE_DETAIL = 'GET_AUDIENCE_DETAIL'
export const GET_AUDIENCE_DETAIL_SUCCESS = 'GET_AUDIENCE_DETAIL_SUCCESS'
export const GET_AUDIENCE_DETAIL_FAILURE = 'GET_AUDIENCE_DETAIL_FAILURE'

export const GET_AUDIENCE_DEMOGRAPHICS = 'GET_AUDIENCE_DEMOGRAPHICS'
export const GET_AUDIENCE_DEMOGRAPHICS_SUCCESS = 'GET_AUDIENCE_DEMOGRAPHICS_SUCCESS'
export const GET_AUDIENCE_DEMOGRAPHICS_FAILURE = 'GET_AUDIENCE_DEMOGRAPHICS_FAILURE'

export const GET_AUDIENCE_LOCALES = 'GET_AUDIENCE_LOCALES'
export const GET_AUDIENCE_LOCALES_SUCCESS = 'GET_AUDIENCE_LOCALES_SUCCESS'
export const GET_AUDIENCE_LOCALES_FAILURE = 'GET_AUDIENCE_LOCALES_FAILURE'

export const SET_TARGET_AUDIENCE = 'SET_TARGET_AUDIENCE'
export const SET_TARGET_AUDIENCE_SUCCESS = 'SET_TARGET_AUDIENCE_SUCCESS'
export const SET_TARGET_AUDIENCE_FAILURE = 'SET_TARGET_AUDIENCE_FAILURE'

export const RESET_TARGET_AUDIENCE = 'RESET_TARGET_AUDIENCE'
export const RESET_TARGET_AUDIENCE_SUCCESS = 'RESET_TARGET_AUDIENCE_SUCCESS'
export const RESET_TARGET_AUDIENCE_FAILURE = 'RESET_TARGET_AUDIENCE_FAILURE'

export const GET_E_TRAC_RAPPORT = 'GET_E_TRAC_RAPPORT'
export const GET_E_TRAC_RAPPORT_SUCCESS = 'GET_E_TRAC_RAPPORT_SUCCESS'
export const GET_E_TRAC_RAPPORT_FAILURE = 'GET_E_TRAC_RAPPORT_FAILURE'

export const GET_TAB_SCORE = 'GET_TAB_SCORE'
export const GET_TAB_SCORE_SUCCESS = 'GET_TAB_SCORE_SUCCESS'
export const GET_TAB_SCORE_FAILURE = 'GET_TAB_SCORE_FAILURE'

export const GET_TAB_SCORE_COMPARISON = 'GET_TAB_SCORE_COMPARISON'
export const GET_TAB_SCORE_COMPARISON_SUCCESS = 'GET_TAB_SCORE_COMPARISON_SUCCESS'
export const GET_TAB_SCORE_COMPARISON_FAILURE = 'GET_TAB_SCORE_COMPARISON_FAILURE'

export const GET_LEGAL_PROFILE = 'GET_LEGAL_PROFILE'
export const GET_LEGAL_PROFILE_SUCCESS = 'GET_LEGAL_PROFILE_SUCCESS'
export const GET_LEGAL_PROFILE_FAILURE = 'GET_LEGAL_PROFILE_FAILURE'

export const GET_LEGAL_BENCHMARK = 'GET_LEGAL_BENCHMARK'
export const GET_LEGAL_BENCHMARK_SUCCESS = 'GET_LEGAL_BENCHMARK_SUCCESS'
export const GET_LEGAL_BENCHMARK_FAILURE = 'GET_LEGAL_BENCHMARK_FAILURE'

export const SET_FOCUS_TIME_TRACK_FILTER = 'SET_FOCUS_TIME_TRACK_FILTER'

export const GET_SENTIMENT_BENCHMARKS = 'GET_SENTIMENT_BENCHMARKS'
export const GET_SENTIMENT_BENCHMARKS_SUCCESS = 'GET_SENTIMENT_BENCHMARKS_SUCCESS'
export const GET_SENTIMENT_BENCHMARKS_FAILURE = 'GET_SENTIMENT_BENCHMARKS_FAILURE'

// Videos

export const UPLOAD_VIDEO = 'UPLOAD_VIDEO'
export const UPLOAD_VIDEO_SUCCESS = 'UPLOAD_VIDEO_SUCCESS'
export const UPLOAD_VIDEO_FAILURE = 'UPLOAD_VIDEO_FAILURE'

export const GET_VIDEOS = 'GET_VIDEOS'
export const GET_VIDEOS_SUCCESS = 'GET_VIDEOS_SUCCESS'
export const GET_VIDEOS_FAILURE = 'GET_VIDEOS_FAILURE'

export const DELETE_VIDEO = 'DELETE_VIDEO'
export const DELETE_VIDEO_SUCCESS = 'DELETE_VIDEO_SUCCESS'
export const DELETE_VIDEO_FAILURE = 'DELETE_VIDEO_FAILURE'

export const SELECT_VIDEO = 'SELECT_VIDEO'
export const DESELECT_VIDEO = 'DESELECT_VIDEO'
export const CLEAR_SELECTION = 'CLEAR_SELECTION'
export const ACCEPT_SELECTIONS = 'ACCEPT_SELECTIONS'
export const REMOVE_SELECTION = 'REMOVE_SELECTION'

export const RESET_STATE = 'RESET_STATE'
