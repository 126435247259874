import {call, put, all, takeEvery, select} from 'redux-saga/effects'
import {push} from 'react-router-redux'
import Api from '../api'
import VideosApi from '../../video/api'
import * as Actions from './actions'
import * as Types from './actions.types'
import * as AuthActions from '../../auth/redux/actions'
import * as NotificationActions from '../../common/redux/actions.notifications'

export default function* campaignFlow() {
  yield all([
    takeEvery(Types.GET_CAMPAIGN_CONFIGS, getCampaignConfigs),
    takeEvery(Types.CREATE_CAMPAIGN, createCampaign),
    takeEvery(Types.GET_CAMPAIGNS, getCampaigns),
    takeEvery(Types.GET_COMPLETED_CAMPAIGNS, getCompletedCampaigns),
    takeEvery(Types.GET_CAMPAIGN, getCampaign),
    takeEvery(Types.GET_CASE_TYPES, getCaseTypes),
    takeEvery(Types.GET_REWARD_STORES, getRewardStores),
    takeEvery(Types.UPDATE_CAMPAIGN, updateCampaign),
    takeEvery(Types.PUBLISH_CAMPAIGN, publishCampaign),
    takeEvery(Types.SAVE_CAMPAIGN_LIST, saveCampaignList),
    takeEvery(Types.SAVE_CAMPAIGN_SURVEY, saveCampaignSurvey),
    takeEvery(Types.CAMPAIGN_PAYMENT, campaignPayment),
    takeEvery(Types.APPLY_DISCOUNT, applyDiscount),
    takeEvery(Types.GET_ENGAGEMENT_EVENT, getEngagementEvent),
    takeEvery(Types.GET_ENGAGEMENT_WITH_DEMOGRAPHICS, getEngagementWithDemographics),
    takeEvery(Types.GET_EMOTION_EVENT, getEmotionEvent),
    takeEvery(Types.GET_EMOTIONS_WITH_DEMOGRAPHICS, getEmotionsWithDemographics),
    takeEvery(Types.GET_DEFAULT_EMOTIONS, getDefaultEmotions),
    takeEvery(Types.GET_DEFAULT_EMOTIONS_SCORE, getDefaultEmotionsScore),
    takeEvery(Types.GET_DEFAULT_ENGAGEMENT_SCORE, getDefaultEngagementScore),
    takeEvery(Types.GET_DEFAULT_RAPPORT, getDefaultRapport),
    takeEvery(Types.GET_LEGAL_PROFILE, getLegalProfile),
    takeEvery(Types.GET_LEGAL_BENCHMARK, getLegalBenchmark),
    takeEvery(Types.GET_ENGAGEMENT_EVENT_BY_TIME, getEngagementEventByTime),
    takeEvery(Types.GET_EMOTION_EVENT_BY_TIME, getEmotionEventByTime),
    takeEvery(Types.GET_GROUP_EMOTION_EVENT_BY_TIME, getGroupEmotionEventByTime),
    takeEvery(Types.GET_ENGAGEMENT_EVENT_BY_TIME_ITEMS, getEngagementEventByTimeItems),
    takeEvery(Types.GET_EMOTION_EVENT_BY_TIME_ITEMS, getEmotionEventByTimeItems),
    takeEvery(Types.GET_DEMOGRAPHICS, getDemographics),
    takeEvery(Types.GET_DEMOGRAPHIC_VALUES, getDemographicValues),
    takeEvery(Types.GET_INDEX_SCORING_RESULTS, getIndexScoringResults),
    takeEvery(Types.GET_DEMOGRAPHICS_WITH_VALUES, getDemographicsWithValues),
    takeEvery(Types.GET_DEFAULT_SURVEY_RESULTS, getDefaultSurveyResults),
    takeEvery(Types.GET_SURVEY_RESULTS, getSurveyResults),
    takeEvery(Types.GET_DEFAULT_SURVEY_QUESTIONS, getDefaultSurveyQuestions),
    takeEvery(Types.GET_QUESTIONS_FROM_CSV, getQuestionsFromCsv),
    takeEvery(Types.GET_AUDIENCE_DETAIL, getAudienceDetail),
    takeEvery(Types.GET_AUDIENCE_DEMOGRAPHICS, getAudienceDemographics),
    takeEvery(Types.GET_AUDIENCE_LOCALES, getAudienceLocales),
    takeEvery(Types.SET_TARGET_AUDIENCE, setTargetAudience),
    takeEvery(Types.RESET_TARGET_AUDIENCE, resetTargetAudience),
    takeEvery(Types.GET_E_TRAC_RAPPORT, getETracRapport),
    takeEvery(Types.GET_TAB_SCORE, getTabScore),
    takeEvery(Types.GET_TAB_SCORE_COMPARISON, getTabScoreComparison),
    takeEvery(Types.GET_SENTIMENT_BENCHMARKS, getSentimentBenchmarks),
    takeEvery(Types.UPLOAD_VIDEO, uploadVideo),
    takeEvery(Types.GET_VIDEOS, getVideos),
    takeEvery(Types.DELETE_VIDEO, deleteVideo),
    takeEvery(Types.SELECT_VIDEO, updateVideosList),
    takeEvery(Types.DESELECT_VIDEO, updateVideosList),
    takeEvery(Types.CLEAR_SELECTION, updateVideosList),
    takeEvery(Types.UPLOAD_VIDEO_SUCCESS, updateVideosList),
  ])
}

function* getCampaignConfigs() {
  try {
    const response = yield call(Api.getCampaignConfigs)
    yield put(Actions.getCampaignConfigsSuccess(response))
  } catch (error) {
    yield put(Actions.getCampaignConfigsFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getCaseTypes() {
  try {
    const response = yield call(Api.getCaseTypes)
    yield put(Actions.getCaseTypesSuccess(response))
  } catch (error) {
    yield put(Actions.getCaseTypesFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* createCampaign(action) {
  const {campaign} = action
  try {
    const response = yield call(Api.createCampaign, campaign)
    yield put(Actions.createCampaignSuccess(response))
    yield put(NotificationActions.createSuccess('Campaign created'))
    yield put(Actions.progressIndicator('select-list'))
    // yield put(push(`/new-test/${response.id}/select-list`))
  } catch (error) {
    yield put(Actions.createCampaignFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getCampaigns(action) {
  const {params} = action
  try {
    const response = yield call(Api.getCampaigns, params)
    yield put(Actions.getCampaignsSuccess(response))
  } catch (error) {
    yield put(Actions.getCampaignsFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getCompletedCampaigns(action) {
  const {params} = action
  try {
    const response = yield call(Api.getCampaigns, params)
    yield put(Actions.getCompletedCampaignsSuccess(response))
  } catch (error) {
    yield put(Actions.getCompletedCampaignsFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getCampaign(action) {
  const {id, params} = action
  try {
    const response = yield call(Api.getCampaign, id, params)
    yield put(Actions.getCampaignSuccess(response))
  } catch (error) {
    yield put(Actions.getCampaignFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getRewardStores() {
  try {
    const response = yield call(Api.getRewardStores)
    yield put(Actions.getRewardStoresSuccess(response))
  } catch (error) {
    yield put(Actions.getRewardStoresFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* updateCampaign(action) {
  const {id, campaign, redirectOnSuccess} = action
  try {
    const response = yield call(Api.updateCampaign, id, campaign)
    yield put(Actions.updateCampaignSuccess(response))
    if (redirectOnSuccess) {
      yield put(push(`/tests/${response.id}`))
    }
  } catch (error) {
    yield put(Actions.updateCampaignFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* publishCampaign(action) {
  const {id, params} = action
  try {
    yield call(Api.publishCampaign, id, params)
    yield put(Actions.publishCampaignSuccess())
  } catch (error) {
    yield put(Actions.publishCampaignFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* saveCampaignList(action) {
  const {id, campaign} = action
  try {
    const response = yield call(Api.updateCampaign, id, campaign)
    yield put(Actions.saveCampaignListSuccess(response))
    yield put(push(`/new-test/${response.id}/survey`))
  } catch (error) {
    yield put(Actions.saveCampaignListFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}
function* saveCampaignSurvey(action) {
  const {id, campaign} = action
  try {
    const response = yield call(Api.updateCampaign, id, campaign)
    yield put(Actions.saveCampaignListSuccess(response))
    yield put(push(`/new-test/${response.id}/payment`))
  } catch (error) {
    yield put(Actions.saveCampaignListFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* campaignPayment(action) {
  const {id, campaign, params} = action
  try {
    const response = yield call(Api.campaignPayment, id, campaign, params)
    yield put(Actions.campaignPaymentSuccess(response))
  } catch (error) {
    yield put(Actions.campaignPaymentFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* applyDiscount(action) {
  const {id, code} = action
  try {
    const response = yield call(Api.applyDiscount, id, code)
    yield put(Actions.applyDiscountSuccess(response))
    yield put(NotificationActions.createSuccess('Discount applied'))
  } catch (error) {
    yield put(Actions.applyDiscountFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getEngagementEvent(action) {
  const {videoId, params} = action
  try {
    const response = yield call(Api.getEngagementEvent, videoId, params)
    yield put(Actions.getEngagementEventSuccess(response, videoId))
  } catch (error) {
    yield put(Actions.getEngagementEventFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getEngagementEventByTime(action) {
  const {videoId, params} = action
  try {
    const response = yield call(Api.getEngagementEventByTime, videoId, params)
    yield put(Actions.getEngagementEventByTimeSuccess(response))
  } catch (error) {
    yield put(Actions.getEngagementEventByTimeFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getEngagementWithDemographics(action) {
  const {videoId, params} = action
  try {
    const response = yield all(
      params.map(query => call(Api.getEngagementWithDemographics, videoId, {demographic: query})),
    )
    yield put(Actions.getEngagementWithDemographicsSuccess({results: response}))
  } catch (error) {
    yield put(Actions.getEngagementWithDemographicsFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getEmotionEventByTime(action) {
  const {videoId, params} = action
  try {
    const response = yield call(Api.getEmotionEventByTime, videoId, params)
    yield put(Actions.getEmotionEventByTimeSuccess(response))
  } catch (error) {
    yield put(Actions.getEmotionEventByTimeFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getGroupEmotionEventByTime(action) {
  const {videoId, params} = action
  try {
    const response = yield call(Api.getGroupEmotionEventByTime, videoId, params)
    yield put(Actions.getGroupEmotionEventByTimeSuccess(response))
  } catch (error) {
    yield put(Actions.getGroupEmotionEventByTimeFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getEmotionEvent(action) {
  const {videoId, params} = action
  try {
    const response = yield call(Api.getEmotionEvent, videoId, params)
    yield put(Actions.getEmotionEventSuccess(response, videoId))
  } catch (error) {
    yield put(Actions.getEmotionEventFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getEmotionsWithDemographics(action) {
  const {videoId, params} = action
  try {
    const response = yield all(
      params.map(query => call(Api.getEmotionsWithDemographics, videoId, {demographic: query})),
    )
    yield put(Actions.getEmotionsWithDemographicsSuccess({results: response}))
  } catch (error) {
    yield put(Actions.getEmotionsWithDemographicsFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getDefaultEmotions(action) {
  const {videoId} = action
  try {
    const response = yield call(Api.getDefaultEmotions, videoId)
    yield put(Actions.getDefaultEmotionsSuccess(response))
  } catch (error) {
    yield put(Actions.getDefaultEmotionsFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getDefaultEmotionsScore(action) {
  const {videoId} = action
  try {
    const response = yield call(Api.getDefaultEmotionsScore, videoId)
    yield put(Actions.getDefaultEmotionsScoreSuccess(response))
  } catch (error) {
    yield put(Actions.getDefaultEmotionsScoreFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getDefaultEngagementScore(action) {
  const {videoId} = action
  try {
    const response = yield call(Api.getDefaultEngagementScore, videoId)
    yield put(Actions.getDefaultEngagementScoreSuccess(response))
  } catch (error) {
    yield put(Actions.getDefaultEngagementScoreFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getDefaultRapport(action) {
  const {videoId} = action
  try {
    const response = yield call(Api.getDefaultRapport, videoId)
    yield put(Actions.getDefaultRapportSuccess(response))
  } catch (error) {
    yield put(Actions.getDefaultRapportFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getLegalProfile(action) {
  const {videoId} = action
  try {
    const response = yield call(Api.getLegalProfile, videoId)
    yield put(Actions.getLegalProfileSuccess(response))
  } catch (error) {
    yield put(Actions.getLegalProfileFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getLegalBenchmark(action) {
  const {videoId, params} = action
  try {
    const response = yield all(
      params.map(query => call(Api.getLegalBenchmark, videoId, {demographic: query})),
    )
    yield put(Actions.getLegalBenchmarkSuccess({results: response}))
  } catch (error) {
    yield put(Actions.getLegalBenchmarkFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getEngagementEventByTimeItems(action) {
  const {videoId, params} = action
  try {
    const response = yield call(Api.getEngagementEventByTimeItems, videoId, params)
    yield put(Actions.getEngagementEventByTimeItemsSuccess(response))
  } catch (error) {
    yield put(Actions.getEngagementEventByTimeItemsFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getEmotionEventByTimeItems(action) {
  const {videoId, params} = action
  try {
    const response = yield call(Api.getEmotionEventByTimeItems, videoId, params)
    yield put(Actions.getEmotionEventByTimeItemsSuccess(response))
  } catch (error) {
    yield put(Actions.getEmotionEventByTimeItemsFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getDemographics(action) {
  const {videoId} = action
  try {
    const response = yield call(Api.getDemographics, videoId)
    yield put(Actions.getDemographicsSuccess(response))
  } catch (error) {
    yield put(Actions.getDemographicsFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getDemographicValues(action) {
  const {field, videoId} = action
  try {
    const response = yield call(Api.getDemographicValues, videoId, field)
    yield put(Actions.getDemographicValuesSuccess(field, response))
  } catch (error) {
    yield put(Actions.getDemographicValuesFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getIndexScoringResults(action) {
  const {campaignId, field} = action
  try {
    const response = yield call(Api.getIndexScoringResults, campaignId, field)
    yield put(Actions.getIdexScoringResultsSuccess(field, response))
  } catch (error) {
    yield put(Actions.getIdexScoringResultsFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getDemographicsWithValues(action) {
  const {videoId} = action
  try {
    const response = yield call(Api.getDemographicsWithValues, videoId)
    yield put(Actions.getDemographicsWithValuesSuccess(response))
  } catch (error) {
    yield put(Actions.getDemographicsWithValuesFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getDefaultSurveyResults(action) {
  const {campaignId, params} = action
  try {
    const response = yield all(
      params.map(query => call(Api.getDefaultSurveyResults, campaignId, {demographic: query})),
    )
    yield put(Actions.getDefaultSurveyResultsSuccess({results: response}))
  } catch (error) {
    yield put(Actions.getDefaultSurveyResultsFailure(error))
  }
}

function* getDefaultSurveyQuestions(action) {
  const {questionType} = action
  try {
    const response = yield call(Api.getDefaultSurveyQuestions, questionType)
    yield put(Actions.getDefaultSurveyQuestionsSuccess(response))
  } catch (error) {
    yield put(Actions.getDefaultSurveyQuestionsFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getQuestionsFromCsv(action) {
  const {csvFile} = action
  try {
    const response = yield call(Api.getQuestionsFromCsv, csvFile)
    yield put(Actions.getQuestionsFromCsvSuccess(response))
  } catch (error) {
    yield put(Actions.getQuestionsFromCsvFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getSurveyResults(action) {
  const {campaignId, params} = action
  try {
    const response = yield call(Api.getSurveyResults, campaignId, params)
    yield put(Actions.getSurveyResultsSuccess(response))
  } catch (error) {
    yield put(Actions.getSurveyResultsFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getAudienceDetail(action) {
  const {campaignId} = action
  try {
    const response = yield call(Api.getAudienceDetail, campaignId)
    yield put(Actions.getAudienceDetailSuccess(response))
  } catch (error) {
    yield put(Actions.getAudienceDetailFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getAudienceDemographics() {
  try {
    const response = yield call(Api.getAudienceDemographics)
    yield put(Actions.getAudienceDemographicsSuccess(response))
  } catch (error) {
    yield put(Actions.getAudienceDemographicsFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getAudienceLocales() {
  try {
    const response = yield call(Api.getAudienceLocales)
    yield put(Actions.getAudienceLocalesSuccess(response))
  } catch (error) {
    yield put(Actions.getAudienceLocalesFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* setTargetAudience(action) {
  const {campaignId, params} = action
  try {
    const response = yield call(Api.setTargetAudience, campaignId, params)
    yield put(Actions.setTargetAudienceSuccess(response))
  } catch (error) {
    yield put(Actions.setTargetAudienceFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* resetTargetAudience(action) {
  const {campaignId} = action
  try {
    const response = yield call(Api.resetTargetAudience, campaignId)
    yield put(Actions.resetTargetAudienceSuccess(response))
  } catch (error) {
    yield put(Actions.resetTargetAudienceFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getETracRapport(action) {
  const {videoId, params} = action
  try {
    const response = yield call(Api.getETracRapport, videoId, params)
    yield put(Actions.getETracRapportSuccess(response))
  } catch (error) {
    yield put(Actions.getETracRapportFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getTabScore(action) {
  const {videoId, params} = action
  try {
    const response = yield call(Api.getTabScore, videoId, params)
    yield put(Actions.getTabScoreSuccess(response))
  } catch (error) {
    yield put(Actions.getTabScoreFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getTabScoreComparison(action) {
  const {videoId, params, labels, focusTimeTrackFilter} = action

  try {
    const response = yield all(
      params.map(query =>
        call(Api.getTabScore, videoId, {demographic: query, focus_time: focusTimeTrackFilter}),
      ),
    )
    yield put(Actions.getTabScoreComparisonSuccess({results: response, labels}))
  } catch (error) {
    yield put(Actions.getTabScoreComparisonFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getSentimentBenchmarks() {
  try {
    const response = yield call(Api.getSentimentBenchmarks)
    yield put(Actions.getSentimentBenchmarksSuccess(response))
  } catch (error) {
    yield put(Actions.getSentimentBenchmarksFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

//Videos

function* uploadVideo(action) {
  const {file} = action
  try {
    const response = yield call(VideosApi.uploadVideo, file)
    yield put(Actions.uploadVideoSuccess(response))
    yield put(NotificationActions.createSuccess('Video uploaded'))
  } catch (error) {
    yield put(Actions.uploadVideoFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* getVideos(action) {
  const {params} = action
  try {
    const response = yield call(VideosApi.getVideos, params)
    yield put(Actions.getVideosSuccess(response))
  } catch (error) {
    yield put(Actions.getVideosFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* deleteVideo(action) {
  const {id, video} = action
  try {
    const response = yield call(VideosApi.deleteVideo, id, video)
    yield put(Actions.deleteVideoSuccess(response))
    const vidResponse = yield call(VideosApi.getVideos)
    yield put(Actions.getVideosSuccess(vidResponse))
  } catch (error) {
    yield put(Actions.deleteVideoFailure(error))
    if (error.response && error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* updateVideosList() {
  const campaign = yield select(state => state.campaign.campaign)
  if (campaign.id) {
    try {
      const updateResponse = yield call(Api.updateCampaign, campaign.id, {
        videos: campaign.videos.map(video => video.id),
      })
      yield put(Actions.updateCampaignSuccess(updateResponse))
    } catch (error) {
      if (error.response && error.response.status === 401) {
        yield put(AuthActions.logout())
      }
    }
  }
}
