export default class ValidationUtils {
  static checkEmail(email, message) {
    const regex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/gim
    if (email && !regex.test(email)) {
      return message || 'Please enter a valid email address.'
    }
  }

  static checkUrl(url, message) {
    const regex = /^(http)s?(:\/\/)([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/gim
    if (url && !regex.test(url)) {
      return message || 'Please enter a valid URL. (ex. http(s)://domain.com)'
    }
  }

  static checkZipcode(zip) {
    if (zip && zip.length > 10) {
      return 'Please enter a valid Zip Code.'
    }
  }

  static checkSsn(ssn) {
    const regex = /^\d{3}-\d{2}-\d{4}$/gim
    if (ssn && !regex.test(ssn)) {
      return 'Please enter a valid Social Security Number. (ex. 999-99-9999)'
    }
  }

  static checkEin(ein) {
    if (ein && ein.length != 9) {
      return 'Please enter a valid EIN.'
    }
  }

  static checkPhoneNumber(phoneNumber, fieldName) {
    const regex = /^\d{3}-\d{3}-\d{4}$/gim
    if (phoneNumber && !regex.test(phoneNumber)) {
      return `Please enter a valid ${fieldName}. (ex. 999-999-9999)`
    }
  }

  static checkFundingGoal(goal) {
    if (!(Number(goal) < 10000000000)) {
      return 'Please keep Funding Goal below 10B'
    }
  }

  static checkInputLength(value, name, max = 60) {
    if (value && value.length > max) {
      return `Please keep ${name} below ${max} characters.`
    }
  }

  static checkWordLength(value, name, max = 60) {
    if (value && value.split(' ').length > max) {
      return `Please keep ${name} below ${max} words.`
    }
  }

  static isFilePdf(fileName) {
    const type = '.pdf'
    return fileName.indexOf(type, fileName.length - type.length) !== -1
  }

  static isFilePdfWithAlert(fileName) {
    const isPdf = ValidationUtils.isFilePdf(fileName)
    if (!isPdf) {
      alert(
        'File "' +
          fileName +
          '" is a ' +
          fileName.slice(fileName.lastIndexOf('.'), fileName.length) +
          ' Oops, wrong file type!\n\n Upload a picture file with a .pdf extension!',
      )
    }
    return isPdf
  }
}
