import React from 'react'
import {Typography, withStyles} from '@material-ui/core'

import noChartData from '../../assets/icons/no_chart_data.svg'

const styles = theme => ({
  noDataContainer: {
    position: 'absolute',
    width: '101%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.6)',
    top: 0,
    left: 0,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  noDataHeader: {
    color: theme.palette.grey[800],
    margin: `${theme.spacing.unit} 0`,
  },
  noDataText: {
    color: theme.palette.grey[600],
    textAlign: 'center',
  },
})

const NoLineChartData = ({classes}) => {
  return (
    <div className={classes.noDataContainer}>
      <img src={noChartData} alt="no data" />
      <Typography variant="h6" className={classes.noDataHeader}>
        There is no data to show
      </Typography>
      <Typography variant="body1" className={classes.noDataText}>
        Unfortunately there is no data to show for the selected demographic.
        <br />
        Please adjust your demographic filters in order to show data.
      </Typography>
    </div>
  )
}

export default withStyles(styles)(NoLineChartData)
