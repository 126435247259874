import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Button, CircularProgress, withStyles} from '@material-ui/core'

import userSession from '../auth/UserSession'

import {apiBaseURL} from '../../../config/settings'

import exportIcon from '../../assets/icons/export.svg'

const styles = theme => ({
  button: {
    height: theme.spacing.unit * 5,
    border: '2px solid #b0b1b31f',
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    position: 'relative',
    '& span': {
      fontSize: '1rem',
      fontWeight: 400,
      color: '#292D32',
      zIndex: 1,
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: 4,
      width: 'calc(100% - 5px)',
      height: 'calc(100% - 5px)',
      background: '#b0b1b31f',
    },
  },
  icon: {
    width: 15,
    marginRight: theme.spacing.unit,
    zIndex: 1,
  },
})

const ExportButton = ({classes, endpoint, reportName, type, onClick}) => {
  const [loading, setLoading] = useState(false)

  const getButtonProps = () => {
    if (typeof onClick === 'function') {
      return {
        onClick: () => onClick(userSession.getToken()),
      }
    }

    return {
      onClick: async () => {
        if (!endpoint) return null

        setLoading(true)

        const response = await fetch(`${apiBaseURL}${endpoint}?token=${userSession.getToken()}`, {
          method: 'GET',
          headers: {
            'Content-Type': `application/${type}`,
          },
        })
        const blob = await response.blob()

        const url = window.URL.createObjectURL(new Blob([blob]))

        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${reportName}.${type}`)

        document.body.appendChild(link)

        link.click()

        link.parentNode.removeChild(link)

        setLoading(false)
      },
    }
  }

  return (
    <Button className={classes.button} {...getButtonProps()}>
      {loading ? (
        <CircularProgress size="15" className={classes.icon} />
      ) : (
        <img className={classes.icon} src={exportIcon} alt="export" />
      )}
      Export CSV
    </Button>
  )
}

ExportButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  endpoint: PropTypes.string,
  reportName: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
}

ExportButton.defaultProps = {
  endpoint: '',
  reportName: '',
  type: '',
  onClick: null,
}

export default withStyles(styles)(ExportButton)
