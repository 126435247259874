import axios from 'axios'
import settings from '../../../config/settings'

const {vevwApiURL, vevwApiKey} = settings

export default class DemoApi {
  static uploadImage(image) {
    const form = new FormData()
    form.append('image', image)
    const config = {
      headers: {
        'X-Api-Key': vevwApiKey,
        'Content-Type': 'multipart/form-data',
      },
    }
    const fileAxios = axios.create()
    return fileAxios.post(
      `${vevwApiURL}/image-capture/detect-engage?use_aws_engage=0`,
      form,
      config,
    )
  }
}
