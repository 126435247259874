const styles = theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  demographicLabel: {
    fontSize: '20px',
    color: '#414347',
    lineHeight: '15px',
    fontWeight: 500,
  },
  clearSelections: {
    cursor: 'pointer',
    lineHeight: '1.1',
    marginLeft: theme.spacing.unit,
    color: theme.palette.primary.main,
    fontSize: '12px',
    fontWeight: 400,
    textDecoration: 'underline',
  },
  selectMenu: {
    border: '1px solid #87888A',
    borderTopColor: 'transparent',
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    marginTop: '-2px',
    overflow: 'hidden',
    '& > div': {
      paddingBottom: 0,
    },
  },
  openControlBottomLine: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '90%',
    height: '1px',
    backgroundColor: '#E6E8EC',
    zIndex: 2,
  },
  inputContainer: {
    '& > div': {
      margin: 0,
      padding: 0,
    },
    '& input': {
      fontSize: '16px !important',
      fontFamily: 'Roboto !important',
      color: '#5C5E63 !important',
    },
  },
  menuOption: {
    whiteSpace: 'pre-wrap',
    lineHeight: 1.3,
    padding: '16px',
    paddingLeft: '44px',
    '&:hover, &:focus': {
      backgroundColor: '#EBF5FF !important',
    },
  },
  selectedOption: {
    backgroundColor: '#EBF5FF !important',
  },
  noOptionsMessage: {
    color: '#87888A',
    padding: '20px 24px',
    fontSize: '16px',
  },
  demographicChip: {
    color: 'white',
    margin: '0 10px 10px 0',
    padding: '11px 12px 11px 16px',
    '& span': {
      padding: 0,
      paddingRight: '12px',
      fontSize: '14px',
    },
  },
  deleteIcon: {
    margin: 0,
    color: 'white',
    '&:hover': {
      filter: 'brightness(0.9)',
      color: 'white',
    },
  },
})

export default styles
