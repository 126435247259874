import React, {useState} from 'react'

import useUpdateEffect from '../../../hooks/useUpdateEffect'

import NewTestContext, {INITIAL_STATE} from './NewTestContext'

import utils from '../utils'

import {questionTypes, objectiveTypes} from '../../constants/NewTest'

const NewTestProvider = ({children}) => {
  const [state, setState] = useState(INITIAL_STATE)
  const [isEditing, setIsEditing] = useState({
    testSetup: false,
    audienceSetup: false,
    surveySetup: false,
  })
  const [hasPassed, setHasPassed] = useState({
    testSetup: false,
    audienceSetup: false,
    surveySetup: false,
  })

  const [awardAnswers, setAwardAnswers] = useState({1: '', 2: ''})
  const [awardAnswersLength, setAwardAnswersLength] = useState(2)

  const [showingObjectivesMoments, setShowingObjectivesMoments] = useState(false)
  const [objectiveQuestions, setObjectiveQuestions] = useState([])
  const [hasOpenedDefaultTooltip, setHasOpenedDefaultTooltip] = useState({
    testSetup: false,
    audienceSetup: false,
    surveySetup: false,
  })
  const [isCheckingAudienceGroups, setIsCheckingAudienceGroups] = useState(false)

  const showObjectivesMoments = () => setShowingObjectivesMoments(true)
  const hideObjectivesMoments = () => setShowingObjectivesMoments(false)

  useUpdateEffect(() => {
    const surveyObjectives = state.objectives.filter(
      ({objectiveType, status}) => objectiveType === objectiveTypes.survey && status,
    )

    const newObjectiveQuestions = []

    surveyObjectives.forEach(({key, question, openEndedQuestion}) => {
      newObjectiveQuestions.push({
        ...question,
        objectiveType: key,
        type: questionTypes['0'],
        choiceType: 0,
      })

      if (openEndedQuestion) {
        newObjectiveQuestions.push({
          id: 1,
          question: openEndedQuestion,
          type: {
            label: 'Open Ended Answer',
            value: 'text',
          },
          choices: ['', ''],
          title: null,
          questionDescription: null,
          conclusionText: '',
          objectiveType: key,
          choiceType: 1,
        })
      }
    })

    setObjectiveQuestions(newObjectiveQuestions)
  }, [state.objectives])

  const setStateField = (key, value) => {
    setState(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  const setStateFields = (fields, values) => {
    const newValues = {...state}

    fields.forEach((field, index) => {
      newValues[field] = values[index]
    })

    setState(newValues)
  }

  const clearStateFields = fields => {
    const newValues = {...state}

    fields.forEach(field => {
      newValues[field] = INITIAL_STATE[field]
    })

    setState(newValues)
  }

  const getSurveyQuestionsObject = () => {
    const surveyQuestions = state.questions.map(question => {
      const {id, ...questionNoId} = question

      return {
        ...questionNoId,
        objectiveType: question.objectiveType || '',
        type: questionTypes[question.choiceType],
      }
    })

    return surveyQuestions
  }

  const getMoments = () => {
    return state.moments.map(moment => ({
      ...moment,
      startTime: utils.stringToSeconds(moment.startTime),
      endTime: utils.stringToSeconds(moment.endTime),
    }))
  }

  const checkEditing = () =>
    isEditing.testSetup ||
    isEditing.surveySetup ||
    isEditing.audienceSetup ||
    isCheckingAudienceGroups

  return (
    <NewTestContext.Provider
      value={{
        ...state,
        isCheckingAudienceGroups,
        setIsCheckingAudienceGroups,
        setStateField,
        setStateFields,
        clearStateFields,
        showingObjectivesMoments,
        showObjectivesMoments,
        hideObjectivesMoments,
        objectiveQuestions,
        setObjectiveQuestions,
        getSurveyQuestionsObject,
        getMoments,
        isEditing,
        setIsEditing,
        checkEditing,
        hasPassed,
        setHasPassed,
        hasOpenedDefaultTooltip,
        setHasOpenedDefaultTooltip,
        awardAnswers,
        setAwardAnswers,
        awardAnswersLength,
        setAwardAnswersLength,
      }}
    >
      {children}
    </NewTestContext.Provider>
  )
}

export default NewTestProvider
