import React from 'react'
import {withStyles} from '@material-ui/core'
import styles from '../styles/Rapport.styles'

const DemographicGroupDetailsModalTabContent = ({
  classes,
  groupTitle,
  tabContent,
  demographicLabelsTable,
  panelistCount,
}) => {
  const getDemographicLabel = demographic => {
    if (demographicLabelsTable) {
      if (typeof demographic === 'string') {
        return demographicLabelsTable[demographic]
      }

      return demographic.displayName
    }

    return demographic
  }

  return (
    <div style={{overflowY: 'auto', maxHeight: '70%'}}>
      <div className={classes.modalDemographic}>{groupTitle} Panelist Count</div>
      <div className={classes.modalSubdemographicsContainer}>
        <div className={classes.modalSubdemographic}>
          <div className={classes.modalSubdemographicsBulletPoint} />
          <div className={classes.modalSubdemographics}>{panelistCount}</div>
        </div>
      </div>
      {Object.keys(tabContent).map((demographic, index) => (
        <React.Fragment key={index}>
          <div className={classes.modalDemographic}>{getDemographicLabel(demographic)}</div>
          <div className={classes.modalSubdemographicsContainer}>
            {tabContent[demographic]?.map((subdemographic, index) => (
              <div className={classes.modalSubdemographic} key={index}>
                <div className={classes.modalSubdemographicsBulletPoint} />
                <div className={classes.modalSubdemographics}>
                  {getDemographicLabel(subdemographic)}
                </div>
              </div>
            ))}
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}

export default withStyles(styles)(DemographicGroupDetailsModalTabContent)
