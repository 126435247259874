import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from '@material-ui/core'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'

import Loading from '../common/Loading'
import VideoUploadArea from './VideoUploadArea'
import RejectedFile from './RejectedFile'

const styles = theme => ({
  uploadButton: {
    width: '70%',
  },
  dialogPaper: {
    padding: theme.spacing.unit * 4,
  },
  dialogHeader: {
    position: 'relative',
    '& h5': {
      fontWeight: 500,
    },
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  buttonsSection: {
    display: 'grid',
    gridGap: `${theme.spacing.unit * 3}px`,
    gridTemplateColumns: '1fr 1fr',
    '& button': {
      padding: `${theme.spacing.unit}px 0`,
    },
  },
  tabRoot: {
    flexGrow: 1,
    textTransform: 'none',
    position: 'relative',
    fontSize: '1rem',
    color: '#808A98',
    '&::after': {
      position: 'absolute',
      content: '""',
      width: '100%',
      height: 2,
      background: '#CFD4DA',
      bottom: 0,
    },
  },
  tabSelected: {
    color: '#252E3B',
  },
  loadingContainer: {
    width: 500,
    height: 200,
  },
  savedVideosContainer: {
    width: 500,
    height: 250,
    overflow: 'auto',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    marginTop: theme.spacing.unit * 3,
    placeItems: 'center',
    gridGap: '24px',
    '& button': {
      padding: 0,
      '&:hover': {
        filter: 'brightness(1.2)',
      },
    },
  },
  savedVideo: {
    width: '100%',
    '& img': {
      width: '100%',
    },
  },
})

const UploadVideo = props => {
  const {
    classes,
    availableVideos,
    videoFile,
    uploadFile,
    setVideoFile,
    isFetching,
    setIsConvertingVideo,
  } = props

  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)
  const [isFileRejected, setIsFileRejected] = useState(false)
  const [rejectedFileError, setRejectedFileError] = useState('')

  const openModal = () => setUploadModalOpen(true)
  const closeModal = () => setUploadModalOpen(false)

  const renderTabContent = () => {
    if (selectedTab === 0) {
      return (
        <>
          <VideoUploadArea
            videoFile={videoFile}
            setVideoFile={setVideoFile}
            uploadFile={uploadFile}
            isFileRejected={isFileRejected}
            setIsFileRejected={setIsFileRejected}
            setRejectedFileError={setRejectedFileError}
            setIsConvertingVideo={setIsConvertingVideo}
            closeModal={closeModal}
          />
          {isFileRejected ? <RejectedFile error={rejectedFileError} /> : null}
        </>
      )
    }

    return isFetching ? (
      <Grid container className={classes.loadingContainer}>
        <Loading />
      </Grid>
    ) : (
      <Grid container className={classes.savedVideosContainer}>
        {availableVideos.map((video, index) => (
          <Button key={index} className={classes.savedVideo} onClick={() => setVideoFile(video)}>
            <img key={video.id} src={video.thumbnail.fullSize} alt={video.videoFilename} />
          </Button>
        ))}
      </Grid>
    )
  }

  return (
    <Grid container>
      <Dialog open={uploadModalOpen} onClose={closeModal} classes={{paper: classes.dialogPaper}}>
        <Grid container className={classes.dialogHeader}>
          <Typography variant="h5">Add Video</Typography>
          <IconButton onClick={closeModal} className={classes.closeButton} aria-label="close">
            <CloseRoundedIcon />
          </IconButton>
        </Grid>
        <Tabs
          value={selectedTab}
          onChange={(_e, value) => setSelectedTab(value)}
          indicatorColor="primary"
        >
          <Tab
            classes={{root: classes.tabRoot, selected: classes.tabSelected}}
            disableRipple
            label="Upload"
          />
          <Tab
            classes={{root: classes.tabRoot, selected: classes.tabSelected}}
            disableRipple
            label="Saved Videos"
          />
        </Tabs>

        {renderTabContent()}
      </Dialog>
      <Button
        variant="contained"
        color="primary"
        onClick={openModal}
        className={classes.uploadButton}
      >
        Upload
      </Button>
    </Grid>
  )
}

UploadVideo.propTypes = {
  availableVideos: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isFetching: PropTypes.bool.isRequired,
  setVideoFile: PropTypes.func.isRequired,
  videoFile: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default withStyles(styles)(UploadVideo)
