import React from 'react'
import _ from 'lodash'
import urlParse from 'url-parse'
import {Icon, Button} from '@material-ui/core'
import {getAbsoluteAssetUrl} from '../utils/PathUtils'

export const TwitterButton = props => {
  const pathname = _.get(props, 'location.pathname')
  const {className} = props

  return (
    <Button
      variant="fab"
      color="primary"
      className={className}
      aria-label="Share on Twitter"
      onClick={onShareClick.bind(this, getTwitterShareUrl(getAbsoluteAssetUrl(pathname)))}
    >
      <Icon className="fa fa-twitter" title="Share on Twitter" aria-hidden="true" />
    </Button>
  )
}

export const FacebookButton = props => {
  const pathname = _.get(props, 'location.pathname')
  const {className} = props

  return (
    <Button
      variant="fab"
      color="primary"
      className={className}
      aria-label="Share on Facebook"
      onClick={onShareClick.bind(this, getFbShareUrl(getAbsoluteAssetUrl(pathname)))}
    >
      <Icon className="fa fa-facebook-official" title="Share on Facebook" aria-hidden="true" />
    </Button>
  )
}

export const GooglePlusButton = props => {
  const pathname = _.get(props, 'location.pathname')
  const {className} = props

  return (
    <Button
      variant="fab"
      color="primary"
      className={className}
      aria-label="Share on Google+"
      onClick={onShareClick.bind(this, getGooglePlusShareUrl(getAbsoluteAssetUrl(pathname)))}
    >
      <Icon className="fa fa-google-plus" title="Share on Google+" aria-hidden="true" />
    </Button>
  )
}

export const LinkedInButton = props => {
  const pathname = _.get(props, 'location.pathname')
  const {className} = props

  return (
    <Button
      variant="fab"
      color="primary"
      className={className}
      aria-label="Share on LinkedIn"
      onClick={onShareClick.bind(this, getLinkedinShareUrl(getAbsoluteAssetUrl(pathname)))}
    >
      <Icon className="fa fa-linkedin-square" title="Share on LinkedIn" aria-hidden="true" />
    </Button>
  )
}

export const PinterestButton = ({location, mediaUrl, className}) => {
  const pathname = _.get(location, 'pathname')
  const media = urlParse(mediaUrl, false)
  media.set('query', null)
  return (
    <Button
      variant="fab"
      color="primary"
      className={className}
      aria-label="Share on Pinterest"
      onClick={onShareClick.bind(
        this,
        getPinterestShareUrl(getAbsoluteAssetUrl(pathname), media.toString()),
      )}
    >
      <Icon className="fa fa-pinterest" title="Share on Pinterest" aria-hidden="true" />
    </Button>
  )
}

export const TumblrButton = props => {
  const pathname = _.get(props, 'location.pathname')
  const {className} = props

  return (
    <Button
      variant="fab"
      color="primary"
      className={className}
      aria-label="Tumblr"
      onClick={onShareClick.bind(this, getTumblrShareUrl(getAbsoluteAssetUrl(pathname)))}
    >
      <Icon className="fa fa-tumblr" title="Share on Tumblr" aria-hidden="true" />
    </Button>
  )
}

function onShareClick(shareUrl, e) {
  e.preventDefault()
  showShareWindow(shareUrl)
}

let shareWinRef = null
function showShareWindow(url) {
  if (!shareWinRef || _.get(shareWinRef, 'closed')) {
    shareWinRef = window.open(
      url,
      'sharewin',
      'left=20,top=20,width=500,height=400,toolbar=1,resizable=0',
    )
  } else {
    shareWinRef.location.href = url
    shareWinRef.focus()
  }
}

function getPinterestShareUrl(url, mediaUrl) {
  return `//www.pinterest.com/pin/create/button/?url=${url}&media=${mediaUrl}`
}

function getTwitterShareUrl(url) {
  return `//twitter.com/intent/tweet?url=${url}`
}

function getFbShareUrl(url) {
  return '//www.facebook.com/sharer/sharer.php?u=' + url
}

function getGooglePlusShareUrl(url) {
  return '//plus.google.com/share?url=' + url
}

function getLinkedinShareUrl(url) {
  return `https://www.linkedin.com/shareArticle?mini=true&url=${url}`
}

function getTumblrShareUrl(url) {
  return `https://tumblr.com/share/?canonicalUrl=${url}`
}
