import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {FormControl, MenuItem, NoSsr, Typography, withStyles} from '@material-ui/core'
import Select, {components} from 'react-select'

import SearchIcon from '@material-ui/icons/Search'

const styles = theme => ({
  qualifiersSelectorContainer: {
    width: '100%',
    position: 'relative',
    zIndex: 3,
  },
  qualifierInputLabel: {
    marginBottom: theme.spacing.unit * 2,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '15px',
  },
  openControlBottomLine: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '90%',
    height: '1px',
    backgroundColor: '#E6E8EC',
    zIndex: 2,
  },
  menuOption: {
    padding: '16px',
    paddingLeft: '44px',
    '&:hover, &:focus': {
      backgroundColor: '#EBF5FF !important',
    },
  },
  selectedOption: {
    backgroundColor: '#EBF5FF !important',
  },
  noOptionsMessage: {
    color: '#87888A',
    padding: '20px 24px',
    paddingLeft: '44px',
    fontSize: '16px',
  },
})

const QualifiersSelector = React.memo(props => {
  const {
    classes,
    demographicFields,
    selectedDemographic,
    setSelectedDemographic,
    searchSelectRef,
  } = props

  const [options, setOptions] = useState([])

  useEffect(() => {
    setOptions(
      Object.values(demographicFields)
        .filter(({key}) => key !== 'gender')
        .map(({key, label, subDemographics, questionText}) => ({
          value: key,
          label,
          subDemographics,
          questionText,
        })),
    )
  }, [demographicFields])

  const Control = ({children, ...props}) => {
    return (
      <components.Control {...props}>
        <SearchIcon style={{marginRight: '-2px'}} />
        {children}
      </components.Control>
    )
  }

  const Option = props => {
    return (
      <MenuItem
        buttonRef={props.innerRef}
        selected={props.isFocused}
        classes={{root: classes.menuOption, selected: classes.selectedOption}}
        {...props.innerProps}
      >
        {props.children}
      </MenuItem>
    )
  }

  const NoOptionsMessage = props => {
    return (
      <Typography className={classes.noOptionsMessage} {...props.innerProps}>
        No results found... try a new search
      </Typography>
    )
  }

  const handleFocus = () => {
    setTimeout(() => {
      setSelectedDemographic(null)
      searchSelectRef.current.select.focus() // regaining focus after clearing input
    }, 15)
  }

  const filterOption = (option, searchTerm) => {
    const {label, value, subDemographics, questionText} = option.data
    const search = searchTerm.toLowerCase()
    return (
      label.toLowerCase().includes(search) ||
      value.toLowerCase().includes(search) ||
      subDemographics.some(subDemographic =>
        subDemographic.option.toLowerCase().includes(search),
      ) ||
      questionText.includes(search)
    )
  }

  const handleInputChange = () => {
    const {menuListRef} = searchSelectRef.current.select
    if (menuListRef) {
      menuListRef.scroll({top: 0})
    }
  }

  const selectStyles = {
    control: (baseStyles, state) => {
      return {
        ...baseStyles,
        position: 'relative',
        padding: '10px 12px !important',
        color: '#989898',
        boxShadow: 'none',
        borderColor: '#c0c0c0',
        '&:hover': {
          boxShadow: 'none',
          borderColor: '#87888A',
        },
        ...(state.menuIsOpen && {
          boxShadow: 'none',
          borderColor: '#87888A',
          borderBottomColor: 'transparent',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          '&:hover, &:focus-within, &:focus, &:active': {
            boxShadow: 'none',
            borderColor: '#87888A',
            borderBottomColor: 'transparent',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '90%',
            height: '1px',
            backgroundColor: '#E6E8EC',
            zIndex: 2,
          },
        }),
      }
    },
    menu: baseStyles => ({
      ...baseStyles,
      border: '1px solid #87888A',
      borderRadius: 0,
      boxShadow: 'none',
      borderTopColor: 'transparent',
      borderBottomRightRadius: '4px',
      borderBottomLeftRadius: '4px',
      marginTop: '-2px',
      overflow: 'hidden',
      '& > div': {
        paddingBottom: 0,
      },
    }),
  }

  return (
    <FormControl variant="outlined" className={classes.qualifiersSelectorContainer}>
      <Typography className={classes.qualifierInputLabel}>
        Search Demographic & Psychographic Qualifiers
      </Typography>
      <NoSsr>
        <Select
          ref={searchSelectRef}
          classes={classes}
          options={options}
          components={{
            Control,
            Option,
            NoOptionsMessage,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            ClearIndicator: () => null,
          }}
          value={selectedDemographic}
          onChange={value => setSelectedDemographic(value)}
          onFocus={handleFocus}
          placeholder="Type in Keyword..."
          styles={selectStyles}
          filterOption={filterOption}
          onInputChange={handleInputChange}
          isClearable
        />
      </NoSsr>
    </FormControl>
  )
})

QualifiersSelector.defaultProps = {
  selectedDemographic: null,
}

QualifiersSelector.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  demographicFields: PropTypes.objectOf(PropTypes.object).isRequired,
  setSelectedDemographic: PropTypes.func.isRequired,
  selectedDemographic: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
}

export default withStyles(styles)(QualifiersSelector)
