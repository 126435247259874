import axios from 'axios'
import humps from 'humps'
import UserSession from '../auth/UserSession'

const fileAxios = axios.create()

fileAxios.interceptors.request.use(request => {
  const authToken = UserSession.getToken()
  if (authToken) {
    if (request.headers && !request.headers.Authorization) {
      request.headers.Authorization = `Token ${authToken}`
    }
  }

  if (request.headers) {
    request.headers['Content-Type'] = 'multipart/form-data'
  }

  return request
})

fileAxios.interceptors.response.use(response => {
  if (response.headers['content-type'] === 'video/mp4') {
    return response
  }

  if (response.data) {
    return humps.camelizeKeys(response.data)
  }
})

export default fileAxios
