const styles = theme => ({
  page: {
    padding: '0',
  },
  wrapper: {
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '75px',
  },
  container: {
    border: '1px solid #E0E0E0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '440px',
    padding: '50px',
    backgroundColor: 'rgba(232, 243, 255, 0.2)',
    borderRadius: '8px',
  },
  title: {
    color: '#37474F',
    fontSize: '24px',
    fontWeight: 500,
    marginBottom: '24px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputSignupAndLogin: {
    padding: '0',
    marginTop: 16,
    height: 48,
    '& input': {
      color: '#455A64',
      borderRadius: '8px',
    },
    '& fieldset': {
      borderRadius: '8px',
    },
    '& p': {
      backgroundColor: 'blue',
    },
  },
  signupButton: {
    height: '45px',
    borderRadius: '8px',
    fontSize: '16px',
    color: 'F2F2F2',
    padding: '0',
    fontWeight: 'normal',
    '&:disabled': {
      backgroundColor: '#CFD8DC',
      color: '#90A4AE',
    },
  },
  loginButton: {
    width: '112px !important',
    height: '45px',
    borderRadius: '8px',
    fontSize: '16px',
    color: 'F2F2F2',
    padding: '0',
    fontWeight: 'normal',
    '&:disabled': {
      backgroundColor: '#CFD8DC',
      color: '#90A4AE',
    },
  },
  socialLoginButton: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168)',
    height: '54px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '20px',
    fontWeight: 500,
  },
  firstNameInput: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    [theme.breakpoints.up('md')]: {
      paddingRight: '6 !important',
    },
  },
  lastNameInput: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '6 !important',
    },
  },
})

export default styles
