const styles = theme => ({
  newTestContainer: {
    width: '100%',
    padding: theme.spacing.unit * 5,
  },
  newTestPaper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  newTestHeader: {
    position: 'relative',
    alignItems: 'center',
    '&::after': {
      content: '""',
      width: 'calc(100% - 48px)',
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      height: 1,
      background: theme.palette.border && theme.palette.border.main,
    },
  },
  newTestTitle: {
    padding: theme.spacing.unit * 3,
  },
  newTestBody: {
    height: '100%',
    [theme.breakpoints.down('1366')]: {
      fontSize: '0.9rem',
    },
    '& > *:not(:last-child)': {
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        width: 1,
        height: 'calc(100% - 50px)',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        background: theme.palette.border && theme.palette.border.main,
      },
    },
  },
  connectorActive: {
    '& $connectorLine': {
      background: theme.palette.primary.main,
    },
  },
  connectorCompleted: {
    '& $connectorLine': {
      background: theme.palette.primary.main,
    },
  },
  connectorDisabled: {
    '& $connectorLine': {
      background: '#c4c4c4',
    },
  },
  connectorLine: {
    border: 'none',
    borderRadius: 50,
    height: 2,
    transition: theme.transitions.create('background'),
  },
  labelActive: {
    color: `${theme.palette.primary.main} !important`,
  },
  labelCompleted: {
    color: `${theme.palette.primary.main} !important`,
  },
  labelDisabled: {
    color: '#c4c4c4 !important',
  },
})

export default styles
