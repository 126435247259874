import React, {useCallback, useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {Button, Grid, Paper, Typography, withStyles} from '@material-ui/core'
import {
  BigPlayButton,
  ControlBar,
  CurrentTimeDisplay,
  PlaybackRateMenuButton,
  Player,
  VolumeMenuButton,
} from 'video-react'

import CompareArrowsIcon from '@material-ui/icons/CompareArrows'
import useModalState from '../../../hooks/useModalState'

import RapportChart from './RapportChart'
import TestDetailHeader from '../../common/TestDetailHeader'
import InfoButtons from '../../common/InfoButtons'
import InfoModal from '../../common/InfoModal'
import Loading from '../../common/Loading'
import ExportSnapshotButton from '../../common/ExportSnapshotButton'

import {
  getCampaign,
  getDemographicsWithValues,
  getDefaultRapport,
  resetState,
} from '../redux/actions'

import utils from '../utils'

import styles from '../styles/Rapport.styles'
import CompareDemographicsModalSentiments from '../../common/CompareDemographicsModalSentiments'
import {initialFetch} from '../../constants/CreativeConclusions'
import DemographicGroupDetailsModal from './DemographicGroupDetailsModal'

let Chart = null

const RapportMap = props => {
  if (!Chart) Chart = require('react-apexcharts').default

  const [hasFetchedDemographics, setHasFetchedDemographics] = useState(false)
  const [selectParents, setSelectParents] = useState([])
  const [selectChildren, setSelectChildren] = useState({})
  const [textModalOpen, openTextModal, closeTextModal] = useModalState(true)
  const [videoModalOpen, openVideoModal, closeVideoModal] = useModalState(false)
  const [detailsModalOpen, openDetailsModal, closeDetailsModal] = useModalState(false)
  const [video, setVideo] = useState({})
  const [modalContent, setModalContent] = useState({})
  const [playerState, setPlayerState] = useState({})
  const [hasSubscribedPlayer, setHasSubscribedPlayer] = useState(false)
  const [demographicLabelsTable, setDemographicLabelsTable] = useState({})
  const [
    compareDemographicsOpen,
    openCompareDemographicsModal,
    closeCompareDemographicsModal,
  ] = useModalState(false)
  const [defaultGroups, setDefaultGroups] = useState([initialFetch])
  const [isComparing, setIsComparing] = useState(false)
  const [eTracRapport, setETracRapport] = useState({groups: []})

  const player = useRef(null)

  const {
    groups,
    classes,
    params,
    campaign,
    resetState,
    demographicsWithValues,
    isFetchingCampaign,
    isFetchingETracRapport,
    getCampaign,
    getDemographicsWithValues,
    getDefaultRapport,
    customRapport,
    defaultRapport,
  } = props

  useEffect(() => {
    if (campaign.campaign.id !== Number(params.id)) {
      getCampaign(params.id, {expand: 'lists'})
    }
    if (!utils.checkFirstTimeVisit('hasVisitedRapportMap')) {
      closeTextModal()
    }
  }, [])

  useEffect(() => {
    if (player.current && !hasSubscribedPlayer) {
      player.current.subscribeToStateChange(handleStateChange)
      setHasSubscribedPlayer(true)
    }
  }, [player.current])

  useEffect(() => {
    if (campaign.campaign.id === Number(params.id)) {
      const {campaignVideoId} = campaign.campaign.campaignVideos[0]

      const allGroups = [...defaultGroups]

      if (groups) {
        const tempDefaultGroups = groups.map((group, index) => {
          const groupObject = utils.createGroupObject(group.demographics)

          return {
            ...groupObject,
            fields: Object.keys(groupObject),
            type: 'creative',
            isDefault: true,
            group: group.name || `Group ${index + 1}`, // update groups in BE to have a default name if no name was set
          }
        })

        allGroups.push(...tempDefaultGroups)

        setDefaultGroups(prevState => [...prevState, ...tempDefaultGroups])
      }

      if (!hasFetchedDemographics) {
        getDemographicsWithValues(campaignVideoId)
        setHasFetchedDemographics(true)
      }

      getDefaultRapport(campaignVideoId)

      if (campaign.campaign.videos) {
        setVideo(campaign.campaign.videos[0])
      }
    }
  }, [campaign.campaign])

  useEffect(() => {
    if (defaultRapport?.groups) {
      const newGroups = [...defaultRapport.groups, ...customRapport.groups]

      setETracRapport({
        ...defaultRapport,
        groups: newGroups,
      })

      setModalContent(
        utils.getDemographicDetailsModalContent(
          defaultRapport.total,
          defaultRapport.groups,
          customRapport.groups,
        ),
      )
    }
  }, [defaultRapport])

  useEffect(() => {
    if (customRapport?.groups?.length) {
      const newGroups = [...defaultRapport.groups, ...customRapport.groups]

      setETracRapport(prevState => ({
        ...prevState,
        groups: newGroups,
      }))

      setModalContent(
        utils.getDemographicDetailsModalContent(
          defaultRapport.total,
          defaultRapport.groups,
          customRapport.groups,
        ),
      )
    }
  }, [customRapport])

  useEffect(() => {
    if (campaign.campaign.id === Number(params.id)) {
      if (hasFetchedDemographics && !_.isEmpty(demographicsWithValues)) {
        const [parents, children] = utils.getSelectParentsAndChildren(demographicsWithValues)

        setSelectParents(parents)
        setSelectChildren(children)

        const labelsTable = utils.getDemographicLabelsTable(demographicsWithValues)
        setDemographicLabelsTable(labelsTable)
      }
    }
  }, [demographicsWithValues])

  const clearResults = () => {
    setETracRapport(defaultRapport)

    setModalContent(
      utils.getDemographicDetailsModalContent(defaultRapport.total, defaultRapport.groups),
    )

    setIsComparing(false)

    resetState(['eTracRapport'])
  }

  const handleStateChange = state => {
    setPlayerState(state)
  }

  const seek = useCallback(seconds => {
    player.current.play()
    player.current.seek(seconds)
  }, [])

  const videoRef = useCallback(ref => {
    if (ref) player.current = ref
  }, [])

  const renderModals = () => {
    return (
      <>
        <InfoModal open={textModalOpen} onClose={closeTextModal}>
          <>
            <Typography variant="h4">Rapport Explained</Typography>
            <Typography variant="body1" style={{maxWidth: '65ch'}}>
              <strong>What is the Rapport Map?</strong> The Rapport Map represents how the Audience
              connected to the story. It reveals which assets drive positive Rapport and where lower
              trending Rapport is noted. Ideally you would like to see the line graph appear as
              close to the Mean line as possible. This indicates the Audience believes your story is
              convincing and persuasive.
              <br />
              <br />
              <strong style={{textTransform: 'capitalize'}}>What is a good Rapport Map?</strong> A
              good Rapport Map should trend as close to the Mean line or above. This indicates the
              audience was aroused and triggered by the story and formed an empathetic connection
              with your case.
              <br />
              <br />
              <strong>How to read this map:</strong>
              <img
                src={require('../../../assets/charts/rapport.png')}
                alt="Rapport Map"
                style={{width: '100%'}}
              />
              <br />
              <br />
              <strong>1. Purpose of the Mean Line:</strong> The Mean Line provides you with a point
              of reference on your map. Use this line to observe how the sentiments trend above
              average and where it scores below the average.
              <br />
              <br />
              <strong>2. Onboarding:</strong> Study the early frames to see how quickly it takes the
              audience to trigger Angst.
              <br />
              <br />
              <strong>3. Understanding the trajectory:</strong> A good Rapport Map should be rich
              with friction and trend as close to the Mean line as possible. It will illustrate an
              empathetic audience and the intention to advocate for your story.
              <br />
              <br />
              <strong>4. Peak End Rule:</strong>
              The last moments of Rapport should be near or above the mean Line. This confirms your
              story has created the desired Rapport with the Audience. Your story has been
              successfully told.
              <br />
              <br />
              <strong>What do I do with this data?</strong> Compare different moments in your
              trajectory and identify the ones that drive the most Rapport. This will help you
              optimize your narrative and increase the level of empathy and persuasion you can
              achieve. Be sure to eliminate any weaker or misunderstood elements in your story that
              do not resonate with your audience and are likely to detract from your arguments.
            </Typography>
          </>
        </InfoModal>
        <InfoModal open={videoModalOpen} onClose={closeVideoModal}>
          <>
            <Typography variant="h4">Rapport Explained</Typography>
            <iframe
              width="750"
              height="480"
              src="https://www.youtube.com/embed/UY-vnbnsaDc?&autoplay=1"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </>
        </InfoModal>
        {!_.isEmpty(modalContent) && (
          <DemographicGroupDetailsModal
            detailsModalOpen={detailsModalOpen}
            closeDetailsModal={closeDetailsModal}
            modalContent={modalContent}
            demographicLabelsTable={demographicLabelsTable}
          />
        )}
      </>
    )
  }

  return (
    <Grid container className={classes.rapportMapContainer}>
      {renderModals()}
      <TestDetailHeader
        title="Rapport Map"
        subTitle="Rapport Map displays the intensity & trajectory of audience empathy"
        responseCount={eTracRapport?.total?.responseCount}
      />
      <Paper elevation={0} className={classes.rapportMapPaper}>
        <Grid container>
          <Grid item xs />
          <Grid item className={classes.paperButtonContainer}>
            <ExportSnapshotButton
              targetId="rapport-chart"
              exportName="RapportMap"
              heightOffset={40}
            />
            {isFetchingETracRapport || isFetchingCampaign ? null : (
              <>
                {(groups &&
                  _.has(eTracRapport, 'groups') &&
                  groups.length === eTracRapport.groups.length) ||
                eTracRapport.length === 0 ? null : (
                  <Button
                    variant="contained"
                    className={classes.clearResultsButton}
                    onClick={clearResults}
                  >
                    Clear Results
                  </Button>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  className={classes.compareButton}
                  onClick={openCompareDemographicsModal}
                  style={{height: '40px'}}
                >
                  <CompareArrowsIcon fontSize="small" className={classes.buttonIcon} />
                  Compare
                </Button>
              </>
            )}

            <CompareDemographicsModalSentiments
              selectParents={selectParents}
              selectChildren={selectChildren}
              defaultGroups={defaultGroups}
              open={compareDemographicsOpen}
              onClose={closeCompareDemographicsModal}
              isComparing={isComparing}
              setIsComparing={setIsComparing}
              isRapportMap
            />
            <InfoButtons openTextModal={openTextModal} openVideoModal={openVideoModal} />
          </Grid>
        </Grid>
        {!_.isEmpty(video) && (
          <Grid container className={classes.videoContainer}>
            <Player
              fluid={false}
              height={320}
              aspectRatio="auto"
              playsInline
              src={video.videoFile}
              poster={video.thumbnail ? video.thumbnail.fullSize : ''}
              ref={videoRef}
            >
              <BigPlayButton position="center" />
              <ControlBar>
                <CurrentTimeDisplay order={4.1} />
                <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                <VolumeMenuButton vertical />
              </ControlBar>
            </Player>
          </Grid>
        )}
        <Grid
          item
          xs
          className={classes.paperHeaderTextContainer}
          style={{marginBottom: -30, position: 'relative', zIndex: 999, width: '20%'}}
        >
          {isFetchingETracRapport || isFetchingCampaign ? null : (
            <Typography
              className={classes.paperHeaderText}
              style={{minWidth: 'initial !important'}}
            >
              Demographics:
              {!_.isEmpty(modalContent) && (
                <Typography
                  component="span"
                  onClick={openDetailsModal}
                  className={classes.filterDetailsButton}
                >
                  Details
                </Typography>
              )}
            </Typography>
          )}
        </Grid>
        {isFetchingCampaign || isFetchingETracRapport ? (
          <Loading />
        ) : (
          <>
            <RapportChart
              eTracRapport={eTracRapport.total?.events}
              eTracRapportByGroup={eTracRapport.groups}
              xaxis={eTracRapport.xaxis}
              yaxis={eTracRapport.yaxis}
              video={video}
              currentTime={playerState.currentTime}
              isPaused={playerState.paused}
              seek={seek}
              duration={video.duration}
              isEmpty={_.isEmpty(eTracRapport.total?.events)}
              Chart={Chart}
            />
          </>
        )}
      </Paper>
    </Grid>
  )
}

RapportMap.propTypes = {
  campaign: PropTypes.objectOf(PropTypes.any).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  params: PropTypes.objectOf(PropTypes.string).isRequired,
  demographicsWithValues: PropTypes.objectOf(PropTypes.object).isRequired,
  isFetchingCampaign: PropTypes.bool.isRequired,
  isFetchingETracRapport: PropTypes.bool.isRequired,
  getCampaign: PropTypes.func.isRequired,
  getDemographicsWithValues: PropTypes.func.isRequired,
}

const mapStateToProps = ({campaign}) => ({
  campaign,
  groups: campaign.campaign.targetAudiences,
  isFetchingCampaign: campaign.isFetching,
  demographicsWithValues: campaign.demographicsWithValues.values,
  isFetchingETracRapport: campaign.defaultRapport.isFetching || campaign.eTracRapport.isFetching,
  defaultRapport: campaign.defaultRapport.results,
  customRapport: campaign.eTracRapport.results,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCampaign,
      getDemographicsWithValues,
      getDefaultRapport,
      resetState,
    },
    dispatch,
  )

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(RapportMap)
