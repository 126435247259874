import React from 'react'
import PropTypes from 'prop-types'
import {IconButton, withStyles} from '@material-ui/core'

import chartIcon from '../../assets/icons/chart.svg'
import gaugeIcon from '../../assets/icons/gauge.svg'

const svgColorFilters = {
  main: 'invert(89%) sepia(3%) saturate(159%) hue-rotate(182deg) brightness(81%) contrast(85%)',
  active:
    'invert(30%) sepia(90%) saturate(1876%) hue-rotate(201deg) brightness(103%) contrast(106%)',
}

const styles = theme => ({
  tabSwitchContainer: {
    display: 'flex',
    border: '2px solid #b0b1b31f',
    borderRadius: 8,
  },
  switchButton: {
    width: 48,
    height: 36,
    borderRadius: 8,
    position: 'relative',
  },
  switchButtonImage: {
    width: 20,
    filter: svgColorFilters.main,
    zIndex: 1,
  },
  activeButtonImage: {
    width: 20,
    filter: svgColorFilters.active,
    zIndex: 1,
  },
  selectedBackground: {
    position: 'absolute',
    display: 'block',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '90%',
    borderRadius: 8,
    background: theme.palette.primary.main,
    filter: 'opacity(0.12)',
  },
})

const TabSwitchButtons = ({classes, showingVerticalBarChart, setShowingVerticalBarChart}) => {
  const selected = showingVerticalBarChart ? 'chart' : 'gauge'

  const isSelected = name => {
    return name === selected
  }

  return (
    <div className={classes.tabSwitchContainer}>
      <IconButton className={classes.switchButton} onClick={() => setShowingVerticalBarChart(true)}>
        {isSelected('chart') && <span className={classes.selectedBackground} />}
        <img
          src={chartIcon}
          alt="chart"
          className={isSelected('chart') ? classes.activeButtonImage : classes.switchButtonImage}
        />
      </IconButton>
      <IconButton
        className={classes.switchButton}
        onClick={() => setShowingVerticalBarChart(false)}
      >
        {isSelected('gauge') && <span className={classes.selectedBackground} />}
        <img
          src={gaugeIcon}
          alt="gauge"
          className={isSelected('gauge') ? classes.activeButtonImage : classes.switchButtonImage}
        />
      </IconButton>
    </div>
  )
}

TabSwitchButtons.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  showingVerticalBarChart: PropTypes.bool.isRequired,
  setShowingVerticalBarChart: PropTypes.func.isRequired,
}

export default withStyles(styles)(TabSwitchButtons)
