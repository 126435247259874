import React from 'react'
import PropTypes from 'prop-types'
import {InputBase, MenuItem, Select, withStyles} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const styles = {
  select: {
    whiteSpace: 'break-spaces',
  },
  menuItem: {
    wordWrap: 'break-word',
  },
  selectMenu: {
    width: '70ch',
    '& ul li': {
      position: 'relative',
      whiteSpace: 'normal',
      height: 'max-content',
      '&::after': {
        content: '""',
        position: 'absolute',
        height: 1,
        width: '95%',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        background: '#F1F2F4',
      },
      '&:last-child::after': {
        width: 0,
      },
    },
  },
}

const SentimentInput = withStyles(theme => ({
  root: {
    borderRadius: theme.spacing.unit / 2,
    color: 'black',
    marginRight: theme.spacing.unit * 2,
    marginTop: '0 !important',
    maxWidth: '100%',
    fontWeight: 500,
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    paddingRight: 60,
  },
}))(InputBase)

const SelectIcon = withStyles(theme => ({
  root: {
    marginRight: theme.spacing.unit / 2,
    color: '#292D32',
  },
}))(ExpandMoreIcon)

const capitalizeFirstLetter = str => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const SurveyResultsQuestionDropdown = ({
  classes,
  dropDownQuestions,
  currentQuestion,
  setCurrentQuestion,
  isFetching,
}) => {
  if (!dropDownQuestions || !dropDownQuestions.length) {
    return null
  }

  return (
    <Select
      classes={{selectMenu: classes.select}}
      value={currentQuestion}
      onChange={({target}) => setCurrentQuestion(target.value)}
      input={<SentimentInput name="sentiment" />}
      IconComponent={SelectIcon}
      MenuProps={{classes: {paper: classes.selectMenu}}}
      disabled={isFetching}
    >
      {dropDownQuestions.map((question, index) => (
        <MenuItem key={index} value={question.question} className={classes.menuItem}>
          {capitalizeFirstLetter(question.question)}
        </MenuItem>
      ))}
    </Select>
  )
}

SurveyResultsQuestionDropdown.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dropDownQuestions: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentQuestion: PropTypes.string.isRequired,
  setCurrentQuestion: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
}

export default withStyles(styles)(SurveyResultsQuestionDropdown)
