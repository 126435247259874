import React from 'react'
import PropTypes from 'prop-types'
import {Grid, Typography, withStyles} from '@material-ui/core'
import SurveyResultsQuestionTypeSwitch from '../campaign/SurveyResultsQuestionTypeSwitch'

const styles = theme => ({
  header: {
    alignItems: 'center',
    '& > div': {
      padding: `${theme.spacing.unit * 4} 0`,
    },
  },
  headerTitle: {
    fontSize: '1.7rem',
    fontWeight: '500',
  },
  headerSubtitle: {
    color: '#4f4f4f',
    marginTop: theme.spacing.unit,
  },
  responses: {
    color: '#4F4F4F',
    fontSize: '1.4rem',
    fontWeight: '400',
  },
})

const TestDetailHeader = ({
  classes,
  title,
  responseCount,
  isSurvey,
  isFetching,
  showingMultipleChoiceAnswer,
  setShowingMultipleChoiceAnswer,
  subTitle,
  noCountResults,
}) => {
  return (
    <Grid container className={classes.header}>
      <Grid item>
        <Typography variant="h3" className={classes.headerTitle}>
          {title}
        </Typography>
        {subTitle && <Typography className={classes.headerSubtitle}>{subTitle}</Typography>}
      </Grid>
      <Grid item xs>
        {isSurvey && (
          <SurveyResultsQuestionTypeSwitch
            showingMultipleChoiceAnswer={showingMultipleChoiceAnswer}
            setShowingMultipleChoiceAnswer={setShowingMultipleChoiceAnswer}
            isFetching={isFetching}
          />
        )}
      </Grid>
      <Grid item>
        {noCountResults ? null : (
          <Typography variant="h3" className={classes.responses}>
            {`${responseCount === 1 ? '1 Response' : `${responseCount} Responses`}`}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

TestDetailHeader.propTypes = {
  title: PropTypes.string.isRequired,
  responseCount: PropTypes.number,
  isSurvey: PropTypes.bool,
  isFetching: PropTypes.bool,
  showingMultipleChoiceAnswer: PropTypes.bool,
  setShowingMultipleChoiceAnswer: PropTypes.func,
  noCountResults: PropTypes.bool,
}

TestDetailHeader.defaultProps = {
  responseCount: 0,
  isSurvey: false,
  isFetching: false,
  showingMultipleChoiceAnswer: false,
  noCountResults: false,
  setShowingMultipleChoiceAnswer: () => {},
}

export default withStyles(styles)(TestDetailHeader)
