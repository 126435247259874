import React from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {Grid, Typography, withStyles} from '@material-ui/core'
import {initResponseStatus, setResponseStatus} from './redux/actions.responseStatus'
import img404 from '../../assets/404.png'

const styles = theme => ({
  text: {
    [theme.breakpoints.down('1400')]: {
      fontSize: '3rem',
    },
  },
})

class NotFoundComponent extends React.Component {
  componentWillMount() {
    this.props.dispatch(setResponseStatus(404))
  }

  componentWillUnmount() {
    this.props.dispatch(initResponseStatus())
  }

  render() {
    const {classes} = this.props

    return (
      <Grid container>
        <Grid container justify="center" alignItems="center">
          <img src={img404} alt="404" style={{maxWidth: '40%'}} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h1" component="h1" align="center" className={classes.text}>
            Ooops.. Page Not Found!
          </Typography>
        </Grid>
      </Grid>
    )
  }
}

NotFoundComponent.displayName = 'NotFoundComponent'

export default compose(connect(), withStyles(styles))(NotFoundComponent)
