import React, {useContext, useEffect, useState} from 'react'
import _ from 'lodash'

import {Button, CircularProgress, Grid, Typography} from '@material-ui/core'

import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {getQuestionsFromCsv} from '../../redux/actions'
import useUpdateEffect from '../../../../hooks/useUpdateEffect'
import {apiBaseURL} from '../../../../../config/settings'

import {contextFields} from '../../../constants/NewTest'
import InputValidator from '../../../common/InputValidator'
import NewTestContext from '../../context/NewTestContext'
import NewTestErrorContext from '../../context/NewTestErrorContext'
import UploadCsvModal from './UploadCsvModal'
import userSession from '../../../auth/UserSession'
import WarningModal from '../WarningModal'

const SurveyCsv = props => {
  const {
    classes,
    questions,
    isFetching,
    questionsFromCsv,
    getQuestionsFromCsv,
    setCurrentQuestion,
    questionsValidators,
  } = props

  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const [csvWarnings, setCsvWarnings] = useState('')

  const {setStateField, csvFile} = useContext(NewTestContext)
  const {csvError, setCsvError} = useContext(NewTestErrorContext)

  useEffect(() => {
    if (_.isEmpty(csvFile)) {
      return
    }

    getQuestionsFromCsv(csvFile)
    setCsvFile(null)
    closeModal()
  }, [csvFile])

  useUpdateEffect(() => {
    if (questionsFromCsv && questionsFromCsv.length) {
      const {isQuestionDuplicated, isQuestionTooShort} = questionsValidators

      let newQuestions = [...questions, ...questionsFromCsv]
      const duplicatedQuestions = []
      const shortQuestions = []
      const validQuestions = questionsFromCsv.filter((_, csvIndex) => {
        const index = questions.length + csvIndex
        if (isQuestionDuplicated(index, newQuestions)) {
          duplicatedQuestions.push(csvIndex)
          return false
        }
        if (isQuestionTooShort(index, newQuestions)) {
          shortQuestions.push(csvIndex)
          return false
        }
        return true
      })
      newQuestions = [...questions, ...validQuestions]
      setStateField(contextFields.questions, newQuestions)

      const newCurrentQuestions = newQuestions.length
        ? newQuestions.length - validQuestions.length
        : -1
      setCurrentQuestion(
        newCurrentQuestions === newQuestions.length ? newCurrentQuestions - 1 : newCurrentQuestions,
      )

      const warningsReasons = []
      if (duplicatedQuestions.length) {
        warningsReasons.push('Duplicated questions')
      }
      if (shortQuestions.length) {
        warningsReasons.push('Short questions')
      }
      if (warningsReasons.length) {
        const lines = [...duplicatedQuestions, ...shortQuestions].map(i => i + 2).join(', ')
        setCsvWarnings([
          `The questions in the following CSV lines were not added: ${lines}`,
          `Reasons: ${warningsReasons.join(', ')}`,
        ])
      }
    }
  }, [questionsFromCsv])

  const openModal = () => setUploadModalOpen(true)
  const closeModal = () => setUploadModalOpen(false)

  const setCsvFile = csvFile => {
    setStateField('csvFile', csvFile)
  }

  const validate = () => {
    if (_.isEmpty(csvFile)) {
      return 'You must upload a csv!'
    }

    setCsvError('')
    return ''
  }

  const handleDownloadTemplates = async () => {
    const response = await fetch(`${apiBaseURL}/surveys/upload-csv`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Token ${userSession.getToken()}`,
      },
    })
    const blob = await response.blob()
    const url = window.URL.createObjectURL(new Blob([blob]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `questions-template.csv`)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
  }

  const renderUploadArea = () => {
    if (!_.isEmpty(csvFile)) {
      return
    }

    return (
      <InputValidator errors={csvError} customValidation={validate}>
        <UploadCsvModal
          uploadModalOpen={uploadModalOpen}
          closeModal={closeModal}
          setCsvFile={setCsvFile}
        />
      </InputValidator>
    )
  }

  return (
    <Grid container className={classes.surveysButtonsContainers}>
      <Typography variant="h6" className={classes.sectionSubtitle2}>
        CSV Template
      </Typography>

      <Grid className={classes.surveysButtonsRow}>
        {isFetching && <CircularProgress size={36.5} className={classes.surveysButtonsBtn} />}
        {!isFetching && (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={openModal}
              className={classes.surveysButtonsBtn}
            >
              + Upload
            </Button>
            <button
              type="button"
              className={classes.surveysButtonsBtnDownloadTemplate}
              onClick={() => handleDownloadTemplates()}
            >
              Download Template
            </button>
          </>
        )}
        {csvWarnings && (
          <WarningModal
            classes={classes}
            warnings={csvWarnings}
            openModal={!!csvWarnings}
            handleCloseModal={() => setCsvWarnings('')}
          />
        )}
      </Grid>
      {renderUploadArea()}
    </Grid>
  )
}

const mapStateToProps = ({campaign}) => {
  return {
    isFetching: campaign.questionsFromCsv.isFetching,
    questionsFromCsv: campaign.questionsFromCsv.results,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getQuestionsFromCsv,
    },
    dispatch,
  )

export default compose(connect(mapStateToProps, mapDispatchToProps))(SurveyCsv)
