const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  tableWrapper: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
    width: 'calc(100% - 48px) ',
    margin: 'auto',
  },
  tableHeader: {
    backgroundColor: '#fff',
    color: 'rgba(62,59,59, 0.6)',
    padding: 0,
    paddingBottom: 16,
    paddingTop: 16,
    paddingLeft: 24,
  },
  row: {
    height: '64px',
    '&:nth-of-type(odd)': {
      backgroundColor: '#FFF',
    },
    '&:nth-of-type(even)': {
      backgroundColor: 'rgba(246, 246, 246, 0.35)',
    },
  },
  tableRowHeader: {
    height: '25px',
  },
  tableCell: {
    padding: '4px 24px',
  },
  link: {
    color: '#047aba',
    textDecoration: 'underline',
  },
  datailsButton: {
    backgroundColor: 'rgba(37,164,184,0.12)',
    color: '#00BFB1',
    boxShadow: 'none',
    width: '100px',
    height: '29px',
  },
  myCampaigns: {
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: '500',
    letterSpacing: '0.45px',
    marginBottom: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableContainer: {
    backgroundColor: '#fff',
  },
  tutorials: {
    height: theme.spacing.unit * 5,
    border: '2px solid #b0b1b31f',
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    marginRight: '42px',
    marginBottom: '15px',
    marginTop: '15px',
    position: 'relative',
    '& span': {
      fontSize: '1rem',
      fontWeight: 400,
      color: '#3B92ED',
      zIndex: 1,
    },
    '& span span': {
      opacity: 0.6,
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: 4,
      width: 'calc(100% - 5px)',
      height: 'calc(100% - 5px)',
      background: '#b0b1b31f',
    },
  },
  testId: {
    fontSize: 13,
    fontWeight: 500,
    textDecoration: 'none',
    display: 'inline',
  },
  testName: {
    fontSize: 13,
    textDecoration: 'none',
    display: 'inline',
  },
  allTestsHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '70px',
  },
  searchInput: {
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, 0.06)',
    width: '290px',
    height: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '20px',
    paddingRight: '10px',
    fontWeight: 'normal',
    fontSize: '12px',
    color: 'rgba(51, 51, 51, 0.25)',
    '& button:hover': {
      backgroundColor: 'transparent',
    },
  },
  tutorialButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  buttonNextAndBack: {
    cursor: 'pointer',
    height: '18px',
    minWidth: '18px',
    listStyle: 'none',
    filter: 'invert(61%) sepia(9%) saturate(597%) hue-rotate(210deg) brightness(92%) contrast(91%)',
  },
  buttonNextAndBackDisabled: {
    userEvent: 'none',
    height: '18px',
    minWidth: '18px',
    listStyle: 'none',
    filter: 'invert(95%) sepia(5%) saturate(512%) hue-rotate(206deg) brightness(90%) contrast(89%)',
  },
  paginationContainer: {
    display: 'flex',
    backgroundColor: 'rgb(243, 243, 243, 0.6)',
    alignItems: 'center',
    height: '27px',
    borderRadius: '5px',
    margin: '23px 0',
    justifyContent: 'center',
    padding: '0px 12px',
    marginRight: '24px',
  },
  page: {
    listStyle: 'none',
    backgroundColor: 'transparent',
    color: '#92A1B9',
    width: '18px',
    height: '18px',
    marginLeft: '4px',
    marginRight: '4px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    fontWeight: 500,
    fontSize: '12px',
    '& a': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '30px',
      height: '30px',
    },
  },
  prev: {
    padding: '5px 20px',
    listStyle: 'none',
    border: '1px solid lightblue',
    backgroundColor: '#888888',
    cursor: 'pointer',
  },
  next: {
    padding: '5px 20px',
    listStyle: 'none',
    border: '1px solid lightblue',
    backgroundColor: '#888888',
    cursor: 'pointer',
  },
  break: {
    listStyle: 'none',
    backgroundColor: 'transparent',
    color: '#92A1B9',
    width: '18px',
    height: '18px',
    marginLeft: '4px',
    marginRight: '4px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    fontWeight: 500,
    fontSize: '12px',
  },
  active: {
    backgroundColor: '#017EFF',
    color: '#FFFFFF',
  },
  reduceTableHeaderHeight: {
    height: 40,
  },
})

export default styles
