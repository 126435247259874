import React from 'react'
import {IndexRoute, Route} from 'react-router'
import {authenticateOnEnter} from './utils/RoutingUtils'
import App from './App.jsx'
import NotFound from './common/NotFound.Page.jsx'
import Dashboard from './dashboard/Dashboard.jsx'
import CampaignList from './campaign/CampaignList.jsx'
// import CampaignAggregateReport from './campaign/CampaignAggregateReport.jsx'
import Profile from './profile/Profile.jsx'
import ProfileInfo from './profile/ProfileInfo.jsx'
import ProfileSecurity from './profile/ProfileSecurity.jsx'
import ConfirmEmail from './profile/ConfirmEmail.jsx'
import ChangeEmailConfirm from './profile/ChangeEmailConfirm.jsx'
import ChangeEmailVerify from './profile/ChangeEmailVerify.jsx'
import PublicUser from './publicUser/PublicUser.jsx'
// import CampaignVideoDetailByTime from './campaign/CampaignVideoDetailByTime'
import Demo from './demo/demo'
import TabScores from './campaign/TabScores'
import SurveyResults from './campaign/SurveyResults'
import RapportMap from './campaign/SentimentMaps/RapportMap'
import SentimentMap from './campaign/SentimentMaps/SentimentMap'
import NewTest from './campaign/NewTest'
import ForgotPasswordRequest from './auth/ForgotPasswordRequest'
import ForgotPasswordConsume from './auth/ForgotPasswordConsume'
import Login from './auth/Login'
import Signup from './auth/Signup'
import Conclusions from './campaign/Conclusions/Conclusions.jsx'

import NewTestProvider from './campaign/context/NewTestProvider'
import NewTestErrorProvider from './campaign/context/NewTestErrorProvider'
import Tutorials from './campaign/Tutorials'

export default function routing(store) {
  const authenticate = authenticateOnEnter.bind(this, store)

  return (
    <Route path="/" component={App}>
      <IndexRoute component={Dashboard} onEnter={authenticate} />
      <Route path="/login" component={Login} />
      <Route path="/signup" component={Signup} />
      <Route path="/forgot" component={ForgotPasswordRequest} />
      <Route path="/forgot-password/:token" component={ForgotPasswordConsume} />
      <Route
        path="/new-test"
        component={props => (
          <NewTestProvider>
            <NewTestErrorProvider>
              <NewTest {...props} />
            </NewTestErrorProvider>
          </NewTestProvider>
        )}
        onEnter={authenticate}
      />
      <Route path="/tutorials" component={Tutorials} onEnter={authenticate} />
      <Route path="/tests" component={CampaignList} onEnter={authenticate} />
      <Route path="/tests/:id/conclusions" component={Conclusions} onEnter={authenticate} />
      <Route path="/tests/:id/tutorials" component={Tutorials} onEnter={authenticate} />
      {/* <Route
        path="/tests/:id/details/"
        component={CampaignVideoDetailByTime}
        // onEnter={authenticate}
      /> */}
      {/* <Route
        path="/tests/:id/details/aggregate-report"
        component={CampaignAggregateReport}
        onEnter={authenticate}
      /> */}
      <Route path="/tests/:id/tab-scores" component={TabScores} onEnter={authenticate} />
      <Route path="/tests/:id/survey-results" component={SurveyResults} onEnter={authenticate} />
      <Route path="/tests/:id/rapport" component={RapportMap} onEnter={authenticate} />
      <Route
        path="/tests/:id/sentiments"
        component={props => <SentimentMap showSentimentSelector {...props} />}
        onEnter={authenticate}
      />
      <Route
        path="/tests/:id/angst"
        component={props => <SentimentMap fixedSentiment="angst" {...props} />}
        onEnter={authenticate}
      />
      <Route
        path="/tests/:id/curiosity"
        component={props => <SentimentMap fixedSentiment="curious" {...props} />}
        onEnter={authenticate}
      />
      <Route
        path="/tests/:id/enjoyment"
        component={props => <SentimentMap fixedSentiment="enjoyment" {...props} />}
        onEnter={authenticate}
      />
      <Route
        path="/tests/:id/humor"
        component={props => <SentimentMap fixedSentiment="humor" {...props} />}
        onEnter={authenticate}
      />
      <Route
        path="/tests/:id/victory"
        component={props => <SentimentMap fixedSentiment="victory" {...props} />}
        onEnter={authenticate}
      />
      <Route path="/profile" component={Profile} onEnter={authenticate}>
        <IndexRoute component={ProfileInfo} />
        <Route path="security" component={ProfileSecurity} />
      </Route>
      <Route path="/confirm-email/:id/:token" component={ConfirmEmail} />
      <Route path="/change-email/:id/:token" component={ChangeEmailConfirm} />
      <Route path="/change-email-verify/:id/:token" component={ChangeEmailVerify} />
      <Route path="/users/:id" component={PublicUser} />
      <Route path="/demo" component={Demo} />
      {/* should always be at the end */}
      <Route path="*" component={NotFound} />
    </Route>
  )
}
