import React from 'react'
import PropTypes from 'prop-types'
import {Grid, IconButton, Typography, withStyles} from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import Remove from '@material-ui/icons/Remove'

const styles = theme => ({
  container: {
    border: `1px solid ${theme.palette.surface[700]}`,
    width: 'max-content',
    alignItems: 'center',
    borderRadius: theme.spacing.unit / 2,
    padding: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 2}px`,
  },
  button: {
    color: theme.palette.surface[700],
    border: `1px solid ${theme.palette.surface[700]}`,
    borderRadius: theme.spacing.unit / 2,
    padding: '2px',
  },
  buttonDisabled: {
    border: `1px solid ${theme.palette.surface[400]}`,
    color: theme.palette.surface[400],
  },
  text: {
    fontWeight: 500,
    color: theme.palette.surface[700],
    padding: `0 ${theme.spacing.unit * 2}`,
    borderLeft: `1px solid ${theme.palette.surface[300]}`,
    borderRight: `1px solid ${theme.palette.surface[300]}`,
    userSelect: 'none',
  },
  icon: {
    fontSize: theme.spacing.unit * 2,
  },
})

const QuantitySelector = ({classes, value, increment, decrement, leftDisabled, rightDisabled}) => {
  return (
    <Grid container className={classes.container}>
      <IconButton
        className={`${classes.button} ${leftDisabled ? classes.buttonDisabled : ''}`}
        onClick={decrement}
        disabled={leftDisabled}
        style={{marginRight: 8}}
      >
        <Remove className={classes.icon} />
      </IconButton>
      <Typography className={classes.text}>{value}</Typography>
      <IconButton
        className={`${classes.button} ${rightDisabled ? classes.buttonDisabled : ''}`}
        onClick={increment}
        disabled={rightDisabled}
        style={{marginLeft: 8}}
      >
        <Add className={classes.icon} />
      </IconButton>
    </Grid>
  )
}

QuantitySelector.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  increment: PropTypes.func.isRequired,
  decrement: PropTypes.func.isRequired,
  leftDisabled: PropTypes.bool.isRequired,
  rightDisabled: PropTypes.bool.isRequired,
}

export default withStyles(styles)(QuantitySelector)
