const styles = theme => ({
  dialogPaper: {
    padding: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit * 3,
    width: '1000px',
    maxWidth: 'initial',
    margin: `${theme.spacing.unit * 6}px ${theme.spacing.unit * 4}`,
  },
  dialogHeader: {
    alignItems: 'center',
    padding: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 2,
    '& h6': {
      fontSize: '1.75rem',
    },
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
  },
  dialogContent: {
    margin: `${theme.spacing.unit * 2} ${theme.spacing.unit}`,
    padding: `0 ${theme.spacing.unit}`,
  },
  groupTitle: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '15px',
    letterSpacing: '-0.02em',
    color: '#131822',
    opacity: 0.9,
    marginBottom: '12px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '80%',
  },
  dialogButton: {
    width: 160,
    boxShadow: 'none',
    '&:active': {
      boxShadow: 'none',
    },
  },
  closeGroupButton: {
    position: 'absolute',
    right: '10%',
    top: 0,
    padding: 0,
    '&:hover': {
      background: 'initial',
    },
  },
  selectAllDivider: {
    visibility: 'hidden',
  },
})

export default styles
