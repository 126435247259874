const styles = () => ({
  container: {
    backgroundColor: '#FFFFFF',
    marginTop: '24px',
    padding: '24px',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)',
  },
  benchmarkResult: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  results: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 800,
    fontSize: '20px',
    color: '#219653',
    paddingBottom: '16px',
  },
  resultsWithoutData: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 800,
    fontSize: '20px',
    color: '#BDBDBD !important',
    paddingBottom: '16px',
  },
  panelTitle: {
    fontWeight: 700,
    fontSize: '20px',
    color: '#333333',
    paddingBottom: '8px',
  },
  description: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#828282',
    paddingBottom: '48px',
  },
  valuesTitle: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#333333',
    paddingBottom: '12px',
    whiteSpace: 'unset',
    textTransform: 'capitalize',
  },
  demographic: {
    fontWeight: 700,
    fontSize: '16px',
    color: '#000000',
    paddingBottom: '12px',
    paddingTop: '4px',
    textTransform: 'capitalize',
  },
  resultBox: {
    marginRight: '28px',
  },
  spinnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
  },
  insideCard: {
    border: '1px solid #BDBFC2',
    width: 'calc(50% - 8px)',
    borderRadius: '8px',
    padding: '20px',
  },
  infoContainer: {
    display: 'flex',
    gap: '28px',
    flexWrap: 'wrap',
  },
  customGroups: {
    display: 'flex',
    gap: '16px',
    flexWrap: 'wrap',
  },
  groupName: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '23px',
    textTransform: 'capitalize',
    color: '#131822',
    marginRight: '12px',
    maxWidth: '64%',
  },
  valueLabels: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    textTransform: 'capitalize',
    color: '#131822',
    opacity: 0.9,
    marginBottom: '12px',
  },
  totalCount: {
    fontFamily: 'Roboto',
    fontWeight: 800,
    fontSize: '20px',
    lineHeight: '23px',
    color: '#017EFF',
  },
  benchmark: {
    fontFamily: 'Roboto',
    fontWeight: 800,
    fontSize: '20px',
    lineHeight: '23px',
    color: '#4CAF50',
  },
  belowBenchmark: {
    fontFamily: 'Roboto',
    fontWeight: 800,
    fontSize: '20px',
    lineHeight: '23px',
    color: '#F44336',
  },
  ellipse: {
    width: '12px',
    height: '12px',
    backgroundColor: '#131822',
    borderRadius: '50%',
    marginRight: '12px',
  },
  details: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textTransform: 'capitalize',
    color: '#0769CE',
    cursor: 'pointer',
  },
  modalGroupName: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '33px',
    color: '#262C38',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
  },
  modalDemographic: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#131822',
    marginBottom: '24px',
    textTransform: 'capitalize',
  },
  modalSubdemographicsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  modalSubdemographicsBulletPoint: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: '#76B9FE',
    marginRight: '8px',
  },
  modalGroupNameBulletPoint: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: '#F44336',
    marginRight: '12px',
  },
  modalSubdemographics: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#131822',
    marginRight: '24px',
    textTransform: 'capitalize',
  },
})

export default styles
