import React from 'react'
import PropTypes from 'prop-types'
import ErrorIcon from '@material-ui/icons/Error'
import {withStyles} from '@material-ui/core'

const styles = theme => ({
  rejectedContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing.unit * 2,
    maxWidth: 500,
    '& p': {
      margin: 0,
      marginLeft: theme.spacing.unit,
      color: theme.palette.secondary.main,
      fontSize: '0.8rem',
    },
  },
})

const RejectedFile = ({classes, error}) => {
  return (
    <div className={classes.rejectedContainer}>
      <ErrorIcon fontSize="small" color="secondary" />
      <p>{error}</p>
    </div>
  )
}

RejectedFile.propTypes = {
  error: PropTypes.string.isRequired,
}

export default withStyles(styles)(RejectedFile)
