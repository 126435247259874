import React from 'react'
import {Button, Dialog, Grid, Typography, withStyles} from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'

const styles = theme => ({
  paperRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing.unit * 3,
    borderRadius: theme.spacing.unit,
  },
  icon: {
    fontSize: '48px',
  },
  text: {
    width: '30ch',
    textAlign: 'center',
    margin: `${theme.spacing.unit * 3} 0`,
    fontWeight: 500,
    fontSize: '1.2rem',
    color: theme.palette.surface[800],
  },
  buttonsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: `${theme.spacing.unit * 2}px`,
    width: '100%',
  },
  button: {
    padding: `${theme.spacing.unit} ${theme.spacing.unit * 5}`,
  },
})

const ConfirmationModal = ({classes, open, text, onConfirm, onCancel}) => {
  return (
    <Dialog PaperProps={{classes: {root: classes.paperRoot}}} open={open}>
      <WarningIcon color="secondary" className={classes.icon} />
      <Typography className={classes.text} variant="body1">
        {text}
      </Typography>
      <Grid className={classes.buttonsContainer}>
        <Button
          className={classes.button}
          variant="outlined"
          color="secondary"
          fullWidth={false}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          fullWidth={false}
          onClick={onConfirm}
        >
          Yes
        </Button>
      </Grid>
    </Dialog>
  )
}

export default withStyles(styles)(ConfirmationModal)
