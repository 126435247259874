import React from 'react'
import PropTypes from 'prop-types'
import {Paper, Tabs, Tab} from '@material-ui/core'
import {IndexLink, Link} from 'react-router'
import {bindActionCreators, compose} from 'redux'
import {withStyles} from '@material-ui/core/styles'
import {connect} from 'react-redux'
import Helmet from 'react-helmet'
import AuthenticatedPage from '../common/AuthenticatedPage.jsx'
import * as Actions from './redux/actions'
import UserUtils from '../utils/UserUtils'

const styles = theme => ({
  container: {
    background: theme.palette.grey['100'],
    marginTop: theme.spacing.unit * 3,
    marginRight: 'auto',
    marginBottom: theme.spacing.unit * 3,
    marginLeft: 'auto',
    maxWidth: '62.5rem',
  },
})

class Profile extends React.Component {
  componentDidMount() {
    this.props.getProfile()
    const ReactPixel = require('react-facebook-pixel').default
    ReactPixel.pageView()
  }

  render() {
    const {classes} = this.props
    const {profile} = this.props.profile
    if (!profile) return null

    return (
      <React.Fragment>
        <Helmet
          title="Profile"
          meta={[
            {
              property: 'og:image',
              content: UserUtils.getSmallImageUrl(profile),
            },
          ]}
        />
        <Paper className={classes.container}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={this.props.location.pathname}
            scrollable
            scrollButtons="auto"
          >
            <Tab
              label="Personal Information"
              component={IndexLink}
              to="/profile"
              value="/profile"
            />
            <Tab
              label="Sign-in & security"
              component={Link}
              to="/profile/security"
              value="/profile/security"
            />
          </Tabs>
          {this.props.children}
        </Paper>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

Profile.displayName = 'Profile'
Profile.contextTypes = {
  router: PropTypes.object.isRequired,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  AuthenticatedPage,
)(Profile)
