import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  Typography,
  Grid,
  Button,
  withStyles,
  TextField,
  CircularProgress,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import {Link} from 'react-router'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import qs from 'qs'
import MuiPhoneNumber from 'mui-phone-input-ssr'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

// import GooglePlusAuthButton from './SocialAuthButtons.jsx'
import InputValidator from '../common/InputValidator.jsx'
import ValidatedComponent from '../common/ValidatedComponent.jsx'

import {createProfile, cleanProfileError} from '../profile/redux/actions'
import * as Actions from './redux/actions'
import {imageFileRegex} from '../constants/Regex'
import settings from '../../../config/settings'
import pageUtil from '../utils/PageUtils'
import {LUCID_USER_CODE} from '../campaign/utils.js'

import styles from './styles/LoginSignup.styles'

class Signup extends React.Component {
  constructor() {
    super()

    this.state = {
      company: '',
      email: '',
      avatar: null,
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      referralCode: '',
      acceptTerms: _.get(settings, 'website.automaticAccountAgreement'),
      acceptConsent: true,
      inputState: null,
      autoCompleteFix: false,
      showPassword: false,
      showPasswordConfirm: false,
    }

    this.emailRef = React.createRef()
    this.passwordRef = React.createRef()
    this.recaptchaRef = React.createRef()

    this.createUser = this.createUser.bind(this)
    this.validateConfirmPassword = this.validateConfirmPassword.bind(this)
    this.submitButtonDisable = this.submitButtonDisable.bind(this)
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  componentDidMount() {
    pageUtil.scrollToTop()
    this.getReferralCode()
    const ReactPixel = require('react-facebook-pixel').default
    ReactPixel.pageView()

    const interval = setInterval(() => {
      if (this.emailRef.current) {
        this.setState({autoCompleteFix: true})
        clearInterval(interval)
      }
    }, 600)
  }

  detectAutofill(element) {
    if (!element) return false
    return (
      window.getComputedStyle(element, null).getPropertyValue('appearance') === 'menulist-button'
    )
  }

  getReferralCode() {
    const {referral_code: referralCode} = qs.parse(document.location.search)
    this.setState({referralCode})
  }

  submitButtonDisable() {
    const {firstName, lastName, email, password, confirmPassword, company} = this.state
    if (firstName && lastName && email && company && password && password === confirmPassword) {
      return true
    }
    return false
  }

  async createUser(event) {
    event.preventDefault()
    const registeredFrom = LUCID_USER_CODE // all users should be lucid managers
    const {isComponentValid, createProfile} = this.props
    const {firstName, lastName, email, company, phoneNumber, password} = this.state

    await this.recaptchaRef.current.reset()
    const captchaToken = await this.recaptchaRef.current.executeAsync()

    if (isComponentValid() && captchaToken) {
      createProfile({
        firstName,
        lastName,
        email,
        companyName: company,
        phoneNumber: phoneNumber.length < 3 ? '' : phoneNumber,
        password,
        registeredFrom,
        captchaToken,
      })
    }
  }

  onChange(fieldName, e) {
    const {errors, cleanProfileError} = this.props

    if (!_.isEmpty(errors[fieldName])) {
      cleanProfileError(fieldName)
    }

    this.setState({[fieldName]: e.target.value})
  }

  onProfileImageUpload(e) {
    const avatar = e.target.files.item(0)
    if (!avatar || !imageFileRegex.test(avatar.type)) {
      return
    }

    const reader = new FileReader()
    reader.onload = e => {
      this.setState({
        avatar: e.target.result,
      })
    }
    reader.readAsDataURL(avatar)
  }

  validateConfirmPassword() {
    const {password, confirmPassword} = this.state
    if (password && confirmPassword && password !== confirmPassword) {
      return 'Both passwords must match'
    }
  }

  handleClickShowPassword(key) {
    this.setState(prevState => ({
      [key]: !prevState[key],
    }))
  }

  render() {
    const {state} = this
    const {
      errors,
      classes,
      isSaving,
      // isNoEmailProvidedError,
      // provider,
      // isSocialLoginLoading,
      // socialLoginOAuth2,
    } = this.props
    return (
      <div className={classes.page}>
        <Helmet title="Sign up" />
        <ReCAPTCHA sitekey={settings.googleReCAPTCHAKey} size="invisible" ref={this.recaptchaRef} />
        <Grid container className={classes.wrapper}>
          <div className={classes.container} style={{padding: '24px 50px'}}>
            <Typography className={classes.title} gutterBottom>
              Create Account
            </Typography>
            <form noValidate>
              <Grid container spacing={32} justify="center">
                <Grid item xs={12}>
                  <Grid container spacing={32}>
                    <Grid item xs={12} md={6} className={classes.firstNameInput}>
                      <InputValidator shouldValidateOnBlur={true}>
                        <TextField
                          className={classes.inputSignupAndLogin}
                          label="First Name"
                          required
                          variant="outlined"
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          value={state.firstName}
                          onChange={this.onChange.bind(this, 'firstName')}
                          margin="dense"
                          style={{marginTop: '16px'}}
                        />
                      </InputValidator>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.lastNameInput}>
                      <InputValidator shouldValidateOnBlur={true}>
                        <TextField
                          className={classes.inputSignupAndLogin}
                          label="Last Name"
                          required
                          variant="outlined"
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          value={state.lastName}
                          onChange={this.onChange.bind(this, 'lastName')}
                          margin="dense"
                          style={{marginTop: '16px'}}
                        />
                      </InputValidator>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{paddingTop: '0', paddingBottom: '0'}}>
                  <InputValidator errors={errors.email} shouldValidateOnBlur={true}>
                    <TextField
                      className={classes.inputSignupAndLogin}
                      label="Email"
                      type="email"
                      name="email"
                      autoComplete="off"
                      required
                      onChange={this.onChange.bind(this, 'email')}
                      value={state.email}
                      variant="outlined"
                      margin="dense"
                      inputRef={this.emailRef}
                      InputLabelProps={{
                        shrink:
                          this.detectAutofill(this.emailRef.current) || Boolean(this.state.email),
                      }}
                    />
                  </InputValidator>
                </Grid>

                <Grid item xs={12} style={{paddingTop: '0', paddingBottom: '0'}}>
                  <InputValidator shouldValidateOnBlur={true}>
                    <TextField
                      className={classes.inputSignupAndLogin}
                      required
                      name="company"
                      label="Company"
                      variant="outlined"
                      type="text"
                      onChange={this.onChange.bind(this, 'company')}
                      value={state.company}
                      margin="dense"
                    />
                  </InputValidator>
                </Grid>

                <Grid item xs={12} style={{paddingTop: '0', paddingBottom: '0'}}>
                  <InputValidator errors={errors.phoneNumber}>
                    <MuiPhoneNumber
                      className={classes.inputSignupAndLogin}
                      defaultCountry="us"
                      value={this.state.phoneNumber}
                      onChange={e => this.onChange('phoneNumber', {target: {value: e}})}
                      variant="outlined"
                      name="phoneNumber"
                      label="Phone Number"
                      margin="dense"
                    />
                  </InputValidator>
                </Grid>

                <Grid item xs={12} style={{paddingTop: '0', paddingBottom: '0'}}>
                  <InputValidator
                    errors={errors.password}
                    shouldValidateOnBlur={true}
                    customValidation={() => {
                      return this.validateConfirmPassword()
                    }}
                    className={this.state.inputState}
                  >
                    <TextField
                      className={classes.inputSignupAndLogin}
                      label="Password"
                      type={state.showPassword ? 'text' : 'password'}
                      variant="outlined"
                      name="password"
                      placeholder="Password"
                      required
                      onChange={this.onChange.bind(this, 'password')}
                      value={state.password}
                      margin="dense"
                      inputRef={this.passwordRef}
                      InputLabelProps={{
                        shrink:
                          this.detectAutofill(this.passwordRef.current) ||
                          Boolean(this.state.password),
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={() => this.handleClickShowPassword('showPassword')}
                            >
                              {state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </InputValidator>
                </Grid>

                <Grid item xs={12} style={{paddingTop: '0', paddingBottom: '0'}}>
                  <InputValidator
                    shouldValidateOnBlur={true}
                    customValidation={() => {
                      return this.validateConfirmPassword()
                    }}
                    className={this.state.inputState}
                  >
                    <TextField
                      className={classes.inputSignupAndLogin}
                      label="Confirm Password"
                      type={state.showPasswordConfirm ? 'text' : 'password'}
                      variant="outlined"
                      name="password-confirm"
                      placeholder="Confirm Password"
                      required
                      onChange={this.onChange.bind(this, 'confirmPassword')}
                      value={state.confirmPassword}
                      margin="dense"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={() => this.handleClickShowPassword('showPasswordConfirm')}
                            >
                              {state.showPasswordConfirm ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </InputValidator>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{display: 'flex', justifyContent: 'center', padding: '16px 0'}}
                >
                  <Button
                    type="submit"
                    name="btn-create"
                    color="primary"
                    variant="contained"
                    onClick={this.createUser}
                    style={{width: '185px'}}
                    disabled={!this.submitButtonDisable()}
                    className={classes.signupButton}
                  >
                    {isSaving ? (
                      <CircularProgress
                        className={classes.progress}
                        style={{color: '#F2F2F2'}}
                        size="24px"
                      />
                    ) : (
                      'Create Account'
                    )}
                  </Button>
                </Grid>
                {/* <div style={{marginBottom: '16px', color: '#37474F', fontSize: '16px'}}>Or</div>
                {_.get(settings, 'website.socialLogins') && (
                  <GooglePlusAuthButton
                    isNoEmailProvidedError={isNoEmailProvidedError && provider === 'google-oauth2'}
                    isLoading={isSocialLoginLoading && provider === 'google-oauth2'}
                    onSendAuthRequest={socialLoginOAuth2}
                  />
                )} */}
                <Grid container style={{display: 'flex', justifyContent: 'center', marginTop: 16}}>
                  <span style={{color: '#37474F', fontSize: '16px', paddingRight: '2px'}}>
                    Have an account?
                  </span>
                  <Link to="/login" style={{color: '#2F80ED'}}>
                    Login
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Grid>

        <div className="push" />
      </div>
    )
  }
}

Signup.displayName = 'Signup'

Signup.contextTypes = {
  router: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions, createProfile, cleanProfileError}, dispatch)
}

function mapStateToProps(state) {
  const {isNoEmailProvidedError, provider, isSocialLoginLoading, socialLoginOAuth2} = state.auth
  return {
    ...state.signup,
    isNoEmailProvidedError,
    provider,
    isSocialLoginLoading,
    socialLoginOAuth2,
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  ValidatedComponent,
)(Signup)
