import {createMuiTheme} from '@material-ui/core/styles'
import {grey} from '@material-ui/core/colors'

const surface = {
  '50': '#FFFFFF',
  '100': '#F6F9FC',
  '200': '#E6E8EC',
  '300': '#D6D8DC',
  '400': '#BDBFC2',
  '500': '#A6A7A9',
  '600': '#87888A',
  '700': '#5C5E63',
  '750': '#414347',
  '800': '#262C38',
  '900': '#131822',
}

export default createMuiTheme({
  typography: {
    fontFamily: 'Roboto',
    useNextVariants: true,
    h4: {
      color: grey[900],
      fontWeight: 700,
      marginBottom: 40,
    },
    subtitle1: {
      fontWeight: '500',
    },
  },
  SvgIcon: {
    width: '2em',
  },
  palette: {
    primary: {main: '#017EFF'},
    secondary: {main: '#EB5757'},
    background: {default: '#fff'},
    border: {main: '#292d3214'},
    grey,
    surface,
  },
  Divider: {
    margin: '15px 0',
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
      },
    },
  },
})
