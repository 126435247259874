import React from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {Grid, Typography, withStyles} from '@material-ui/core'

import CompareGaugeGroup from './CompareGaugeGroup'
import Loading from '../common/Loading'

import styles from './styles/TabScores.styles'

const CompareGauges = ({classes, tabScoreComparison, labels, isFetching}) => {
  if (isFetching) {
    return <Loading />
  }

  return (
    <Grid container>
      {tabScoreComparison.map(({audienceResults, benchmarks, yaxis, responseCount}, index) => (
        <Grid key={index} container className={classes.gaugeList}>
          <Grid item xs={12} className={classes.compareGroupLabel}>
            <Typography>{labels[index]}</Typography>
            <Grid item xs />
            <Typography>
              {`${responseCount} ${responseCount === 1 ? 'Response' : 'Responses'}`}
            </Typography>
          </Grid>
          <CompareGaugeGroup
            audienceResults={audienceResults}
            benchmarks={benchmarks}
            scores={yaxis[0].data}
          />
        </Grid>
      ))}
    </Grid>
  )
}

CompareGauges.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isFetching: PropTypes.bool.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  tabScoreComparison: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const mapStateToProps = ({campaign}) => ({
  tabScoreComparison: campaign.tabScoreComparison.results,
  isFetching: campaign.tabScoreComparison.isFetching,
  labels: campaign.tabScoreComparison.labels,
})

export default compose(connect(mapStateToProps), withStyles(styles))(CompareGauges)
