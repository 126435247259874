import React, {useState, useContext, useEffect} from 'react'
import _ from 'lodash'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'
import StripeCheckout from 'react-stripe-checkout'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import CloseIcon from '@material-ui/icons/Close'

import useUpdateEffect from '../../hooks/useUpdateEffect'

import Loading from '../common/Loading'
import InfoTooltip from '../common/InfoTooltip'
import Divider from '../common/Divider'

import NewTestContext from './context/NewTestContext'
import NewTestErrorContext from './context/NewTestErrorContext'

import {testTypes, errorModalTexts, contextFields} from '../constants/NewTest'
import settings from '../../../config/settings.json'
import utils from './utils'
import tagManager, {eventTypes} from '../utils/GTMUtils'

import {createCampaign, campaignPayment, uploadVideo, publishCampaign} from './redux/actions'
import {getProfile} from '../profile/redux/actions'
import CampaignApi from './api'

import styles from './styles/OrderSummary.styles'

const OrderSummary = props => {
  const {
    classes,
    credits,
    campaignPayment,
    caseTypes,
    campaignVideos,
    campaign,
    uploadVideo,
    createCampaign,
    publishCampaign,
    getProfile,
    router,
    errors,
    uploadProgress,
    paymentStatus,
    configs,
    registeredFrom,
  } = props

  const [premiumReport, setPremiumReport] = useState(false)
  const [useCredits, setUseCredits] = useState(false)
  const [totalCost, setTotalCost] = useState(0)
  const [testCost, setTestCost] = useState(0)
  const [creditsApplied, setCreditsApplied] = useState(0)
  const [balanceDue, setBalanceDue] = useState(0)
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const [errorModalText, setErrorModalText] = useState(errorModalTexts.invalidData)
  const [processingModalOpen, setProcessingModalOpen] = useState(false)
  const [processingText, setProcessingText] = useState('')
  const [hasUploadedVideo, setHasUploadedVideo] = useState(false)
  const [hasCreatedCampaign, setHasCreatedCampaign] = useState(false)
  const [paymentToken, setPaymentToken] = useState(null)
  const [video, setVideo] = useState(null)
  const [promoCode, setPromoCode] = useState('')
  const [discount, setDiscount] = useState({value: 0, percentage: 0})
  const [discountError, setDiscountError] = useState('')
  const [discountApplied, setDiscountApplied] = useState(0)
  const [totalCostDiscounted, setTotalCostDiscounted] = useState(totalCost)
  const [checkingPromoCode, setCheckingPromoCode] = useState(false)

  const {
    testName,
    startDate,
    timezone,
    questions,
    videoFile,
    audienceGroups,
    testType,
    getSurveyQuestionsObject,
    caseType,
    getMoments,
    checkEditing,
    objectives,
    setStateField,
    setStateFields,
    coreIssue,
  } = useContext(NewTestContext)

  const {hasErrors} = useContext(NewTestErrorContext)

  const isLucidUser = utils.isLucidUser(registeredFrom)

  const isEditing = checkEditing()
  const disablePublishButton = isEditing || hasErrors()

  const calcDiscountApplied = () => {
    const {value, percentage} = discount
    if (value === 0) {
      const totalDiscount = totalCost * 0.01 * percentage
      setDiscountApplied(totalDiscount)
      setTotalCostDiscounted(totalCost - totalDiscount)
    } else if (value > totalCost) {
      setDiscountApplied(totalCost)
      setTotalCostDiscounted(0)
    } else {
      setDiscountApplied(value)
      setTotalCostDiscounted(totalCost - value)
    }
  }

  const validatePromoCode = async event => {
    event.preventDefault()
    setCheckingPromoCode(true)
    const promo = await CampaignApi.getDiscount(promoCode)
    setCheckingPromoCode(false)
    if (promo.error) {
      setDiscountError(promo.error)
      setDiscount({value: 0, percentage: 0})
      return
    }
    setDiscount(promo.discount)
  }

  const clearDiscount = () => {
    setDiscount({value: 0, percentage: 0})
    setPromoCode('')
  }

  useEffect(() => {
    calcDiscountApplied()
  }, [discount, totalCost])

  const getNumberOfCompletes = () => {
    return audienceGroups.reduce((acc, group) => acc + group.totalCompletes, 0)
  }

  const getGroupsCost = () => {
    return audienceGroups.reduce(
      (acc, group) => acc + Math.round(group.groupCost * 10 ** 2) / 10 ** 2,
      0,
    )
  }

  const getNumberOfExtraQuestions = () => {
    return questions.reduce((acc, question) => (question.id ? acc : acc + 1), 0)
  }

  const getSurveyQuestionsPrice = () => {
    const numberOfCompletes = getNumberOfCompletes()
    const numberOfQuestions = getNumberOfExtraQuestions()

    return numberOfCompletes * numberOfQuestions * configs.surveyCost
  }

  const getTestCost = () => {
    const numberOfCompletes = getNumberOfCompletes()
    const totalMinutes = videoFile.duration ? Math.ceil(videoFile.duration / 60) : 0

    const extraMinCost =
      (totalMinutes > configs.minutesIncludedInBaseCost
        ? totalMinutes - configs.minutesIncludedInBaseCost
        : 0) *
      configs.pricePerAdditionalMinute *
      numberOfCompletes

    const extraPanelistCost =
      (numberOfCompletes <= configs.campaignMinimumPanelists
        ? 0
        : numberOfCompletes - configs.campaignMinimumPanelists) * configs.pricePerAdditionalPanelist

    const cost = configs.baseTestCost + extraMinCost + extraPanelistCost

    setTestCost(cost)

    return cost
  }

  const getTotalPrice = () => {
    let total = 0

    if (premiumReport) {
      total += configs.premiumReportCost
    }

    total += getSurveyQuestionsPrice()
    total += getTestCost()

    if (isLucidUser) {
      total += getGroupsCost()
    }

    return total < configs.baseTestCost ? configs.baseTestCost : total
  }

  const convertVideoDuration = () => {
    if (videoFile && videoFile.duration) {
      const {duration} = videoFile
      if (duration > 60) {
        const sec = duration % 60
        const min = (duration - sec) / 60
        return `${Math.ceil(min)} min, ${Math.ceil(sec)} sec`
      }
      return `${Math.ceil(duration)} sec`
    }
    return '0 sec'
  }

  const createCampaignObject = () => {
    let campaignCaseType = null
    let newCampaignVideos = []

    if (testType === testTypes.legal) {
      const foundCaseType = caseTypes.find(({name}) => name === caseType)
      campaignCaseType = foundCaseType?.id
    }

    if (video) {
      newCampaignVideos = [video.id]
    } else if (campaignVideos[0].id) {
      newCampaignVideos = [campaignVideos[0].id]
    } else {
      newCampaignVideos = [campaignVideos[0]]
    }

    return {
      discount: discount.id ? discount.id : null,
      title: testName,
      candidate: testName,
      fromDate: startDate,
      timezone,
      videos: newCampaignVideos,
      campaignType: testType,
      addPremiumReport: premiumReport,
      surveyCost: getSurveyQuestionsPrice(),
      surveyQuestions: getSurveyQuestionsObject(),
      caseType: campaignCaseType,
      moments: getMoments(),
      targetAudiences: audienceGroups,
      objectives: objectives.filter(({status}) => status).map(({id}) => id),
      coreIssue,
    }
  }

  useEffect(() => {
    setStateFields(
      [contextFields.startDate, contextFields.timezone],
      [new Date(), Intl.DateTimeFormat().resolvedOptions().timeZone],
    )
  }, [])

  useEffect(() => {
    setTotalCost(getTotalPrice())
  }, [questions, premiumReport, videoFile, audienceGroups])

  useEffect(() => {
    setCreditsApplied(useCredits ? Math.min(totalCostDiscounted, credits) : 0)
  }, [totalCostDiscounted, credits, useCredits])

  useEffect(() => {
    setBalanceDue(totalCostDiscounted - creditsApplied)
  }, [totalCostDiscounted, creditsApplied])

  const handlePublish = token => {
    if (isEditing) {
      setErrorModalText(errorModalTexts.unsavedData)
      openModal('error')
    } else if (hasErrors()) {
      setErrorModalText(errorModalTexts.invalidData)
      openModal('error')
    } else {
      openModal('process')

      if (!videoFile.id) {
        uploadVideo(videoFile)
        setProcessingText('Video Is Uploading')
        setHasUploadedVideo(true)
      } else {
        setVideo(videoFile)
      }

      setPaymentToken(token)
    }
  }

  useEffect(() => {
    if (hasUploadedVideo || video) {
      const campaign = createCampaignObject()
      createCampaign(campaign)
      setProcessingText('Campaign Is Being Created')
      setHasCreatedCampaign(true)
      if (discount && discount.id) {
        CampaignApi.applyTestDiscount(discount.id)
      }
      setHasUploadedVideo(false)
      setVideo(null)
    }
  }, [campaignVideos, video])

  useEffect(() => {
    if (hasCreatedCampaign) {
      const options = creditsApplied > 0 ? {applyCredits: true} : {}

      if (!paymentToken) {
        // publish
        publishCampaign(campaign.id, options)
      } else {
        // pay
        campaignPayment(campaign.id, {paymentData: paymentToken}, options)
      }

      setHasCreatedCampaign(false)
      setProcessingText('Processing Payment')
      getProfile()
    }
  }, [campaign])

  useEffect(() => {
    if (processingText === 'Processing Payment') {
      if (paymentStatus === 'success') {
        tagManager.fireEvent(eventTypes.placePurchase, {purchase_amount: balanceDue})
        setProcessingText('Payment Successful')
        setStateField(contextFields.paymentProcessed, true)
      } else setProcessingText('Processing Failed')
    }
  }, [paymentStatus])

  useUpdateEffect(() => {
    if (!/^Request req_.*/.test(errors[0])) {
      setErrorModalText(errorModalTexts.invalidData)
      openModal('error')
      closeModal('process')
      setProcessingText('')
    }
  }, [errors])

  const modalTable = {
    error: value => setErrorModalOpen(value),
    process: value => setProcessingModalOpen(value),
  }

  const openModal = modalKey => {
    modalTable[modalKey](true)
  }

  const closeModal = modalKey => {
    modalTable[modalKey](false)
  }

  const handleFailure = () => {
    closeModal('process')
  }

  const showProcessingFeedback = () => {
    if (processingText === 'Processing Failed') {
      if (!_.isEmpty(errors)) {
        return (
          <>
            <ErrorIcon className={classes.failModalIcon} />
            <Typography className={classes.successModalHeader} variant="body1">
              {processingText}
            </Typography>
            <Typography className={classes.successModalText} variant="body1">
              {errors[0].replace(/^(Request.*\:\ )/, '')}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.successModalButton}
              onClick={handleFailure}
            >
              Try Again
            </Button>
          </>
        )
      }
    }

    if (processingText === 'Payment Successful') {
      return (
        <>
          <CheckCircleIcon className={classes.successModalIcon} />
          <Typography className={classes.successModalHeader} variant="body1">
            {processingText}
          </Typography>
          <Typography className={classes.successModalText} variant="body1">
            We are selecting your audience and will notify you when the test is published.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.successModalButton}
            onClick={() => router.push('/')}
          >
            Go To My Tests
          </Button>
        </>
      )
    }

    return (
      <>
        <Loading percentCompleted={uploadProgress} />
        <Typography className={classes.invalidDataModalText} variant="body1">
          {processingText}
        </Typography>
      </>
    )
  }

  const renderTooltipText = () => {
    return (
      <div>
        <a
          href="https://creative.emotiontrac.com/pricing/"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.tooltipContent}
        >
          <span style={{fontWeight: 900, textDecoration: 'underline'}}>Click here</span> to view
          more pricing information
        </a>
      </div>
    )
  }

  const renderDivider = () => {
    return <Divider style={{margin: '16px 0', backgroundColor: '#292d3214'}} />
  }

  return (
    <Grid item lg={3} md={6} sm={12} className={classes.orderSummarySection}>
      <Dialog
        PaperProps={{classes: {root: classes.invalidDataModalBody}}}
        open={errorModalOpen}
        onClose={() => closeModal('error')}
      >
        <WarningIcon color="secondary" fontSize="large" />
        <Typography className={classes.invalidDataModalText} variant="body1">
          {errorModalText}
        </Typography>
        <Button
          className={classes.invalidDataModalButton}
          variant="contained"
          color="primary"
          fullWidth={false}
          onClick={() => closeModal('error')}
        >
          Update
        </Button>
      </Dialog>
      <Dialog
        PaperProps={{classes: {root: classes.invalidDataModalBody}}}
        open={processingModalOpen}
      >
        {showProcessingFeedback()}
      </Dialog>

      <Grid className={classes.testInfoSection}>
        <Typography
          variant="h6"
          className={classes.sectionTitle}
          style={{position: 'relative', width: 'fit-content'}}
        >
          Order Summary
          <InfoTooltip text={renderTooltipText()} placement="top" interactive />
        </Typography>

        <Grid>
          <Grid container className={classes.costItem}>
            <Typography variant="h6" className={classes.sectionSubtitle}>
              Base Cost
            </Typography>
            <Typography variant="body1" className={classes.cost}>
              {utils.formatValue(testCost)}
            </Typography>
          </Grid>
          <Grid container className={classes.costItem}>
            <Typography variant="h6" className={classes.sectionDetails}>
              Video Length
            </Typography>
            <Typography
              variant="body1"
              className={`${classes.sectionDetails} ${classes.sectionDetailsValue}`}
            >
              {convertVideoDuration()}
            </Typography>
          </Grid>
          <Grid container className={classes.costItem}>
            <Typography variant="h6" className={classes.sectionDetails}>
              Total Panelists
            </Typography>
            <Typography
              variant="body1"
              className={`${classes.sectionDetails} ${classes.sectionDetailsValue}`}
            >
              {getNumberOfCompletes()}
            </Typography>
          </Grid>
        </Grid>

        {isLucidUser && (
          <Grid>
            <Grid container className={classes.costItem}>
              <Typography variant="h6" className={classes.sectionSubtitle}>
                Custom Audience
              </Typography>
              <Typography variant="body1" className={classes.cost}>
                {utils.formatValue(getGroupsCost())}
              </Typography>
            </Grid>
            {audienceGroups.map(group => (
              <Grid container className={classes.costItem}>
                <Typography variant="h6" className={classes.sectionDetails}>
                  {group.name}
                </Typography>
                <Typography
                  variant="body1"
                  className={`${classes.sectionDetails} ${classes.sectionDetailsValue}`}
                >
                  {utils.formatValue(group.groupCost)}
                </Typography>
              </Grid>
            ))}
          </Grid>
        )}

        <Grid>
          <Grid container className={classes.costItem}>
            <Typography variant="h6" className={classes.sectionSubtitle}>
              Custom Survey
            </Typography>
            <Typography variant="body1" className={classes.cost}>
              {utils.formatValue(getSurveyQuestionsPrice())}
            </Typography>
          </Grid>
          <Grid container className={classes.costItem}>
            <Typography variant="h6" className={classes.sectionDetails}>
              Total Questions
            </Typography>
            <Typography
              variant="body1"
              className={`${classes.sectionDetails} ${classes.sectionDetailsValue}`}
            >
              {getNumberOfExtraQuestions()}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {renderDivider()}

      <Grid className={classes.premiumReportSection}>
        <Grid container className={classes.costItem}>
          <Typography variant="h6" className={classes.sectionSubtitle}>
            Premium Report
            <InfoTooltip
              text={
                <p>
                  Premium Reports include a pre-test call with an Insights Manager to discuss test
                  objectives.
                  <br />
                  <br />
                  The post-test delivery includes a detailed analysis and written report that the
                  analyst will review with you via Zoom.
                </p>
              }
              placement="top"
            />
          </Typography>
          <Typography variant="body1" className={classes.cost}>
            {utils.formatValue(configs.premiumReportCost || 1200)}
          </Typography>
        </Grid>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              color="primary"
              checked={premiumReport}
              onChange={() => setPremiumReport(!premiumReport)}
              classes={{root: classes.checkboxRoot}}
            />
          }
          label="Add a premium report"
          classes={{root: classes.formControlLabelRoot, label: classes.formControlLabel}}
        />
      </Grid>

      {renderDivider()}

      <Grid className={classes.promoCodeSection}>
        <Typography
          variant="h6"
          className={classes.sectionSubtitle}
          style={{lineHeight: '16px', marginBottom: '12px'}}
        >
          Apply Promo Code
        </Typography>
        <form style={{marginBottom: 0}} onSubmit={validatePromoCode}>
          <Grid
            container
            className={classes.costItem}
            style={{display: 'flex', alignItems: 'flex-start'}}
          >
            <Grid style={{flex: 1, display: 'flex'}}>
              <TextField
                error={Boolean(discountError)}
                helperText={discountError}
                value={promoCode}
                onChange={({target}) => {
                  setDiscountError('')
                  setPromoCode(target.value)
                }}
                variant="outlined"
                placeholder="Promo code"
                style={{flex: 1}}
                className={classes.promoCodeInput}
              />
            </Grid>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{marginLeft: '8px', height: '40px'}}
              onClick={validatePromoCode}
            >
              {checkingPromoCode ? (
                <CircularProgress style={{color: 'white'}} size={16} />
              ) : (
                'Apply'
              )}
            </Button>
          </Grid>
        </form>
        {discount.code ? (
          <Button
            onClick={() => clearDiscount()}
            variant="contained"
            color="primary"
            style={{
              color: '#414347',
              fontSize: '14px',
              lineHeight: '14px',
              background: '#E6E8EC',
              width: 'fit-content',
              fontWeight: 600,
              padding: '8.5px 12px',
              marginTop: '8px',
            }}
          >
            {discount.code}
            <CloseIcon className={classes.closeIcon} />
          </Button>
        ) : null}
      </Grid>

      {renderDivider()}

      <Grid className={classes.creditsSection}>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              color="primary"
              checked={useCredits}
              onChange={() => setUseCredits(!useCredits)}
              classes={{root: classes.checkboxRoot}}
            />
          }
          label="Apply credits for payment"
          classes={{root: classes.formControlLabelRoot, label: classes.formControlLabel}}
        />
        <Typography variant="body1" className={classes.formControlLabel}>
          You have
          <span className={classes.creditsSpan}>{` ${utils.formatValue(credits)} `}</span>
          credits available
        </Typography>
      </Grid>

      {renderDivider()}

      <Grid className={classes.totalSection}>
        <Grid container className={classes.costItem}>
          <Typography variant="h6" className={classes.total}>
            Total
          </Typography>
          <Typography variant="body1" className={classes.total} style={{marginLeft: 'auto'}}>
            {utils.formatValue(totalCost)}
          </Typography>
        </Grid>
        <Grid container className={classes.costItem}>
          <Typography variant="h6" className={classes.totalSummary}>
            Promo Discount
          </Typography>
          <Typography variant="body1" className={classes.totalSummaryValue}>
            {utils.formatValue(discountApplied)}
          </Typography>
        </Grid>
        <Grid container className={classes.costItem}>
          <Typography variant="h6" className={classes.totalSummary}>
            Credits Applied
          </Typography>
          <Typography variant="body1" className={classes.totalSummaryValue}>
            {utils.formatValue(creditsApplied)}
          </Typography>
        </Grid>
        <Grid container className={classes.costItem}>
          <Typography variant="h6" className={classes.totalSummary}>
            Balance Due
          </Typography>
          <Typography variant="body1" className={classes.totalSummaryValue}>
            {utils.formatValue(balanceDue)}
          </Typography>
        </Grid>
      </Grid>

      {balanceDue > 0 ? (
        <Tooltip
          classes={{tooltip: classes.orderButtonTooltip}}
          placement="top"
          title="Save all your changes or fix the errors to proceed with publishing your test."
          disableHoverListener={!disablePublishButton}
          disableFocusListener
          disableTouchListener
        >
          <span style={{display: 'block', width: '100%'}}>
            <StripeCheckout
              stripeKey={settings.stripeKey}
              amount={balanceDue * 100}
              token={token => {
                const ReactPixel = require('react-facebook-pixel').default
                ReactPixel.track('Purchase', {currency: 'USD', value: balanceDue})
                handlePublish(token)
              }}
              locale="auto"
              name="EmotionTrac"
              ComponentClass="div"
              style={{width: '100%'}}
              disabled={disablePublishButton}
            >
              <Button
                variant="contained"
                color="primary"
                style={{width: '100%'}}
                onClick={e => {
                  if (disablePublishButton) {
                    e.stopPropagation()
                    setErrorModalText(errorModalTexts.unsavedData)
                    openModal('error')
                  }
                }}
                disabled={disablePublishButton}
              >
                Place Order
              </Button>
            </StripeCheckout>
          </span>
        </Tooltip>
      ) : (
        <Tooltip
          classes={{tooltip: classes.orderButtonTooltip}}
          placement="top"
          title="Save all your changes or fix the errors to proceed with publishing your test."
          disableHoverListener={!disablePublishButton}
          disableFocusListener
          disableTouchListener
        >
          <span style={{display: 'block', width: '100%'}}>
            <Button
              variant="contained"
              color="primary"
              style={{width: '100%'}}
              onClick={() => handlePublish()}
              disabled={disablePublishButton}
            >
              Publish
            </Button>
          </span>
        </Tooltip>
      )}
    </Grid>
  )
}

const mapStateToProps = ({profile, campaign}) => ({
  credits: profile?.profile?.totalCredits,
  registeredFrom: profile?.profile?.registeredFrom,
  campaignVideos: campaign.campaign.videos,
  caseTypes: campaign.caseTypes.results,
  campaign: campaign.campaign,
  errors: campaign.errors,
  uploadProgress: campaign.uploadProgress,
  paymentStatus: campaign.paymentStatus,
  configs: campaign.configs,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createCampaign,
      uploadVideo,
      campaignPayment,
      publishCampaign,
      getProfile,
    },
    dispatch,
  )

OrderSummary.defaultProps = {
  credits: 0,
  configs: {
    premiumReportCost: 1200,
    pricePerAdditionalMinute: 6,
    pricePerMinute: 6,
    surveyCost: 1.2,
    testCost: 600,
    campaignAudienceTargetingMinimumValue: 100,
    priceForAdditionalPannelists: 6,
  },
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(OrderSummary)
