import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import {action as toggleMenu} from 'redux-burger-menu'
import {
  AppBar,
  Avatar,
  Toolbar,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  IconButton,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import {Link} from 'react-router'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {logout} from '../auth/redux/actions'

import burgerIcon from '../../assets/icons/burger.svg'

const styles = theme => ({
  navbar: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    padding: `${theme.spacing.unit} ${theme.spacing.unit * 3}`,
    transition: theme.transitions.create('padding-left', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  navbarDropdown: {
    zIndex: 1,
  },
  profileInfoContainer: {
    width: 'max-content',
  },
  profileInfo: {
    padding: `0 ${theme.spacing.unit * 2}`,
  },
  name: {
    fontWeight: '500',
    color: '#292d32',
  },
  roleInfo: {
    color: '#292d32b3',
    fontSize: '0.75rem',
  },
  profilePicture: {
    border: '2px solid rgba(52, 180, 235, 0.5)',
  },
})

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searchText: '',
      profileMenuOpened: false,
    }

    this.logout = this.logout.bind(this)
    this.handleProfileMenuOpen = this.handleProfileMenuOpen.bind(this)
    this.handleProfileMenuClose = this.handleProfileMenuClose.bind(this)
  }

  componentWillMount() {
    this.setState({searchText: this.props.searchText})
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.searchText !== nextProps.searchText) {
      this.setState({searchText: nextProps.searchText})
    }
  }

  handleProfileMenuOpen() {
    this.setState({profileMenuOpened: true})
  }

  handleProfileMenuClose() {
    this.setState({profileMenuOpened: false})
  }

  logout() {
    this.props.logout()
  }

  onSearch(keyEvent) {
    const key = keyEvent.which || keyEvent.keyCode

    if (key === 13) {
      const {searchText} = this.state

      const query = {q: searchText}
      this.context.router.push({pathname: '/', query})
    }
  }

  render() {
    const {props} = this
    const {classes, sidebarWidth, toggleSidebar, isSidebarVisible} = props
    const profile = _.get(props, 'profile')

    let profileText = ''
    if (profile) {
      profileText = profile.name ? profile.name : profile.email
    }

    return (
      <AppBar
        className={classes.navbar}
        position="fixed"
        style={{paddingLeft: `${isSidebarVisible ? sidebarWidth + 24 : 24}px`}}
      >
        <Toolbar disableGutters>
          <Grid container>
            <Popper
              anchorEl={this.profileButtonRef}
              open={this.state.profileMenuOpened}
              transition
              disablePortal
              className={classes.navbarDropdown}
            >
              {({TransitionProps, placement}) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={this.handleProfileMenuClose}>
                      <MenuList>
                        <MenuItem button={false}>{profileText}</MenuItem>
                        <Divider />
                        <MenuItem
                          onClick={this.handleProfileMenuClose}
                          component={Link}
                          to="/profile"
                        >
                          View Profile
                        </MenuItem>
                        <MenuItem
                          onClick={e => {
                            this.handleProfileMenuClose(e)
                            this.logout(e)
                          }}
                        >
                          Logout
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <Grid item>
              <IconButton onClick={toggleSidebar}>
                <img src={burgerIcon} alt="menu" />
              </IconButton>
            </Grid>
            <Grid item xs />
            {profile && (
              <Grid container className={classes.profileInfoContainer} alignItems="center">
                <Grid item>
                  <Avatar
                    alt="Profile Picture"
                    src={profile.avatar.small}
                    className={classes.profilePicture}
                  />
                </Grid>
                <Grid item xs className={classes.profileInfo}>
                  <Grid item xs={12}>
                    <Typography className={classes.name}>
                      {`${profile.firstName} ${profile.lastName}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography classes={{root: classes.roleInfo}} className={classes.roleInfo}>
                      {profile.email}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid>
                  <IconButton
                    buttonRef={n => {
                      this.profileButtonRef = n
                    }}
                    onClick={this.handleProfileMenuOpen}
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    )
  }
}

Header.displayName = 'Header'
Header.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
  profile: PropTypes.objectOf(PropTypes.any),
  onHomeClick: PropTypes.func.isRequired,
}
Header.contextTypes = {
  router: PropTypes.objectOf(PropTypes.any).isRequired,
}
Header.defaultProps = {
  profile: {},
}

function mapStateToProps(state) {
  return {searchText: _.get(state, 'home.query.q', '')}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({toggleMenu, logout}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header))
