import {createContext} from 'react'

import pageUtil from '../../utils/PageUtils'

import {defaultTimezone, testTypes} from '../../constants/NewTest'

export const INITIAL_STATE = {
  timezone: defaultTimezone,
  startDate: pageUtil.getDatetimeInTimeZone(defaultTimezone),
  videoFile: {},
  csvFile: null,
  testName: '',
  testType: testTypes.creative,
  audienceGroups: [],
  questions: [],
  caseType: '',
  objectives: [],
  moments: [],
  paymentProcessed: false,
  coreIssue: '',
}

const NewTestContext = createContext(INITIAL_STATE)

export default NewTestContext
