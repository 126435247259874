import numeral from 'numeral'

export function getDisplayAmount(amount, symbol = '$') {
  const displayAmount = Number(amount)
  return numeral(displayAmount)
    .format(`${symbol}0.00a`)
    .toUpperCase()
}

export function currencyFormat(amount, locale = 'en-US') {
  const options = {
    style: 'currency',
    currency: 'USD',
  }

  const formatter = new Intl.NumberFormat(locale, options)
  return formatter.format(amount)
}
