export default class ShareUtils {
  static showShareWindow(url) {
    if (window && (typeof ShareUtils.shareWinRef === 'undefined' || this.shareWinRef.closed)) {
      ShareUtils.shareWinRef = window.open(
        url,
        'sharewin',
        'left=20,top=20,width=500,height=400,toolbar=1,resizable=0',
      )
    } else {
      ShareUtils.shareWinRef.location.href = url
      ShareUtils.shareWinRef.focus()
    }
  }

  static getPinterestShareUrl(url, imageUrl, description) {
    return `//www.pinterest.com/pin/create/button/?url=${url}&media=${imageUrl}&description=${description}`
  }

  static getTwitterShareUrl(url, text, hashtags) {
    return `//twitter.com/intent/tweet?text=${text}&hashtags=${hashtags}&url=${url}`
  }

  static getFbShareUrl(url) {
    return '//www.facebook.com/sharer/sharer.php?u=' + url
  }

  static getGooglePlusShareUrl(url) {
    return '//plus.google.com/share?url=' + url
  }

  static getLinkedinShareUrl(url, title, summary, source) {
    return `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${summary}&source=${source}`
  }

  static getTumblrShareUrl(url, title, tags, image, caption) {
    return `https://tumblr.com/share/?title=${title}&tags=${tags}&content=${image}&caption=${caption}&show-via=FundThis&canonicalUrl=${url}`
  }

  static getCostByListSize(list, paymentInfo) {
    let selectInfo = null
    for (const info of paymentInfo) {
      if (list.size >= info.minimum) {
        selectInfo = info
        return selectInfo
      }
    }
    if (selectInfo === null) {
      selectInfo = paymentInfo[paymentInfo.length - 1]
    }
    return selectInfo
  }

  static getTotalCostPerUser(campaign, list, paymentInfo) {
    const paymentConfig = this.getCostByListSize(list, paymentInfo)
    let totalCost = 0
    if (paymentConfig) {
      const {videos} = campaign
      videos.forEach(video => {
        if (video.duration) {
          totalCost += video.duration * paymentConfig.amountPerSecond
        }
      })
    }

    return totalCost
  }

  static applyDiscount(totalAmount, discount) {
    if (!discount || totalAmount < 1) {
      return parseFloat(totalAmount)
    }

    if (discount.value) {
      return parseFloat(totalAmount) - discount.value
    }
    const dicsAmount = (discount.percentage / 100) * parseFloat(totalAmount)
    return parseFloat(totalAmount) - dicsAmount
  }

  static gettotalCost(campaign, selectedList, paymentConfig) {
    let totalCost = 0
    selectedList.forEach(list => {
      const totalCostPerUser = ShareUtils.getTotalCostPerUser(campaign, list, paymentConfig)
      totalCost += totalCostPerUser * list.size
    })
    return totalCost
  }
}
