import React, {useEffect, useState, useContext, useRef} from 'react'
import PropTypes from 'prop-types'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {
  Grid,
  Paper,
  Step,
  Stepper,
  StepLabel,
  StepConnector,
  Typography,
  withStyles,
  withWidth,
} from '@material-ui/core'

import ObjectivesMoments from './TestSetup/ObjectivesMoments'
import TestSetup from './TestSetup'
import SurveySetup from './SurveySetup'
import AudienceSetup from './AudienceSetup'
import OrderSummary from './OrderSummary'
import EmptyTab from './EmptyTab'

import NewTestContext from './context/NewTestContext'

import {getVideos, getCampaignConfigs, getCaseTypes} from './redux/actions'

import {steps, tabCount, stepIndexes} from '../constants/NewTest'

import styles from './styles/NewTest.styles'

const NewTest = props => {
  const {
    classes,
    getVideos,
    availableVideos,
    isFetching,
    width,
    router,
    route,
    getCampaignConfigs,
    getCaseTypes,
  } = props

  const {showingObjectivesMoments, hideObjectivesMoments, paymentProcessed} = useContext(
    NewTestContext,
  )

  const unregisterRouteHook = useRef()

  const [activeStep, setActiveStep] = useState(stepIndexes.testSetup)
  const [hookMounted, setHookMounted] = useState(false)

  useEffect(() => {
    getVideos()
    getCampaignConfigs()
    getCaseTypes()

    return () => {
      window.onbeforeunload = null
      if (typeof unregisterRouteHook.current === 'function') unregisterRouteHook.current()
    }
  }, [])

  useEffect(() => {
    if (paymentProcessed && hookMounted) {
      setHookMounted(false)
      unregisterRouteHook.current()
      window.onbeforeunload = null
    } else if (!paymentProcessed && !hookMounted) {
      setHookMounted(true)
      unregisterRouteHook.current = router.setRouteLeaveHook(route, routerWillLeave)
      window.onbeforeunload = () => true
    }
  }, [paymentProcessed])

  const routerWillLeave = () => {
    if (global.confirm('Leave test creation? Changes you made may not be saved')) {
      return true
    }

    window.history.pushState(null, null, route.path)

    return false
  }

  const renderEmptyTabs = () => {
    const tabs = []

    for (let i = 0; i < tabCount[width] - activeStep; i++) tabs.push(<EmptyTab key={i} />)

    return tabs
  }

  const renderSteps = () => {
    const stepComponents = [
      <TestSetup
        key={stepIndexes.testSetup}
        isActive={activeStep === stepIndexes.testSetup}
        availableVideos={availableVideos}
        isFetching={isFetching}
        nextStep={() => setActiveStep(stepIndexes.survey)}
        router={router}
      />,
      <SurveySetup
        key={stepIndexes.survey}
        isActive={activeStep === stepIndexes.survey}
        nextStep={() => setActiveStep(stepIndexes.audience)}
      />,
      <AudienceSetup
        key={stepIndexes.audience}
        isActive={activeStep === stepIndexes.audience}
        nextStep={() => setActiveStep(stepIndexes.order)}
      />,
      <OrderSummary
        key={stepIndexes.order}
        isActive={activeStep === stepIndexes.order}
        router={router}
      />,
    ]

    return stepComponents.slice(0, activeStep + 1)
  }

  const connector = (
    <StepConnector
      classes={{
        active: classes.connectorActive,
        completed: classes.connectorCompleted,
        disabled: classes.connectorDisabled,
        line: classes.connectorLine,
      }}
    />
  )

  if (showingObjectivesMoments) {
    return <ObjectivesMoments hideObjectivesMoments={hideObjectivesMoments} />
  }

  return (
    <Grid container className={classes.newTestContainer}>
      <Paper className={classes.newTestPaper} elevation={0}>
        <Grid container className={classes.newTestHeader}>
          <Grid item xs>
            <Typography variant="h6" className={classes.newTestTitle}>
              Create New Test
            </Typography>
          </Grid>
          <Grid item xs>
            <Stepper activeStep={activeStep} connector={connector}>
              {steps.map(step => (
                <Step key={step}>
                  <StepLabel
                    classes={{
                      active: classes.labelActive,
                      completed: classes.labelCompleted,
                      disabled: classes.labelDisabled,
                    }}
                  >
                    {step}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
        <Grid container className={classes.newTestBody}>
          {renderSteps()}
          {renderEmptyTabs()}
        </Grid>
      </Paper>
    </Grid>
  )
}

NewTest.propTypes = {
  availableVideos: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  getVideos: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  width: PropTypes.string.isRequired,
}

const mapStateToProps = ({campaign}) => ({
  isFetching: campaign.isFetching,
  availableVideos: campaign.availableVideos,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getVideos,
      getCampaignConfigs,
      getCaseTypes,
    },
    dispatch,
  )

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withWidth(),
)(NewTest)
