import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {Grid, Typography, withStyles} from '@material-ui/core'

import useModalState from '../../hooks/useModalState'

import VerticalChart from './VerticalChart'
import Loading from '../common/Loading'
import NoDataAvailable from '../common/NoDataAvailable'
import InfoTooltip from '../common/InfoTooltip'

import styles from './styles/VerticalChart.styles'

let Chart = null

const VerticalCharts = ({classes, tabScoreResults, isFetching, filterText}) => {
  if (!Chart) Chart = require('react-apexcharts').default

  const [isTrustTooltipOpen, openTrustTooltip, closeTrustTooltip] = useModalState(false)
  const [isAttentionTooltipOpen, openAttentionTooltip, closeAttentionTooltip] = useModalState(false)
  const [
    isBelievabilityTooltipOpen,
    openBelievabilityTooltip,
    closeBelievabilityTooltip,
  ] = useModalState(false)

  const {tabscorexaxis, tabscoreyaxis} = tabScoreResults

  if (isFetching) return <Loading />

  return (
    <Grid container className={classes.chartsContainer}>
      {_.isEmpty(tabscoreyaxis) || _.isEmpty(tabscorexaxis) ? (
        <>
          {filterText && (
            <Grid item xs={12} className={classes.filterTextContainer}>
              <Typography className={classes.filterText}>{filterText}</Typography>
            </Grid>
          )}
          <NoDataAvailable />
        </>
      ) : (
        <>
          {filterText && (
            <Grid item xs={12}>
              <Typography className={classes.filterText}>{filterText}</Typography>
            </Grid>
          )}
          <Grid item xs={6} lg={4}>
            <Grid container justify="center">
              <Grid container className={classes.textContainer}>
                <Typography className={classes.chartTitle}>Trust</Typography>
                <Typography
                  className={classes.chartBenchmark}
                  onMouseEnter={openTrustTooltip}
                  onMouseLeave={closeTrustTooltip}
                >
                  {`Benchmark = ${tabScoreResults.benchmarks.trust}`}
                  <InfoTooltip
                    open={isTrustTooltipOpen}
                    style={{fontSize: '14px'}}
                    text=""
                    // text="-- ask client for text --"
                  />
                </Typography>
                <Typography className={classes.chartDescription}>
                  How trusting the audience finds the content.
                </Typography>
              </Grid>
              <VerticalChart
                xaxis={tabscorexaxis.trust}
                yaxis={tabscoreyaxis.trust}
                Chart={Chart}
              />
            </Grid>
          </Grid>
          <Grid item xs={6} lg={4}>
            <Grid container justify="center">
              <Grid container className={classes.textContainer}>
                <Typography className={classes.chartTitle}>Attention</Typography>
                <Typography
                  className={classes.chartBenchmark}
                  onMouseEnter={openAttentionTooltip}
                  onMouseLeave={closeAttentionTooltip}
                >
                  {`Benchmark = ${tabScoreResults.benchmarks.attention}`}
                  <InfoTooltip
                    open={isAttentionTooltipOpen}
                    style={{fontSize: '14px'}}
                    text=""
                    // text="-- ask client for text --"
                  />
                </Typography>
                <Typography className={classes.chartDescription}>
                  How much attention the audience had with the content.
                </Typography>
              </Grid>
              <VerticalChart
                xaxis={tabscorexaxis.attention}
                yaxis={tabscoreyaxis.attention}
                Chart={Chart}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Grid container justify="center">
              <Grid container className={classes.textContainer}>
                <Typography className={classes.chartTitle}>Believability</Typography>
                <Typography
                  className={classes.chartBenchmark}
                  onMouseEnter={openBelievabilityTooltip}
                  onMouseLeave={closeBelievabilityTooltip}
                >
                  {`Benchmark = ${tabScoreResults.benchmarks.believability}`}
                  <InfoTooltip
                    open={isBelievabilityTooltipOpen}
                    style={{fontSize: '14px'}}
                    text=""
                    // text="-- ask client for text --"
                  />
                </Typography>
                <Typography className={classes.chartDescription}>
                  How believable the audience found the content.
                </Typography>
              </Grid>
              <VerticalChart
                xaxis={tabscorexaxis.believability}
                yaxis={tabscoreyaxis.believability}
                Chart={Chart}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}

VerticalCharts.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  tabScoreResults: PropTypes.objectOf(PropTypes.any).isRequired,
  isFetching: PropTypes.bool.isRequired,
  filterText: PropTypes.string.isRequired,
}

export default withStyles(styles)(VerticalCharts)
