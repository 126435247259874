import React, {useContext, useState} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Collapse,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  withStyles,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import InfoTooltip from '../../../common/InfoTooltip'

import {currencyFormat} from '../../../utils/MoneyUtils'

import styles from './styles'
import NewTestErrorContext from '../../context/NewTestErrorContext'

const AudienceGroupCard = ({classes, group, editGroup, deleteGroup, showGroupCost}) => {
  const {audienceGroupErrors} = useContext(NewTestErrorContext)

  const [isCollapsed, setIsCollapsed] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [menuAnchor, setMenuAnchor] = useState(null)
  const [isErrorTooltipOpen, setIsErrorTooltipOpen] = useState(false)

  const hasError = audienceGroupErrors[group.id]

  const openMenu = anchor => {
    setMenuAnchor(anchor)
    setIsMenuOpen(true)
  }

  const closeMenu = () => {
    setMenuAnchor(null)
    setIsMenuOpen(false)
  }

  const handleMenuClick = e => {
    e.stopPropagation()
    openMenu(e.currentTarget)
  }

  const handleMenuClose = e => {
    e.stopPropagation()
    closeMenu()
  }

  const handleGroupDeletion = e => {
    e.stopPropagation()
    deleteGroup()
    closeMenu()
  }

  const handleGroupEdit = e => {
    e.stopPropagation()
    editGroup()
    closeMenu()
  }

  const getSubDemographicLabels = demographic => {
    const result = []

    for (let i = 0; i < demographic.subDemographics.length; i++) {
      const subDemographic = demographic.subDemographics[i]

      result.push(subDemographic.displayName)
    }

    return result.join(', ')
  }

  return (
    <Grid className={classes.card}>
      <Grid
        className={clsx(classes.header, hasError ? classes.audienceCardError : '')}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <Grid className={classes.collapseIcon}>
          {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </Grid>

        <Typography className={classes.groupName}>{group.name}</Typography>

        <IconButton classes={{root: classes.menuButton}} onClick={handleMenuClick}>
          <MoreHorizIcon className={classes.menuIcon} />
        </IconButton>

        <Menu
          open={isMenuOpen}
          onClose={handleMenuClose}
          anchorEl={menuAnchor}
          PaperProps={{
            style: {
              transform: 'translateX(10px) translateY(50px)',
            },
          }}
        >
          <MenuItem onClick={handleGroupEdit}>
            <EditIcon className={`${classes.actionIcon} ${classes.editIcon}`} />
            View/Edit
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleGroupDeletion}>
            <DeleteIcon className={`${classes.actionIcon} ${classes.deleteIcon}`} />
            Delete
          </MenuItem>
        </Menu>
      </Grid>

      <Collapse in={!isCollapsed}>
        <Grid className={clsx(classes.body, hasError ? classes.audienceCardBodyError : '')}>
          {group.demographics.map((demographic, index) => (
            <Grid key={index} className={classes.demographicDetails}>
              <Typography className={classes.demographicLabel}>{demographic.label}:</Typography>
              <Typography className={classes.subDemographics}>
                {getSubDemographicLabels(demographic)}
              </Typography>
            </Grid>
          ))}
          {showGroupCost && (
            <Grid className={`${classes.demographicDetails} ${classes.groupCostDetails}`}>
              <Typography className={classes.demographicLabel}>Cost:</Typography>
              <Typography className={classes.subDemographics}>
                {currencyFormat(group.groupCost)}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Collapse>

      {hasError ? (
        <Grid className={classes.audienceCardErrorContainer}>
          <Typography style={{color: 'white', marginRight: 8, fontWeight: 600}}>
            Please Edit Selections
          </Typography>
          <InfoTooltip
            text={
              <span>
                Unfortunately, your current selections can not be fulfilled. Please refine your
                selections and/or change days in field to ensure delivery.
              </span>
            }
            placement="right"
            open={isErrorTooltipOpen}
            iconProps={{style: {position: 'initial', color: 'white'}}}
            onOpen={() => setIsErrorTooltipOpen(true)}
            onClose={() => setIsErrorTooltipOpen(false)}
          />
        </Grid>
      ) : (
        <Grid className={classes.panelistsContainer}>
          <Typography>{group.totalCompletes} Panelists</Typography>
        </Grid>
      )}
    </Grid>
  )
}

AudienceGroupCard.defaultProps = {
  showGroupCost: false,
}

AudienceGroupCard.propTypes = {
  group: PropTypes.objectOf(PropTypes.any).isRequired,
  editGroup: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  showGroupCost: PropTypes.bool,
}

export default withStyles(styles)(AudienceGroupCard)
