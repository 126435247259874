import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router'
import Helmet from 'react-helmet'
import {Typography, Grid, Button, withStyles, TextField, CircularProgress} from '@material-ui/core'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import InputValidator from '../common/InputValidator.jsx'
import validatedComponent from '../common/ValidatedComponent.jsx'
import * as Actions from './redux/actions.forgotPw'
import styles from '../common/styles/CardLayout.styles'
import checkCircle from '../../assets/icons/checkCircle.svg'

class ForgotPasswordConsume extends React.Component {
  constructor() {
    super()

    this.state = {
      password: '',
      confirmPassword: '',
    }
    this.validatePasswords = this.validatePasswords.bind(this)
  }

  componentDidMount() {
    if (!this.props.params.token) {
      this.context.router.replace('/forgot')
    }
    const ReactPixel = require('react-facebook-pixel').default
    ReactPixel.pageView()
  }

  validatePasswords() {
    const {password, confirmPassword} = this.state
    if (password !== confirmPassword) {
      return 'Both passwords must match'
    }
  }

  onResetClick(e) {
    e.preventDefault()

    const {
      isComponentValid,
      resetForgotPw,
      params: {token},
    } = this.props
    if (isComponentValid()) {
      resetForgotPw(token, this.state.password)
    }
  }

  renderForgotPasswordConsume(password, confirmPassword, isReset, classes, isFetching) {
    if (!isReset) {
      return (
        <>
          <Typography gutterBottom className={classes.title}>
            Reset password
          </Typography>
          <form noValidate>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <InputValidator
                  shouldValidateOnBlur={true}
                  customValidation={() => {
                    return this.validatePasswords()
                  }}
                >
                  <TextField
                    required
                    value={password}
                    placeholder="Enter new password"
                    id="outlined-password-input"
                    onChange={e => this.setState({password: e.target.value})}
                    label="Enter new password"
                    className={classes.inputSignupAndLogin}
                    type="password"
                    autoComplete="current-password"
                    variant="outlined"
                    margin="dense"
                    style={{height: 38}}
                  />
                </InputValidator>
              </Grid>

              <Grid item xs={12} style={{paddingTop: '0'}}>
                <InputValidator
                  required
                  shouldValidateOnBlur={true}
                  customValidation={() => {
                    return this.validatePasswords()
                  }}
                >
                  <TextField
                    required
                    value={confirmPassword}
                    placeholder="Retype new password"
                    onChange={e => this.setState({confirmPassword: e.target.value})}
                    label="Retype new password"
                    className={classes.inputSignupAndLogin}
                    type="password"
                    autoComplete="current-password"
                    variant="outlined"
                    margin="dense"
                    style={{height: 38}}
                  />
                </InputValidator>
              </Grid>

              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <Button
                  onClick={this.onResetClick.bind(this)}
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!this.state.password || !this.state.confirmPassword}
                  style={{width: '190px', height: '45px'}}
                  className={classes.forgotButton}
                >
                  {isFetching ? (
                    <CircularProgress size="24px" style={{color: '#F2F2F2'}} />
                  ) : (
                    'Reset Password'
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      )
    }
    return (
      <>
        <Grid container spacing={24}>
          <Grid
            item
            xs={12}
            style={{
              textAlign: 'center',
            }}
          >
            <img src={checkCircle} alt="check circle" />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              textAlign: 'center',
              padding: '0',
            }}
          >
            <Typography gutterBottom className={classes.title} style={{margin: 0}}>
              Success!
            </Typography>
          </Grid>

          <Grid item xs={12} style={{paddingTop: '0'}}>
            <p className={classes.hintText}>
              Your new password has been successfully set. You can now login.
            </p>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Button color="primary" variant="contained" style={{padding: '0', marginTop: '16px'}}>
            <Link
              to="login"
              style={{
                width: '112px',
                height: '45px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textDecoration: 'none',
                color: '#FFF',
              }}
            >
              Login
            </Link>
          </Button>
        </Grid>
      </>
    )
  }

  render() {
    const {password, confirmPassword} = this.state
    const {isReset, classes} = this.props
    return (
      <div className="registrationPage">
        <Helmet title="Reset password" />
        <Grid container justify="center">
          <Grid
            className={classes.loginContainer}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '440px',
            }}
          >
            <div
              style={{
                padding: '50px',
                borderRadius: '8px',
                border: '1px solid #E0E0E0',
                background: 'rgba(232, 243, 255, 0.2)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {this.renderForgotPasswordConsume(password, confirmPassword, isReset, classes)}
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

ForgotPasswordConsume.displayName = 'ForgotPasswordConsume'

ForgotPasswordConsume.propTypes = {
  params: PropTypes.shape({
    token: PropTypes.string.isRequired,
  }),
}

ForgotPasswordConsume.contextTypes = {
  router: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

function mapStateToProps(state) {
  return {...state.forgotPw}
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  validatedComponent,
)(ForgotPasswordConsume)
