// import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {Link} from 'react-router'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import queryString from 'query-string'
import {
  Typography,
  Grid,
  Button,
  withStyles,
  TextField,
  CircularProgress,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

import InputValidator from '../common/InputValidator.jsx'
import Loading from '../common/Loading.jsx'
import validatedComponent from '../common/ValidatedComponent.jsx'
// import GooglePlusAuthButton from './SocialAuthButtons.jsx'

import * as Actions from './redux/actions'
// import settings from '../../../config/settings'

import pageUtil from '../utils/PageUtils'

import styles from './styles/LoginSignup.styles'

class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      password: '',
      autoCompleteFix: false,
      canSubmit: false,
      showPassword: false,
    }

    this.popup = null
    this.usernameRef = React.createRef()
    this.passwordRef = React.createRef()

    this.changeUser = this.changeUser.bind(this)
    this.changePassword = this.changePassword.bind(this)
    this.login = this.login.bind(this)
    this.redirectIfAuthed = this.redirectIfAuthed.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this)
  }

  login() {
    const {isComponentValid, login} = this.props
    if (isComponentValid()) {
      login(this.state.username, this.state.password)
    }
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search)
    const id = parseInt(values.id, 10)
    const {token} = values
    pageUtil.scrollToTop()
    if (this.props.location.search.includes('id' && 'token')) {
      this.props.autoLogin(id, token)
    }
    this.redirectIfAuthed(this.props)
    const ReactPixel = require('react-facebook-pixel').default
    ReactPixel.pageView()

    const interval = setInterval(() => {
      if (this.usernameRef.current) {
        this.setState({autoCompleteFix: true})
        clearInterval(interval)
      }
    }, 600)
  }

  detectAutofill(element) {
    if (!element) return false
    return (
      window.getComputedStyle(element, null).getPropertyValue('appearance') === 'menulist-button'
    )
  }

  componentWillReceiveProps(nextProps) {
    this.redirectIfAuthed(nextProps)
  }

  redirectIfAuthed(props) {
    const {location, token} = props
    if (token) {
      if (location.query.redirectTo) {
        this.context.router.push(location.query.redirectTo)
      } else {
        this.context.router.push('/')
      }
      return true
    }
    return false
  }

  onFormSubmit(e) {
    e.preventDefault()

    this.login()
  }

  handleClickShowPassword() {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword,
    }))
  }

  render() {
    const {username, password, canSubmit, showPassword} = this.state
    const {
      errors,
      classes,
      isFetching,
      // isNoEmailProvidedError,
      // provider,
      // isSocialLoginLoading,
      // socialLoginOAuth2,
    } = this.props

    return (
      <div className={classes.page}>
        {this.props.location.search.includes('id' && 'token') ? (
          <Loading />
        ) : (
          <>
            <Helmet title="Log In" />
            <Grid container className={classes.wrapper}>
              <div className={classes.container}>
                <Typography gutterBottom className={classes.title}>
                  Login
                </Typography>
                <form noValidate className={classes.form} onSubmit={this.onFormSubmit}>
                  <div>
                    <InputValidator errors={errors.email} shouldValidateOnBlur={true}>
                      <TextField
                        label="Email Address"
                        type="email"
                        name="email"
                        autoComplete="email"
                        variant="outlined"
                        value={username}
                        onChange={this.changeUser}
                        className={classes.inputSignupAndLogin}
                        margin="dense"
                        style={{marginTop: '0'}}
                        inputRef={this.usernameRef}
                        InputLabelProps={{
                          shrink:
                            this.detectAutofill(this.usernameRef.current) || Boolean(username),
                        }}
                      />
                    </InputValidator>

                    <InputValidator errors={errors.password} shouldValidateOnBlur={true}>
                      <TextField
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="current-password"
                        variant="outlined"
                        value={password}
                        onChange={this.changePassword}
                        className={classes.inputSignupAndLogin}
                        inputRef={this.passwordRef}
                        margin="dense"
                        InputLabelProps={{
                          shrink:
                            this.detectAutofill(this.passwordRef.current) || Boolean(password),
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Toggle password visibility"
                                onClick={this.handleClickShowPassword}
                              >
                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </InputValidator>
                    <Link
                      to="/forgot"
                      style={{
                        display: 'block',
                        color: '#37474F',
                        fontSize: '16px',
                        textDecoration: 'none',
                        marginTop: '16px',
                      }}
                    >
                      Forgot Password?
                    </Link>
                  </div>

                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!canSubmit}
                    style={{width: '140px', margin: '32px 0px'}}
                    className={classes.loginButton}
                    type="submit"
                  >
                    {isFetching ? (
                      <CircularProgress style={{color: '#F2F2F2'}} size="24px" />
                    ) : (
                      'Login'
                    )}
                  </Button>
                  <div>
                    <span
                      style={{
                        color: '#37474F',
                        fontSize: '16px',
                      }}
                    >
                      New user?{' '}
                    </span>
                    <Link to="/signup" style={{color: '#2F80ED'}}>
                      Create Account
                    </Link>
                  </div>
                  {/* <div style={{margin: '32px 0px', color: '#37474F', fontSize: '16px'}}>Or</div>
                  {_.get(settings, 'website.socialLogins') && (
                    <React.Fragment>
                      <GooglePlusAuthButton
                        isNoEmailProvidedError={
                          isNoEmailProvidedError && provider === 'google-oauth2'
                        }
                        isLoading={isSocialLoginLoading && provider === 'google-oauth2'}
                        onSendAuthRequest={socialLoginOAuth2}
                      />
                    </React.Fragment>
                  )} */}
                </form>
              </div>
            </Grid>
          </>
        )}
      </div>
    )
  }

  changeUser(event) {
    const username = event.target.value
    this.setState(prevState => {
      return {
        username,
        canSubmit: prevState.password && username,
      }
    })
  }

  changePassword(event) {
    const password = event.target.value
    this.setState(prevState => {
      return {
        password,
        canSubmit: prevState.username && password,
      }
    })
  }
}

Login.displayName = 'Login'

Login.propTypes = {}

Login.contextTypes = {
  router: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

function mapStateToProps(state) {
  return {...state.auth}
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  validatedComponent,
)(Login)
