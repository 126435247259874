import React, {useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import DeleteButton from '../../common/DeleteButton'
import AddButton from '../../common/AddButton'
import InfoTooltip from '../../common/InfoTooltip'

import {choiceTypes} from '../../constants/NewTest'

import styles from '../styles/TestSetup.styles'
import useModalState from '../../../hooks/useModalState'

const SelectIcon = withStyles(theme => ({
  root: {
    marginRight: theme.spacing.unit / 2,
    color: theme.palette.grey[800],
  },
}))(ExpandMoreIcon)

const QuestionForm = props => {
  const {
    classes,
    question,
    setQuestion,
    removeQuestion,
    setChoiceType,
    setChoice,
    addChoice,
    removeChoice,
    currentQuestion,
    questionError,
    validateQuestion,
    choiceErrors,
    validateFields,
    disableInputs,
    maxAnswers,
  } = props

  const [isMaxAnswersTooltipOpen, openMaxAnswersTooltip, closeMaxAnswersTooltip] = useModalState(
    false,
  )

  const lastChoiceInputRef = useRef(null)

  useEffect(() => {
    if (lastChoiceInputRef.current) {
      lastChoiceInputRef.current.scrollIntoView({behavior: 'smooth'})
    }
  }, [question.choices])

  const getInputRef = index => {
    if (index === question.choices.length - 1) {
      return {
        inputRef: ref => {
          lastChoiceInputRef.current = ref
        },
      }
    }

    return {}
  }

  const renderChoices = () => {
    return question.choices.map((choice, index) => (
      <TextField
        {...getInputRef(index)}
        key={index}
        value={choice}
        onChange={({target}) => {
          setChoice(target.value, index)
          validateFields(currentQuestion)
        }}
        variant="outlined"
        className={classes.nameInput}
        InputProps={{
          classes: {
            root: classes.nameInputOutline,
            notchedOutline: choiceErrors[index] ? classes.errorOutline : classes.notchedOutline,
          },
          endAdornment: !disableInputs ? (
            <InputAdornment position="end">
              <DeleteButton
                onClick={() => removeChoice(index)}
                disabled={question.choices.length <= 2}
              />
            </InputAdornment>
          ) : null,
          readOnly: disableInputs,
          onBlur: () => validateFields(currentQuestion),
        }}
        placeholder="Type your answer"
        helperText={choiceErrors[index]}
        FormHelperTextProps={{
          className: classes.inputErrorText,
          errormessage: 'true',
        }}
        disabled={disableInputs}
      />
    ))
  }

  return (
    <Grid container className={classes.questionFormContainer}>
      <Grid container className={classes.questionHeader} style={{alignItems: 'center'}}>
        <Typography variant="body1" className={classes.sectionSubtitle}>
          {`Question - ${currentQuestion + 1}`}
        </Typography>

        {!disableInputs && <DeleteButton onClick={removeQuestion} />}
      </Grid>
      <Typography variant="body1" className={classes.sectionSubtitle}>
        Question Type
      </Typography>
      <Select
        style={{overflow: 'initial'}}
        className={classes.selectRoot}
        classes={{
          icon: `${classes.selectIcon} ${disableInputs ? classes.disabledIcon : ''}`,
          selectMenu: classes.selectMenu,
        }}
        value={question.choiceType}
        variant="outlined"
        IconComponent={SelectIcon}
        onChange={({target}) => {
          setChoiceType(target.value)
        }}
        input={<OutlinedInput labelWidth={0} classes={{notchedOutline: classes.notchedOutline}} />}
        disabled={disableInputs}
      >
        <MenuItem value={choiceTypes.multipleChoice}>Multiple Choice</MenuItem>
        <MenuItem value={choiceTypes.openEnded}>Open Ended</MenuItem>
      </Select>

      <TextField
        value={question.question}
        onChange={({target}) => {
          setQuestion(target.value)
          validateQuestion()
        }}
        variant="outlined"
        className={classes.nameInput}
        InputProps={{
          classes: {
            root: classes.nameInputOutline,
            notchedOutline: questionError ? classes.errorOutline : classes.notchedOutline,
          },
          onBlur: validateQuestion,
          readOnly: disableInputs,
        }}
        placeholder="Type your question"
        helperText={questionError}
        FormHelperTextProps={{
          className: classes.inputErrorText,
          errormessage: 'true',
        }}
        multiline
        disabled={disableInputs}
      />

      {question.choiceType === choiceTypes.multipleChoice && (
        <>
          <Typography variant="h6" className={classes.sectionTitle} style={{width: 'max-content'}}>
            Answer
            <InfoTooltip
              text={
                <div>
                  <span>Recommended Survey Answers:</span>
                  <ul style={{margin: '8 8 0 0', paddingLeft: 20, lineHeight: '24px'}}>
                    <li>Yes/No</li>
                    <li>True/False</li>
                    <li>Rating Scales</li>
                    <ul>
                      <li>Type 1:</li>
                      <ul>
                        <li>Very Likely</li>
                        <li>Somewhat Likely</li>
                        <li>Somewhat Unlikely</li>
                        <li>Very Unlikely</li>
                      </ul>
                      <li>Type 2:</li>
                      <ul>
                        <li>Strongly Agree</li>
                        <li>Somewhat Agree</li>
                        <li>Somewhat Disagree</li>
                        <li>Strongly Disagree</li>
                      </ul>
                    </ul>
                  </ul>
                </div>
              }
              placement="right"
            />
          </Typography>
          {renderChoices()}
          {!disableInputs && (
            <Grid container style={{alignItems: 'center'}}>
              {question.choices.length >= maxAnswers ? (
                <InfoTooltip
                  text={`You can only add a maximum of ${maxAnswers} answers.`}
                  placement="left"
                  open={isMaxAnswersTooltipOpen}
                  showIcon={false}
                >
                  <AddButton
                    disableRipple
                    className={classes.disabledAddButton}
                    onMouseEnter={openMaxAnswersTooltip}
                    onMouseLeave={closeMaxAnswersTooltip}
                  />
                </InfoTooltip>
              ) : (
                <AddButton onClick={addChoice} />
              )}
              <Typography>Add New Answer</Typography>
            </Grid>
          )}
        </>
      )}
    </Grid>
  )
}

QuestionForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  question: PropTypes.shape({
    question: PropTypes.string,
    choiceType: PropTypes.number,
    choices: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setQuestion: PropTypes.func.isRequired,
  removeQuestion: PropTypes.func.isRequired,
  setChoiceType: PropTypes.func.isRequired,
  setChoice: PropTypes.func.isRequired,
  addChoice: PropTypes.func.isRequired,
  removeChoice: PropTypes.func.isRequired,
  currentQuestion: PropTypes.number.isRequired,
  questionError: PropTypes.string.isRequired,
  validateQuestion: PropTypes.func.isRequired,
  choiceErrors: PropTypes.objectOf(PropTypes.string).isRequired,
  validateFields: PropTypes.func.isRequired,
  disableInputs: PropTypes.bool.isRequired,
}

export default withStyles(styles)(QuestionForm)
