import _ from 'lodash'
import * as Types from '../../profile/redux/actions.types'
import * as ErrorUtils from '../../utils/ErrorUtils'

export default function createProfile(state = getInitialState(), action) {
  switch (action.type) {
    case Types.PROFILE_CREATE_INIT:
      return getInitialState()
    case Types.PROFILE_CREATE:
      return {
        ...state,
        isSaving: true,
      }
    case Types.PROFILE_CREATE_SUCCESS:
      return {
        ...state,
        isSaving: false,
        savedProfile: action.payload,
      }
    case Types.PROFILE_CREATE_FAILURE:
      return {
        isSaving: false,
        savedProfile: null,
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.PROFILE_CLEAN_ERROR:
      return {
        ...state,
        errors: _.omit(state.errors, action.fieldName),
      }
    default:
      return state
  }
}

export function getInitialState() {
  return {
    isSaving: false,
    savedProfile: null,
    errors: {},
  }
}
