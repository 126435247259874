import React from 'react'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'
import {Grid} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import Helmet from 'react-helmet'
import Loading from '../common/Loading.jsx'
import {
  getCampaigns,
  getCompletedCampaigns,
  setDemographicFilter,
  getCaseTypes,
  resetCampaign,
} from '../campaign/redux/actions'

import pageUtil from '../utils/PageUtils'
import NewTableData from '../campaign/TableData.jsx'
import EmptyCampaign from '../campaign/EmptyCampaign.jsx'

import settings from '../../../config/settings.json'

import styles from '../common/styles/CardLayout.styles'

class Dashboard extends React.Component {
  constructor() {
    super()
    this.state = {
      page: 0,
      date: new Date().toISOString().substring(0, 10),
    }
    this.changePage = this.changePage.bind(this)
    this.getStatus = this.getStatus.bind(this)
    this.getOutcome = this.getOutcome.bind(this)
    this.rowsPerPage = settings.apiPageSize
  }

  componentDidMount() {
    this.props.resetCampaign()
    this.props.setDemographicFilter({})
    pageUtil.scrollToTop()
    this.props.getCampaigns({
      orderBy: '-from_date',
      expand: 'lists',
      date: this.state.date,
    })
    this.props.getCompletedCampaigns({
      orderBy: '-from_date',
      expand: 'lists',
    })
    this.props.getCaseTypes()
    const ReactPixel = require('react-facebook-pixel').default
    ReactPixel.pageView()
  }

  changePage(e, page) {
    this.props.getCompletedCampaigns({orderBy: '-from_date', page: page + 1, expand: 'lists'})
    this.setState({page})
  }

  //this function will be used when the outcome feature is done
  getOutcome() {
    const randomNumber = Math.floor(Math.random() * 4)
    if (randomNumber === 0) {
      return (
        <p
          className={`${this.props.classes.settledOutcome} ${this.props.classes.statusAndOutcomeFlag}`}
        >
          Settled
        </p>
      )
    }
    if (randomNumber === 1) {
      return (
        <p
          className={`${this.props.classes.inProcessOutcome} ${this.props.classes.statusAndOutcomeFlag}`}
        >
          In Process
        </p>
      )
    }
    if (randomNumber === 2) {
      return (
        <p
          className={`${this.props.classes.lostOutcome} ${this.props.classes.statusAndOutcomeFlag}`}
        >
          Lost
        </p>
      )
    }
    return (
      <p className={`${this.props.classes.wonOutcome} ${this.props.classes.statusAndOutcomeFlag}`}>
        Won
      </p>
    )
  }

  getTotalViewsRequired(campaign) {
    if (campaign.targetAudiences && campaign.targetAudiences.length) {
      return campaign.targetAudiences.reduce((acc, curr) => acc + curr.totalCompletes, 0)
    }

    return campaign.lists.reduce((acc, curr) => {
      return acc + curr.totalDelivery
    }, 0)
  }

  getStatus(campaign) {
    const fromDate = new Date(campaign.fromDate)
    const today = new Date()
    const totalViewsRequired = this.getTotalViewsRequired(campaign)
    const totalViews = campaign.totalVideoViews

    if (campaign.isLucidCampaign) {
      if (campaign.lists.some(list => !list.isLucidSurveyLive) || !campaign.lists.length) {
        return (
          <p
            className={`${this.props.classes.processingStatus} ${this.props.classes.statusAndOutcomeFlag}`}
          >
            Processing
          </p>
        )
      }
    } else if (campaign.lists.length === 0) {
      return (
        <p
          className={`${this.props.classes.processingStatus} ${this.props.classes.statusAndOutcomeFlag}`}
        >
          Processing
        </p>
      )
    }

    if (fromDate > today) {
      return (
        <p
          className={`${this.props.classes.pendingStatus} ${this.props.classes.statusAndOutcomeFlag}`}
        >
          Pending
        </p>
      )
    }
    if (totalViewsRequired > totalViews) {
      return (
        <p
          className={`${this.props.classes.inProgressStatus} ${this.props.classes.statusAndOutcomeFlag}`}
        >
          In Progress
        </p>
      )
    }
    if (totalViewsRequired <= totalViews) {
      return (
        <p
          className={`${this.props.classes.completedStatus} ${this.props.classes.statusAndOutcomeFlag}`}
        >
          Completed
        </p>
      )
    }
  }

  getListSize(lists) {
    let total = 0
    if (lists) {
      lists.forEach(list => {
        total += list.size
      })
    }

    return total.toLocaleString()
  }

  render() {
    const {results, isFetching, classes, completedCampaigns} = this.props

    return (
      <Grid className={classes.dashboardContainer}>
        <Helmet title="Dashboard" />
        {isFetching || completedCampaigns.isFetching ? (
          <Loading />
        ) : (
          <>
            {results.length === 0 && completedCampaigns.results.length === 0 ? (
              <EmptyCampaign />
            ) : (
              <>
                {completedCampaigns.results.length > 0 ? (
                  <NewTableData
                    data={completedCampaigns.results}
                    getStatus={this.getStatus}
                    getOutcome={this.getOutcome}
                    count={completedCampaigns.count}
                    page={this.state.page}
                    rowsPerPage={this.rowsPerPage}
                    changePage={this.changePage}
                  />
                ) : (
                  <Loading />
                )}
              </>
            )}
          </>
        )}
      </Grid>
    )
  }
}

Dashboard.displayName = 'Dashboard'

function mapStateToProps(state) {
  return {
    ...state.campaign,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {getCampaigns, getCompletedCampaigns, setDemographicFilter, getCaseTypes, resetCampaign},
    dispatch,
  )
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Dashboard)
