const styles = theme => ({
  card: {
    border: `1px solid ${theme.palette.surface[400]}`,
    borderRadius: theme.spacing.unit,
    overflow: 'hidden',
    marginBottom: theme.spacing.unit * 2,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    background: '#2B93FF',
    width: '100%',
    cursor: 'pointer',
    padding: theme.spacing.unit,
    '& > p': {
      color: 'white',
    },
  },
  collapseIcon: {
    border: '1px solid white',
    borderRadius: '50%',
    color: 'white',
    padding: theme.spacing.unit / 2,
    marginRight: theme.spacing.unit,
    '& > svg': {
      fontSize: 16,
    },
  },
  menuButton: {
    padding: 4,
    marginLeft: 'auto',
  },
  menuIcon: {
    color: 'white',
  },
  groupName: {
    fontWeight: '500',
    fontSize: '1rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  body: {
    background: '#F9FCFF',
    padding: `${theme.spacing.unit} ${theme.spacing.unit * 2}`,
  },
  demographicDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:not(:last-child)': {
      marginBottom: theme.spacing.unit,
    },
  },
  demographicLabel: {
    whiteSpace: 'nowrap',
  },
  groupCostDetails: {
    paddingTop: '8px',
    borderTop: '1px solid #dfdfdf',
    '& p': {
      fontWeight: 600,
    },
  },
  subDemographics: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: '16px',
  },
  panelistsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.surface[200],
    padding: theme.spacing.unit,
    borderTop: `1px solid ${theme.palette.surface[400]}`,
    '& > p': {
      color: theme.palette.surface[750],
      fontWeight: 600,
    },
  },
  actionButton: {
    borderRadius: '0 !important',
    color: 'white',
    background: 'none',
    boxShadow: 'none !important',
    textTransform: 'none',
    '&:hover': {
      background: 'none',
    },
  },
  actionIcon: {
    fontSize: '1.2rem',
    marginRight: theme.spacing.unit,
    marginTop: -2,
  },
  editIcon: {
    color: theme.palette.primary.main,
  },
  deleteIcon: {
    color: theme.palette.secondary.main,
  },
  audienceCardError: {
    background: '#EB5757',
  },
  audienceCardBodyError: {
    background: '#FFEBEE',
  },
  audienceCardErrorContainer: {
    display: 'flex',
    background: '#EB5757',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing.unit} ${theme.spacing.unit * 1.5}`,
  },
})

export default styles
