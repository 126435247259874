import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import _, {uniqueId} from 'lodash'
import md5 from 'md5'
import PropTypes from 'prop-types'
import {
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Typography,
  withStyles,
} from '@material-ui/core'

import InfoIcon from '@material-ui/icons/Info'

import QualifiersSelector from './QualifiersSelector'
import QualifierOptionsSelector from './QualifierOptionsSelector'
import AudienceErrorModal from '../AudienceErrorModal'
import DemographicSelectionsDisplay from './DemographicSelectionsDisplay'
import AudienceCountrySelector from './AudienceCountrySelector'
import GenderSelector from './GenderSelector'
import QuantitySelector from '../../../common/QuantitySelector'
import InfoTooltip from '../../../common/InfoTooltip'
import Divider from '../../../common/Divider'

import {createError} from '../../../common/redux/actions.notifications'
import {contextFields} from '../../../constants/NewTest'
import useModalState from '../../../../hooks/useModalState'
import useUpdateEffect from '../../../../hooks/useUpdateEffect'
import NewTestContext from '../../context/NewTestContext'
import {currencyFormat} from '../../../utils/MoneyUtils'
import NewTestErrorContext from '../../context/NewTestErrorContext'
import CampaignApi from '../../api'
import utils from '../../utils'

import styles from './styles'

const defaultLocale = {value: 'eng_us', label: 'English - United States', lucidId: 9}

const AudienceSelectionModal = props => {
  const {
    classes,
    isOpen,
    group,
    currentGroup,
    closeModal,
    demographicFields,
    configs,
    createError,
    registeredFrom,
    audienceLocales,
  } = props

  const {audienceGroups, setStateField, videoFile, getSurveyQuestionsObject} = useContext(
    NewTestContext,
  )

  const {audienceGroupErrors, handleAudienceGroupErrors} = useContext(NewTestErrorContext)

  const searchSelectRef = useRef()

  const [groupTempState, setGroupTempState] = useState({})
  const [nameInputError, setNameInputError] = useState('')
  const [selectedDemographic, setSelectedDemographic] = useState(null)
  const [selectedLocale, setSelectedLocale] = useState(group?.locale || defaultLocale)
  const [checkingAudienceGroup, setCheckingAudienceGroup] = useState({})
  const [duplicatedGroupName, setDuplicatedGroupName] = useState('')
  const [feasibilityError, setFeasibilityError] = useState(audienceGroupErrors[group?.id])
  const [lucidInfo, setLucidInfo] = useState({
    groupCost: 0,
    lucidWebappLink: '',
    price: 0,
  })

  const [
    duplicateErrorModalOpen,
    openDuplicateErrorModal,
    closeDuplicateErrorModal,
  ] = useModalState(false)

  const [genderErrorModalOpen, openGenderErrorModal, closeGenderErrorModal] = useModalState(false)

  const isLucidUser = utils.isLucidUser(registeredFrom)

  const getDefaultDemographics = () => {
    const genderField = demographicFields[selectedLocale.lucidId || 9].gender

    return genderField || null
  }

  const currentDemographicFields = useMemo(() => {
    const currentLocaleDemographics = demographicFields[selectedLocale.lucidId || 9]
    const defaultDemographics = getDefaultDemographics()

    setSelectedDemographic(null)
    setGroupTempState(prevState => ({
      ...prevState,
      demographics: defaultDemographics ? [defaultDemographics] : [],
      locale: selectedLocale,
    }))
    return currentLocaleDemographics
  }, [selectedLocale])

  const genderDemographicTable = useMemo(() => {
    const genderDemographic = currentDemographicFields.gender

    return genderDemographic.subDemographics.reduce(
      (acc, genderObject) => ({...acc, [genderObject.precode]: genderObject}),
      {},
    )
  }, [currentDemographicFields])

  const changeGroupName = name => {
    setNameInputError('')
    setGroupTempState(prevState => ({...prevState, name}))
  }

  const getNewGroupObject = totalCompletes => {
    const defaultDemographics = getDefaultDemographics()

    return {
      id: uniqueId(),
      name: '',
      totalCompletes: totalCompletes || 0,
      daysInField: 1,
      demographics: defaultDemographics ? [defaultDemographics] : [],
      locale: selectedLocale,
    }
  }

  useEffect(() => {
    if (!group) {
      setGroupTempState(getNewGroupObject(configs.panelistsGroupDefaultValue))
    } else {
      setGroupTempState(_.cloneDeep(group))
    }
  }, [])

  useUpdateEffect(() => {
    setFeasibilityError(audienceGroupErrors[groupTempState?.id])
  }, [audienceGroupErrors])

  const handleCheckingAudienceGroup = (id, status) => {
    setCheckingAudienceGroup(prevState => {
      if (status) {
        return {...prevState, [id]: true}
      }

      const {[id]: skip, ...rest} = prevState
      return rest
    })
  }

  const checkAudienceGroup = async (groupTempState, apiCallId) => {
    handleCheckingAudienceGroup('preload', false)

    const defaultGroupObject = getNewGroupObject()

    if (!_.isEqual(defaultGroupObject, groupTempState)) {
      try {
        setFeasibilityError('')
        const {groupCost, lucidWebappLink, price} = await CampaignApi.checkAudienceGroups({
          audienceGroups: [{...groupTempState, videoLength: videoFile.duration}],
          surveyQuestions: getSurveyQuestionsObject(),
        })

        setLucidInfo({groupCost, lucidWebappLink, price})
      } catch (error) {
        const {data} = error.response

        if (data?.error) {
          setFeasibilityError(data.error)
        } else {
          createError(error.message)
        }
      }
      handleCheckingAudienceGroup(apiCallId, false)
    }
  }

  const checkAudienceGroupDebounce = useCallback(
    _.debounce(groupTempState => {
      const apiCallId = uniqueId()

      handleCheckingAudienceGroup(apiCallId, true)
      checkAudienceGroup(groupTempState, apiCallId)
    }, 2000),
    [],
  )

  useUpdateEffect(() => {
    handleCheckingAudienceGroup('preload', true)

    if (isLucidUser) {
      checkAudienceGroupDebounce(groupTempState)
    } else {
      const newGroupObject = getNewGroupObject(configs.panelistsGroupDefaultValue)

      if (!_.isEqual(newGroupObject, groupTempState) && !_.isEqual(group, groupTempState)) {
        checkAudienceGroupDebounce(groupTempState)
      }
    }
  }, [groupTempState.totalCompletes, groupTempState.daysInField, groupTempState.demographics])

  const handleGroupTempState = data => {
    setGroupTempState(prevState => ({
      ...prevState,
      ...data,
    }))
  }

  const incrementCompletes = () => {
    handleGroupTempState({
      totalCompletes:
        groupTempState.totalCompletes + configs.groupPanelistsIncrementDecrementOffset,
    })
  }

  const decrementCompletes = () => {
    const newTotalCompletes =
      groupTempState.totalCompletes - configs.groupPanelistsIncrementDecrementOffset

    if (newTotalCompletes >= configs.minimumPanelistsPerGroup) {
      handleGroupTempState({
        totalCompletes:
          groupTempState.totalCompletes - configs.groupPanelistsIncrementDecrementOffset,
      })
    }
  }

  const incrementDaysInField = () => {
    setGroupTempState(prevState => ({
      ...prevState,
      daysInField: prevState.daysInField + 1,
    }))
  }

  const decrementDaysInField = () => {
    setGroupTempState(prevState => ({
      ...prevState,
      daysInField: prevState.daysInField - 1,
    }))
  }

  const getLabelFromDemographicKey = key => {
    return currentDemographicFields[key]?.label
  }

  const addDemographic = (key, subDemographics) => {
    const newDemographic = {
      key,
      order: currentDemographicFields[key]?.order,
      questionId: currentDemographicFields[key]?.questionId,
      label: getLabelFromDemographicKey(key),
      subDemographics: subDemographics || [],
    }

    handleGroupTempState({
      demographics: [...groupTempState.demographics, newDemographic],
    })
  }

  const removeDemographic = key => {
    handleGroupTempState({
      demographics: groupTempState.demographics.filter(demographic => key !== demographic.key),
    })
  }

  const updateDemographic = (key, subDemographics) => {
    if (subDemographics.length) {
      const newDemographic = {
        key,
        order: currentDemographicFields[key]?.order,
        questionId: currentDemographicFields[key]?.questionId,
        label: getLabelFromDemographicKey(key),
        subDemographics,
      }

      handleGroupTempState({
        demographics: groupTempState.demographics.map(demographic =>
          key === demographic.key ? newDemographic : demographic,
        ),
      })
    } else {
      removeDemographic(key)
    }
  }

  const isGroupNameEmpty = () => {
    return !groupTempState.name
  }

  const isGenderSelected = () => {
    const genderDemographic = groupTempState.demographics.find(({key}) => key === 'gender')

    if (!genderDemographic) return false

    return !!genderDemographic.subDemographics.length
  }

  const isGroupNameDuplicated = () => {
    for (let i = 0; i < audienceGroups.length; i++) {
      if (
        audienceGroups[i].name.toLowerCase() === groupTempState.name.toLowerCase() &&
        currentGroup !== i
      ) {
        return true
      }
    }

    return false
  }

  const hasDuplicatedGroups = () => {
    setDuplicatedGroupName('')

    const sortedTempGroup = _.sortBy(groupTempState.demographics, 'key')
    const tempGroupString = md5(JSON.stringify(sortedTempGroup))

    for (let i = 0; i < audienceGroups.length; i++) {
      if (i !== currentGroup) {
        const sortedCurrentGroup = _.sortBy(audienceGroups[i].demographics, 'key')
        const currentGroupString = md5(JSON.stringify(sortedCurrentGroup))

        if (tempGroupString === currentGroupString) {
          setDuplicatedGroupName(audienceGroups[i].name)
          return true
        }
      }
    }

    return false
  }

  const isGroupValid = () => {
    if (isGroupNameEmpty()) {
      setNameInputError('Group name can not be empty.')
      utils.scrollToError()
      return false
    }

    if (isGroupNameDuplicated()) {
      setNameInputError('Group name already exists.')
      utils.scrollToError()
      return false
    }

    if (!isGenderSelected()) {
      openGenderErrorModal()
      return false
    }

    if (hasDuplicatedGroups()) {
      openDuplicateErrorModal()
      return false
    }

    return true
  }

  const saveGroup = async () => {
    if (isGroupValid()) {
      const groupWithLucidInfo = {...groupTempState, ...lucidInfo}

      if (!group) {
        setStateField(contextFields.audienceGroups, [...audienceGroups, groupWithLucidInfo])
      } else {
        setStateField(
          contextFields.audienceGroups,
          audienceGroups.map((audienceGroup, groupIndex) => {
            if (groupIndex === currentGroup) {
              return groupWithLucidInfo
            }

            return audienceGroup
          }),
        )
      }

      handleAudienceGroupErrors({[groupTempState.id]: false})
      closeModal()
    }
  }

  const isCheckingAudienceGroup = () => {
    if (_.isEmpty(checkingAudienceGroup)) {
      return false
    }

    return Object.values(checkingAudienceGroup).some(status => status)
  }

  const getGroupCost = () => {
    if (isCheckingAudienceGroup() || lucidInfo.groupCost === 0) {
      return <CircularProgress style={{color: '#017EFF', marginRight: 16}} size={16} />
    }

    if (feasibilityError) {
      return (
        <span style={{color: '#F44336', display: 'flex', alignItems: 'center'}}>
          <InfoIcon fontSize="small" style={{marginRight: '4px'}} />
          <span
            style={{
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '16px',
            }}
          >
            Not Feasible
          </span>
        </span>
      )
    }

    return <Typography className={classes.cost}>{currencyFormat(lucidInfo.groupCost)}</Typography>
  }

  return (
    <Dialog open={isOpen} classes={{paper: classes.paper}}>
      <AudienceErrorModal
        open={duplicateErrorModalOpen}
        onClose={closeDuplicateErrorModal}
        text={`The selections you made for this group match exactly with ${duplicatedGroupName}.`}
        secondaryText="Please change at least one of the selections to continue."
      />
      <AudienceErrorModal
        open={genderErrorModalOpen}
        onClose={closeGenderErrorModal}
        text="You need to select at least one gender to proceed."
      />

      <Grid className={classes.leftSection}>
        {/* title + close button */}
        <Grid container className={classes.header}>
          <Typography variant="h5" className={classes.title}>
            Add Audience Group
          </Typography>
        </Grid>

        <Typography className={classes.subtitle}>
          {`Each group must have at least ${configs.minimumPanelistsPerGroup} panelists.`}
        </Typography>

        <Grid
          style={{padding: '0 8px 24px 0', overflow: 'auto', flexGrow: '1', marginRight: '16px'}}
        >
          {/* group name */}
          <FormControl
            variant="outlined"
            className={`${classes.container} ${classes.nameInputContainer}`}
            error={!!nameInputError}
          >
            <Typography className={classes.inputLabel}>Group Name</Typography>
            <OutlinedInput
              placeholder="Type your group name"
              value={groupTempState?.name || ''}
              onChange={({target}) => changeGroupName(target.value)}
              labelWidth={0}
              inputProps={{className: classes.nameInput, maxLength: 40}}
            />
            {groupTempState?.name?.length ? (
              <Typography className={classes.nameLength}>
                {`${groupTempState.name.length}/40`}
              </Typography>
            ) : null}
            {nameInputError && (
              <FormHelperText errormessage="true" variant="standard">
                {nameInputError}
              </FormHelperText>
            )}
          </FormControl>

          {isLucidUser && (
            <AudienceCountrySelector
              selectedLocale={selectedLocale}
              audienceLocales={audienceLocales}
              setSelectedLocale={setSelectedLocale}
            />
          )}

          <Grid container>
            {/* total panelists selector */}
            <Grid
              className={`${classes.quantitySelectorContainer}`}
              style={{marginRight: '24px', flexBasis: '50%'}}
            >
              <QuantitySelector
                value={groupTempState.totalCompletes}
                increment={incrementCompletes}
                decrement={decrementCompletes}
                leftDisabled={groupTempState.totalCompletes === configs.minimumPanelistsPerGroup}
                rightDisabled={false}
              />
              <Typography className={classes.totalPanelists}>
                Total Panelists
                <InfoTooltip
                  text={`In order to create a successful test, you need at least ${configs.campaignMinimumPanelists} total panelists.`}
                  placement="top"
                />
              </Typography>
            </Grid>

            {/* days in field selector */}
            {isLucidUser && (
              <Grid className={`${classes.quantitySelectorContainer}`}>
                <QuantitySelector
                  value={groupTempState.daysInField}
                  increment={incrementDaysInField}
                  decrement={decrementDaysInField}
                  leftDisabled={groupTempState.daysInField === 1}
                  rightDisabled={groupTempState.daysInField === 14} // lucid: "Days" must be a number and less than or equal to 14
                />
                <Typography className={classes.totalPanelists}>
                  Days In Field
                  <InfoTooltip
                    text={
                      <span>
                        Set the number of days you expect this group to be completed.
                        <br />
                        <br />
                        By extending the length of time to completion, this will make your study
                        more feasible and minimize any additional audience costs.
                        <br />
                        <br />
                        Final number of days in the field could vary slightly.
                      </span>
                    }
                    placement="top"
                  />
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid container className={`${classes.container} ${classes.genderSelectorContainer}`}>
            <GenderSelector
              groupTempState={groupTempState}
              updateDemographic={updateDemographic}
              genderDemographicTable={genderDemographicTable}
            />
          </Grid>

          <Grid container>
            <QualifiersSelector
              demographicFields={currentDemographicFields}
              selectedDemographic={selectedDemographic}
              setSelectedDemographic={setSelectedDemographic}
              searchSelectRef={searchSelectRef}
            />
          </Grid>
          {selectedDemographic?.value && (
            <>
              <Divider />
              <QualifierOptionsSelector
                demographic={currentDemographicFields[selectedDemographic.value]}
                groupTempStateDemographics={groupTempState.demographics}
                demographicHelpers={{
                  addDemographic,
                  removeDemographic,
                  updateDemographic,
                }}
              />
            </>
          )}
        </Grid>
      </Grid>

      <Grid className={classes.rightSection}>
        <Grid container className={classes.header}>
          <Typography variant="h5" className={classes.title}>
            Selection Summary
          </Typography>
        </Grid>

        <Typography className={classes.subtitle}>
          Summary of all the qualifiers you selected.
        </Typography>

        <DemographicSelectionsDisplay
          groupTempState={groupTempState}
          removeDemographic={removeDemographic}
          updateDemographic={updateDemographic}
          searchSelectRef={searchSelectRef}
        />

        {isLucidUser && (
          <>
            <Divider />
            <Grid className={classes.costContainer}>
              <Typography className={classes.costLabel}>Custom Audience Group</Typography>
              {getGroupCost()}
            </Grid>
          </>
        )}

        <Grid className={classes.buttonsContainer} style={{marginTop: isLucidUser ? 0 : '20px'}}>
          <Button color="secondary" variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={saveGroup}
            disabled={
              Boolean(feasibilityError) ||
              isCheckingAudienceGroup() ||
              (isLucidUser && lucidInfo.groupCost === 0)
            }
          >
            {isCheckingAudienceGroup() && !isLucidUser && (
              <CircularProgress style={{color: 'white', marginRight: 16}} size={16} />
            )}
            Save Group
          </Button>
          {feasibilityError && (
            <Typography component="span" className={classes.feasibilityError}>
              {feasibilityError}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Dialog>
  )
}

AudienceSelectionModal.defaultProps = {
  group: null,
}

AudienceSelectionModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  currentGroup: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  demographicFields: PropTypes.objectOf(PropTypes.object).isRequired,
  configs: PropTypes.objectOf(PropTypes.number).isRequired,
  group: PropTypes.objectOf(PropTypes.any),
  audienceLocales: PropTypes.arrayOf(PropTypes.object),
}

const mapStateToProps = ({campaign, profile}) => ({
  configs: campaign.configs,
  registeredFrom: profile?.profile?.registeredFrom,
})

const mapDispatchToProps = dispatch => ({
  createError: error => dispatch(createError(error)),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(AudienceSelectionModal)
