import React, {useEffect} from 'react'
import {Modal, Typography, withStyles, Button, Paper} from '@material-ui/core'

import welcomeModalBanner from '../../assets/welcome_modal_banner.svg'

const styles = theme => ({
  modalBody: {
    display: 'flex',
    justifyContent: 'center',
    overflowY: 'auto',
  },
  infoModalContainer: {
    position: 'relative',
    margin: 'auto',
    background: '#FFFFFF',
    borderRadius: '8px',
    '&:focus-visible': {
      outline: 'none',
    },
  },
  textContainer: {
    textAlign: 'center',
    padding: '1rem',
  },
  welcomeTitle: {
    fontWeight: 400,
    fontSize: 28,
    paddingTop: theme.spacing.unit * 2,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  subTitle1: {
    fontWeight: 400,
    fontSize: 16,
    paddingTop: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 3,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  ctaBtn: {
    padding: '0.5rem 2rem',
    borderRadius: 8,
  },
  modalBannerImg: {
    width: '100%',
    borderRadius: '8px 8px 0 0',
  },
})

const WelcomeModal = ({classes}) => {
  const [open, setOpen] = React.useState(true)

  useEffect(() => {
    if (window.sessionStorage.getItem('hasVisitedWelcomeModal')) {
      handleClose()
    }
  }, [])

  const handleClose = () => {
    if (!window.sessionStorage.getItem('hasVisitedWelcomeModal'))
      window.sessionStorage.setItem('hasVisitedWelcomeModal', true)
    setOpen(false)
  }
  return (
    <>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
        className={classes.modalBody}
        disableBackdropClick
      >
        <Paper elevation={1} className={classes.infoModalContainer}>
          <img
            className={classes.modalBannerImg}
            src={welcomeModalBanner}
            alt="welcome-modal-banner"
          />
          <div className={classes.textContainer}>
            <Typography className={classes.welcomeTitle}>
              Test drive the demo video to view <br />
              the audience insights!
            </Typography>
            <Typography className={classes.subTitle1}>
              Visit the Tutorial section to learn more!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.ctaBtn}
              onClick={handleClose}
            >
              Test Drive Now!
            </Button>
          </div>
        </Paper>
      </Modal>
    </>
  )
}

export default withStyles(styles)(WelcomeModal)
