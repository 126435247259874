import React from 'react'
import PropTypes from 'prop-types'
import {Button, CircularProgress, Typography, withStyles} from '@material-ui/core'
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined'

const styles = theme => ({
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    cursor: 'pointer',
    marginBottom: 4,
    '& span': {
      lineHeight: '1.1',
      marginLeft: theme.spacing.unit,
      color: theme.palette.primary.main,
      fontWeight: 400,
      textDecoration: 'underline',
    },
    '&:hover span': {
      fontWeight: 500,
    },
  },
  primaryLoadingWrapper: {
    paddingBottom: 4,
  },
  nextLoadingWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing.unit * 4,
  },
})

const SaveSetupChangesButton = ({
  classes,
  hasPassed,
  isEditing,
  onClick,
  loading,
  variant,
  ...props
}) => {
  if (hasPassed && isEditing) {
    if (variant === 'primary') {
      return (
        <Typography onClick={onClick} className={classes.buttonContainer} {...props}>
          {loading ? (
            <div className={classes.primaryLoadingWrapper}>
              <CircularProgress size={16} />
            </div>
          ) : (
            <SaveOutlinedIcon fontSize="small" color="primary" />
          )}
          <span>Save Changes</span>
        </Typography>
      )
    }
    if (variant === 'secondary') {
      if (loading) {
        return (
          <div className={classes.nextLoadingWrapper}>
            <CircularProgress size={36} />
          </div>
        )
      }

      return (
        <Button variant="contained" color="primary" onClick={onClick} {...props}>
          Save Changes
        </Button>
      )
    }
  }

  return null
}

SaveSetupChangesButton.propTypes = {
  hasPassed: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  variant: PropTypes.string,
}

SaveSetupChangesButton.defaultProps = {
  loading: false,
  variant: 'primary',
}

export default withStyles(styles)(SaveSetupChangesButton)
