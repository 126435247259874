import settings from '../../../config/settings'
import axios from '../api/axios'
import axiosFile from '../api/fileAxios'

const {apiBaseURL} = settings

export default class VideoApi {
  static uploadVideo(file, onUploadProgress) {
    const form = new FormData()
    form.append('video_file', file)
    const config = {
      onUploadProgress,
    }
    return axiosFile.post(`${apiBaseURL}/videos`, form, config)
  }

  static getVideos(params) {
    return axios.get(`${apiBaseURL}/videos`, {params})
  }

  static deleteVideo(id, video) {
    return axios.patch(`${apiBaseURL}/videos/${id}`, video)
  }

  static convertVideo(form) {
    return axiosFile.post(`${apiBaseURL}/videos/convert`, form, {
      responseType: 'blob',
    })
  }
}
