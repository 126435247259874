import {groupMaleFemaleColors, groupSentimentColors} from './constants'

export const formatter = value => {
  return value !== undefined
    ? new Date(Math.round(value) * 1000).toISOString().substring(14, 19)
    : ''
}

export const getTickAmount = (downMd, duration) => {
  if (duration <= 50) {
    if (duration % 2 === 0) return duration / 2

    if (duration % 3 === 0) return duration / 3

    if (duration % 5 === 0) return duration / 5
  }

  if (downMd) {
    return 17
  }

  return 25
}

export const getSentimentXaxis = emotionsByTime =>
  Object.keys(emotionsByTime).map(key => Number(key))

export const getSentimentYaxis = (emotionsByTime, currentEmotion) => {
  let yaxisSum = 0
  let max = -Infinity
  let mean = 0

  const yaxis = Object.values(emotionsByTime).map(emotions => {
    const value = emotions[currentEmotion] || 0
    max = value > max ? value : max
    yaxisSum += value
    return value
  })

  if (yaxisSum === 0) {
    mean = 0
  } else {
    mean = yaxisSum / yaxis.length
  }

  return [yaxis, mean, max]
}

export const getGroupGender = groupDetails => {
  if (!groupDetails?.gender) return []

  return groupDetails.gender.map(gender => {
    const groupGender = typeof gender === 'string' ? gender : gender.option
    return groupGender
      .replace(/male \/ un homme/g, 'male')
      .replace(/female \/ une femme/g, 'female')
  })
}

export const getGroupColor = (groupGender, index, shouldPickRandomColor) => {
  if (shouldPickRandomColor)
    return {color: groupSentimentColors[index], hasSelectedGenderColor: false}

  if (groupGender.length !== 1)
    return {color: groupSentimentColors[index], hasSelectedGenderColor: false}

  return {color: groupMaleFemaleColors[groupGender[0]], hasSelectedGenderColor: true}
}

export const getSentimentBenchmarkData = (sentimentBenchmarks, currentEmotion, yaxis, max) => {
  if (!sentimentBenchmarks) return []

  const benchmark = sentimentBenchmarks[currentEmotion]

  if (!benchmark) return []

  const benchmarkValue = (benchmark.value / 100) * max

  return [yaxis.map(() => benchmarkValue), benchmarkValue]
}
