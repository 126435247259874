import _ from 'lodash'
// import moment from 'moment'
import settings from '../../../config/settings'
// import 'moment-timezone'

export default class PageUtils {
  static getTitle() {
    return _.get(settings, 'website.title')
  }

  static scrollToTop() {
    const cosParameter = window.scrollY / 2

    let scrollCount = 0

    let oldTimestamp = performance.now()

    function step(newTimestamp) {
      scrollCount += Math.PI / (1000 / (newTimestamp - oldTimestamp))
      if (scrollCount >= Math.PI) window.scrollTo(0, 0)
      if (window.scrollY === 0) return
      window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)))
      oldTimestamp = newTimestamp
      window.requestAnimationFrame(step)
    }

    window.requestAnimationFrame(step)
  }

  static downloadFile(blob, fileName, type = 'text/csv') {
    const a = document.createElement('a')
    a.style.display = 'none'
    document.body.appendChild(a)

    a.href = window.URL.createObjectURL(blob, {type})

    a.setAttribute('download', fileName)

    a.click()

    window.URL.revokeObjectURL(a.href)
    document.body.removeChild(a)
  }

  static getDurationText(duration) {
    const min = Math.floor(duration / 60)
    const sec = duration % 60
    let durationText = ''
    if (min > 0) {
      durationText += min + ' min '
    }

    if (sec > 0) {
      durationText += sec + ' sec'
    }

    return durationText
  }

  static getDatetimeInTimeZone(timezone) {
    if (!timezone) {
      return new Date()
    }
    return new Date(new Date().toLocaleString('en-US', {timeZone: timezone}))
  }

  static getDatetimeStringInTimeZone(datetime, timezone) {
    if (!datetime) {
      return
    }
    if (!timezone) {
      return new Date(datetime.replace('Z', '')).toLocaleString('en-US').replace(':00', '')
    }
    return new Date(datetime).toLocaleString('en-US', {timeZone: timezone}).replace(':00', '')
  }

  static getDatetimeInTimeZoneFromDate(datetime, timezone) {
    if (!datetime) {
      return
    }
    if (!timezone) {
      return new Date(datetime.replace('Z', ''))
    }
    return new Date(
      new Date(datetime.replace('Z', '')).toLocaleString('en-US', {timeZone: timezone}),
    )
  }

  // static getFormatedDateStringWithTimezone(datetime, timezone) {
  //   /**
  //    * Generate a string containing a formatted date for a given
  //    * time zone.
  //    * @param {datetime} str A string containing a date in the format
  //    * "Weekday Month(abbr.) DD YYYY HH:MM:SS GMT[+XXXX][-XXXX]
  //    * (Time Zone Name)".
  //    * @param {timezone} str A string containing the time zone "UTC" or
  //    * a time zone name listed in the "IANA time zone database".
  //    * @returns {datetimeFormatted} str A string containing a date in
  //    * the format "YYYY-MM-DDTHH:MM[Z][+XXXX][-XXXX]".
  //    */
  //   return moment.tz(datetime, 'ddd MMM D YYYY HH:mm:ss', timezone).format('YYYY-MM-DDTHH:mmZ')
  // }
}
