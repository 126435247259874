import React, {useCallback, useEffect, useRef, useState} from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import {Button, Grid, Paper, Typography, withStyles} from '@material-ui/core'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {
  BigPlayButton,
  ControlBar,
  CurrentTimeDisplay,
  PlaybackRateMenuButton,
  Player,
  VolumeMenuButton,
} from 'video-react'
import CompareArrowsIcon from '@material-ui/icons/CompareArrows'
import {initialFetch} from '../../constants/CreativeConclusions'

import useModalState from '../../../hooks/useModalState'

import MultipleSentimentChart from './MultipleSentimentChart'
import SentimentDropdown from './SentimentDropdown'
import InfoButtons from '../../common/InfoButtons'
import InfoModal from '../../common/InfoModal'
import TestDetailHeader from '../../common/TestDetailHeader'
import Loading from '../../common/Loading'
import ExportButton from '../../common/ExportButton'
import ExportSnapshotButton from '../../common/ExportSnapshotButton'
import CompareDemographicsModalSentiments from '../../common/CompareDemographicsModalSentiments'

import {
  getCampaign,
  getDemographicsWithValues,
  getSentimentBenchmarks,
  getDefaultEmotions,
  resetState,
} from '../redux/actions'

import sentimentInfo from './sentimentInfo'
import {creativeSentiments, legalSentiments, emotionDisplayNames} from './constants'

import utils from '../utils'

import styles from '../styles/Rapport.styles'

import DemographicGroupDetailsModal from './DemographicGroupDetailsModal'

let Chart = null

const SentimentMap = props => {
  if (!Chart) Chart = require('react-apexcharts').default

  const {
    groups,
    classes,
    campaign,
    params,
    isFetchingEmotions,
    isFetchingCampaign,
    demographicsWithValues,
    customGroupEmotionsByTime,
    responseCount,
    getCampaign,
    getDefaultEmotions,
    getDemographicsWithValues,
    getSentimentBenchmarks,
    isFetchingSentimentBenchmarks,
    sentimentBenchmarks,
    defaultEmotions,
    resetState,
    showSentimentSelector,
    fixedSentiment,
  } = props

  const [hasFetchedDemographics, setHasFetchedDemographics] = useState(false)
  const [selectParents, setSelectParents] = useState([])
  const [selectChildren, setSelectChildren] = useState({})
  const [textModalOpen, openTextModal, closeTextModal] = useModalState(true)
  const [videoModalOpen, openVideoModal, closeVideoModal] = useModalState(false)
  const [detailsModalOpen, openDetailsModal, closeDetailsModal] = useModalState(false)
  const [video, setVideo] = useState({})
  const [playerState, setPlayerState] = useState({})
  const [hasSubscribedPlayer, setHasSubscribedPlayer] = useState(false)
  const [currentEmotion, setCurrentEmotion] = useState(fixedSentiment || '')
  const [modalContent, setModalContent] = useState({})
  const [
    compareDemographicsOpen,
    openCompareDemographicsModal,
    closeCompareDemographicsModal,
  ] = useModalState(false)
  const [isComparing, setIsComparing] = useState(false)
  const [defaultGroups, setDefaultGroups] = useState([initialFetch])
  const [demographicLabelsTable, setDemographicLabelsTable] = useState({})
  const [groupEmotionsByTime, setGroupEmotionsByTime] = useState({groups: []})

  const player = useRef(null)

  const emotionDisplayName = emotionDisplayNames[currentEmotion]

  useEffect(() => {
    if (campaign.campaign.id !== Number(params.id)) {
      getCampaign(params.id, {expand: 'lists'})
    }
    if (!utils.checkFirstTimeVisit(`hasVisited${emotionDisplayName}Map`)) {
      closeTextModal()
    }
  }, [])

  useEffect(() => {
    if (player.current && !hasSubscribedPlayer) {
      player.current.subscribeToStateChange(handleStateChange)
      setHasSubscribedPlayer(true)
    }
  }, [player.current])

  useEffect(() => {
    if (campaign.campaign.id === Number(params.id)) {
      const {campaignVideoId} = campaign.campaign.campaignVideos[0]

      const allGroups = [...defaultGroups]

      if (groups) {
        const tempDefaultGroups = groups.map((group, index) => {
          const groupObject = utils.createGroupObject(group.demographics)

          return {
            ...groupObject,
            fields: Object.keys(groupObject),
            type: 'creative',
            isDefault: true,
            group: group.name || `Group ${index + 1}`, // update groups in BE to have a default name if no name was set
          }
        })

        allGroups.push(...tempDefaultGroups)

        setDefaultGroups(prevState => [...prevState, ...tempDefaultGroups])
      }

      if (!fixedSentiment) {
        setCurrentEmotion(
          utils.isLegalCampaign(campaign.campaign) ? legalSentiments[0] : creativeSentiments[0],
        )
      }

      if (!hasFetchedDemographics) {
        getSentimentBenchmarks()
        getDemographicsWithValues(campaignVideoId)
        setHasFetchedDemographics(true)
      }

      getDefaultEmotions(campaignVideoId)

      if (campaign.campaign.videos) {
        setVideo(campaign.campaign.videos[0])
      }
    }
  }, [campaign.campaign])

  useEffect(() => {
    if (defaultEmotions?.groups) {
      const newGroups = [...defaultEmotions.groups, ...customGroupEmotionsByTime.groups]

      setGroupEmotionsByTime({
        ...defaultEmotions,
        groups: newGroups,
      })

      setModalContent(
        utils.getDemographicDetailsModalContent(
          defaultEmotions.total,
          defaultEmotions.groups,
          customGroupEmotionsByTime.groups,
        ),
      )
    }
  }, [defaultEmotions])

  useEffect(() => {
    if (customGroupEmotionsByTime?.groups?.length) {
      const newGroups = [...defaultEmotions.groups, ...customGroupEmotionsByTime.groups]

      setGroupEmotionsByTime(prevState => ({
        ...prevState,
        groups: newGroups,
      }))

      setModalContent(
        utils.getDemographicDetailsModalContent(
          defaultEmotions.total,
          defaultEmotions.groups,
          customGroupEmotionsByTime.groups,
        ),
      )
    }
  }, [customGroupEmotionsByTime])

  useEffect(() => {
    if (campaign.campaign.id === Number(params.id)) {
      if (hasFetchedDemographics && !_.isEmpty(demographicsWithValues)) {
        const [parents, children] = utils.getSelectParentsAndChildren(demographicsWithValues)

        setSelectParents(parents)
        setSelectChildren(children)

        const labelsTable = utils.getDemographicLabelsTable(demographicsWithValues)
        setDemographicLabelsTable(labelsTable)
      }
    }
  }, [demographicsWithValues])

  const clearResults = () => {
    setGroupEmotionsByTime(defaultEmotions)

    setModalContent(
      utils.getDemographicDetailsModalContent(defaultEmotions.total, defaultEmotions.groups),
    )

    setIsComparing(false)

    resetState(['groupEmotionsByTime'])
  }

  const handleStateChange = state => {
    setPlayerState(state)
  }

  const seek = useCallback(seconds => {
    player.current.play()
    player.current.seek(seconds)
  }, [])

  const videoRef = useCallback(ref => {
    if (ref) player.current = ref
  }, [])

  const renderSentimentsExplainedText = () => {
    return (
      <Typography variant="body1" style={{maxWidth: '65ch'}}>
        <strong>What are maps?</strong> Maps illustrate the pattern of emotional reactions elicited
        by the audience while watching the content. Maps can be filtered to view data by a variety
        of audience profiles.
        <br />
        <br />
        <strong>What is the {emotionDisplayName} Map? </strong>
        {sentimentInfo[currentEmotion]?.about}
        <br />
        <br />
        <strong>What is a good {emotionDisplayName} map? </strong>
        {sentimentInfo[currentEmotion]?.qualityCriteria}
        <br />
        <br />
        <strong>How to read this map:</strong>
        <img
          src={sentimentInfo[currentEmotion]?.image}
          alt={`${emotionDisplayName} Map`}
          style={{width: '100%'}}
        />
        <br />
        <br />
        <strong>1. Purpose of the Mean Line: </strong>
        {sentimentInfo[currentEmotion]?.meanLinePurpose}
        <br />
        <br />
        <strong>2. Onboarding: </strong>
        {sentimentInfo[currentEmotion]?.onboarding}
        <br />
        <br />
        <strong>3. Understanding the trajectory: </strong>
        {sentimentInfo[currentEmotion]?.trajectory}
        <br />
        <br />
        <strong>4. Peak End Rule: </strong>
        {sentimentInfo[currentEmotion]?.peakEndRule}
        <br />
        <br />
        <strong>What do I do with this data?</strong> {sentimentInfo[currentEmotion]?.conclusion}
      </Typography>
    )
  }

  const renderModals = () => {
    return (
      <>
        <InfoModal open={textModalOpen} onClose={closeTextModal}>
          <>
            <Typography variant="h4">{emotionDisplayName} Explained</Typography>
            {renderSentimentsExplainedText()}
          </>
        </InfoModal>
        <InfoModal open={videoModalOpen} onClose={closeVideoModal}>
          <>
            <Typography variant="h4">{emotionDisplayName} Explained</Typography>
            <iframe
              width="750"
              height="480"
              src="https://www.youtube.com/embed/UY-vnbnsaDc?&autoplay=1"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </>
        </InfoModal>
        {!_.isEmpty(modalContent) && (
          <DemographicGroupDetailsModal
            detailsModalOpen={detailsModalOpen}
            closeDetailsModal={closeDetailsModal}
            modalContent={modalContent}
            demographicLabelsTable={demographicLabelsTable}
          />
        )}
      </>
    )
  }

  return (
    <Grid container className={classes.rapportMapContainer}>
      {renderModals()}
      <TestDetailHeader
        title={fixedSentiment ? `${emotionDisplayName} Map` : 'Sentiment Moods'}
        responseCount={responseCount}
      />
      <Paper elevation={0} className={classes.rapportMapPaper}>
        <Grid container>
          <Grid item xs />
          <Grid item className={classes.paperButtonContainer}>
            <div style={{display: 'none'}}>
              <ExportButton
                endpoint={
                  campaign.campaign.campaignVideos
                    ? `/campaign-videos/${campaign.campaign.campaignVideos[0].campaignVideoId}/emotions-by-time-csv`
                    : ''
                }
              />
            </div>
            <ExportSnapshotButton targetId="multiple-sentiment-chart" exportName="SentimentMap" />
            <Grid container className={classes.paperHeader}>
              <Grid item xs className={classes.paperHeaderTextContainer} />
              <Grid item className={classes.paperButtonContainer}>
                {isFetchingEmotions ||
                isFetchingCampaign ||
                (groups && groups.length === groupEmotionsByTime.groups.length) ||
                groupEmotionsByTime.groups.length === 0 ? null : (
                  <Button
                    variant="contained"
                    className={classes.clearResultsButton}
                    onClick={clearResults}
                  >
                    Clear Results
                  </Button>
                )}
                {isFetchingEmotions || isFetchingCampaign ? null : (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.compareButton}
                    onClick={openCompareDemographicsModal}
                    style={{height: '40px'}}
                  >
                    <CompareArrowsIcon fontSize="small" className={classes.buttonIcon} />
                    Compare
                  </Button>
                )}
                <CompareDemographicsModalSentiments
                  selectParents={selectParents}
                  selectChildren={selectChildren}
                  defaultGroups={defaultGroups}
                  open={compareDemographicsOpen}
                  onClose={closeCompareDemographicsModal}
                  isComparing={isComparing}
                  setIsComparing={setIsComparing}
                />
              </Grid>
            </Grid>
            {showSentimentSelector && (
              <SentimentDropdown
                emotions={
                  utils.isLegalCampaign(campaign.campaign) ? legalSentiments : creativeSentiments
                }
                currentEmotion={currentEmotion}
                setCurrentEmotion={setCurrentEmotion}
              />
            )}
            <InfoButtons openTextModal={openTextModal} openVideoModal={openVideoModal} />
          </Grid>
        </Grid>
        {!_.isEmpty(video) && (
          <Grid container className={classes.videoContainer}>
            <Player
              fluid={false}
              height={320}
              aspectRatio="auto"
              playsInline
              src={video.videoFile}
              poster={video.thumbnail ? video.thumbnail.fullSize : ''}
              ref={videoRef}
            >
              <BigPlayButton position="center" />
              <ControlBar>
                <CurrentTimeDisplay order={4.1} />
                <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                <VolumeMenuButton vertical />
              </ControlBar>
            </Player>
          </Grid>
        )}
        {isFetchingEmotions || isFetchingCampaign ? null : (
          <Typography
            style={{
              fontWeight: '500',
              fontSize: '1.2rem',
            }}
          >
            Demographics:
            {!_.isEmpty(modalContent) && (
              <Typography
                component="span"
                onClick={openDetailsModal}
                className={classes.filterDetailsButton}
              >
                Details
              </Typography>
            )}
          </Typography>
        )}
        {isFetchingEmotions || isFetchingCampaign || isFetchingSentimentBenchmarks ? (
          <Loading />
        ) : (
          <>
            <MultipleSentimentChart
              showYAxis
              showMeanLine={false}
              sentimentBenchmarks={sentimentBenchmarks}
              emotionsByTime={groupEmotionsByTime.total?.events}
              emotionsByGroup={groupEmotionsByTime.groups}
              video={video}
              currentTime={playerState.currentTime}
              isPaused={playerState.paused}
              seek={seek}
              duration={video.duration}
              currentEmotion={currentEmotion}
              isEmpty={_.isEmpty(groupEmotionsByTime.total?.events)}
              Chart={Chart}
            />
          </>
        )}
      </Paper>
    </Grid>
  )
}

SentimentMap.propTypes = {
  campaign: PropTypes.objectOf(PropTypes.any).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  demographicsWithValues: PropTypes.objectOf(PropTypes.object).isRequired,
  customGroupEmotionsByTime: PropTypes.objectOf(PropTypes.any).isRequired,
  emotionsByTime: PropTypes.objectOf(PropTypes.object).isRequired,
  defaultEmotions: PropTypes.objectOf(PropTypes.any).isRequired,
  getCampaign: PropTypes.func.isRequired,
  getDemographicsWithValues: PropTypes.func.isRequired,
  isFetchingCampaign: PropTypes.bool.isRequired,
  isFetchingEmotions: PropTypes.bool.isRequired,
  params: PropTypes.objectOf(PropTypes.string).isRequired,
  responseCount: PropTypes.number,
}

SentimentMap.defaultProps = {
  responseCount: 0,
}

const mapStateToProps = ({campaign}) => ({
  campaign,
  groups: campaign.campaign.targetAudiences,
  campaignType: campaign.campaign.campaignType,
  isFetchingCampaign: campaign.isFetching,
  emotionsByTime: campaign.emotionsByTime.events,
  responseCount: campaign.defaultEmotions?.results?.total?.responseCount,
  demographicsWithValues: campaign.demographicsWithValues.values,
  customGroupEmotionsByTime: campaign.groupEmotionsByTime,
  isFetchingEmotions:
    campaign.defaultEmotions.isFetching || campaign.groupEmotionsByTime.isFetching,
  defaultEmotions: campaign.defaultEmotions.results,
  sentimentBenchmarks: campaign.sentimentBenchmarks,
  isFetchingSentimentBenchmarks: campaign.sentimentBenchmarks.isFetching,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCampaign,
      getDemographicsWithValues,
      getSentimentBenchmarks,
      getDefaultEmotions,
      resetState,
    },
    dispatch,
  )

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(SentimentMap)
