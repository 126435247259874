import React from 'react'
import PropTypes from 'prop-types'
import {ClickAwayListener, Tooltip, withStyles} from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

const styles = theme => ({
  tooltip: {
    padding: theme.spacing.unit * 2,
    maxWidth: 400,
    background: '#002F5F',
    borderRadius: '5px',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '18px',
  },
  icon: {
    position: 'absolute',
    left: 'calc(100% + 4px)',
    top: '-4px',
    fontSize: 16,
  },
})

const InfoTooltip = ({
  classes,
  text,
  placement,
  iconProps,
  open,
  onClose,
  showIcon,
  children,
  ...props
}) => {
  if (!text) {
    return null
  }

  const openControl = open !== null ? {open} : {}

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Tooltip
        classes={{tooltip: classes.tooltip}}
        {...props}
        {...openControl}
        onClose={onClose}
        title={text}
        placement={placement}
      >
        {showIcon ? (
          <HelpOutlineIcon className={classes.icon} color="primary" {...iconProps} />
        ) : (
          {...children}
        )}
      </Tooltip>
    </ClickAwayListener>
  )
}

InfoTooltip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  placement: PropTypes.string,
  open: PropTypes.bool,
  showIcon: PropTypes.bool,
  onClose: PropTypes.func,
  iconProps: PropTypes.objectOf(PropTypes.any),
}

InfoTooltip.defaultProps = {
  iconProps: {},
  onClose: () => {},
  open: null,
  showIcon: true,
  placement: 'bottom',
}

export default withStyles(styles)(InfoTooltip)
