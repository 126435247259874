import React from 'react'
import {Grid, withStyles} from '@material-ui/core'

const styles = () => ({
  emptyTabContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 24,
  },
  stripesWrapper: {
    width: '100%',
    display: 'grid',
    gridGap: '24px',
    margin: '32px 0',
  },
  stripe: {
    width: '80%',
    borderRadius: 5,
    height: 24,
    background: '#F8FAFA',
  },
  button: {
    marginTop: 'auto',
    width: 100,
    height: 36,
    background: '#F8FAFA',
    borderRadius: 5,
    alignSelf: 'flex-end',
  },
})

const EmptyTab = ({classes}) => {
  return (
    <Grid item lg={3} md={6} sm={12} className={classes.emptyTabContainer}>
      <Grid container className={classes.stripesContainer}>
        <div className={classes.stripesWrapper}>
          <div className={classes.stripe} style={{width: '80%'}} />
          <div className={classes.stripe} style={{width: '100%'}} />
          <div className={classes.stripe} style={{width: '50%'}} />
        </div>
      </Grid>
      <Grid container className={classes.stripesContainer}>
        <div className={classes.stripesWrapper}>
          <div className={classes.stripe} style={{width: '30%'}} />
          <div className={classes.stripe} style={{width: '70%'}} />
          <div className={classes.stripe} style={{width: '100%'}} />
        </div>
      </Grid>
      <Grid container className={classes.stripesContainer}>
        <div className={classes.stripesWrapper}>
          <div className={classes.stripe} style={{width: '80%'}} />
          <div className={classes.stripe} style={{width: '100%'}} />
          <div className={classes.stripe} style={{width: '50%'}} />
        </div>
      </Grid>
      <div className={classes.button} />
    </Grid>
  )
}

export default withStyles(styles)(EmptyTab)
