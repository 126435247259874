import * as Types from './actions.types'

export function getProfile() {
  return {type: Types.PROFILE_GET}
}

export function getProfileSuccess(profile) {
  return {type: Types.PROFILE_GET_SUCCESS, payload: profile}
}

export function getProfileFailure(error) {
  return {type: Types.PROFILE_GET_FAILURE, error}
}

export function createProfile(profile) {
  return {type: Types.PROFILE_CREATE, payload: profile}
}

export function createProfileSuccess(profile) {
  return {type: Types.PROFILE_CREATE_SUCCESS, payload: profile}
}

export function createProfileFailure(error) {
  return {type: Types.PROFILE_CREATE_FAILURE, error}
}

export function cleanProfileError(fieldName) {
  return {type: Types.PROFILE_CLEAN_ERROR, fieldName}
}

export function createProfileInit() {
  return {type: Types.PROFILE_CREATE_INIT}
}

export function initProfileEdit() {
  return {type: Types.PROFILE_EDIT_INIT}
}

export function updateProfile(update) {
  return {type: Types.PROFILE_UPDATE, payload: update}
}

export function saveProfile(id, payload) {
  return {type: Types.PROFILE_SAVE, id, payload}
}

export function toggleProfileEdit() {
  return {type: Types.PROFILE_TOGGLE_EDIT}
}

export function saveProfileSuccess(profile) {
  return {type: Types.PROFILE_SAVE_SUCCESS, payload: profile}
}

export function saveProfileFailure(error) {
  return {type: Types.PROFILE_SAVE_FAILURE, error}
}

export function changePassword(currentPassword, newPassword) {
  return {type: Types.PROFILE_CHANGE_PASSWORD, currentPassword, newPassword}
}

export function changePasswordSuccess(response) {
  return {type: Types.PROFILE_CHANGE_PASSWORD_SUCCESS, response}
}

export function changePasswordFailure(error) {
  return {type: Types.PROFILE_CHANGE_PASSWORD_FAILURE, error}
}

export function updateChangePassword(update) {
  return {type: Types.PROFILE_CHANGE_PASSWORD_UPDATE, update}
}
