import React from 'react'
import VideocamIcon from '@material-ui/icons/Videocam'

import InfoButton from './InfoButton'

import infoIcon from '../../assets/icons/info.svg'

const InfoButtons = ({openTextModal, openVideoModal}) => {
  return (
    <>
      <InfoButton icon={infoIcon} alt="info" onClick={() => openTextModal()} />
      <div style={{display: 'none'}}>
        <InfoButton
          icon={<VideocamIcon color="primary" fontSize="small" />}
          alt="tutorial"
          onClick={() => openVideoModal()}
        />
      </div>
    </>
  )
}

export default InfoButtons
