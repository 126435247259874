import React from 'react'
import PropTypes from 'prop-types'
import {IconButton, withStyles} from '@material-ui/core'

const styles = () => ({
  infoButton: {
    borderRadius: 8,
    background: 'white',
    border: '2px solid #b0b1b31f',
    position: 'relative',
    padding: 3,
    width: 40,
    height: 40,
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '90%',
      height: '90%',
      background: '#b0b1b31f',
      borderRadius: 8,
    },
    '& img': {
      width: '70%',
      zIndex: 1,
    },
  },
})

const InfoButton = ({classes, icon, alt, onClick}) => {
  return (
    <IconButton onClick={onClick} aria-label={alt} className={classes.infoButton}>
      {typeof icon === 'string' ? <img src={icon} alt={alt} /> : icon}
    </IconButton>
  )
}

InfoButton.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  alt: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(InfoButton)
