import React from 'react'
import PropTypes from 'prop-types'
import {Paper, Modal, IconButton, withStyles} from '@material-ui/core'

import CloseRoundedIcon from '@material-ui/icons/CloseRounded'

const styles = theme => ({
  modalBody: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing.unit * 2,
    overflowY: 'auto',
  },
  infoModalContainer: {
    position: 'relative',
    padding: theme.spacing.unit * 6,
    margin: 'auto',
    '&:focus-visible': {
      outline: 'none',
    },
    '& p': {
      color: theme.palette.grey[800],
      fontWeight: 500,
      maxWidth: '55ch',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[800],
  },
})

const InfoModal = ({classes, open = true, onClose, children, paperProps}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="info-modal"
      aria-describedby="info-modal"
      className={classes.modalBody}
    >
      <Paper elevation={1} className={classes.infoModalContainer} {...paperProps}>
        <IconButton onClick={onClose} className={classes.closeButton} aria-label="close">
          <CloseRoundedIcon />
        </IconButton>
        {children}
      </Paper>
    </Modal>
  )
}

InfoModal.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  paperProps: PropTypes.objectOf(PropTypes.any),
}

InfoModal.defaultProps = {
  paperProps: {},
}

export default withStyles(styles)(InfoModal)
