import React, {useState} from 'react'

import NewTestErrorContext from './NewTestErrorContext'

const NewTestErrorProvider = ({children}) => {
  const [videoError, setVideoError] = useState('')
  const [nameError, setNameError] = useState('')
  const [caseTypeError, setCaseTypeError] = useState('')
  const [objectivesMomentsError, setObjectivesMomentsError] = useState('')
  const [surveyQuestionError, setSurveyQuestionError] = useState('')
  const [surveyChoiceErrors, setSurveyChoiceErrors] = useState({})
  const [momentNameErrors, setMomentNameErrors] = useState({})
  const [momentStartTimeErrors, setMomentStartTimeErrors] = useState({})
  const [momentEndTimeErrors, setMomentEndTimeErrors] = useState({})
  const [audienceInputErrors, setAudienceInputErrors] = useState({demographicsSelector: {}})
  const [audienceGroupErrors, setAudienceGroupErrors] = useState({}) // {groupName: 'error message'} or {groupName: false}
  const [csvError, setCsvError] = useState('')

  const handleAudienceGroupErrors = newValue => {
    setAudienceGroupErrors(prevState => ({...prevState, ...newValue}))
  }

  const hasAudienceGroupErrors = () => {
    return Object.values(audienceGroupErrors).reduce((acc, current) => acc + current, false)
  }

  const hasErrors = () => {
    let errors =
      Boolean(videoError) +
      Boolean(nameError) +
      Boolean(caseTypeError) +
      Boolean(objectivesMomentsError) +
      Boolean(surveyQuestionError) +
      Boolean(csvError)

    Object.values(momentNameErrors).forEach(value => {
      errors += Boolean(value)
    })

    Object.values(momentStartTimeErrors).forEach(value => {
      errors += Boolean(value)
    })

    Object.values(momentEndTimeErrors).forEach(value => {
      errors += Boolean(value)
    })

    Object.values(audienceInputErrors).forEach(value => {
      Object.values(value).forEach(selectorValue => {
        errors += Boolean(selectorValue)
      })
    })

    Object.values(surveyChoiceErrors).forEach(value => {
      errors += Boolean(value)
    })

    errors += hasAudienceGroupErrors()

    return Boolean(errors)
  }

  return (
    <NewTestErrorContext.Provider
      value={{
        videoError,
        setVideoError,
        nameError,
        setNameError,
        caseTypeError,
        setCaseTypeError,
        objectivesMomentsError,
        setObjectivesMomentsError,
        momentNameErrors,
        setMomentNameErrors,
        momentStartTimeErrors,
        setMomentStartTimeErrors,
        momentEndTimeErrors,
        setMomentEndTimeErrors,
        audienceInputErrors,
        setAudienceInputErrors,
        surveyQuestionError,
        setSurveyQuestionError,
        surveyChoiceErrors,
        setSurveyChoiceErrors,
        audienceGroupErrors,
        handleAudienceGroupErrors,
        csvError,
        setCsvError,
        hasAudienceGroupErrors,
        hasErrors,
      }}
    >
      {children}
    </NewTestErrorContext.Provider>
  )
}

export default NewTestErrorProvider
