import React from 'react'
import ReactDOM from 'react-dom'
import {browserHistory} from 'react-router'
import {createGenerateClassName} from '@material-ui/core/styles'
import {syncHistoryWithStore} from 'react-router-redux'
import UserSession from './shared/auth/UserSession'
import theme from './shared/theme'
import './styles/main.scss'
import Root from './Root.jsx'
import store from './store.js'

// Export React so the dev tools can find it
if (window === window.top) {
  window.React = React
}

UserSession.setClientAuthCookie()

const history = syncHistoryWithStore(browserHistory, store)

// Create a new class name generator.
const generateClassName = createGenerateClassName()

const renderApp = () => {
  ReactDOM.hydrate(
    <Root store={store} history={history} theme={theme} generateClassName={generateClassName} />,
    document.getElementById('app'),
  )
}

renderApp()

if (module.hot) {
  module.hot.accept('./Root.jsx', () => renderApp())
}
