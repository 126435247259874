import React from 'react'
import PropTypes from 'prop-types'
import {Chip, Grid, Typography, withStyles} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import GenderSelector from '../GenderSelector'

import styles from './styles'

const DemographicSelectionsDisplay = props => {
  const {classes, groupTempState, removeDemographic, updateDemographic, searchSelectRef} = props

  const renderSubDemographics = demographic => {
    const {subDemographics} = demographic

    return subDemographics.map(subDemographic => (
      <Chip
        key={subDemographic.option}
        tabIndex={-1}
        className={classes.demographicChip}
        label={subDemographic.displayName}
        color="primary"
        onDelete={() => {
          updateDemographic(
            demographic.key,
            subDemographics.filter(
              currentSubDemographic => currentSubDemographic.option !== subDemographic.option,
            ),
          )
        }}
        deleteIcon={<CloseIcon className={classes.deleteIcon} />}
      />
    ))
  }

  const handleLabelClick = demographic => {
    const select = searchSelectRef.current?.select

    if (select) {
      select.setValue({value: demographic.key, label: demographic.label})
    }
  }

  return (
    <Grid className={classes.container}>
      <GenderSelector
        groupTempState={groupTempState}
        updateDemographic={updateDemographic}
        labelStyles={{fontWeight: 400}}
      />

      {groupTempState?.demographics
        .filter(({key}) => key !== 'gender')
        .map(demographic => (
          <Grid key={demographic.key} className={classes.demographicContainer}>
            <Grid className={classes.header}>
              <Typography className={classes.label} onClick={() => handleLabelClick(demographic)}>
                {demographic.label}
              </Typography>
              <Typography
                className={classes.removeDemographicButton}
                onClick={() => removeDemographic(demographic.key)}
              >
                Remove
              </Typography>
            </Grid>
            {renderSubDemographics(demographic)}
          </Grid>
        ))}
    </Grid>
  )
}

DemographicSelectionsDisplay.propTypes = {
  groupTempState: PropTypes.shape({
    name: PropTypes.string.isRequired,
    totalCompletes: PropTypes.number.isRequired,
    daysInField: PropTypes.number.isRequired,
    demographics: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  removeDemographic: PropTypes.func.isRequired,
  updateDemographic: PropTypes.func.isRequired,
  searchSelectRef: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default withStyles(styles)(DemographicSelectionsDisplay)
