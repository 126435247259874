import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Dialog, Grid, IconButton, Typography, withStyles} from '@material-ui/core'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'

import CsvUploadArea from './CsvUploadArea'
import RejectedFile from '../../../video/RejectedFile'

const styles = theme => ({
  uploadButton: {
    width: '70%',
  },
  dialogPaper: {
    padding: theme.spacing.unit * 4,
  },
  dialogHeader: {
    position: 'relative',
    '& h5': {
      fontWeight: 500,
    },
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
})

const UploadCsvModal = props => {
  const {classes, uploadFile, setCsvFile, setIsConvertingCsv, uploadModalOpen, closeModal} = props

  const [isFileRejected, setIsFileRejected] = useState(false)
  const [rejectedFileError, setRejectedFileError] = useState('')

  const renderTabContent = () => {
    return (
      <>
        <CsvUploadArea
          setCsvFile={setCsvFile}
          uploadFile={uploadFile}
          isFileRejected={isFileRejected}
          setIsFileRejected={setIsFileRejected}
          setRejectedFileError={setRejectedFileError}
          setIsConvertingCsv={setIsConvertingCsv}
          closeModal={closeModal}
        />
        {isFileRejected ? <RejectedFile error={rejectedFileError} /> : null}
      </>
    )
  }

  return (
    <>
      <Dialog open={uploadModalOpen} onClose={closeModal} classes={{paper: classes.dialogPaper}}>
        <Grid container className={classes.dialogHeader}>
          <Typography variant="h5">Upload CSV</Typography>
          <IconButton onClick={closeModal} className={classes.closeButton} aria-label="close">
            <CloseRoundedIcon />
          </IconButton>
        </Grid>

        {renderTabContent()}
      </Dialog>
    </>
  )
}

UploadCsvModal.propTypes = {
  setCsvFile: PropTypes.func.isRequired,
  uploadModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default withStyles(styles)(UploadCsvModal)
