import React, {useState} from 'react'
import {Link} from 'react-router'
import {withStyles} from '@material-ui/core/styles'
import {Button, Grid, IconButton, InputBase, Paper, Typography} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import TutorialVideoButton from './Tutorials/TutorialVideoButton'
import InfoModal from '../common/InfoModal'
import styles from './styles/EmptyCampaign.styles'
import {videoModalKey} from '../constants/ModalKeys'
import EmptyCampaignImg from '../../assets/icons/emptyCampaign.svg'
import searchIcon from '../../assets/icons/search.svg'

import WelcomeModal from './WelcomeModal'

const EmptyCampaign = ({classes}) => {
  const [videoModalOpen, setVideoModalOpen] = useState(false)
  const [videoModalURL, setVideoModalURL] = useState('')
  const [videoModalTitle, setVideoModalTitle] = useState('')

  const modalTable = {
    [videoModalKey]: value => setVideoModalOpen(value),
  }

  const openModal = modalKey => {
    modalTable[modalKey](true)
  }

  const closeModal = modalKey => {
    modalTable[modalKey](false)
  }

  const renderModals = () => {
    return (
      <>
        <InfoModal open={videoModalOpen} onClose={() => closeModal(videoModalKey)}>
          <>
            <Typography variant="h4" style={{marginBottom: 0}}>
              {videoModalTitle}
            </Typography>
            <iframe
              width="750"
              height="480"
              src={videoModalURL}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </>
        </InfoModal>
        <WelcomeModal />
      </>
    )
  }

  return (
    <div className={classes.root}>
      {renderModals()}
      <div className={classes.myCampaigns}>
        <div>My Tests</div>
        <Paper className={classes.searchInput}>
          <InputBase className={classes.input} placeholder="Search Tests" />
          <IconButton className={classes.iconButton} aria-label="Search">
            <img className={classes.icon} src={searchIcon} alt="search" />
          </IconButton>
        </Paper>
      </div>
      <div className={classes.tableContainer}>
        <img src={EmptyCampaignImg} alt="empty campaign" />
        <div style={{marginBottom: '6px'}}>Looks like you have no tests yet!</div>
        <div>Why don't you create your first one?</div>
        <Button
          component={Link}
          to="/new-test"
          className={classes.newTestButton}
          variant="contained"
          color="primary"
        >
          <AddIcon fontSize="small" className={classes.buttonIcon} />
          Create New Test
        </Button>
        <div>
          <Typography variant="h5" style={{paddingLeft: '80px', fontWeight: 500}}>
            Informational Videos
          </Typography>
          <div className={classes.informationalVideos}>
            <Grid container className={classes.buttonsContainer}>
              <Grid>
                <TutorialVideoButton
                  title="Testing Content with EmotionTrac"
                  time="2:44"
                  openVideoModal={() => {
                    openModal(videoModalKey)
                    setVideoModalURL(
                      'https://player.vimeo.com/video/764341009?h=3595257795&app_id=122963',
                    )
                    setVideoModalTitle('Testing Content with EmotionTrac')
                  }}
                />
              </Grid>
              <Grid>
                <TutorialVideoButton
                  title="Setting Up Your Test"
                  time="3:46"
                  openVideoModal={() => {
                    openModal(videoModalKey)
                    setVideoModalURL(
                      'https://player.vimeo.com/video/764340482?h=f4474afd88&app_id=122963',
                    )
                    setVideoModalTitle('Setting Up Your Test')
                  }}
                />
              </Grid>
              <Grid>
                <TutorialVideoButton
                  title="Navigating Test Results"
                  time="5:14"
                  openVideoModal={() => {
                    openModal(videoModalKey)
                    setVideoModalURL(
                      'https://player.vimeo.com/video/764339750?h=ce1b682cd8&app_id=122963',
                    )
                    setVideoModalTitle('Navigating Test Results')
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(EmptyCampaign)
