import * as Types from './actions.types'

export function detectEngage(image) {
  return {type: Types.DETECT_ENGANGE, image}
}

export function detectEngageSuccess(payload) {
  return {type: Types.DETECT_ENGANGE_SUCCESS, payload}
}

export function detectEngageFailure(error) {
  return {type: Types.DETECT_ENGANGE_FAILURE, error}
}

export function detectEngageClear() {
  return {type: Types.DETECT_ENGANGE_CLEAR}
}
