export const genders = {
  male: 'male',
  female: 'female',
  all: 'all',
}

export const gender = 'gender'

export const genderQueryTable = {
  male: ['male'],
  female: ['female'],
  all: ['male', 'female'],
}

export const initialFetch = {
  fields: ['gender'],
  gender: ['male', 'female'],
  type: 'creative',
  group: 'All Panelists',
  isDefault: true,
}
