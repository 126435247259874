// default
const onboarding =
  'Study the early frames to see how quickly it takes the audience to connect with your messaging.'
const trajectory =
  'Observe the emerging pattern of the Map which identifies the moments in time that are forming an empathetic connection and which ones are not.'
const peakEndRule =
  'The mood you leave them in is the mood they take away. This is represented by the trajectory of the final moments landing at the Mean Line or above.'
const meanLinePurpose =
  'The Mean Line provides you with a point of reference on your map. Use this line to observe how the sentiments trend above average and where it scores below the average.'
const qualityCriteria =
  'A Map with friction. This is illustrated by repeating spikes and dips above and below the Mean Line. Spikes represent positive moments and Dips depict weak moments.'
const conclusion =
  'Compare different moments in your trajectory and identify the ones that have the most impact. This will help you optimize your narrative and increase the level of empathy and persuasion you can achieve. Be sure to eliminate any weaker or misunderstood elements in your story that do not resonate with your audience and are likely to detract from your arguments.'
const legalConclusion =
  'The first step in analyzing your data is to filter it by demographics, such as age, gender, etc., that can impact behavior and emotions. By doing so, we can identify patterns and differences in how different groups of people respond to your story and use that information to optimize it for maximum impact. At the same time, you should also look for weaker, misunderstood, elements of your story and eliminate them. These are the parts that do not resonate with your audience and are likely to detract from your message.'

const sentimentInfo = {
  angry: {
    image: require('../../../assets/charts/angry.png'),
    about:
      'This map illustrates the pattern of critical reactions as elicited by the viewing audience. ' +
      'It identifies the mood of the viewer moment by moment while watching the content. ' +
      'A Critical mood is triggered when the audience is Annoyed, Frustrated or Angered by the content.',
    qualityCriteria,
    onboarding,
    trajectory,
    meanLinePurpose,
    peakEndRule,
    conclusion:
      'Observe where your content is evoking the most Criticism with the audience, ' +
      'and where your message can be optimized by eliminating the weaker areas of your content.',
  },
  angst: {
    image: require('../../../assets/charts/angst.png'),
    about:
      'The Angst Map illustrates where and when the audience experiences Angst as represented through the spikes and dips. Angst that triggers the Audience, indicates they are emotionally invested in your story. This map compounds three sentiments: Fear, Despair and Anger.',
    qualityCriteria:
      'A good Angst Map is illustrated by a repeating pattern of spikes and dips above and below the Mean Line. A pattern of continuous friction confirms the audience was aroused and triggered by the content and formed an empathetic connection with your story.',
    meanLinePurpose,
    onboarding: 'Study the early frames to see how quickly it takes the audience to trigger Angst.',
    trajectory:
      'Look for an Angst Map rich with friction, represented by spikes and dips from beginning to end. Friction based Maps tell us the Audience is reacting to the Anguish you strongly want to trigger and identify with your case story.',
    peakEndRule:
      'The last moments of Angst should be near or above the mean Line. This confirms your story has left the Audience in a mood that empathizes with your story.',
    conclusion,
  },
  humor: {
    image: require('../../../assets/charts/humor.png'),
    about:
      'This map represents the average of two sentiments: Joy and Surprise. It identifies the mood of the viewer moment by moment while watching the content. Humor is captured when the audience finds the content to be Amusing, Funny or Witty.',
    qualityCriteria,
    meanLinePurpose,
    onboarding,
    trajectory,
    peakEndRule,
    conclusion:
      'Observe where your content is generating Humor and confirm that is the intended emotion at that time.',
  },
  enjoyment: {
    image: require('../../../assets/charts/enjoyment.png'),
    about:
      'This map represents the average of three positive sentiments: Joy, Surprise and Curiosity. The map illustrates frame by frame, where and when the audience experiences Enjoyment and where they do not, as illustrated through the spikes and dips.',
    qualityCriteria,
    meanLinePurpose,
    onboarding,
    trajectory,
    peakEndRule,
    conclusion:
      'Observe where your content is arousing the most Enjoyment with the audience and where your message can be optimized by identifying the less Enjoyable moments of your content.',
  },
  curious: {
    image: require('../../../assets/charts/curious.png'),
    about:
      'The Engagement Map informs us if the viewer is getting enough relevant and compelling information to drive and maintain their interest in the Story.',
    qualityCriteria:
      'A good Engagement Map displays a pattern of continuous friction confirming the audience was aroused by your story.',
    meanLinePurpose,
    onboarding:
      'Study the early frames to see how quickly it takes the audience to cognitively connect with your messaging.',
    trajectory:
      'Observe the emerging pattern of the Map which identifies the moments in time that are producing greater or fewer moments of interest.',
    peakEndRule:
      'The line graph should return to the Mean line or below. A Peak End that spikes above the Mean line infers that viewers were left either confused, not believing the content, or seeking more information.',
    conclusion,
  },
  happy: {
    image: require('../../../assets/charts/happy.png'),
    about:
      'This map illustrates the pattern of joyful reactions as elicited by the viewing audience. ' +
      'It identifies the mood of the viewer moment by moment while watching the content. ' +
      'Joy is captured when the audience is expressing emotions ranging from being Happy, Prideful, Entertained or Excited in reaction to the content.',
    qualityCriteria,
    onboarding,
    trajectory,
    meanLinePurpose,
    peakEndRule,
    conclusion:
      'Observe where your content is driving the most Joyful connection with the audience, ' +
      'and where your message can be optimized by eliminating the weaker areas of your content.',
  },
  sad: {
    image: require('../../../assets/charts/sad.png'),
    about:
      'This map illustrated the pattern of downbeat reactions as elicited by the viewing audience. ' +
      'It identifies the mood of the viewer moment by moment while watching the content. ' +
      'Sadness is captured when the audience is expressing emotions ranging from Disappointment, Disbelief or Doubt in reaction to the content.',
    qualityCriteria,
    onboarding,
    trajectory,
    meanLinePurpose,
    peakEndRule,
    conclusion:
      'Observe where your content is evoking the most Discouraging connection with the audience, ' +
      'and where your message can be optimized by eliminating the weaker areas of your content.',
  },
  disgusted: {
    image: require('../../../assets/charts/disgusted.png'),
    about:
      'This map illustrates the pattern of distasteful reactions as elicited by the viewing audience. ' +
      'It identifies the mood of the viewer moment by moment while watching the content. ' +
      'Aversion is captured when the audience is expressing emotions ranging from Distaste, Dislike or Disgust.',
    qualityCriteria,
    onboarding,
    trajectory,
    meanLinePurpose,
    peakEndRule,
    conclusion:
      'Observe where your content is evoking any Aversion with the audience, ' +
      'and where your message can be optimized by eliminating the weaker areas of your content.',
  },
  surprised: {
    image: require('../../../assets/charts/surprised.png'),
    about:
      'This map illustrates the pattern of surprised reactions as elicited by the viewing audience. ' +
      'It identifies the mood of the viewer moment by moment while watching the content. ' +
      'Surprise is captured when the audience found the content to be Exciting, Original, Innovative or Amazing.',
    qualityCriteria,
    onboarding,
    trajectory,
    meanLinePurpose,
    peakEndRule,
    conclusion:
      'Observe where your content is evoking any Surprise with the audience, ' +
      'and where your message can be optimized by eliminating the weaker areas of your content.',
  },
  fear: {
    image: require('../../../assets/charts/fear.png'),
    about:
      'This map illustrates the pattern of skeptical reactions as elicited by the viewing audience. ' +
      'It identifies the mood of the viewer moment by moment while watching the content. ' +
      'Skepticism is captured when the audience finds the content to be potentially Ambiguous, Unconvincing, Alarming and Worrisome.',
    qualityCriteria,
    onboarding,
    trajectory,
    meanLinePurpose,
    peakEndRule,
    conclusion:
      'Observe where your content is evoking any Skepticism with the audience, ' +
      'and where your message can be optimized by eliminating the weaker areas of your content.',
  },
  victory: {
    image: require('../../../assets/charts/victory.png'),
    about:
      'The Conclusion Map represents to what extent the Audience perceives a successful outcome. It could be a moment or last a few frames.',
    qualityCriteria:
      'A good Conclusion Map is illustrated by a repeating pattern of spikes and dips above and below the Mean Line.  A pattern of continuous friction confirms the audience emoted a sense of Victory, as intended by the content, and formed an “winning opinion” with your story.',
    meanLinePurpose,
    onboarding:
      'Study the early frames to see how quickly it takes the audience to trigger Victory, and where and when it happens in the Story specifically.',
    trajectory:
      'Look for a Conclusion Map rich with friction, represented by spikes and dips from beginning to end. Friction based Maps tell us the Audience is reacting to their perceived potential of a win for the client.',
    peakEndRule:
      'The last moments of Victory should be near or above the mean Line. This confirms your story has left the Audience in a mood that empathizes with your story.  Spikes at the end of this Map indicate the jury will positively advocate for your client as they perceive a victorious outcome.',
    conclusion: legalConclusion,
  },
  bittersweet: {
    image: require('../../../assets/charts/bittersweet.png'),
    about:
      'This graph depicts where and where the audience is emotionally triggered by both positive and negative influences of the Story.',
    qualityCriteria:
      'Maps with friction, as illustrated by a repeating pattern of spikes and dips above and below the Mean Line. A pattern of high friction signals the audience was indeed aroused and triggered in some way by the content, and importantly, have formed an Bittersweet connection with your case story.',
    meanLinePurpose,
    onboarding:
      'Study the early frames to see how quickly it takes the audience to trigger a state of Bittersweetness, and where and when it happens in the Story specifically. ',
    trajectory:
      'Look for a Bittersweet Map rich with friction, represented by spikes and dips from beginning to end. Friction based Maps tell us the Audience is reacting to their perceived grief at the loss and joy at the potential for a happy ending.',
    peakEndRule:
      'The last moments of Bittersweet should be near or above the mean Line. This confirms your story has left the Audience in a mood that empathizes with your story. ',
    conclusion: legalConclusion,
  },
  disapproval: {
    image: require('../../../assets/charts/disapproval.png'),
    about:
      'When the Audience senses something in the case that challenges their deeply held beliefs, we would see a very spikey map here. Reactions of disapproval could be seen for example as  dislike for a Witness, expressing their opinions regarding the Valuation, etc.',
    qualityCriteria:
      'Maps with friction, as illustrated by a repeating pattern of spikes and dips above and below the Mean Line.',
    meanLinePurpose,
    onboarding:
      'Study the early frames to see how quickly it takes the audience to trigger a state of Disapproval, and where and when it happens in the Story specifically.',
    trajectory:
      'Look for a Disapproval Map rich with friction, represented by spikes and dips from beginning to end. Friction based Maps tell us the Audience is reacting to their perceived potential of a win for the client.',
    peakEndRule:
      'The last moments of Disapproval should be near or above the mean Line. This confirms your story has left the Audience in a mood that empathizes with your story.',
    conclusion: legalConclusion,
  },
  outrage: {
    image: require('../../../assets/charts/outrage.png'),
    about:
      'Outrage is ignited when moments that trigger anger emerge, but come as a surprise to the Audience Pay attention to moments when Outrage is spiked on the map, as it indicates the audience is feeling quite indignant in response to what they see and hear.  However, Triggering this emotion at any time during the presentation is always good for the Plaintiff.',
    qualityCriteria:
      'Maps with friction, as illustrated by a repeating pattern of spikes and dips above and below the Mean Line. A pattern of high friction signals the audience was outraged in some way by the content.',
    meanLinePurpose,
    onboarding:
      'Study the early frames to see how quickly it takes the audience to trigger a state of Outrage, and where and when it happens in the Story specifically.',
    trajectory:
      'Look for an Outrage Map rich with friction, represented by spikes and dips from beginning to end. Friction based Maps tell us the Audience is reacting to their perceived potential of a win for the client.',
    peakEndRule:
      'The last moments of Outrage should be near or above the mean Line. This confirms your story has left the Audience in a mood that empathizes with your story. ',
    conclusion: legalConclusion,
  },
  shock: {
    image: require('../../../assets/charts/shock.png'),
    about:
      'This emotion is aroused when the audience is in awe-struck regarding the depth or detail of your case story.',
    qualityCriteria:
      'Maps with friction, as illustrated by a repeating pattern of spikes and dips above and below the Mean Line. A pattern of high friction signals the audience was shocked in some way by the content.',
    meanLinePurpose,
    onboarding:
      'Study the early frames to see how quickly it takes the audience to trigger a state of Shock and where and when it happens in the Story specifically.',
    trajectory:
      'Look for a Shock Map rich with friction, represented by spikes and dips from beginning to end.',
    peakEndRule:
      'The last moments of Shock should be near or above the mean Line. This confirms your story has left the Audience in a mood that empathizes with your story.',
    conclusion: legalConclusion,
  },
  rapport: {
    image: require('../../../assets/charts/rapport.png'),
    about:
      'This map represents a pattern of empathy the audience finds with your content. It reveals which assets resonate most and which resonates least.',
    qualityCriteria:
      "Spikes in the green area indicate High Rapport, representing the audience's empathy at that moment in the story. Dips in the red areaindicate Low Rapport, representing the audience being less empathetic at those moments inthe story.",
    trajectory:
      'Look for a Rapport Map rich with friction. A map with constant spikes and dips indicates an empathetic audience. This means the story is delivering an emotionally evocative message.',
    peakEndRule:
      'The last moments of a Rapport Map should be at the mid point. If there are spikes in Rapport as the story closes, the audience is still empathetic. Below the midpoint, the audience is expressing a disconnect with the last moments of the story.',
    conclusion:
      'Compare moments on the trajectory to see which moments dominate to create an empathetic connection with the audience. Use this to optimize your narrative to drive the most empathy with your jury.',
  },
}

export default sentimentInfo
