import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {Button, Grid, Paper, Typography, withStyles} from '@material-ui/core'
import CompareArrowsIcon from '@material-ui/icons/CompareArrows'
import VideocamIcon from '@material-ui/icons/Videocam'

import useModalState from '../../../hooks/useModalState.js'

import Loading from '../../common/Loading.jsx'
import InfoButtons from '../../common/InfoButtons.jsx'
import InfoModal from '../../common/InfoModal.jsx'
import TestDetailHeader from '../../common/TestDetailHeader.jsx'
import SummaryPanels from './SummaryPanels.jsx'
import BenchmarkExplained from '../BenchmarkExplained.jsx'

import {
  getDefaultSurveyResults,
  getDefaultSurveyQuestions,
  getDefaultEmotionsScore,
  getDefaultEngagementScore,
  resetState,
} from '../redux/actions.js'

import CompareDemographicsModalSummary from '../../common/CompareDemographicsModalSummary.jsx'
import InfoButton from '../../common/InfoButton.jsx'

import {initialFetch} from '../../constants/CreativeConclusions.js'
import {testTypes} from '../../constants/NewTest.js'
import utils from '../utils.js'

import styles from '../styles/TabScores.styles.js'

function CreativeConclusion(props) {
  const [textModalOpen, openTextModal, closeTextModal] = useModalState(false)
  const [videoModalOpen, openVideoModal, closeVideoModal] = useModalState(false)
  const [selectParents, setSelectParents] = useState([])
  const [selectChildren, setSelectChildren] = useState({})
  const [hasFetchedCreativeConclusions, setHasFetchedCreativeConclusions] = useState(false)
  const [defaultGroups, setDefaultGroups] = useState([initialFetch])
  const [
    compareDemographicsOpen,
    openCompareDemographicsModal,
    closeCompareDemographicsModal,
  ] = useModalState(false)
  const [isComparing, setIsComparing] = useState(false)
  const [demographicLabelsTable, setDemographicLabelsTable] = useState({})
  const [emotions, setEmotions] = useState([])
  const [engagements, setEngagements] = useState([])

  const {
    campaign,
    classes,
    targetAudiences,
    demographicsWithValues,
    getDefaultSurveyResults,
    getDefaultSurveyQuestions,
    getDefaultEmotionsScore,
    getDefaultEngagementScore,
    defaultEmotionsScore,
    defaultEngagementScore,
    defaultSurveyQuestions,
    customEmotions,
    customEngagements,
    defaultSurveyResults,
    isFetchingEngagement,
    isFetchingEmotions,
    isFetchingDefaultSurveyResults,
    surveyQuestions,
    resetState,
    params,
  } = props

  useEffect(() => {
    if (campaign.campaign.id === Number(params.id)) {
      const {campaignVideoId} = campaign.campaign.campaignVideos[0]
      const {id} = campaign.campaign
      const allGroups = [...defaultGroups]

      if (targetAudiences) {
        const tempDefaultGroups = targetAudiences.map((group, index) => {
          const groupObject = utils.createGroupObject(group.demographics)

          return {
            ...groupObject,
            fields: Object.keys(groupObject),
            type: 'creative',
            isDefault: true,
            listIndex: index,
            group: group.name || `Group ${index + 1}`,
          }
        })

        allGroups.push(...tempDefaultGroups)

        setDefaultGroups(prevState => [...prevState, ...tempDefaultGroups])
      }

      if (!hasFetchedCreativeConclusions) {
        getDefaultEmotionsScore(campaignVideoId)
        getDefaultEngagementScore(campaignVideoId)
        getDefaultSurveyResults(id, allGroups)
        getDefaultSurveyQuestions(testTypes.creative)
        setHasFetchedCreativeConclusions(true)
      }
    }
  }, [campaign.campaign])

  useEffect(() => {
    setEmotions([...defaultEmotionsScore, ...customEmotions])
  }, [defaultEmotionsScore, customEmotions])

  useEffect(() => {
    setEngagements([...defaultEngagementScore, ...customEngagements])
  }, [defaultEngagementScore, customEngagements])

  useEffect(() => {
    if (campaign.campaign.id === Number(params.id)) {
      const [parents, children] = utils.getSelectParentsAndChildren(demographicsWithValues)

      setSelectParents(parents)
      setSelectChildren(children)

      const labelsTable = utils.getDemographicLabelsTable(demographicsWithValues)
      setDemographicLabelsTable(labelsTable)
    }
  }, [demographicsWithValues])

  const engagementCardCalc = () => {
    if (engagements?.length) {
      const results = engagements.map(engage => ({
        ...engage,
        total: engage.count === 0 ? 'N/A' : engage.results.engagement.value,
      }))
      return results
    }
  }

  const appealCardCalc = () => {
    if (emotions?.length) {
      const results = emotions.map(emotion => ({
        ...emotion,
        total: emotion.count === 0 ? 'N/A' : emotion.results.emotions.neutral,
      }))
      return results
    }
  }

  const defaultResultsCalc = defaultSurveyQuestion => {
    if (defaultSurveyResults?.length) {
      const questions = defaultSurveyResults.reduce((prev, curr) => {
        const foundQuestion = curr.find(question => question.question === defaultSurveyQuestion)
        return foundQuestion ? [...prev, foundQuestion] : prev
      }, [])

      const results = questions.map(question => ({
        ...question,
        total: question.answers.result,
      }))

      return results
    }
  }

  const storyCardCalc = () => {
    if (emotions?.length && engagements?.length) {
      const results = emotions.map((emotion, index) => {
        const confusedEmotion = emotion.results?.emotions?.confused || 0
        const engagement = engagements[index]?.results?.engagement?.value || 0

        const total = !emotion.count ? 'N/A' : confusedEmotion + engagement

        return {
          ...emotion,
          total,
        }
      })
      return results
    }
  }

  const clearResults = () => {
    const {id} = campaign.campaign
    setEmotions(defaultEmotionsScore)
    setEngagements(defaultEngagementScore)
    resetState(['emotionsWithDemographics', 'engagementWithDemographics', 'defaultSurveyResults'])
    getDefaultSurveyResults(id, [...defaultGroups])
  }

  const getSurveyInfoText = surveyQuestion => {
    if (!defaultSurveyQuestions) return ''

    const defaultQuestion = defaultSurveyQuestions.find(
      question => question.title === surveyQuestion.title,
    )

    if (!defaultQuestion || !defaultQuestion.conclusionText) return ''

    const splitText = defaultQuestion.conclusionText.split('\\n')

    return (
      <span>
        {splitText.map((text, index) => (
          <div key={index}>
            {text}
            <br />
          </div>
        ))}
      </span>
    )
  }

  const renderModals = () => {
    return (
      <>
        <InfoModal open={textModalOpen} onClose={closeTextModal}>
          <>
            <Typography variant="h4">TAB&rsquo;s Explained</Typography>
            <Typography variant="body1">
              <strong>Trust, Attention & Believability</strong> are core KPIs. The scores represent
              the audience&apos;s takeaway. These KPI&apos;s call out any red flags and/or pain
              points in your message.
              <br />
              <br />
              <strong>Takeaway</strong> If any of your scores are not aligned with the benchmarks,
              review your message to see specifically what sentiments are driving positive or
              negative reactions.
              <br />
              <br />
              <strong>Trust Metric</strong> reports how factual the audience finds the content.
              Scores above the benchmark metric indicate that the audience considers your message to
              be truthful. Below the benchmark means the audience may be skeptical to your story
              content.
              <br />
              <br />
              <strong>Attention Metric</strong> speaks to level of the audience engagement. Scores
              above the benchmark indicate that the audience finds the content compelling. Scores
              below the benchmark indicate that the audience finds your messaging unclear and/or
              confusing.
              <br />
              <br />
              <strong>Believability Metric</strong> reports how truthful/convincing your message is
              to the audience. Scores above the benchmark indicate that the audience believes in the
              message. Scores below the benchmark indicate a lack of belief in your message.
            </Typography>
          </>
        </InfoModal>
        <InfoModal open={videoModalOpen} onClose={closeVideoModal}>
          <>
            <Typography variant="h4">TAB&rsquo;s Explained</Typography>
            <iframe
              width="750"
              height="480"
              src="https://www.youtube.com/embed/UY-vnbnsaDc?&autoplay=1"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </>
        </InfoModal>
      </>
    )
  }

  return (
    <Grid container className={classes.tabScoresContainer}>
      {renderModals()}
      <TestDetailHeader
        title="Key Metrics"
        subTitle="These metrics represent how your test scored with the audience"
        noCountResults
      />
      <Paper elevation={0} className={classes.tabScoresPaper}>
        <BenchmarkExplained type={testTypes.creative} />
        {campaign.campaign.isFecthing ? (
          <Loading />
        ) : (
          <>
            <Grid container className={classes.paperHeader}>
              <Grid item xs className={classes.paperHeaderTextContainer} />
              <Grid item className={classes.paperButtonContainer}>
                {isFetchingEmotions ||
                isFetchingEngagement ||
                isFetchingDefaultSurveyResults ||
                (engagements && engagements.length === defaultGroups.length) ? null : (
                  <Button
                    variant="contained"
                    className={classes.clearResultsButton}
                    onClick={clearResults}
                  >
                    Clear Results
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.compareButton}
                  onClick={openCompareDemographicsModal}
                >
                  <CompareArrowsIcon fontSize="small" className={classes.buttonIcon} />
                  Compare
                </Button>
                <div style={{display: 'none'}}>
                  <InfoButtons openTextModal={openTextModal} openVideoModal={openVideoModal} />
                  <InfoButton
                    icon={<VideocamIcon color="primary" fontSize="small" />}
                    alt="tutorial"
                    onClick={() => openVideoModal()}
                  />
                </div>
                <CompareDemographicsModalSummary
                  selectParents={selectParents}
                  selectChildren={selectChildren}
                  defaultGroups={defaultGroups}
                  open={compareDemographicsOpen}
                  onClose={closeCompareDemographicsModal}
                  isComparing={isComparing}
                  setIsComparing={setIsComparing}
                />
              </Grid>
            </Grid>

            <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
              <SummaryPanels
                title="Story Index"
                valueTitle="Benchmark"
                description="How Immersed Is The Audience With The Content"
                benchmark={100}
                data={storyCardCalc()}
                demographicLabelsTable={demographicLabelsTable}
                loading={isFetchingEmotions || isFetchingEngagement}
                infoText={
                  <span>
                    This metric is compounded by joining the sentiments of Engagement and Curiosity.
                    Research has proven that this measure is the purest read of viewers&apos; true
                    inner intellectual and emotional reactions to the message. It is collected using
                    Emotion Analysis.
                    <br />
                    <br />
                    This Benchmark score represents the audiences&apos; immersion in the content.
                    The higher the score, the more immersed the audience is in the content.
                  </span>
                }
              />
              <SummaryPanels
                title="Engagement"
                valueTitle="Benchmark"
                description="How Engaged Is The Audience With The Content"
                benchmark={90}
                data={engagementCardCalc()}
                demographicLabelsTable={demographicLabelsTable}
                engagement={engagements}
                loading={isFetchingEngagement}
                infoText={
                  <span>
                    This metric measures the audience&apos;s attention to the content. High-scoring
                    Engagement indicates an active and intentional affinity with the message. It is
                    collected using Emotion Analysis.
                    <br />
                    <br />
                    The Benchmark represents the average percentage of how engaged the audience is
                    throughout the entire video.
                  </span>
                }
              />
              <SummaryPanels
                title="Rapport"
                valueTitle="Benchmark"
                description="How Appealing Does The Audience Find The Content"
                benchmark={50}
                emotions={emotions}
                data={appealCardCalc()}
                demographicLabelsTable={demographicLabelsTable}
                loading={isFetchingEmotions}
                infoText={
                  <span>
                    This metric measures how deeply the audience is emotionally invested in the
                    content. It is collected using Emotion Analysis.
                    <br />
                    <br />
                    The Benchmark represents the average percentage the audience is being moved
                    emotionally.
                  </span>
                }
              />
              {surveyQuestions &&
                surveyQuestions
                  .filter(
                    survey => survey.objectiveType === 'creative' && survey.choices.length < 5,
                  )
                  .map((defaultSurvey, index) => (
                    <SummaryPanels
                      key={index}
                      title={defaultSurvey.title ? defaultSurvey.title : ''}
                      description={
                        defaultSurvey.questionDescription ? defaultSurvey.questionDescription : ''
                      }
                      data={defaultResultsCalc(defaultSurvey.question)}
                      demographicLabelsTable={demographicLabelsTable}
                      loading={isFetchingDefaultSurveyResults}
                      infoText={getSurveyInfoText(defaultSurvey)}
                    />
                  ))}
            </div>
          </>
        )}
      </Paper>
    </Grid>
  )
}

CreativeConclusion.propTypes = {
  campaign: PropTypes.objectOf(PropTypes.any).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  demographicsWithValues: PropTypes.objectOf(PropTypes.object).isRequired,
  params: PropTypes.shape({id: PropTypes.string}).isRequired,
}

const mapStateToProps = ({campaign}) => ({
  campaign,
  targetAudiences: campaign.campaign.targetAudiences,
  demographicsWithValues: campaign.demographicsWithValues.values,
  focusTimeTrackFilter: campaign.focusTimeTrackFilter,
  customEmotions: campaign.emotionsWithDemographics.emotions || [],
  defaultEmotionsScore: campaign.defaultEmotionsScore.results || [],
  customEngagements: campaign.engagementWithDemographics.engagements || [],
  defaultEngagementScore: campaign.defaultEngagementScore.results || [],
  defaultSurveyResults: campaign.defaultSurveyResults.results,
  defaultSurveyQuestions: campaign.defaultSurveyQuestions.results,
  isFetchingDefaultSurveyResults: campaign.defaultSurveyResults.isFetching,
  isFetchingEngagement:
    campaign.engagementWithDemographics.isFetching || campaign.defaultEngagementScore.isFetching,
  isFetchingEmotions:
    campaign.emotionsWithDemographics.isFetching || campaign.defaultEmotionsScore.isFetching,
  surveyQuestions: campaign.campaign.surveyQuestions,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDefaultSurveyResults,
      getDefaultSurveyQuestions,
      getDefaultEmotionsScore,
      getDefaultEngagementScore,
      resetState,
    },
    dispatch,
  )

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(CreativeConclusion)
