import React, {useContext, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Button, Grid, Typography, withStyles} from '@material-ui/core'

import NewTestErrorContext from '../campaign/context/NewTestErrorContext'

import noThumbnailImage from '../../assets/no-thumb.svg'

const styles = theme => ({
  thumbWrapper: {
    flexWrap: 'nowrap',
    overflow: 'hidden',
  },
  thumbContainer: {
    display: 'flex',
    minWidth: 80,
    height: 80,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 8,
    '& *': {
      position: 'absolute',
      width: '100%',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  nameContainer: {
    paddingLeft: theme.spacing.unit * 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    overflow: 'hidden',
    '& p': {
      fontSize: '0.8rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%',
    },
    '& button': {
      padding: 0,
      background: 'initial',
      color: '#EB5757',
      textDecoration: 'underline',
      '&:hover': {
        background: 'initial',
        filter: 'brightness(1.1)',
        textDecoration: 'underline',
      },
    },
  },
})

const VideoThumb = ({classes, videoFile, setVideoFile}) => {
  const [noThumbnail, setNoThumbnail] = useState(false)
  const [thumbElement, setThumbElement] = useState(null)

  const {setVideoError} = useContext(NewTestErrorContext)

  const getThumb = videoFile => {
    if (videoFile.thumbnail) {
      setNoThumbnail(false)
      return (
        <img
          src={videoFile.thumbnail.fullSize}
          alt={videoFile.videoFilename}
          className={classes.thumb}
        />
      )
    }

    if (videoFile.preview) {
      setNoThumbnail(false)
      return <video src={videoFile.preview} className={classes.thumb} />
    }

    setNoThumbnail(true)
    return <img src={noThumbnailImage} className={classes.thumb} alt="no thumbnail" />
  }

  useEffect(() => {
    setThumbElement(getThumb(videoFile))
  }, [])

  return (
    <Grid container className={classes.thumbWrapper}>
      <div className={classes.thumbContainer} style={{background: noThumbnail ? '' : 'black'}}>
        {thumbElement}
      </div>
      <div className={classes.nameContainer}>
        <Typography variant="body1">{videoFile.path || videoFile.videoFilename}</Typography>
        <Button
          onClick={() => {
            setVideoFile({})
            setVideoError('You must select a video!')
          }}
          style={{
            textAlign: 'left',
            fontSize: '0.8rem',
          }}
        >
          Remove Video
        </Button>
      </div>
    </Grid>
  )
}

VideoThumb.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  setVideoFile: PropTypes.func.isRequired,
  videoFile: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default withStyles(styles)(VideoThumb)
