const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  newTestButton: {
    display: 'flex',
    justifyContent: 'center',
    color: 'white !important',
    textTransform: 'none',
    boxShadow: 'none',
    marginTop: '25px',
    width: '164px',
    marginBottom: '100px', //delete when adding videos
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
  },
  myCampaigns: {
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: '500',
    letterSpacing: '0.45px',
    marginBottom: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableContainer: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '& img': {
      paddingTop: '100px',
      paddingBottom: '50px',
    },
    '& div': {
      fontSize: '24px',
    },
  },
  searchInput: {
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, 0.06)',
    width: '290px',
    height: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '20px',
    paddingRight: '10px',
    fontWeight: 'normal',
    fontSize: '12px',
    color: 'rgba(51, 51, 51, 0.25)',
    '& button:hover': {
      backgroundColor: 'transparent',
    },
  },
  informationalVideos: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingLeft: '80px',
    paddingRight: '80px',
    paddingTop: '26px',
    paddingBottom: '100px',
    '& p': {
      fontSize: '24px',
      fontWeight: 500,
      marginBottom: '10px',
    },
  },
  buttonsContainer: {
    maxWidth: '970px',
    '& div:not(:last-child) button': {
      marginRight: '16px',
    },
  },
})

export default styles
