import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Button} from '@material-ui/core'
import Videocam from '@material-ui/icons/Videocam'
import PlayArrow from '@material-ui/icons/PlayArrow'

const styles = () => ({
  videoButton: {
    height: '70px',
    marginTop: '15px',
    display: 'flex',
    justifyContent: 'flex-start',
    '& p': {
      fontSize: '16px',
      fontWeight: 500,
    },
    '& span': {
      fontSize: '12px',
    },
  },
  videoIcon: {
    color: '#017EFF',
    fontSize: '40px',
  },
  videoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    height: '48px',
    width: '48px',
    backgroundColor: 'rgba(47, 128, 237, 0.1)',
  },
  timer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#828282',
  },
  videoButtonInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: '15px',
  },
})

const TutorialVideoButton = ({classes, title, time, openVideoModal, width}) => {
  return (
    <>
      <Button
        variant="outlined"
        className={classes.videoButton}
        onClick={() => openVideoModal()}
        style={{width: width || 'initial'}}
      >
        <div className={classes.videoContainer}>
          <Videocam className={classes.videoIcon} />
        </div>
        <div className={classes.videoButtonInfo}>
          <span style={{fontSize: '16px', fontWeight: 500}}>{title}</span>
          <div className={classes.timer}>
            <PlayArrow style={{fontSize: '12px', marginRight: '10px'}} />
            <span>{time}</span>
          </div>
        </div>
      </Button>
    </>
  )
}

export default withStyles(styles)(TutorialVideoButton)
