import React from 'react'
import {withStyles} from '@material-ui/core'

const styles = theme => ({
  switchContainer: {
    fontFamily: 'Roboto',
    position: 'relative',
    display: 'flex',
    border: `2px solid ${theme.palette.border && theme.palette.border.main}`,
    marginLeft: 'auto',
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    width: theme.spacing.unit * 12,
    height: 36,
    borderRadius: 8,
    cursor: 'pointer',
    background: 'transparent',
  },
  leftContainer: {
    display: 'flex',
    width: '50%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rightContainer: {
    display: 'flex',
    width: '50%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerText: {
    pointerEvents: 'none',
    fontWeight: 500,
    zIndex: 1,
  },
  selectedOverlay: {
    width: theme.spacing.unit * 5.5,
    height: 28,
    position: 'absolute',
    transition: theme.transitions.create(['right', 'background']),
    transitionDuration: '0.1s',
    top: '50%',
    transform: 'translateY(-50%)',
    border: '4px solid transparent',
    borderRadius: 5,
    pointerEvents: 'none',
    userSelect: 'none',
    zIndex: 0,
  },
  checked: {
    background: '#219653',
    right: 2,
  },
  unchecked: {
    background: '#EB5757',
    right: '50%',
  },
})

const SwitchButton = ({classes, offText, onText, checked, onClick}) => {
  return (
    <button type="button" className={classes.switchContainer} onClick={onClick}>
      <div className={classes.leftContainer}>
        <span
          className={classes.containerText}
          style={{color: checked ? 'c4c4c4' : 'white', marginLeft: '-4'}}
        >
          {offText}
        </span>
      </div>
      <div className={classes.rightContainer}>
        <span
          className={classes.containerText}
          style={{color: checked ? 'white' : 'c4c4c4', marginRight: '-4'}}
        >
          {onText}
        </span>
      </div>
      <div
        className={`${classes.selectedOverlay} ${checked ? classes.checked : classes.unchecked}`}
      />
    </button>
  )
}

export default withStyles(styles)(SwitchButton)
