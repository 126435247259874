import thunkMiddleware from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import {applyMiddleware, createStore} from 'redux'
import {routerMiddleware} from 'react-router-redux'
import {browserHistory} from 'react-router'
import {createLogger} from 'redux-logger'
import createRavenMiddleware from 'raven-for-redux'
import Raven from 'raven-js'
import reduxCatch from 'redux-catch'
import reducers from './shared/reducers'
import sagas from './shared/sagas'
import settings from '../config/settings'

const sagaMiddleware = createSagaMiddleware()

const initialState = {}

const reduxLoggerMiddleware = createLogger()

const reduxMiddleware = [thunkMiddleware, sagaMiddleware, routerMiddleware(browserHistory)]

if (__DEVCLIENT__) {
  //turn off redux logs in production
  reduxMiddleware.push(reduxLoggerMiddleware)
}
if (settings.sentry && settings.sentry.publicDSN) {
  const {sentry} = settings
  Raven.config(sentry.publicDSN, {
    release: sentry.release,
    environment: sentry.environment,
  }).install()
  reduxMiddleware.unshift(createRavenMiddleware(Raven))
  reduxMiddleware.push(reduxCatch(error => Raven.captureException(error)))
}

const store = createStore(reducers, initialState, applyMiddleware(...reduxMiddleware))
sagaMiddleware.run(sagas)
export default store
