import React from 'react'
import {Link} from 'react-router'
import {AppBar, Toolbar, Button, withStyles} from '@material-ui/core'
import CTLogo from '../../assets/ET-LogoNew.svg'

const styles = () => ({
  navbar: {
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
  },
  navbarSignupLink: {
    boxShadow: 'none',
    fontSize: '13px',
    fontWeight: 'normal',
    letterSpacing: '-0.02em',
    width: '130px',
    borderRadius: '5px',
    height: '36px',
  },
  navbarLoginLink: {
    color: '#4F4F4F',
    fontSize: '16px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    marginRight: '24px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  brandImageContainer: {
    flex: 1,
    paddingLeft: 3,
    paddingRight: 20,
  },
  headerContainer: {
    width: '960px',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '80px',
  },
})

const LoginHeader = ({classes}) => {
  return (
    <AppBar color="default" className={classes.navbar} position="fixed">
      <Toolbar className={classes.headerContainer}>
        <div className={classes.brandImageContainer}>
          <Link to="/">
            <img src={CTLogo} alt="logo" />
          </Link>
        </div>
        <Button component={Link} to="/login" className={classes.navbarLoginLink}>
          Login
        </Button>
        <Button
          component={Link}
          to="/signup"
          className={classes.navbarSignupLink}
          color="primary"
          variant="contained"
        >
          Create Account
        </Button>
      </Toolbar>
    </AppBar>
  )
}

export default withStyles(styles)(LoginHeader)
