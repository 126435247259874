const styles = theme => ({
  tabScoresContainer: {
    padding: `0 ${theme.spacing.unit * 4} ${theme.spacing.unit * 4}`,
    flexDirection: 'column',
  },
  responses: {
    color: '#4F4F4F',
    fontSize: '1.4rem',
    fontWeight: '400',
  },
  tabScoresPaper: {
    padding: `${theme.spacing.unit * 2} ${theme.spacing.unit * 4}`,
    width: '100%',
  },
  paperHeaderTextContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  paperHeaderText: {
    minWidth: 211,
    fontWeight: '700',
    fontSize: '1.2rem',
  },
  paperButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    '& > *': {
      margin: `0 ${theme.spacing.unit / 2}`,
    },
  },
  gaugeList: {
    padding: `${theme.spacing.unit * 3} 0`,
    [theme.breakpoints.down(1280)]: {
      '& > div:last-child': {
        marginTop: theme.spacing.unit * 3,
      },
    },
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
  },
  compareButton: {
    height: theme.spacing.unit * 5,
    boxShadow: 'none',
    '&:active': {
      boxShadow: 'none',
    },
  },
  clearComparisonButton: {
    height: theme.spacing.unit * 5,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    [theme.breakpoints.down(1060)]: {
      paddingLeft: 12,
      paddingRight: 12,
    },
    boxShadow: 'none',
    '&:active': {
      boxShadow: 'none',
    },
  },
  compareGroupLabel: {
    display: 'flex',
    padding: '0 24px !important',
    marginBottom: theme.spacing.unit * 3,
    '& p': {
      textTransform: 'capitalize',
      color: theme.palette.grey[800],
      fontSize: '1rem',
      fontWeight: 500,
    },
  },
  filterText: {
    textTransform: 'capitalize',
    marginBottom: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit * 3,
    color: theme.palette.grey[800],
    fontSize: '1rem',
    fontWeight: 500,
    [theme.breakpoints.down(1077)]: {
      paddingLeft: theme.spacing.unit / 2,
    },
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabsIndicator: {
    backgroundColor: '#292D32',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    color: '#828282',
    fontWeight: 400,
    fontSize: '14px',
    '&:hover': {
      color: '#333333',
      opacity: 1,
    },
    '&:tabSelected': {
      color: '#333333',
      fontWeight: 500,
    },
  },
  tabSelected: {},
  benchmarkLabel: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    color: '#333333',
  },
  benchmarkExplained: {
    fontSize: '20px',
    fontWeight: 700,
    marginBottom: '20px',
  },
  clearResultsButton: {
    color: '#FFFFFF',
    backgroundColor: '#F44336',
    height: '40px',
    '&:hover': {
      backgroundColor: 'rgba(244, 67, 54, 0.87) !important',
    },
  },
})

export default styles
