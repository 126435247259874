import React from 'react'
import PropTypes from 'prop-types'
import {IconButton, withStyles} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

const styles = theme => ({
  addChoiceButton: {
    border: `1px solid ${theme.palette.border && theme.palette.border.main}`,
    borderRadius: theme.spacing.unit,
    padding: theme.spacing.unit / 4,
    marginRight: theme.spacing.unit * 1.5,
  },
})

const AddButton = ({
  classes,
  className,
  onClick,
  disabled,
  onMouseEnter,
  onMouseLeave,
  iconProps,
  ...rest
}) => {
  return (
    <IconButton
      color="primary"
      aria-label="add"
      className={`${classes.addChoiceButton} ${className}`}
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...rest}
    >
      <AddIcon {...iconProps} />
    </IconButton>
  )
}

AddButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  iconProps: PropTypes.objectOf(PropTypes.any),
}

AddButton.defaultProps = {
  iconProps: {},
  className: '',
  disabled: false,
  onClick: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
}

export default withStyles(styles)(AddButton)
