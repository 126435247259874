import settings from '../../../config/settings'
import axios from '../api/axios'
import fileAxios from '../api/fileAxios'

const {apiBaseURL} = settings

export default class CampaignApi {
  static checkAudienceGroups(params) {
    return axios.post(`${apiBaseURL}/audience/audience-population-check`, params)
  }

  static createCampaign(campaign) {
    return axios.post(`${apiBaseURL}/campaigns`, campaign)
  }

  static getCaseTypes() {
    return axios.get(`${apiBaseURL}/case-types`)
  }

  static getCampaignConfigs() {
    return axios.get(`${apiBaseURL}/campaigns/configs`)
  }

  static getDiscount(params) {
    return axios.post(`${apiBaseURL}/discount/get-promo-code`, {params})
  }

  static applyTestDiscount(id) {
    return axios.patch(`${apiBaseURL}/discount/${id}`)
  }

  static getCampaigns(params) {
    return axios.get(`${apiBaseURL}/campaigns`, {params})
  }

  static getCampaign(id, params) {
    return axios.get(`${apiBaseURL}/campaigns/${id}`, {params})
  }

  static getRewardStores() {
    return axios.get(`${apiBaseURL}/campaigns/reward-stores`)
  }

  static updateCampaign(id, campaign) {
    return axios.patch(`${apiBaseURL}/campaigns/${id}?ignore_payment=true`, campaign)
  }

  static publishCampaign(id, params) {
    return axios.post(`${apiBaseURL}/campaigns/${id}/publish`, null, {
      params: {ignorePayment: true, ...params},
    })
  }

  static campaignPayment(id, campaign, params) {
    return axios.post(`${apiBaseURL}/campaigns/${id}/payment`, campaign, {
      params: {
        ignorePayment: true,
        ...params,
      },
    })
  }

  static applyDiscount(id, code) {
    return axios.post(`${apiBaseURL}/campaigns/${id}/discount?ignore_payment=true`, {code})
  }

  static getEngagementEvent(id, params) {
    return axios.get(`${apiBaseURL}/campaign-videos/${id}/events`, {params})
  }

  static getEmotionEvent(id, params) {
    return axios.post(`${apiBaseURL}/campaign-videos/${id}/emotions`, {params})
  }

  static getEngagementEventByTime(id, params) {
    return axios.get(`${apiBaseURL}/campaign-videos/${id}/events-by-time`, {params})
  }

  static getEngagementWithDemographics(id, params) {
    return axios.post(`${apiBaseURL}/campaign-videos/${id}/engagement-with-demographics`, {params})
  }

  static getEmotionsWithDemographics(id, params) {
    return axios.post(`${apiBaseURL}/campaign-videos/${id}/emotions-with-demographics`, {params})
  }

  static getLegalProfile(id) {
    return axios.get(`${apiBaseURL}/campaign-videos/${id}/legal-profile`)
  }

  static getLegalBenchmark(id, params) {
    return axios.post(`${apiBaseURL}/campaign-videos/${id}/legal-benchmark`, {params})
  }

  static getEmotionEventByTime(id, params) {
    return axios.post(`${apiBaseURL}/campaign-videos/${id}/emotions-by-time`, {params})
  }

  static getGroupEmotionEventByTime(id, params) {
    return axios.post(`${apiBaseURL}/campaign-videos/${id}/group-emotions-by-time`, {params})
  }

  static getEngagementEventByTimeItems(id, params) {
    return axios.get(`${apiBaseURL}/campaign-videos/${id}/events-by-time-items`, {params})
  }

  static getEmotionEventByTimeItems(id, params) {
    return axios.get(`${apiBaseURL}/campaign-videos/${id}/emotions-by-time-items`, {params})
  }

  static getDefaultEmotions(id) {
    return axios.get(`${apiBaseURL}/campaign-videos/${id}/default-emotions`)
  }

  static getDefaultEmotionsScore(id) {
    return axios.get(`${apiBaseURL}/campaign-videos/${id}/default-emotions-score`)
  }

  static getDefaultEngagementScore(id) {
    return axios.get(`${apiBaseURL}/campaign-videos/${id}/default-engagement-score`)
  }

  static getDefaultRapport(id) {
    return axios.get(`${apiBaseURL}/campaign-videos/${id}/default-rapport`)
  }

  static getDemographics(id) {
    return axios.get(`${apiBaseURL}/campaign-videos/${id}/demographics`)
  }

  static getDemographicValues(id, field) {
    return axios.get(`${apiBaseURL}/campaign-videos/${id}/demographics-values?field=${field}`)
  }

  static getIndexScoringResults(id, params) {
    return axios.get(`${apiBaseURL}/campaigns/${id}/index-scoring-results`, {params})
  }

  static getDemographicsWithValues(id) {
    return axios.get(`${apiBaseURL}/campaign-videos/${id}/demographics-with-values`)
  }

  static getDefaultSurveyResults(id, params) {
    return axios.post(`${apiBaseURL}/campaigns/${id}/default-survey-results`, {params})
  }

  static getDefaultSurveyQuestions(questionType) {
    return axios.get(`${apiBaseURL}/default-survey-questions?type=${questionType}`)
  }

  static getQuestionsFromCsv(csvFile) {
    const form = new FormData()
    form.append('file', csvFile)
    return fileAxios.post(`${apiBaseURL}/surveys/upload-csv`, form)
  }

  static getSurveyResults(id, params) {
    return axios.get(`${apiBaseURL}/campaigns/${id}/survey-results`, {params})
  }

  static getAudienceDetail(id) {
    return axios.get(`${apiBaseURL}/campaigns/${id}/audience-detail`)
  }

  static getAudienceDemographics() {
    return axios.get(`${apiBaseURL}/demographic-screening`)
  }

  static getAudienceLocales() {
    return axios.get(`${apiBaseURL}/audience/locales`)
  }

  static setTargetAudience(campId, params) {
    return axios.post(`${apiBaseURL}/demographic-screening/${campId}/set-target-audience`, {params})
  }

  static resetTargetAudience(campId) {
    return axios.post(`${apiBaseURL}/demographic-screening/${campId}/reset-target-audience`)
  }

  static getETracRapport(videoId, params) {
    return axios.post(`${apiBaseURL}/campaign-videos/${videoId}/e-trac-rapport`, {params})
  }

  static getTabScore(videoId, params) {
    return axios.post(`${apiBaseURL}/campaign-videos/${videoId}/tab-score`, {params})
  }

  static getSentimentPDFReport(videoId, token, data) {
    return axios.post(
      `${apiBaseURL}/campaign-videos/${videoId}/sentiment-pdf-report?token=${token}`,
      data,
      {
        responseType: 'arraybuffer',
      },
    )
  }

  static getValuationPDFReport(videoId, token) {
    return axios.get(
      `${apiBaseURL}/campaign-videos/${videoId}/demand-valuation-report?token=${token}`,
      {
        responseType: 'arraybuffer',
      },
    )
  }

  static getSentimentBenchmarks() {
    return axios.get(`${apiBaseURL}/sentiment-benchmarks`)
  }
}
