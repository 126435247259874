import VideoApi from '../video/api'

export default {
  convertVideo: async file => {
    const form = new FormData()
    form.append('video', file)
    const response = await VideoApi.convertVideo(form)

    const newFile = new File([response.data], `${file.name.split('.')[0]}.mp4`, {
      type: response.data.type,
    })

    return Object.assign(newFile, {
      blob: response.data,
      preview: URL.createObjectURL(response.data),
      videoFilename: `${file.name.split('.')[0]}.mp4`,
    })
  },
}
