import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
} from '@material-ui/core'

import {lucidGenderId} from '../../../constants/NewTest'

const styles = () => ({
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  controlLabel: {
    marginLeft: '-10px',
  },
  label: {
    fontSize: '16px',
    lineHeight: '15px',
  },
  radioButton: {
    padding: '8px',
  },
})

const GenderSelector = props => {
  const {classes, groupTempState, updateDemographic, labelStyles, genderDemographicTable} = props

  const getGenderState = () => {
    if (groupTempState.demographics) {
      const genderDemographic = groupTempState.demographics.find(
        ({questionId}) => questionId === lucidGenderId,
      )

      return genderDemographic.subDemographics
        .sort((a, b) => a.precode - b.precode)
        .map(({precode}) => precode)
        .join(',')
    }

    return ''
  }

  const handleChange = ({target}) => {
    updateDemographic(
      'gender',
      target.value.split(',').map(precode => genderDemographicTable[precode]),
    )
  }

  return (
    <FormControl>
      <Typography
        style={{
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '15px',
          marginBottom: '8px',
          ...labelStyles,
        }}
      >
        Gender
      </Typography>
      <RadioGroup className={classes.radioGroup} value={getGenderState()} onChange={handleChange}>
        <FormControlLabel
          classes={{root: classes.controlLabel, label: classes.label}}
          value="1,2"
          control={<Radio classes={{root: classes.radioButton}} color="primary" disableRipple />}
          label="Both"
        />
        <FormControlLabel
          classes={{root: classes.controlLabel, label: classes.label}}
          value="2"
          control={<Radio classes={{root: classes.radioButton}} color="primary" disableRipple />}
          label="Female"
        />
        <FormControlLabel
          classes={{root: classes.controlLabel, label: classes.label}}
          value="1"
          control={<Radio classes={{root: classes.radioButton}} color="primary" disableRipple />}
          label="Male"
        />
      </RadioGroup>
    </FormControl>
  )
}

GenderSelector.defaultProps = {
  labelStyles: {},
}

GenderSelector.propTypes = {
  groupTempState: PropTypes.objectOf(PropTypes.any).isRequired,
  updateDemographic: PropTypes.func.isRequired,
  labelStyles: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
}

export default withStyles(styles)(GenderSelector)
