import React, {useMemo, useState} from 'react'
import {Button, CircularProgress, Collapse, Typography, withStyles} from '@material-ui/core'
import _ from 'lodash'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import {connect} from 'react-redux'
import {compose} from 'redux'

import InfoTooltip from '../../common/InfoTooltip'
import InfoModal from '../../common/InfoModal'

import useModalState from '../../../hooks/useModalState'

import styles from '../styles/SummaryPanel.styles'
import utils from '../utils'

function SummaryPanels(props) {
  const {
    classes,
    targetAudiences,
    demographicLabelsTable,
    title,
    description,
    infoText,
    data,
    loading,
    benchmark,
  } = props

  const [isTooltipOpen, openTooltip, closeTooltip] = useModalState(false)
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [detailsModalOpen, openDetailsModal, closeDetailsModal] = useModalState(false)
  const [modalContent, setModalContent] = useState({})

  const allPanelistsResult = useMemo(
    () => data?.find(group => group.group === 'All Panelists') || [],
    [data],
  )

  const isValueValid = value => {
    return typeof value === 'number' && !Number.isNaN(value)
  }

  const formatValue = value => {
    if (!isValueValid(value)) {
      return '-'
    }
    return title === 'Story Index' ? `${Math.floor(value)}` : `${Math.floor(value)}%`
  }

  const getResultStyle = value => {
    if (!isValueValid(value)) return {color: '#131822'}

    if (value < benchmark - 5) return {color: '#EB5757'}

    return {color: '#219653'}
  }

  const getInfoToModal = (data, allPanelists = false) => {
    if (allPanelists) {
      const defaultGroups = data
        .filter(group => group.isDefault)
        .map(({group}) => group)
        .join(', ')
      const modalData = {
        ...data[0],
        subGroup: defaultGroups ? `(${defaultGroups})` : null,
        demographicsSelected: targetAudiences
          ? utils.getDemographicsFromGroups(targetAudiences)
          : data[0].demographicsSelected,
      }
      setModalContent(modalData)
    } else {
      setModalContent(data)
    }

    openDetailsModal()
  }

  const renderAllPanelistsCard = () => {
    return (
      <div className={classes.insideCard} style={{marginBottom: '16px'}}>
        <div style={{marginBottom: '16px', display: 'flex', alignItems: 'center'}}>
          <div className={classes.ellipse} />
          <span className={classes.groupName}>{allPanelistsResult.group}</span>
          <span>
            <Typography onClick={() => getInfoToModal(data, true)} className={classes.details}>
              Details
            </Typography>
          </span>
        </div>
        <div className={classes.infoContainer}>
          {benchmark && (
            <div>
              <div className={classes.valueLabels}>Benchmark</div>
              {title === 'Story Index' ? (
                <div className={classes.benchmark}>{benchmark}</div>
              ) : (
                <div className={classes.benchmark}>{benchmark}%</div>
              )}
            </div>
          )}
          <div>
            <div className={classes.valueLabels}>Score</div>
            <div className={classes.benchmark} style={getResultStyle(allPanelistsResult.total)}>
              {formatValue(allPanelistsResult.total)}
            </div>
          </div>
          <div>
            <div className={classes.valueLabels}>Total Count</div>
            <div className={classes.totalCount}>{allPanelistsResult.count}</div>
          </div>
        </div>
      </div>
    )
  }

  const renderCard = () => {
    let filteredData = data.filter(current => current.group !== 'All Panelists')
    const defaultFilteredData = filteredData.filter(current => current.isDefault)
    if (defaultFilteredData.length === 1) {
      filteredData = filteredData.filter(current => !current.isDefault)
    }
    return (
      <>
        {filteredData.map((data, index) => (
          <div className={classes.insideCard} key={index}>
            <div
              style={{
                marginBottom: '16px',
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <div
                className={classes.ellipse}
                style={{backgroundColor: data.isDefault ? '#017EFF' : '#F67D00'}}
              />
              <span className={classes.groupName}>{data.group}</span>
              <span>
                <Typography onClick={() => getInfoToModal(data)} className={classes.details}>
                  Details
                </Typography>
              </span>
            </div>
            <div className={classes.infoContainer}>
              {benchmark && (
                <div>
                  <div className={classes.valueLabels}>Benchmark</div>
                  {title === 'Story Index' ? (
                    <div className={classes.benchmark}>{benchmark}</div>
                  ) : (
                    <div className={classes.benchmark}>{benchmark}%</div>
                  )}
                </div>
              )}
              <div>
                <div className={classes.valueLabels}>Score</div>
                <div className={classes.benchmark} style={getResultStyle(data.total)}>
                  {formatValue(data.total)}
                </div>
              </div>
              <div>
                <div className={classes.valueLabels}>Total Count</div>
                <div className={classes.totalCount}>{data.count}</div>
              </div>
            </div>
          </div>
        ))}
      </>
    )
  }

  const getDemographicLabel = demographic => {
    if (demographicLabelsTable) {
      if (typeof demographic === 'string') {
        return demographicLabelsTable[demographic]
      }

      return demographic.displayName
    }

    return demographic
  }

  const renderModal = () => {
    return (
      <>
        {!_.isEmpty(modalContent) && (
          <InfoModal
            open={detailsModalOpen}
            onClose={closeDetailsModal}
            paperProps={{style: {width: '600px'}}}
          >
            <div style={{marginBottom: '20px'}}>
              <div className={classes.modalGroupName} style={{marginBottom: 0}}>
                <div className={classes.modalGroupNameBulletPoint} />
                {modalContent.group}
              </div>
              {modalContent.subGroup ? (
                <div
                  style={{
                    fontStyle: 'italic',
                    fontWeight: 400,
                    fontSize: '14px',
                    color: '#414347',
                  }}
                >
                  {modalContent.subGroup}
                </div>
              ) : null}
            </div>

            <div style={{overflowY: 'auto', maxHeight: '70%'}}>
              {Object.keys(modalContent.demographicsSelected).map((demographic, index) => (
                <React.Fragment key={index}>
                  <div className={classes.modalDemographic}>{getDemographicLabel(demographic)}</div>
                  <div className={classes.modalSubdemographicsContainer}>
                    {modalContent.demographicsSelected[demographic].map((subdemographic, index) => (
                      <div
                        style={{display: 'flex', alignItems: 'center', marginBottom: '32px'}}
                        key={index}
                      >
                        <div className={classes.modalSubdemographicsBulletPoint} />
                        <div className={classes.modalSubdemographics}>
                          {getDemographicLabel(subdemographic)}
                        </div>
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </InfoModal>
        )}
      </>
    )
  }

  return (
    <>
      {renderModal()}
      <div className={classes.container} style={{width: '100%'}}>
        <div
          className={classes.panelTitle}
          style={{position: 'relative', width: 'max-content'}}
          onMouseEnter={openTooltip}
          onMouseLeave={closeTooltip}
        >
          {title}
          <InfoTooltip open={isTooltipOpen} text={infoText} placement="right" />
        </div>
        <div className={classes.description}>{description}</div>
        {!data || loading ? (
          <div className={classes.spinnerContainer}>
            <CircularProgress className={classes.progress} />
          </div>
        ) : (
          <>
            {data && renderAllPanelistsCard()}
            {data && data.length > 1 && (
              <>
                {data && data.length !== 2 && (
                  <div style={{display: 'flex', justifyContent: 'center', marginBottom: '16px'}}>
                    <Button onClick={() => setIsCollapsed(!isCollapsed)}>
                      {!isCollapsed ? 'Show All Groups' : 'Hide All Groups'}
                      {!isCollapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                    </Button>
                  </div>
                )}

                <Collapse in={isCollapsed}>
                  <div className={classes.customGroups}>{renderCard()}</div>
                </Collapse>
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}

const mapStateToProps = ({campaign}) => ({
  targetAudiences: campaign.campaign.targetAudiences,
})

export default compose(connect(mapStateToProps), withStyles(styles))(SummaryPanels)
