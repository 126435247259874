import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {TextField, withStyles} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import useStateCallback from '../../hooks/useStateCallback'

import DemographicSelect from './DemographicSelect'

import utils from '../campaign/utils'

const styles = theme => ({
  select: {
    width: theme.spacing.unit * 20,
    height: theme.spacing.unit * 5,
    '& div': {
      width: '100%',
      height: '100%',
      padding: 0,
    },
  },
  icon: {
    marginRight: theme.spacing.unit,
    color: '#292d3299 !important',
  },
  outline: {
    borderColor: '#b0b1b31f !important',
  },
  selectBox: {
    width: theme.spacing.unit * 50,
    padding: theme.spacing.unit * 2,
    '& ul': {
      padding: 0,
    },
  },
})

const DemographicDropdownButton = props => {
  const [selectedGroups, setSelectedGroups] = useStateCallback({})
  const [selectedFilters, setSelectedFilters] = useStateCallback({})

  const {classes, getFilteredData, selectParents, selectChildren, groups} = props

  const filterDemographics = filters => {
    if (typeof getFilteredData === 'function') {
      getFilteredData(filters)
    }
  }

  const filterGroups = groups => {
    const names = []
    const filters = []

    Object.entries(groups).forEach(([name, values]) => {
      filters.push(utils.createGroupObject(values))
      names.push(name)
    })

    getFilteredData(filters, names)
  }

  const handleGroupChange = group => {
    if (selectedGroups[group.name]) {
      setSelectedGroups(prevState => _.omit(prevState, group.name), filterGroups)
    } else {
      setSelectedGroups(
        prevState => ({...prevState, [group.name]: group.demographics}),
        filterGroups,
      )
    }
  }

  const areAllGroupsSelected = () => {
    return groups.length === Object.keys(selectedGroups).length
  }

  const isGroupSelected = group => {
    return Boolean(selectedGroups[group.name])
  }

  const handleSelectAllGroupsChange = () => {
    const newSelectedGroups = {}

    if (areAllGroupsSelected()) {
      setSelectedGroups({}, filterGroups)
    } else {
      groups.forEach(group => {
        newSelectedGroups[group.name] = group.demographics
      })

      setSelectedGroups(newSelectedGroups, filterGroups)
    }
  }

  const handleSelectAllChange = () => {
    const newFilters = {}

    if (areAllSelected()) {
      setSelectedFilters({}, filterDemographics)
    } else {
      selectParents.forEach(parent => {
        newFilters[parent.label] = _.map(selectChildren[parent.label], 'label')
      })

      setSelectedFilters(newFilters, filterDemographics)
    }
  }

  const areAllSelected = () => {
    const filterKeys = Object.keys(selectedFilters)

    if (filterKeys.length !== selectParents.length) return false

    for (let i = 0; i < filterKeys.length; i++) {
      const parent = filterKeys[i]

      if (selectedFilters[parent].length !== selectChildren[parent].length) return false
    }

    return true
  }

  const handleParentChange = label => {
    const children = selectedFilters[label]

    if (children && children.length) {
      setSelectedFilters(_.omit(selectedFilters, label), filterDemographics)
    } else {
      setSelectedFilters(
        {
          ...selectedFilters,
          [label]: _.map(selectChildren[label], 'label'),
        },
        filterDemographics,
      )
    }
  }

  const isParentSelected = label => {
    if (!selectedFilters[label]) return false
    return selectedFilters[label].length === selectChildren[label].length
  }

  const handleChildChange = (parent, label) => {
    const children = selectedFilters[parent]

    if (isChildSelected(parent, label)) {
      const newChildren = _.pull(children, label)

      if (newChildren.length) {
        setSelectedFilters(
          {
            ...selectedFilters,
            [parent]: newChildren,
          },
          filterDemographics,
        )
      } else {
        setSelectedFilters(_.omit(selectedFilters, parent), filterDemographics)
      }
    } else {
      setSelectedFilters(
        {
          ...selectedFilters,
          [parent]: children ? _.concat(children, label) : [label],
        },
        filterDemographics,
      )
    }
  }

  const isChildSelected = (parent, label) => {
    const children = selectedFilters[parent]

    if (!children) return false
    return _.indexOf(children, label) >= 0
  }

  const selectedCount = Object.keys(selectedFilters).reduce(
    (acc, curr) => acc + selectedFilters[curr].length,
    0,
  )

  return (
    <TextField
      value=""
      select
      label={`Demographics${selectedCount ? ` (${selectedCount})` : ''}`}
      InputProps={{classes: {notchedOutline: classes.outline}}}
      variant="outlined"
      InputLabelProps={{
        shrink: false,
        style: {
          left: '45%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          color: '#292D32',
          width: '100%',
          textAlign: 'center',
        },
      }}
      SelectProps={{
        classes: {icon: classes.icon},
        className: classes.select,
        IconComponent: ExpandMoreIcon,
        MenuProps: {classes: {paper: classes.selectBox}},
        style: selectedCount ? {width: 186} : {},
      }}
    >
      <DemographicSelect
        selectParents={selectParents}
        selectChildren={selectChildren}
        groups={groups}
        handleGroupChange={handleGroupChange}
        handleSelectAllGroupsChange={handleSelectAllGroupsChange}
        areAllGroupsSelected={areAllGroupsSelected}
        isGroupSelected={isGroupSelected}
        handleSelectAllChange={handleSelectAllChange}
        areAllSelected={areAllSelected}
        handleParentChange={handleParentChange}
        isParentSelected={isParentSelected}
        handleChildChange={handleChildChange}
        isChildSelected={isChildSelected}
        disabledDemographics={!_.isEmpty(selectedGroups)}
        disabledGroups={!_.isEmpty(selectedFilters)}
      />
    </TextField>
  )
}

DemographicDropdownButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  getFilteredData: PropTypes.func.isRequired,
  selectChildren: PropTypes.objectOf(PropTypes.array).isRequired,
  selectParents: PropTypes.arrayOf(PropTypes.object).isRequired,
  groups: PropTypes.arrayOf(PropTypes.object),
}

DemographicDropdownButton.defaultProps = {
  groups: [],
}

export default React.memo(withStyles(styles)(DemographicDropdownButton))
