import React from 'react'
import {withStyles} from '@material-ui/core'

import {testTypes} from '../constants/NewTest'

const styles = () => ({
  benchmarkLabel: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    color: '#333333',
  },
  benchmarkExplained: {
    fontSize: '1.2rem',
    fontWeight: 700,
    marginBottom: '20px',
    color: '#F67D00',
  },
  benchmarkContainer: {
    backgroundColor: '#F6F9FC',
    padding: '16px',
    marginBottom: '24px',
  },
})

const BenchmarkExplained = ({classes, type, noTitle, ...rest}) => {
  const results = {
    [testTypes.legal]: {
      good: 'the audience is supportive',
      bad:
        'review your strategy by viewing the maps and optimize and re-test until benchmarks are achieved',
    },
    [testTypes.creative]: {
      good: 'proceed with advertising',
      bad: 'review creative moments, optimize and re-test until benchmarks are achieved',
    },
  }

  return (
    <div {...rest} className={classes.benchmarkContainer}>
      {!noTitle ? <div className={classes.benchmarkExplained}>Benchmark Explained</div> : null}
      <div
        className={classes.benchmarkLabel}
        style={{
          marginBottom: '8px',
          fontWeight: noTitle ? '300' : '600',
        }}
      >
        <span>Within 5 points of Benchmark -{results[type].good}</span>
      </div>
      <div
        className={classes.benchmarkLabel}
        style={{
          fontWeight: noTitle ? '300' : '600',
        }}
      >
        <span>Below 5 points of Benchmark -{results[type].bad}</span>
      </div>
    </div>
  )
}

export default withStyles(styles)(BenchmarkExplained)
