const styles = theme => ({
  rapportMapContainer: {
    padding: `0 ${theme.spacing.unit * 4} ${theme.spacing.unit * 4}`,
    flexDirection: 'column',
  },
  rapportMapPaper: {
    padding: `${theme.spacing.unit * 2} ${theme.spacing.unit * 4}`,
    paddingBottom: theme.spacing.unit * 4,
    width: '100%',
    overflow: 'hidden',
    '& svg': {
      overflow: 'visible', // chart fix
    },
  },
  paperHeaderTextContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  paperHeaderText: {
    minWidth: 211,
    fontWeight: '500',
    fontSize: '1.2rem',
    [theme.breakpoints.down(1230)]: {
      paddingBottom: theme.spacing.unit * 2,
    },
  },
  filterDetailsButton: {
    marginLeft: theme.spacing.unit,
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    fontSize: '1rem',
    cursor: 'pointer',
    display: 'inline-block',
  },
  paperButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    '& > *': {
      margin: `0 ${theme.spacing.unit / 2}`,
    },
  },
  activeFilters: {
    fontWeight: 400,
    textTransform: 'capitalize',
  },
  videoContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    background: 'black',
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  modalGroupName: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '33px',
    color: '#262C38',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
  },
  modalGroupSubtitle: {
    fontWeight: '400',
    fontStyle: 'italic',
    marginBottom: theme.spacing.unit * 2,
  },
  modalDemographic: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#131822',
    marginBottom: '24px',
    textTransform: 'capitalize',
  },
  modalSubdemographicsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    // '&:not(:last-child)': {
    //   marginBottom: theme.spacing.unit * 4,
    // },
  },
  modalSubdemographicsBulletPoint: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: '#2B93FF',
    marginRight: '8px',
  },
  modalGroupNameBulletPoint: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: '#F44336',
    marginRight: '12px',
  },
  modalSubdemographics: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#131822',
    marginRight: '24px',
    textTransform: 'capitalize',
  },
  modalSubdemographic: {
    marginBottom: theme.spacing.unit * 3,
    display: 'flex',
    alignItems: 'center',
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
  },
  clearResultsButton: {
    color: '#FFFFFF',
    backgroundColor: '#F44336',
    height: '40px',
    '&:hover': {
      backgroundColor: 'rgba(244, 67, 54, 0.87) !important',
    },
  },
})

export default styles
