export const colors = ['#219653', '#898989', '#017EFF', '#FF0099']

export const options = {
  chart: {
    toolbar: {
      tools: {
        download: false,
      },
    },
    fontFamily: 'Roboto',
    animations: {
      enabled: false,
    },
  },
  colors,
  plotOptions: {
    bar: {
      columnWidth: '70%',
      distributed: true,
      dataLabels: {
        position: 'center',
      },
    },
  },
  fill: {
    opacity: 1,
  },
  legend: {
    show: false,
  },
  tooltip: {
    enabled: false,
  },
  xaxis: {
    labels: {
      style: {
        colors,
        fontSize: '12px',
        fontWeight: 'bold',
      },
    },
  },
  yaxis: {
    min: 0,
    max: 100,
    tickAmount: 5,
  },
  dataLabels: {
    enabled: true,
    style: {
      colors: ['#fff'],
    },
  },
}
