import React from 'react'
import _ from 'lodash'
import FileSaver from 'file-saver'
import humps from 'humps'
import CampaignApi from './api'

import {testTypes} from '../constants/NewTest'

export const LUCID_USER_CODE = 6

export default {
  getStatus: campaign => {
    const fromDate = new Date(campaign.fromDate)
    const toDate = new Date(campaign.toDate)
    const today = new Date()
    if (fromDate > today) {
      return <span style={{color: '#047aba'}}>Pending</span>
    }
    if (fromDate <= today && today <= toDate) {
      return <span style={{color: '#ff8906'}}>Running</span>
    }
    if (toDate < today) {
      return <span style={{color: '#55bc24'}}>Complete</span>
    }
  },
  checkFirstTimeVisit: key => {
    const hasVisited = window.sessionStorage.getItem(key)

    if (!hasVisited) {
      window.sessionStorage.setItem(key, true)
      return true
    }

    return false
  },
  getQueryFromFilters: selectedFilters => {
    const validFields = []
    const validFilters = {}

    Object.keys(selectedFilters).forEach(field => {
      const current = selectedFilters[field]
      if (current.length) {
        validFields.push(field)
        validFilters[field] = current
      }
    })

    if (_.isEmpty(validFields) || _.isEmpty(validFilters)) return {}

    return {fields: validFields, ...validFilters}
  },
  createFilterText: (filterQuery, selectParents) => {
    const result = []

    for (let i = 0; i < selectParents.length; i++) {
      const current = filterQuery[selectParents[i].label]
      if (current) result.push(...current)
    }

    return result.length ? result.join(', ') : 'All Demographics'
  },
  getDemographicCount: demographics => {
    const count = Object.values(demographics).reduce((acc, curr) => acc + curr.length, 0)

    return count || 'All'
  },
  getFilterText: (count, text) => {
    return `(${count}) ${text}${count > 1 || count === 'All' ? 's' : ''}`
  },
  getDemographicsFromGroups: groups => {
    const generateAudienceGroupData = audienceGroup => {
      const groupData = {}

      audienceGroup.demographics.forEach(({key, subDemographics}) => {
        groupData[key] = [...subDemographics]
      })

      return groupData
    }

    const demographics = {}

    if (groups) {
      groups.forEach(audienceGroup => {
        const groupData = generateAudienceGroupData(audienceGroup)

        for (const demographic in groupData) {
          if (demographic in demographics) {
            demographics[demographic] = _.union(demographics[demographic], groupData[demographic])
          } else {
            demographics[demographic] = [...groupData[demographic]]
          }
        }
      })
      return demographics
    }

    return {}
  },
  getDemographicLabelsTable: (demographicsWithValues = {}) => {
    return Object.values(demographicsWithValues).reduce(
      (acc, curr) => ({
        ...acc,
        [curr.label]: curr.value,
        [humps.camelize(curr.label)]: curr.value,
      }),
      {},
    )
  },
  getSelectParentsAndChildren: (demographics, filteredFields = []) => {
    const parents = []
    const children = {}

    Object.values(demographics).forEach(demographic => {
      const lowercaseDemographic = {
        ...demographic,
        label: demographic.label.toLowerCase(),
        value: demographic.value,
      }
      if (lowercaseDemographic.level === 1 && !filteredFields.includes(lowercaseDemographic.label))
        parents.push(lowercaseDemographic)
      else if (children[lowercaseDemographic.parent])
        children[lowercaseDemographic.parent].push(lowercaseDemographic)
      else children[lowercaseDemographic.parent] = [lowercaseDemographic]
    })

    return [parents, children]
  },
  downloadSentimentPDFReport: async (videoId, token, sentiment, chartSrc) => {
    const response = await CampaignApi.getSentimentPDFReport(videoId, token, {sentiment, chartSrc})
    FileSaver.saveAs(
      new Blob([response], {type: 'application/pdf'}),
      `${sentiment}_report${videoId}.pdf`,
    )
  },
  downloadValuationReport: async (videoId, token) => {
    const response = await CampaignApi.getValuationPDFReport(videoId, token)
    FileSaver.saveAs(
      new Blob([response], {type: 'application/pdf'}),
      `demand_valuation_report${videoId}.pdf`,
    )
  },
  getPrevIndex: (current, length) => (current + length - 1) % length,
  getNextIndex: (current, length) => (current + length + 1) % length,
  isLucidUser: registeredFrom => registeredFrom === LUCID_USER_CODE,
  filterAudienceDemographics: (audienceDemographics, registeredFrom) => {
    if (registeredFrom === LUCID_USER_CODE) {
      return audienceDemographics.filter(demographic => demographic.questionId)
    }

    return audienceDemographics.filter(demographic => !demographic.questionId)
  },
  formatAudienceDemographics: audienceDemographics => {
    const demographicFields = {}

    audienceDemographics.forEach(
      ({demographic, displayName, precodes, questionId, options, questionText}, index) => {
        demographicFields[demographic] = {
          key: demographic,
          order: index,
          precodes,
          questionId,
          label: displayName,
          questionText,
          subDemographics: options,
        }
      },
    )

    return demographicFields
  },
  decamelizeAudienceDemographicKeys: audienceDemographics => {
    const demographicFields = {}

    Object.entries(audienceDemographics).forEach(([countryId, values]) => {
      Object.values(values).forEach(value => {
        if (!demographicFields[countryId]) {
          demographicFields[countryId] = {[value.key]: value}
        } else {
          demographicFields[countryId] = {
            ...demographicFields[countryId],
            [value.key]: value,
          }
        }
      })
    })

    return demographicFields
  },
  stringToSeconds: timeString => {
    if (!timeString) return 0

    const [minutes, seconds] = timeString.split(':')

    return Number(minutes) * 60 + Number(seconds)
  },
  secondsToString: seconds => {
    if (!seconds) {
      return '00:00'
    }

    const date = new Date(seconds * 1000)
    return date.toISOString().match(/\d\d\:\d\d(?=\.)/)[0]
  },
  formatDate: (datetime, timeZone) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }

    if (!datetime) {
      return
    }
    if (!timeZone) {
      return new Date(datetime)
        .toLocaleString('en-US', {...options})
        .replace(':00', '')
        .replace(',', ' @')
    }
    return new Date(datetime)
      .toLocaleString('en-US', {
        timeZone,
        ...options,
      })
      .replace(':00', '')
      .replace(',', ' @')
  },
  formatValue: value => {
    if (!value) {
      return '$ 0.00'
    }

    return value
      .toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
      .replace('$', '$ ')
  },
  scrollToError: () => {
    setTimeout(() => {
      const errorElements = document.querySelectorAll(`*[errormessage="true"]`)
      if (errorElements) {
        for (let i = 0; i < errorElements.length; i++) {
          if (errorElements[i].innerText) {
            errorElements[i].scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'start',
            })
            return
          }
        }
      }
    }, 200)
  },
  isOldCampaign: campaign => {
    return !campaign.targetAudiences || !campaign.targetAudiences.length
  },
  isLegalCampaign: campaign => {
    return Number(campaign.campaignType) === Number(testTypes.legal)
  },
  isCampaignCompleted: campaign => {
    return campaign.completed
  },
  createGroupObject: group => {
    return group.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.key]: curr.subDemographics,
      }
    }, {})
  },
  mergeGroupObjects: groups => {
    return groups.reduce((acc, curr) => {
      return _.mergeWith(acc, curr, (objValue, srcValue) => {
        if (_.isArray(objValue)) {
          return _.union(srcValue, objValue)
        }
      })
    }, {})
  },
  getDemographicDetailsModalContent: (total, groups, customGroups) => {
    const data = [[total], groups, customGroups]
    const modalContent = []

    data.forEach(item => {
      if (item) {
        item.forEach(group => {
          modalContent.push({
            groupName: group.groupName,
            panelistCount: group.responseCount,
            groupDetails: group.groupDetails,
          })
        })
      }
    })

    return modalContent
  },
}
