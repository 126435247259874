import React from 'react'
import {withStyles} from '@material-ui/core'

const styles = () => ({
  blueBar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    width: '100%',
    height: '80px',
    fontSize: '12px',
    color: '#6F767E',
    marginTop: '80px',
  },
})

const Footer = ({classes}) => {
  return (
    <footer>
      <div className={classes.blueBar}>
        <div className={classes.copyright}>Copyright ©{2022} EmotionTrac</div>
        <div>All rights reserved.</div>
      </div>
    </footer>
  )
}

export default withStyles(styles)(Footer)
