export const tabCount = {
  xl: 3,
  lg: 3,
  md: 1,
  sm: 0,
}

export const steps = ['Setup & Schedule', 'Surveys', 'Select Audience', 'Order Summary']

export const stepIndexes = {
  testSetup: 0,
  survey: 1,
  audience: 2,
  order: 3,
}

export const choiceTypes = {
  multipleChoice: 0,
  openEnded: 1,
}

export const testTypes = {
  legal: '0',
  creative: '1',
}

export const testNameMaxLength = 58

export const minQuestionLength = 10

export const maxMoments = 8

export const defaultTimezone = 'America/New_York'

export const contextFields = {
  timezone: 'timezone',
  startDate: 'startDate',
  videoFile: 'videoFile',
  testName: 'testName',
  testType: 'testType',
  audienceGroups: 'audienceGroups',
  questions: 'questions',
  caseType: 'caseType',
  objectives: 'objectives',
  moments: 'moments',
  paymentProcessed: 'paymentProcessed',
  coreIssue: 'coreIssue',
}

export const costs = {
  test: 600,
  surveyQuestion: 2,
  premiumReport: 1200,
}

export const questionTypes = {
  0: {label: 'Multiple Choice Question', value: 'multiple-choice'},
  1: {label: 'Open Ended Answer', value: 'text'},
}

export const objectiveTypes = {
  survey: 0,
}

export const errorModalTexts = {
  invalidData: 'You have invalid data in your test. Please change before continuing!',
  unsavedData: 'You have unsaved changes. Please save your changes before continuing',
}

export const lucidGenderId = 43
