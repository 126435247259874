import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {FormControl, FormHelperText, InputLabel} from '@material-ui/core'
import ValidationUtils from '../utils/ValidationUtils'
import {ValidatorContext} from './ValidatedComponent'

class InputValidator extends React.Component {
  constructor() {
    super()

    this.validation = {
      isValid: true,
      message: '',
      inputState: 'input-empty',
    }

    this.state = {
      isValid: true,
      message: '',
      inputState: 'input-empty',
    }

    this.onBlurValidate = this.onBlurValidate.bind(this)
    this.getRequiredErrorMsg = this.getRequiredErrorMsg.bind(this)
    this.validate = this.validate.bind(this)
    this.setValidationState = this.setValidationState.bind(this)
    this.getValidationMessage = this.getValidationMessage.bind(this)
    this.isValid = this.isValid.bind(this)
  }

  getInput() {
    const {children} = this.props
    return children && !children.length ? children : null
  }

  componentDidMount() {
    const {registerValidator} = this.props

    if (registerValidator) {
      registerValidator({
        owner: this,
        message: this.props.message,
        validate: this.validate,
      })
    }
  }

  componentWillUnmount() {
    const {unregisterValidator} = this.props
    if (unregisterValidator) {
      unregisterValidator(this)
    }
  }

  setValidationState(message) {
    const state = {message, isValid: !message}
    this.validation = state
    this.setState(state)
  }

  getValidationMessage() {
    return _.cloneDeep(this.validation.message)
  }

  isValid() {
    return this.validation.isValid
  }

  validate() {
    const {children, fieldName, required, onValidated, customValidation} = this.props
    const input = this.getInput()
    let message

    if (input) {
      const {value, type, required} = input.props

      if (required && !value && value !== 0 && value !== false) {
        message = this.getRequiredErrorMsg()
      } else if (type === 'email') {
        message = ValidationUtils.checkEmail(value)
      } else if (type === 'url') {
        message = ValidationUtils.checkUrl(value)
      } else if (type === 'number') {
        const {min, max} = input.props
        if (min && Number(value) < Number(min)) {
          message = (fieldName || 'This field') + ' is less than ' + min
        }
        if (max && Number(value) > Number(max)) {
          message = (fieldName || 'This field') + ' is greater than ' + max
        }
      }
    }
    if (required && !children.props.value) {
      message = this.getRequiredErrorMsg()
    }

    if (!message && customValidation) {
      message = customValidation()
    }

    this.setValidationState(message)

    if (onValidated) {
      onValidated()
    }

    //toggles input border color
    if (message || !this.validation.isValid) {
      this.setState({inputState: 'input-error'})
    } else if (input && input.props.value) {
      this.setState({inputState: 'input-correct'})
    } else {
      this.setState({inputState: 'input-empty'})
    }
  }

  getRequiredErrorMsg() {
    return (this.props.fieldName || 'This field') + ' is required'
  }

  onBlurValidate() {
    this.validate()
  }

  render() {
    const {props, state} = this
    const isChildInput = !!this.getInput()
    const canShowLabel = this.props.showLabel && this.props.fieldName

    const children =
      isChildInput && props.shouldValidateOnBlur
        ? React.Children.map(props.children, child => {
            return React.cloneElement(child, {
              onBlur: this.onBlurValidate,
            })
          })
        : props.children

    const isValid = !(this.getErrorFromProps() || state.message)
    return (
      <FormControl
        error={!isValid}
        className={this.props.className}
        fullWidth={this.props.fullWidth}
      >
        {canShowLabel && <InputLabel>{this.props.label || this.props.fieldName}</InputLabel>}
        {children}
        {!isValid && (
          <FormHelperText errormessage="true">
            {this.getErrorFromProps() || state.message}
          </FormHelperText>
        )}
      </FormControl>
    )
  }

  getErrorFromProps() {
    const {errors} = this.props

    if (errors) {
      if (Array.isArray(errors)) {
        return errors.join(', ')
      }

      return errors.toString()
    }
  }
}

InputValidator.displayName = 'InputValidator'

InputValidator.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  customValidation: PropTypes.func,
  onValidated: PropTypes.func,
  fieldName: PropTypes.string,
  showLabel: PropTypes.bool,
  fullWidth: PropTypes.bool,
}

InputValidator.defaultProps = {
  showLabel: true,
  fullWidth: true,
}

InputValidator.contextTypes = {
  registerValidator: PropTypes.func,
  unregisterValidator: PropTypes.func,
}

export {InputValidator as InputValidatorCmp}

export default props => (
  <ValidatorContext.Consumer>
    {({registerValidator, unregisterValidator}) => (
      <InputValidator
        {...props}
        registerValidator={registerValidator}
        unregisterValidator={unregisterValidator}
      />
    )}
  </ValidatorContext.Consumer>
)
