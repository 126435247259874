const styles = theme => ({
  paper: {
    padding: `${theme.spacing.unit * 4}`,
    display: 'flex',
    flexDirection: 'row',
    gridTemplateColumns: '1.3fr 1fr',
    height: '90vh',
    width: '90%',
    maxWidth: '1276px',
  },
  leftSection: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid #BDBFC2',
    flexBasis: '60%',
  },
  rightSection: {
    marginLeft: '40px',
    flexBasis: '38%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 700,
    fontSize: '1.6rem',
  },
  subtitle: {
    color: theme.palette.surface[700],
    fontSize: '0.9rem',
    marginBottom: theme.spacing.unit * 2,
  },
  container: {
    marginBottom: theme.spacing.unit * 3,
  },
  quantitySelectorContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing.unit * 3,
  },
  genderSelectorContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  totalPanelists: {
    userSelect: 'none',
    position: 'relative',
    marginLeft: theme.spacing.unit,
  },
  nameInputContainer: {
    width: '50%',
    marginRight: theme.spacing.unit * 3,
  },
  inputLabel: {
    marginBottom: theme.spacing.unit * 2,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '15px',
  },
  nameInput: {
    padding: '14px',
  },
  nameLength: {
    top: 'calc(100% + 4px)',
    right: 0,
    position: 'absolute',
    fontSize: '0.75rem',
    color: theme.palette.surface[600],
  },
  costContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing.unit * 3,
  },
  costLabel: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '23px',
  },
  cost: {
    fontWeight: 300,
    fontSize: '24px',
    lineHeight: '28px',
  },
  buttonsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '16px',
  },
  feasibilityError: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
    fontSize: '16px',
    lineHeight: '18.75px',
    color: '#F44336',
  },
})

export default styles
