import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {Grid, Typography, withStyles} from '@material-ui/core'

import useModalState from '../../hooks/useModalState'

import VerticalChart from './VerticalChart'
import NoDataAvailable from '../common/NoDataAvailable'
import InfoTooltip from '../common/InfoTooltip'

import styles from './styles/VerticalChart.styles'

const Verticalchartgroup = ({classes, benchmarks, xaxis, yaxis, Chart}) => {
  const [isTrustTooltipOpen, openTrustTooltip, closeTrustTooltip] = useModalState(false)
  const [isAttentionTooltipOpen, openAttentionTooltip, closeAttentionTooltip] = useModalState(false)
  const [
    isBelievabilityTooltipOpen,
    openBelievabilityTooltip,
    closeBelievabilityTooltip,
  ] = useModalState(false)

  if (_.isEmpty(xaxis) || _.isEmpty(yaxis)) {
    return (
      <Grid container className={classes.chartsContainer}>
        <NoDataAvailable />
      </Grid>
    )
  }

  return (
    <Grid container className={classes.chartsContainer}>
      <Grid item xs={6} lg={4}>
        <Grid container justify="center">
          <Grid container className={classes.textContainer}>
            <Typography className={classes.chartTitle}>Trust</Typography>
            <Typography
              className={classes.chartBenchmark}
              onMouseEnter={openTrustTooltip}
              onMouseLeave={closeTrustTooltip}
            >
              {`Benchmark = ${benchmarks.trust}`}
              <InfoTooltip
                open={isTrustTooltipOpen}
                style={{fontSize: '14px'}}
                text=""
                // text="-- ask client for text --"
              />
            </Typography>
            <Typography className={classes.chartDescription}>
              How trusting the audience finds the content.
            </Typography>
          </Grid>
          <VerticalChart xaxis={xaxis.trust} yaxis={yaxis.trust} Chart={Chart} />
        </Grid>
      </Grid>
      <Grid item xs={6} lg={4}>
        <Grid container justify="center">
          <Grid container className={classes.textContainer}>
            <Typography className={classes.chartTitle}>Attention</Typography>
            <Typography
              className={classes.chartBenchmark}
              onMouseEnter={openAttentionTooltip}
              onMouseLeave={closeAttentionTooltip}
            >
              {`Benchmark = ${benchmarks.attention}`}
              <InfoTooltip
                open={isAttentionTooltipOpen}
                style={{fontSize: '14px'}}
                text=""
                // text="-- ask client for text --"
              />
            </Typography>
            <Typography className={classes.chartDescription}>
              How much attention the audience had with the content.
            </Typography>
          </Grid>
          <VerticalChart xaxis={xaxis.attention} yaxis={yaxis.attention} Chart={Chart} />
        </Grid>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Grid container justify="center">
          <Grid container className={classes.textContainer}>
            <Typography className={classes.chartTitle}>Believability</Typography>
            <Typography
              className={classes.chartBenchmark}
              onMouseEnter={openBelievabilityTooltip}
              onMouseLeave={closeBelievabilityTooltip}
            >
              {`Benchmark = ${benchmarks.believability}`}
              <InfoTooltip
                open={isBelievabilityTooltipOpen}
                style={{fontSize: '14px'}}
                text=""
                // text="-- ask client for text --"
              />
            </Typography>
            <Typography className={classes.chartDescription}>
              How believable the audience found the content.
            </Typography>
          </Grid>
          <VerticalChart xaxis={xaxis.believability} yaxis={yaxis.believability} Chart={Chart} />
        </Grid>
      </Grid>
    </Grid>
  )
}

Verticalchartgroup.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  benchmarks: PropTypes.objectOf(PropTypes.number).isRequired,
  xaxis: PropTypes.objectOf(PropTypes.array).isRequired,
  yaxis: PropTypes.objectOf(PropTypes.array).isRequired,
  Chart: PropTypes.func.isRequired,
}

export default withStyles(styles)(Verticalchartgroup)
