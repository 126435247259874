import React, {useState} from 'react'
import {Link} from 'react-router'
import {withStyles} from '@material-ui/core/styles'
import {
  Button,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import Videocam from '@material-ui/icons/Videocam'
import ReactPaginate from 'react-paginate'
import {connect} from 'react-redux'
import {compose} from 'redux'

import useModalState from '../../hooks/useModalState'

import TutorialVideoButton from './Tutorials/TutorialVideoButton'
import InfoModal from '../common/InfoModal'

import {testTypes} from '../constants/NewTest'

import searchIcon from '../../assets/icons/search.svg'
import circleNext from '../../assets/icons/circleNext.svg'
import circleBack from '../../assets/icons/circleBack.svg'

import styles from './styles/NewTableData.styles'

import WelcomeModal from './WelcomeModal'

const StyledTableRow = withStyles({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#EFEFEF',
    },
  },
})(TableRow)

const NewTableData = ({
  classes,
  data,
  count,
  getStatus,
  changePage,
  page,
  rowsPerPage,
  caseTypes,
  // getOutcome,
  userId,
}) => {
  const [videoButtonsModalOpen, openVideoButtonsModal, closeVideoButtonsModal] = useModalState(
    false,
  )
  const [videoModalOpen, openVideoModal, closeVideoModal] = useModalState(false)
  const [videoModalURL, setVideoModalURL] = useState('')
  const [videoModalTitle, setVideoModalTitle] = useState('')

  const getPageLabel = (page, count, rowsPerPage) => {
    const isFirstPage = page === 0
    const isLastPage = page === Math.floor(count / rowsPerPage)
    const minItem = isFirstPage ? 1 : page * rowsPerPage + 1
    const maxItem = isLastPage ? count : (page + 1) * rowsPerPage

    return `Showing ${minItem} to ${maxItem} of ${count}`
  }

  const getCampaignDetailLink = campaign => {
    let link = `/tests/${campaign.id}`

    if (campaign.campaignType === Number(testTypes.legal)) {
      link += '/tab-scores'
    } else {
      link += '/conclusions'
    }

    return link
  }

  const renderModals = () => {
    return (
      <>
        <InfoModal open={videoButtonsModalOpen} onClose={closeVideoButtonsModal}>
          <>
            <Typography variant="h5">Informational Videos</Typography>
            <div className={classes.tutorialButtons}>
              <TutorialVideoButton
                title="Testing Content with EmotionTrac"
                time="2:44"
                openVideoModal={() => {
                  openVideoModal()
                  setVideoModalURL(
                    'https://player.vimeo.com/video/764341009?h=3595257795&app_id=122963',
                  )
                  setVideoModalTitle('Testing Content with EmotionTrac')
                }}
                width="100%"
              />
              <TutorialVideoButton
                title="Setting Up Your Test"
                time="3:46"
                openVideoModal={() => {
                  openVideoModal()
                  setVideoModalURL(
                    'https://player.vimeo.com/video/764340482?h=f4474afd88&app_id=122963',
                  )
                  setVideoModalTitle('Setting Up Your Test')
                }}
                width="100%"
              />
              <TutorialVideoButton
                title="Navigating Test Results"
                time="5:14"
                openVideoModal={() => {
                  openVideoModal()
                  setVideoModalURL(
                    'https://player.vimeo.com/video/764339750?h=ce1b682cd8&app_id=122963',
                  )
                  setVideoModalTitle('Navigating Test Results')
                }}
                width="100%"
              />
            </div>
          </>
        </InfoModal>
        <InfoModal open={videoModalOpen} onClose={closeVideoModal}>
          <>
            <Typography variant="h4" style={{marginBottom: 0}}>
              {videoModalTitle}
            </Typography>
            <iframe
              width="750"
              height="480"
              src={videoModalURL}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </>
        </InfoModal>
        {!hasCreatedTest(data, userId) && <WelcomeModal />}
      </>
    )
  }

  const handleTypeTest = campaign => {
    if (campaign.campaignType === 1) {
      return 'Creative'
    }
    if (campaign.campaignType === 0 && caseTypes.results.length > 0 && campaign.caseType) {
      const caseType = caseTypes.results.find(caseType => caseType.id === campaign.caseType)
      return caseType ? caseType.name : 'Legal'
    }
    return 'Legal'
  }

  const hasCreatedTest = (testList, userId) => {
    return testList.some(test => test.user === userId)
  }

  return (
    <div className={classes.root}>
      {renderModals()}
      <div className={classes.myCampaigns}>
        <div>My Tests</div>
        <Paper className={classes.searchInput}>
          <InputBase className={classes.input} placeholder="Search Tests" />
          <IconButton className={classes.iconButton} aria-label="Search">
            <img className={classes.icon} src={searchIcon} alt="search" />
          </IconButton>
        </Paper>
      </div>
      <div className={classes.tableContainer}>
        <div className={classes.allTestsHeader}>
          <span style={{marginLeft: 18}}>All Tests</span>
          <div>
            <Button className={classes.tutorials} onClick={openVideoButtonsModal}>
              <Videocam className={classes.rightIcon} />
              <span>Tutorials</span>
            </Button>
          </div>
        </div>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.tableRowHeader}>
                <TableCell align="left" className={classes.tableHeader}>
                  Test ID
                </TableCell>
                <TableCell align="left" className={classes.tableHeader}>
                  Test Name
                </TableCell>
                <TableCell align="left" className={classes.tableHeader}>
                  Test Type
                </TableCell>
                <TableCell align="left" className={classes.tableHeader}>
                  Test Date
                </TableCell>
                <TableCell align="left" className={classes.tableHeader}>
                  Status
                </TableCell>
                <TableCell align="left" className={classes.tableHeader}>
                  Details
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(campaign => {
                const time = new Date(campaign.fromDate).toLocaleTimeString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                })
                const date = new Date(campaign.fromDate).toLocaleDateString('en-US')
                return (
                  <StyledTableRow key={campaign.id} className={classes.row}>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography
                        className={classes.testId}
                        component={Link}
                        to={getCampaignDetailLink(campaign)}
                      >
                        #{campaign.id}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography
                        className={classes.testName}
                        component={Link}
                        to={getCampaignDetailLink(campaign)}
                      >
                        {campaign.title.length > 30
                          ? `${campaign.title.substring(0, 25)}...`
                          : campaign.title}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <span>{handleTypeTest(campaign)}</span>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <span style={{width: '130px'}}>
                        {date} {time}
                      </span>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <span>{getStatus(campaign)}</span>
                    </TableCell>
                    <TableCell align="left">
                      {!campaign.isPublished ? (
                        <span style={{color: '#828282', fontWeight: 500}}>Test Processing</span>
                      ) : (
                        <Button
                          component={Link}
                          to={getCampaignDetailLink(campaign)}
                          className={classes.datailsButton}
                        >
                          View Results
                        </Button>
                      )}
                    </TableCell>
                  </StyledTableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
        <Grid container>
          <Grid item style={{alignSelf: 'center', marginLeft: '24px'}}>
            <div style={{fontSize: '13px', color: 'rgba(54, 56, 83, 0.6)'}}>
              {getPageLabel(page, count, rowsPerPage)}
            </div>
          </Grid>
          <Grid item xs />
          <Grid item>
            <ReactPaginate
              breakLabel="..."
              pageCount={Math.ceil(count / rowsPerPage)}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              nextLabel={<img src={circleNext} alt="next" />}
              previousLabel={<img src={circleBack} alt="back" />}
              containerClassName={classes.paginationContainer}
              pageClassName={classes.page}
              previousClassName={
                page === 0 ? classes.buttonNextAndBackDisabled : classes.buttonNextAndBack
              }
              nextClassName={
                page === Math.floor(count / rowsPerPage)
                  ? classes.buttonNextAndBackDisabled
                  : classes.buttonNextAndBack
              }
              breakClassName={classes.break}
              activeClassName={classes.active}
              onPageChange={page => {
                changePage(null, page.selected)
              }}
              forcePage={page}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

const mapStateToProps = ({campaign, profile}) => ({
  caseTypes: campaign.caseTypes,
  userId: profile?.profile?.id,
})

export default compose(connect(mapStateToProps), withStyles(styles))(NewTableData)
