import React from 'react'
import PropTypes from 'prop-types'

const LineChartTooltip = ({video, dataPointIndex}) => {
  // avoiding ssr issues
  const tooltipStyles = {
    background: 'black',
    color: 'white',
    height: 110,
    width: 200,
    zIndex: 999,
  }

  const imageStyles = {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
  }

  let backgroundPosition

  if (video.sequenceDimension.height > 200) {
    const seqPosition = dataPointIndex % 60
    backgroundPosition = `-${Math.floor(seqPosition) * 200}px -${200 *
      Math.floor(dataPointIndex / 60) +
      45}px`
  } else {
    backgroundPosition = `-${dataPointIndex * 200}px`
  }

  return (
    <div style={tooltipStyles}>
      <div
        style={{
          ...imageStyles,
          backgroundPosition,
          backgroundImage: `url(${video.sequence.fullSize})`,
        }}
      />
    </div>
  )
}

LineChartTooltip.propTypes = {
  video: PropTypes.objectOf(PropTypes.any).isRequired,
  dataPointIndex: PropTypes.number.isRequired,
}

export default LineChartTooltip
