import * as Types from './actions.types'

export function login(email, password) {
  return {type: Types.LOGIN, email, password}
}

export function autoLogin(id, token) {
  return {type: Types.AUTO_LOGIN, id, token}
}

export function socialLoginOAuth1Step1(provider, redirectUri) {
  return {type: Types.SOCIAL_LOGIN_OAUTH1_STEP1, provider, redirectUri}
}

export function socialLoginOAuth1Step1Success(payload) {
  return {type: Types.SOCIAL_LOGIN_OAUTH1_STEP1_SUCCESS, ...payload}
}

export function socialLoginOAuth1Step2(payload) {
  return {type: Types.SOCIAL_LOGIN_OAUTH1_STEP2, payload}
}

export function socialLoginOAuth2(provider, code, redirectUri, email) {
  return {type: Types.SOCIAL_LOGIN_OAUTH2, provider, code, redirectUri, email}
}

export function loginSuccess(token) {
  return {type: Types.LOGIN_SUCCESS, token}
}

export function loginFailure(error) {
  return {type: Types.LOGIN_FAILURE, error}
}

export function logout() {
  return {type: Types.LOGOUT}
}
