import React from 'react'
import {Input, Button, Typography, Grid, Divider, withStyles} from '@material-ui/core'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import InputValidator from '../common/InputValidator.jsx'
import ValidatedComponent from '../common/ValidatedComponent.jsx'
import ChangeEmail from './ChangeEmail.jsx'
import Loading from '../common/Loading.jsx'
import * as ChangeEmailActions from './redux/actions.changeEmail'
import * as ProfileActions from './redux/actions'

const styles = theme => ({
  container: {
    padding: theme.spacing.unit * 3,
  },
})

class ProfileSecurity extends React.Component {
  render() {
    const {currentPassword, newPassword, confirmNewPassword} = this.props.passwordEdit
    const {
      profile,
      isSaving,
      passwordEditErrors,
      errors,
      updateChangePassword,
      changeEmail,
      cancelChangeEmail,
      resendConfirmEmail,
      resendVerifyEmail,
      classes,
    } = this.props

    return (
      <Grid container spacing={32} className={classes.container}>
        <Grid item xs={12}>
          {isSaving && <Loading />}
        </Grid>

        <Grid item xs={12}>
          <Typography component="h3" variant="h3" gutterBottom>
            Change Password
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <InputValidator fieldName="Current password" errors={passwordEditErrors.currentPassword}>
            <Input
              type="password"
              value={currentPassword}
              required
              placeholder="Current password"
              onChange={e => updateChangePassword({currentPassword: e.target.value})}
            />
          </InputValidator>
        </Grid>

        <Grid item xs={12}>
          <InputValidator
            fieldName="New password"
            errors={passwordEditErrors.newPassword}
            customValidation={() => {
              return this.validatePasswords()
            }}
          >
            <Input
              type="password"
              value={newPassword}
              required
              placeholder="New password"
              onChange={e => updateChangePassword({newPassword: e.target.value})}
            />
          </InputValidator>
        </Grid>

        <Grid item xs={12}>
          <InputValidator
            fieldName="Confirm new password"
            customValidation={() => {
              return this.validatePasswords()
            }}
          >
            <Input
              type="password"
              value={confirmNewPassword}
              required
              placeholder="Confirm new password"
              onChange={e => updateChangePassword({confirmNewPassword: e.target.value})}
            />
          </InputValidator>
        </Grid>

        <Grid item xs={12}>
          <Button onClick={this.onChangePasswordClick.bind(this)} variant="contained">
            Change password
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <ChangeEmail
          email={profile.email}
          savedNewEmail={profile.newEmail}
          isNewEmailConfirmed={profile.isNewEmailConfirmed}
          errors={errors}
          onChangeEmail={changeEmail.bind(this)}
          onResendConfirmationEmail={resendConfirmEmail.bind(this)}
          onResendVerificationEmail={resendVerifyEmail.bind(this)}
          onCancelChangeEmail={cancelChangeEmail.bind(this)}
        />
      </Grid>
    )
  }

  onChangePasswordClick() {
    const {
      passwordEdit: {currentPassword, newPassword},
      isComponentValid,
      changePassword,
    } = this.props

    if (isComponentValid()) {
      changePassword(currentPassword, newPassword)
    }
  }

  validatePasswords() {
    const {newPassword, confirmNewPassword} = this.props.passwordEdit
    if (newPassword !== confirmNewPassword) {
      return 'Both passwords must match'
    }
  }
}

function mapStateToProps(state) {
  return {...state.profile}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...ChangeEmailActions,
      ...ProfileActions,
    },
    dispatch,
  )
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  ValidatedComponent,
)(ProfileSecurity)
