import {useEffect, useRef} from 'react'

const useUpdateEffect = (callback, deps) => {
  const didMount = useRef(false)

  useEffect(() => {
    if (!didMount.current) didMount.current = true
    else callback()
  }, deps)
}

export default useUpdateEffect
