import React from 'react'
import PropTypes from 'prop-types'
import {Grid} from '@material-ui/core'

import Gauge from './Gauge'
import NoDataAvailable from '../common/NoDataAvailable'

const CompareGroup = ({audienceResults, benchmarks, scores}) => {
  if (!audienceResults || !scores.length) {
    return <NoDataAvailable />
  }

  return (
    <>
      <Grid item xs={6} lg={4}>
        <Gauge
          title="Trust"
          audienceResult={audienceResults.trust}
          benchmark={benchmarks.trust}
          score={scores[0]}
        />
      </Grid>
      <Grid item xs={6} lg={4}>
        <Gauge
          title="Attention"
          audienceResult={audienceResults.attention}
          benchmark={benchmarks.attention}
          score={scores[1]}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <Gauge
          title="Believability"
          audienceResult={audienceResults.believability}
          benchmark={benchmarks.believability}
          score={scores[2]}
        />
      </Grid>
    </>
  )
}

CompareGroup.propTypes = {
  audienceResults: PropTypes.objectOf(PropTypes.string).isRequired,
  benchmarks: PropTypes.objectOf(PropTypes.number).isRequired,
  scores: PropTypes.arrayOf(PropTypes.number).isRequired,
}

export default CompareGroup
