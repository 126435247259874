export const styles = theme => ({
  countrySelectorContainer: {
    marginBottom: theme.spacing.unit * 3,
  },
  label: {
    fontSize: '1rem',
    fontWeight: 500,
    marginBottom: theme.spacing.unit * 2,
  },
})

export const selectStyles = {
  control: (baseStyles, state) => {
    return {
      ...baseStyles,
      position: 'relative',
      padding: '8px 4px !important',
      color: '#989898',
      boxShadow: 'none',
      borderColor: '#c0c0c0',
      '&:hover': {
        boxShadow: 'none',
        borderColor: '#87888A',
      },
      ...(state.menuIsOpen && {
        boxShadow: 'none',
        borderColor: '#87888A',
        borderBottomColor: 'transparent',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        '&:hover, &:focus-within, &:focus, &:active': {
          boxShadow: 'none',
          borderColor: '#87888A',
          borderBottomColor: 'transparent',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          width: '90%',
          height: '1px',
          backgroundColor: '#E6E8EC',
          zIndex: 2,
        },
      }),
    }
  },
  menu: baseStyles => ({
    ...baseStyles,
    border: '1px solid #87888A',
    borderRadius: 0,
    boxShadow: 'none',
    borderTopColor: 'transparent',
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    marginTop: '-2px',
    overflow: 'hidden',
    '& > div': {
      paddingBottom: 0,
    },
  }),
  input: baseStyles => ({
    ...baseStyles,
    '& > div': {
      margin: 0,
      padding: 0,
    },
    '& input': {
      fontSize: '16px !important',
      fontFamily: 'Roboto !important',
      color: '#5C5E63 !important',
    },
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: '#000000de',
    backgroundColor: state.isSelected ? '#EBF5FF !important' : baseStyles.backgroundColor,
    whiteSpace: 'pre-wrap',
    lineHeight: 1.3,
    padding: '16px',
    '&:hover, &:focus': {
      backgroundColor: '#EBF5FF !important',
    },
  }),
}
