import React, {useState} from 'react'
import PropTypes from 'prop-types'
import * as htmlToImage from 'html-to-image'
import _ from 'lodash'

import {Button, CircularProgress, withStyles, Typography, Dialog} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'

import exportIcon from '../../assets/icons/export.svg'

const styles = theme => ({
  button: {
    height: theme.spacing.unit * 5,
    border: '2px solid #b0b1b31f',
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    position: 'relative',
    '& span': {
      fontSize: '1rem',
      fontWeight: 400,
      color: '#292D32',
      zIndex: 1,
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: 4,
      width: 'calc(100% - 5px)',
      height: 'calc(100% - 5px)',
      background: '#b0b1b31f',
    },
  },
  icon: {
    width: 15,
    marginRight: theme.spacing.unit,
    zIndex: 1,
  },
  invalidDataModalBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 4}px`,
    borderRadius: theme.spacing.unit,
  },
  errorModalHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: `0 0 ${theme.spacing.unit * 2}px`,
  },
  errorModalIcon: {
    fontSize: '27px',
    marginRight: '4px',
    color: theme.palette.secondary.main,
  },
  errorModalHeader: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '27px',
    color: theme.palette.grey[800],
  },
  errorModalText: {
    width: '35ch',
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '1.2em',
    color: theme.palette.grey[600],
  },
  errorModalButton: {
    marginTop: theme.spacing.unit * 3,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 3}px`,
    fontSize: '1.2rem',
  },
})

const ExportSnapshotButton = ({
  classes,
  targetId,
  exportName,
  targetTmpStyle,
  widthOffset,
  heightOffset,
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const showErrorFeedback = () => {
    if (error && !_.isEmpty(error)) {
      return (
        <>
          <div className={classes.errorModalHeaderContainer}>
            <ErrorIcon className={classes.errorModalIcon} />
            <Typography className={classes.errorModalHeader} variant="body1">
              Export failed!
            </Typography>
          </div>
          <Typography className={classes.errorModalText} variant="body1">
            An error occurred while trying to export the chart.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.errorModalButton}
            onClick={() => setError(false)}
          >
            Try again later
          </Button>
        </>
      )
    }

    return <></>
  }

  const getButtonProps = () => {
    return {
      onClick: async () => {
        setLoading(true)
        const node = document.getElementById(targetId)
        try {
          const dataUrl = await htmlToImage.toPng(node, {
            backgroundColor: '#fff',
            style: targetTmpStyle,
            width: widthOffset ? node.offsetWidth + widthOffset : null,
            height: heightOffset ? node.offsetHeight + heightOffset : null,
          })
          const a = document.createElement('a')
          a.href = dataUrl
          a.download = `${exportName}.png`
          a.click()
        } catch (error) {
          console.error('oops, something went wrong!', error)
          setError(error.message)
        }
        setLoading(false)
      },
    }
  }

  return (
    <div>
      <Button className={classes.button} style={{whiteSpace: 'nowrap'}} {...getButtonProps()}>
        {loading ? (
          <CircularProgress size="15" className={classes.icon} />
        ) : (
          <img className={classes.icon} src={exportIcon} alt="export" />
        )}
        Export PNG
      </Button>
      <Dialog PaperProps={{classes: {root: classes.invalidDataModalBody}}} open={error}>
        {showErrorFeedback()}
      </Dialog>
    </div>
  )
}

ExportSnapshotButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  targetId: PropTypes.string.isRequired,
  exportName: PropTypes.string,
  targetTmpStyle: PropTypes.objectOf(PropTypes.string),
  widthOffset: PropTypes.number,
  heightOffset: PropTypes.number,
}

ExportSnapshotButton.defaultProps = {
  exportName: 'export-png',
  targetTmpStyle: {padding: '20px'},
  widthOffset: 40,
  heightOffset: 0,
}

export default withStyles(styles)(ExportSnapshotButton)
