import React, {useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {
  ExpansionPanelDetails,
  Grid,
  IconButton,
  InputBase,
  ListItem,
  Paper,
  Tab,
  Tabs,
  Typography,
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelSummary as MuiExpansionPanelSummary,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import InfoModal from '../../common/InfoModal.jsx'
import TutorialVideoCard from './TutorialVideoCard.jsx'
import SentimentsTutorial from './SentimentsTutorial.jsx'

import {videoModalKey} from '../../constants/ModalKeys.js'
import {tutorialVideos, tutorialDocumentation} from './constants.js'
import searchIcon from '../../../assets/icons/search.svg'
import styles from '../styles/Tutorials.styles.js'

const ExpansionPanel = withStyles({
  root: {
    backgroundColor: 'transparent',
    border: 0,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  expanded: {
    margin: 'auto',
  },
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'transparent',
    borderBottom: 0,
    padding: 0,
  },
  expanded: {},
})(props => <MuiExpansionPanelSummary {...props} />)

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary'

const Tutorials = ({classes}) => {
  const [tabValue, setTabValue] = useState(0)
  const [expanded, setExpanded] = useState('rapportMap')
  const [selected, setSelected] = useState('rapport')
  const [videoModalOpen, setVideoModalOpen] = useState(false)
  const [videoModalURL, setVideoModalURL] = useState('')
  const [videoModalTitle, setVideoModalTitle] = useState('')

  const modalTable = {
    [videoModalKey]: value => setVideoModalOpen(value),
  }

  const openModal = modalKey => {
    modalTable[modalKey](true)
  }

  const closeModal = modalKey => {
    modalTable[modalKey](false)
  }

  const renderModals = () => {
    return (
      <>
        <InfoModal open={videoModalOpen} onClose={() => closeModal(videoModalKey)}>
          <>
            <Typography variant="h4" style={{marginBottom: 0, maxWidth: '30ch'}}>
              {videoModalTitle}
            </Typography>
            <iframe
              width="750"
              height="480"
              src={videoModalURL}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </>
        </InfoModal>
      </>
    )
  }

  const handleChange = (event, value) => {
    setTabValue(value)
  }

  const handleChangeAccordion = panel => {
    setExpanded(expanded === panel ? '' : panel)
  }

  return (
    <div className={classes.root}>
      {renderModals()}
      <div className={classes.myCampaigns}>
        <div>Tutorials</div>
        <Paper className={classes.searchInput}>
          <InputBase className={classes.input} placeholder="Search tutorials" />
          <IconButton className={classes.iconButton} aria-label="Search">
            <img className={classes.icon} src={searchIcon} alt="search" />
          </IconButton>
        </Paper>
      </div>
      <div>
        <div>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
            variant="scrollable"
            scrollButtons="off"
            indicatorColor="primary"
          >
            <Tab
              label="Video Tutorials"
              disableRipple
              classes={{root: classes.tabRoot, selected: classes.tabSelected}}
              style={{marginRight: '24px'}}
            />
            <Tab
              label="Documentation"
              disableRipple
              classes={{root: classes.tabRoot, selected: classes.tabSelected}}
            />
          </Tabs>
        </div>
        <div
          style={{height: '2px', width: '100%', backgroundColor: '#D6D8DC', marginTop: '-2px'}}
        />
        {tabValue === 0 && (
          <Grid container spacing={16} style={{marginTop: '24px'}}>
            {tutorialVideos.map(e => (
              <TutorialVideoCard
                title={e.title}
                time={e.time}
                videoThumb={e.videoThumb}
                openVideoModal={() => {
                  openModal(videoModalKey)
                  setVideoModalURL(e.videoUrl)
                  setVideoModalTitle(e.title)
                }}
              />
            ))}
          </Grid>
        )}
        {tabValue === 1 && (
          <div style={{display: 'flex', marginTop: '32px'}}>
            <div style={{width: '245px', paddingRight: '20px', flex: 1}}>
              {tutorialDocumentation.map(e => (
                <ExpansionPanel
                  expanded={expanded === e.slug}
                  onChange={() => handleChangeAccordion(e.slug)}
                >
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <img src={e.icon} alt={e.title} />
                    <Typography style={{marginLeft: '12px', fontWeight: 500, fontSize: '16px'}}>
                      {e.title}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                    style={{display: 'flex', flexDirection: 'column', gap: '8px', padding: 0}}
                  >
                    {e.subMenu.map(item => (
                      <ListItem
                        button
                        onClick={() => setSelected(item.sentiment)}
                        style={{
                          backgroundColor: selected === item.sentiment ? '#0769CE' : 'initial',
                          color: selected === item.sentiment ? '#FFF' : 'initial',
                          padding: '8px 32px',
                          borderRadius: '2px',
                        }}
                      >
                        {item.title}
                      </ListItem>
                    ))}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
            </div>
            <div style={{borderLeft: '2px solid #D6D8DC', width: 'calc(100% - 250px)'}}>
              <SentimentsTutorial sentiment={selected} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(Tutorials)
