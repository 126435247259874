import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Grid,
  Typography,
  withStyles,
  OutlinedInput,
  FormControl,
  FormHelperText,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'

import DemographicSelect from './DemographicSelect'

import {
  getEngagementWithDemographics,
  getEmotionsWithDemographics,
  getDefaultSurveyResults,
} from '../campaign/redux/actions'

import utils from '../campaign/utils'

import styles from './styles/CompareDemographics.styles'

const initialState = {
  group1: {},
  group2: {},
  group3: {},
}

const CompareDemographicsModalSummary = props => {
  const {
    classes,
    selectParents,
    selectChildren,
    open,
    onClose,
    defaultGroups,
    getEngagementWithDemographics,
    getEmotionsWithDemographics,
    getDefaultSurveyResults,
    campaignVideos,
    isComparing,
    setIsComparing,
    focusTimeTrackFilter,
    campaign,
  } = props

  const [groupStates, setGroupStates] = useState(initialState)

  const [group2Visible, setGroup2Visible] = useState(false)
  const [group3Visible, setGroup3Visible] = useState(false)

  const [group1NameError, setGroup1NameError] = useState('')
  const [group2NameError, setGroup2NameError] = useState('')
  const [group3NameError, setGroup3NameError] = useState('')

  const group1Key = 'group1'
  const group2Key = 'group2'
  const group3Key = 'group3'

  useEffect(() => {
    if (!isComparing) {
      setGroupStates(initialState)
    }
  }, [isComparing])

  useEffect(() => {
    if (isComparing) handleClick()
  }, [focusTimeTrackFilter])

  const handleGroupStates = (groupKey, value) => {
    setGroupStates(prevState => ({...prevState, [groupKey]: value}))
  }

  const handleSelectAllChange = groupKey => {
    const newFilters = {}

    if (areAllSelected(groupKey)) {
      handleGroupStates(groupKey, {})
    } else {
      selectParents.forEach(parent => {
        newFilters[parent.label] = _.map(selectChildren[parent.label], 'label')
      })

      handleGroupStates(groupKey, newFilters)
    }
  }

  const areAllSelected = groupKey => {
    const selectedFilters = groupStates[groupKey]
    const filterKeys = Object.keys(selectedFilters)

    if (filterKeys.length !== selectParents.length) return false

    for (let i = 0; i < filterKeys.length; i++) {
      const parent = filterKeys[i]

      if (selectedFilters[parent].length !== selectChildren[parent].length) return false
    }

    return true
  }

  const handleParentChange = (groupKey, label) => {
    const selectedFilters = groupStates[groupKey]
    const children = selectedFilters[label]

    handleGroupStates(groupKey, {
      ...groupStates[groupKey],
      [label]: children && children.length ? [] : _.map(selectChildren[label], 'label'),
    })
  }

  const isParentSelected = (groupKey, label) => {
    const selectedFilters = groupStates[groupKey]

    if (!selectedFilters[label]) return false
    return selectedFilters[label].length === selectChildren[label].length
  }

  const handleChildChange = (groupKey, parent, label) => {
    const selectedFilters = groupStates[groupKey]
    const children = selectedFilters[parent]

    if (isChildSelected(groupKey, parent, label)) {
      handleGroupStates(groupKey, {
        ...groupStates[groupKey],
        [parent]: _.pull(children, label),
      })
    } else {
      handleGroupStates(groupKey, {
        ...groupStates[groupKey],
        [parent]: children ? _.concat(children, label) : [label],
      })
    }
  }

  const isChildSelected = (groupKey, parent, label) => {
    const selectedFilters = groupStates[groupKey]
    const children = selectedFilters[parent]

    if (!children) return false
    return _.indexOf(children, label) >= 0
  }

  const handleGroup2Close = () => {
    setGroup2Visible(false)
    handleGroupStates()(group2Key, {})
  }

  const handleGroup3Close = () => {
    setGroup3Visible(false)
    handleGroupStates()(group3Key, {})
  }

  const generateQueries = () => {
    const groupArray = [groupStates.group1]

    if (group2Visible) groupArray.push(groupStates.group2)
    if (group3Visible) groupArray.push(groupStates.group3)

    return groupArray.map(currentGroup => {
      const {group, ...rest} = currentGroup
      return {...utils.getQueryFromFilters(rest), group}
    })
  }

  const validateGroupName = (name, isVisible, setError) => {
    if (!name && isVisible) {
      setError("Group name can't be empty")
      return false
    }
    return true
  }

  const validateGroups = () => {
    const isGroup1Valid = validateGroupName(groupStates[group1Key].group, true, setGroup1NameError)
    const isGroup2Valid = validateGroupName(
      groupStates[group2Key].group,
      group2Visible,
      setGroup2NameError,
    )
    const isGroup3Valid = validateGroupName(
      groupStates[group3Key].group,
      group3Visible,
      setGroup3NameError,
    )
    const areGroupsValid = isGroup1Valid && isGroup2Valid && isGroup3Valid

    if (!areGroupsValid) {
      utils.scrollToError()
    }

    return areGroupsValid
  }

  const handleClick = () => {
    if (campaignVideos && validateGroups()) {
      setIsComparing(true)

      const {campaignVideoId} = campaignVideos[0]
      const {id} = campaign

      const queries = generateQueries()
      queries.unshift(...defaultGroups)

      const defaultSurveyQuery = queries.map(query => ({
        ...query,
        type: 'creative',
      }))

      getEngagementWithDemographics(campaignVideoId, queries)
      getEmotionsWithDemographics(campaignVideoId, queries)
      getDefaultSurveyResults(id, defaultSurveyQuery)
      onClose()
    }
  }

  const handleGroupToShow = () => {
    if (group2Visible === false) {
      setGroup2Visible(true)
      return
    }
    setGroup3Visible(true)
  }

  const handleGroupNameChange = (groupKey, value, setError) => {
    handleGroupStates(groupKey, {
      ...groupStates[groupKey],
      group: value,
    })
    setError('')
  }

  return (
    <Dialog open={open} onClose={onClose} classes={{paper: classes.dialogPaper}}>
      <Grid container className={classes.dialogHeader}>
        <Grid>
          <Typography variant="h6">Compare Demographics</Typography>
          <Typography>
            Create customized groups from your total audience to compare results.
          </Typography>
        </Grid>
        <Grid item xs />
        <Button
          onClick={() => handleGroupToShow()}
          className={classes.dialogButton}
          variant="outlined"
          color="primary"
          disabled={group3Visible && group2Visible}
          disableRipple
        >
          <AddIcon fontSize="small" className={classes.buttonIcon} />
          New Group
        </Button>
      </Grid>
      <DialogContent className={classes.dialogContent}>
        <Grid container>
          <Grid item xs={4}>
            <Typography className={classes.groupTitle}>Group Name</Typography>
            <FormControl error={group1NameError} style={{width: '90%'}}>
              <OutlinedInput
                placeholder="Type Group Name"
                labelWidth={0}
                value={groupStates[group1Key].group}
                onChange={({target}) =>
                  handleGroupNameChange(group1Key, target.value, setGroup1NameError)
                }
              />

              <FormHelperText errormessage="true">{group1NameError}</FormHelperText>
            </FormControl>
            <DemographicSelect
              selectParents={selectParents}
              selectChildren={selectChildren}
              handleSelectAllChange={() => handleSelectAllChange(group1Key)}
              areAllSelected={() => areAllSelected(group1Key)}
              handleParentChange={label => handleParentChange(group1Key, label)}
              isParentSelected={label => isParentSelected(group1Key, label)}
              handleChildChange={(parent, label) => handleChildChange(group1Key, parent, label)}
              isChildSelected={(parent, label) => isChildSelected(group1Key, parent, label)}
            />
          </Grid>
          {group2Visible && (
            <Grid item xs={4} style={{position: 'relative'}}>
              <IconButton
                size="small"
                color="secondary"
                className={classes.closeGroupButton}
                aria-label="remove"
                disableRipple
                onClick={handleGroup2Close}
              >
                <CloseIcon />
              </IconButton>
              <Typography className={classes.groupTitle}>Group Name</Typography>
              <FormControl error={group2NameError} style={{width: '90%'}}>
                <OutlinedInput
                  placeholder="Type Group Name"
                  labelWidth={0}
                  value={groupStates[group2Key].group}
                  onChange={({target}) =>
                    handleGroupNameChange(group2Key, target.value, setGroup2NameError)
                  }
                />

                <FormHelperText errormessage="true">{group2NameError}</FormHelperText>
              </FormControl>
              <DemographicSelect
                selectParents={selectParents}
                selectChildren={selectChildren}
                handleSelectAllChange={() => handleSelectAllChange(group2Key)}
                areAllSelected={() => areAllSelected(group2Key)}
                handleParentChange={label => handleParentChange(group2Key, label)}
                isParentSelected={label => isParentSelected(group2Key, label)}
                handleChildChange={(parent, label) => handleChildChange(group2Key, parent, label)}
                isChildSelected={(parent, label) => isChildSelected(group2Key, parent, label)}
              />
            </Grid>
          )}

          {group3Visible && (
            <Grid item xs={4} style={{position: 'relative'}}>
              <IconButton
                size="small"
                color="secondary"
                className={classes.closeGroupButton}
                aria-label="remove"
                disableRipple
                onClick={handleGroup3Close}
              >
                <CloseIcon />
              </IconButton>
              <Typography className={classes.groupTitle}>Group Name</Typography>
              <FormControl error={group3NameError} style={{width: '90%'}}>
                <OutlinedInput
                  placeholder="Type Group Name"
                  labelWidth={0}
                  value={groupStates[group3Key].group}
                  onChange={({target}) =>
                    handleGroupNameChange(group3Key, target.value, setGroup3NameError)
                  }
                />

                <FormHelperText errormessage="true">{group3NameError}</FormHelperText>
              </FormControl>
              <DemographicSelect
                selectParents={selectParents}
                selectChildren={selectChildren}
                handleSelectAllChange={() => handleSelectAllChange(group3Key)}
                areAllSelected={() => areAllSelected(group3Key)}
                handleParentChange={label => handleParentChange(group3Key, label)}
                isParentSelected={label => isParentSelected(group3Key, label)}
                handleChildChange={(parent, label) => handleChildChange(group3Key, parent, label)}
                isChildSelected={(parent, label) => isChildSelected(group3Key, parent, label)}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.dialogButton}
          variant="outlined"
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          className={classes.dialogButton}
          variant="contained"
          color="primary"
          onClick={handleClick}
        >
          Run Comparison
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CompareDemographicsModalSummary.propTypes = {
  campaignVideos: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isComparing: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectChildren: PropTypes.objectOf(PropTypes.array).isRequired,
  selectParents: PropTypes.arrayOf(PropTypes.object).isRequired,
  setIsComparing: PropTypes.func.isRequired,
  defaultGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
}

CompareDemographicsModalSummary.defaultProps = {
  campaignVideos: [],
}

const mapStateToProps = ({campaign}) => ({
  campaign: campaign.campaign,
  campaignVideos: campaign.campaign.campaignVideos,
  focusTimeTrackFilter: campaign.focusTimeTrackFilter,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getEngagementWithDemographics,
      getEmotionsWithDemographics,
      getDefaultSurveyResults,
    },
    dispatch,
  )

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(CompareDemographicsModalSummary)
